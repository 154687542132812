import React, { useState, useEffect } from "react";
import * as _ from "lodash";
import { useGetOrganizationQuery } from "../../../../api/organization";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import CreateEditOrganization from "./CreateEditOrganization";
import DeleteModal from "../../../../components/delete/DeleteModal";
import EditIcon from "../../../../assets/images/action/editIcon.svg";
import DeleteIcon from "../../../../assets/images/action/deleteIcon.svg";
import PluseIcon from "../../../../assets/images/action/plusIcon.svg";
import WhitePlus from "../../../../assets/images/action/white-plus.svg";
import MainLayout from "../../../../components/layouts/MainLayout";
import DataNotFound from "../../../../components/shared/DataNotFound/DataNotFound";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const Organization = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [organizationData, setOrganizationData] = useState([]);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteOrganizationId, setDeleteOrganizationId] = useState("");
    const [organizationDetail, setOrganizationDetail] = useState("");

    const { data: organization, refetch } = useGetOrganizationQuery();

    useEffect(() => {
        if (organization?.data?.status === "success") {
            return;
        } else {
            toast.error(organization?.error?.data?.message, { autoClose: false });
        }
        if (organization) {
            setOrganizationData(organization?.data);
        } else {
            setOrganizationData([]);
        }
    }, [organization, refetch]);

    const rows =
        organizationData &&
        _.map(organizationData, (item, index) => {
            return {
                no: index + 1,
                id: _.get(item, "organizationId", ""),
                station: _.get(item, "station", ""),
                subscriptionDate: _.get(item, "subscriptionDate", ""),
                logo: _.get(item, "logo", ""),
                expireDate: _.get(item, "expireDate", ""),
                status: _.get(item, "status", ""),
                name: _.get(item, "name", ""),
                email: _.get(item, "email", ""),
                contactNumber: _.get(item, "contactNumber", ""),
                contactName: _.get(item, "contactName", ""),
            };
        });
    const columns = [
        {
            field: "no",
            headerName: "No",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <span>No</span>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.no}</span>
                    </div>
                );
            },
            width: "lg" ? 80 : "sm" && 20,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "action",
            headerName: "Action",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <span>Action</span>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="d-flex align-items-center justify-content-around w-100">
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowEditForm(true);
                                setOrganizationDetail(params.row);
                            }}
                        >
                            <img src={EditIcon} alt={"EditIcon"} height={"20px"} width={"20px"} />
                        </div>
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowDeleteModal(true);
                                setDeleteOrganizationId(params.row.id);
                            }}
                        >
                            <img src={DeleteIcon} alt={"DeleteIcon"} height={"20px"} width={"20px"} />
                        </div>
                    </div>
                );
            },
            width: "lg" ? 80 : "sm" && 20,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "station",
            headerName: "Station",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <span>City/Station</span>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.station || "-"}</span>
                    </div>
                );
            },
            width: 300,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "subscriptionDate",
            headerName: "SubscriptionDate",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <span>Subscription Date</span>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.subscriptionDate || "-"}</span>
                    </div>
                );
            },
            width: 200,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "expireDate",
            headerName: "ExpireDate",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <span>Expire Date</span>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.expireDate || "-"}</span>
                    </div>
                );
            },
            width: 200,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "status",
            headerName: "Status",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <span>Status</span>
                </div>
            ),
            renderCell: (params) => {
                let statusText, statusColor;

                // Set text and color based on the 'status' field
                if (params.row.status === "green") {
                    statusText = "Active";
                    statusColor = "success";
                } else if (params.row.status === "yellow") {
                    statusText = "Active";
                    statusColor = "warning";
                } else if (params.row.status === "red") {
                    statusText = "Inactive";
                    statusColor = "danger";
                }
                return (
                    <div className={`fs-item text-${statusColor}`}>
                        <span>{statusText}</span>
                    </div>
                );
            },
            width: 100,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "name",
            headerName: "Name",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <span>Name</span>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.name || "-"}</span>
                    </div>
                );
            },
            width: 200,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "email",
            headerName: "Email",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <span>Email</span>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.email || "-"}</span>
                    </div>
                );
            },
            width: 200,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },

        {
            field: "contactNumber",
            headerName: "ContactNumber",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <span>Contact Number</span>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.contactNumber || "-"}</span>
                    </div>
                );
            },
            width: 200,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "contactName",
            headerName: "ContactName",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <span>Contact Name</span>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.contactName || "-"}</span>
                    </div>
                );
            },
            width: 200,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
    ];

    const handleRowClick = (params) => {
        const organizationId = params.row?.id;
        const station = params.row?.station;
        const organizationDetail = params.row;
        navigate(`/organization/profile/${organizationId}/${station}`);
    };

    return (
        <div>
            <MainLayout>
                <div className="dataList-table">
                    {!_.isEmpty(organizationData) ? (
                        <DataGrid
                            className="my-4"
                            onRowClick={handleRowClick}
                            rows={rows}
                            columns={columns}
                            autoHeight
                            hideFooterPagination={true}
                            rowHeight={40}
                            rowSelection={false}
                        />
                    ) : (
                        <DataNotFound texts={"Organization"} />
                    )}
                </div>
                <div className="btn-add d-none d-md-block d-lg-block" onClick={() => setShowCreateForm(true)}>
                    <button className="btn-custom">
                        <img alt="WhitePlus" src={WhitePlus} className="me-3" />
                        Add
                    </button>
                </div>
                <div className="customAdd d-block d-sm-block d-md-none">
                    <div className="add" onClick={() => setShowCreateForm(true)}>
                        <img src={PluseIcon} alt={"PluseIcon"} height={"50px"} width={"50px"} />
                    </div>
                </div>
            </MainLayout>
            {showCreateForm && <CreateEditOrganization show={showCreateForm} onHide={() => setShowCreateForm(false)} refecthCreateEditOrganization={refetch} />}
            {showEditForm && (
                <CreateEditOrganization
                    organizationDetail={organizationDetail}
                    showEditForm={showEditForm}
                    show={showEditForm}
                    onHide={() => setShowEditForm(false)}
                    refecthCreateEditOrganization={refetch}
                />
            )}
            {showDeleteModal && (
                <DeleteModal
                    deleteId={deleteOrganizationId}
                    organization={showDeleteModal}
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    refetch={refetch}
                />
            )}
        </div>
    );
};

export default Organization;
