import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import Form from "react-bootstrap/Form";
import MainLayout from "../../../../../components/layouts/MainLayout";
import DeleteModal from "../../../../../components/delete/DeleteModal";
import { useGetTdgLibraryQuery, useUpdateTdgPublishMutation } from "../../../../../api/firegroundTasks";
import { useGetAllDataMutation, useSyncDataMutation } from "../../../../../api/importData";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../../../utils/constants/variables/routes";
import { DataGrid } from "@mui/x-data-grid";
import "./style.scss";
import Elips from "../../../../../assets/images/action/elips.svg";
import PluseIcon from "../../../../../assets/images/action/plusIcon.svg";
import EditIcon from "../../../../../assets/images/action/editIcon.svg";
import DeleteIcon from "../../../../../assets/images/action/deleteIcon.svg";
import RightArrow from "../../../../../assets/images/action/right-arrow.svg";
import WhitePlus from "../../../../../assets/images/action/white-plus.svg";
import bulletIcon from "../../../../../assets/images/action/bulletIcon.svg";
import DataNotFound from "../../../../../components/shared/DataNotFound/DataNotFound";
import ImportFiregroundTasks from "./ImportFiregroundTasks";
import { useDispatch } from "react-redux";
import { setTdgLibrary } from "../../../../../redux/firegroundTasksSlice";
import { defaultValues } from "../../../../../utils/constants/forms/defaultValues";
import { API } from "../../../../../utils/constants/variables/apiFields";
import { BiImport } from "react-icons/bi";
import { LiaSyncAltSolid } from "react-icons/lia";

const FiregroundTasks = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { responseType, incidentType, assignmentType } = params;
    const [responseTypeName, responseTypeId] = responseType?.split(/[=]+/);
    const [incidentTypeName, incidentTypeId] = incidentType?.split(/[=]+/);
    console.log("assignmentType***=======", assignmentType);
    const [assignmentName, assignmentId] = assignmentType?.split(/[=]+/);
    console.log("assignmentName******=======", assignmentName);
    const originalDataId = location.state?.originalDataId;

    const [userRole, setUserRole] = useState("");
    const [tdgLibraryData, setTdgLibraryData] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showImportModal, setShowImportModal] = useState(false);
    const [importTdgLibraryData, setImportTdgLibraryData] = useState([]);
    const [deleteTdgLibraryId, setDeleteTdgLibraryId] = useState("");
    const [type, setType] = useState("tdgLibraries");

    const { data: tdgLibrary, refetch } = useGetTdgLibraryQuery(assignmentId);
    const [updatePublish] = useUpdateTdgPublishMutation();
    const [importTdgLibrary] = useGetAllDataMutation();
    const [suyncData] = useSyncDataMutation();

    const form = useForm({
        defaultValues: defaultValues.FIREGROUND_TASKS,
    });
    const { control } = form;

    useEffect(() => {
        const getUserRole = localStorage.getItem("userRole");
        if (getUserRole) {
            setUserRole(getUserRole);
        }
    }, []);

    useEffect(() => {
        refetch();
        if (tdgLibrary?.data?.status === "success") {
            return;
        } else {
            toast.error(tdgLibrary?.error?.data?.message, { autoClose: false });
        }
        if (tdgLibrary) {
            setTdgLibraryData(tdgLibrary?.data);
            dispatch(setTdgLibrary(tdgLibrary?.data));
        } else {
            setTdgLibraryData([]);
        }
    }, [tdgLibrary, refetch]);

    const handleSyncData = async (currentDataId) => {
        const payload = { type, currentDataId };
        const response = await suyncData(payload);
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            refetch();
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };
    const handleImportData = async () => {
        const payload = { type, assignmentId };
        const response = await importTdgLibrary(payload);
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            setShowImportModal(true);
            setImportTdgLibraryData(response?.data?.data);
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    const rows =
        tdgLibraryData &&
        _.map(tdgLibraryData, (item) => {
            return {
                id: _.get(item, "tdgLibraryId", ""),
                assignmentId: _.get(item, "assignmentId", ""),
                responseTypeId: _.get(item, "responseTypeId", ""),
                incidentTypeId: _.get(item, "incidentTypeId", ""),
                originalDataId: _.get(item, "originalDataId", ""),
                audio: _.get(item, "audio", ""),
                name: _.get(item, "name", ""),
                goalObjective: _.get(item, "goalObjective", ""),
                image: _.get(item, "image", ""),
                missionBriefing: _.get(item, "missionBriefing", ""),
                text: _.get(item, "text", ""),
                targetAudience: _.get(item, "targetAudience", ""),
                bestPracticesTdg: _.get(item, "bestPracticesTdg", ""),
                publish: _.get(item, "publish", ""),
                isMatch: _.get(item, "isMatch", ""),
            };
        });

    const columns = [
        {
            field: "no",
            headerName: "#",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>#</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-center w-100">
                        <div>
                            <div>
                                <img src={bulletIcon} alt={"bulletIcon"} height={"20px"} width={"20px"} />
                            </div>
                            {params.row.isMatch === false && userRole == "organization" && (
                                <LiaSyncAltSolid
                                    className="mt-2 icon-sync"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleSyncData(params.row.id);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                );
            },
            width: "lg" ? 150 : "sm" && 20,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "publish",
            headerName: "Publish",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Publish</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                const tdgLibraryData = params.row;
                return (
                    <div
                        className="fs-item"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Controller
                            control={control}
                            name={API.FIREGROUND_TASKS.PUBLISH}
                            render={({ field: { value } }) => {
                                return (
                                    <Form.Check
                                        type="switch"
                                        id={`custom-switch-${tdgLibraryData.id}`}
                                        className="customSwitch p-0 m-0"
                                        defaultChecked={tdgLibraryData.publish}
                                        value={value}
                                        onChange={(e) => handlePublishChange(e, tdgLibraryData.id)}
                                        reverse
                                    />
                                );
                            }}
                        />
                    </div>
                );
            },
            width: "lg" ? 200 : "sm" && 20,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "action",
            headerName: "Action",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Action</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                const tdgLibraryDetail = params.row;
                return (
                    <div className="d-flex align-items-center justify-content-center gap-2 w-100">
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(
                                    `/gameFunnel/firegroundTasks/edit/${responseTypeName}=${responseTypeId}/${incidentTypeName}=${incidentTypeId}/${assignmentName}=${assignmentId}`,
                                    { state: tdgLibraryDetail }
                                );
                            }}
                        >
                            <img src={EditIcon} alt={"EditIcon"} height={"20px"} width={"20px"} />
                        </div>
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowDeleteModal(true);
                                setDeleteTdgLibraryId(tdgLibraryDetail?.id);
                            }}
                        >
                            <img src={DeleteIcon} alt={"DeleteIcon"} height={"20px"} width={"20px"} />
                        </div>
                    </div>
                );
            },
            width: "lg" ? 200 : "sm" && 20,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "name",
            headerName: "Name",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Name</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.name || "-"}</span>
                    </div>
                );
            },
            width: 250,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "goalObjective",
            headerName: "Goal / Objective",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Goal / Objective</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.goalObjective || "-"}</span>
                    </div>
                );
            },
            width: 250,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "bestPracticesTdg",
            headerName: "Best Practices",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Best Practices</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                const firstItem = params.row.bestPracticesTdg[0];
                const name = firstItem ? firstItem.name : "-";
                return (
                    <div className="fs-item">
                        <span>{name}</span>
                    </div>
                );
            },
            width: 250,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "missionBriefing",
            headerName: "Task Briefing",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Task Briefing</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.missionBriefing || "-"}</span>
                    </div>
                );
            },
            width: 250,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
    ];

    const handleRowClick = (params) => {
        console.log("params====", params);
        const tdgLibraryId = params.row?.id;
        const tdgLibraryName = params.row?.name;
        const originalDataId = params.row?.originalDataId;
        navigate(
            `/gameFunnel/tacticalDecisionGame/${responseTypeName}=${responseTypeId}/${incidentTypeName}=${incidentTypeId}/${assignmentName}=${assignmentId}/${tdgLibraryName}=${tdgLibraryId}`,
            { state: originalDataId }
        );
    };

    const handlePublishChange = async (e, id) => {
        const isChecked = e.target.checked;
        const stringId = id;
        const response = await updatePublish({ id: stringId, publish: isChecked });
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            refetch();
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    return (
        <div>
            <MainLayout className="me-0">
                <div className="row">
                    <div className="col-12 my-2">
                        <div className="bread-crumbs d-flex align-items-center">
                            <div className="d-flex align-items-center me-1">
                                <span className="text-breadcrumb me-1" onClick={() => navigate(ROUTES.GAMEFUNNEL.RESPONSE)}>
                                    {responseTypeName}
                                </span>
                                <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                            </div>
                            <div className="d-flex align-items-center me-1">
                                <span
                                    className="text-breadcrumb me-1"
                                    onClick={() => {
                                        navigate(`/gameFunnel/incidentTypes/${responseTypeName}=${responseTypeId}`);
                                    }}
                                >
                                    {incidentTypeName}
                                </span>
                                <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                            </div>
                            <div className="d-flex align-items-center me-1">
                                <span
                                    className="text-breadcrumb me-1"
                                    onClick={() => {
                                        navigate(`/gameFunnel/assignments/${responseTypeName}=${responseTypeId}/${incidentTypeName}=${incidentTypeId}`);
                                    }}
                                >
                                    {assignmentName}
                                </span>
                                <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 hr-bottom d-flex align-items-center justify-content-between mb-3">
                        <div>
                            <button className="btn-custom">Fireground Tasks</button>
                        </div>
                        {userRole == "organization" && originalDataId !== null && (
                            <div>
                                <button className="btn-import d-flex align-items-center" onClick={handleImportData}>
                                    <span className="me-2" style={{ fontWeight: "700" }}>
                                        <BiImport />
                                    </span>
                                    <span>Import</span>
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="dataList-table">
                        {!_.isEmpty(tdgLibraryData) ? (
                            <DataGrid
                                className="my-2 "
                                onRowClick={handleRowClick}
                                rows={rows}
                                columns={columns}
                                autoHeight
                                hideFooterPagination={true}
                                rowHeight={60}
                                rowSelection={false}
                            />
                        ) : (
                            <DataNotFound texts={"TDG"} />
                        )}
                    </div>
                </div>
                <div
                    className="btn-add d-none d-md-block d-lg-block"
                    onClick={() => {
                        navigate(
                            `/gameFunnel/firegroundTasks/create/${responseTypeName}=${responseTypeId}/${incidentTypeName}=${incidentTypeId}/${assignmentName}=${assignmentId}`
                        );
                    }}
                >
                    <button className="btn-custom">
                        <img alt="WhitePlus" src={WhitePlus} className="me-3" />
                        Add Fireground Tasks
                    </button>
                </div>
                <div className="customAdd d-block d-sm-block d-md-none">
                    <div
                        className="add"
                        onClick={() => {
                            navigate(
                                `/gameFunnel/firegroundTasks/create/${responseTypeName}=${responseTypeId}/${incidentTypeName}=${incidentTypeId}/${assignmentName}=${assignmentId}`
                            );
                        }}
                    >
                        <img src={PluseIcon} alt={"PluseIcon"} height={"50px"} width={"50px"} />
                    </div>
                </div>
            </MainLayout>

            {showDeleteModal && (
                <DeleteModal
                    deleteId={deleteTdgLibraryId}
                    tdgLibrary={showDeleteModal}
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    refetch={refetch}
                />
            )}
            {showImportModal && (
                <ImportFiregroundTasks
                    importTdgLibraryData={importTdgLibraryData}
                    showImportModal={showImportModal}
                    show={showImportModal}
                    assignmentId={assignmentId}
                    onHide={() => setShowImportModal(false)}
                    refetchImportData={refetch}
                />
            )}
        </div>
    );
};

export default FiregroundTasks;
