import { createSlice } from "@reduxjs/toolkit";

const actionKeySlice = createSlice({
    name: "actionKey",
    initialState: {
        actionKeyData: {},
    },
    reducers: {
        setActionKey: (state, action) => {
            state.actionKeyData = action.payload;
        },
    },
});

export const { setActionKey } = actionKeySlice.actions;
export default actionKeySlice.reducer;
