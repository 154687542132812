import React, { useEffect, useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import * as _ from "lodash";
import Select from "react-select";
// import "./style.scss";
import { useFieldArray, Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import InputGroup from "react-bootstrap/InputGroup";
import FileInput from "../../../../components/shared/FileInput/FileInput";
import MainLayout from "../../../../components/layouts/MainLayout";
import Elips from "../../../../assets/images/action/elips.svg";
import DeleteIcon from "../../../../assets/images/action/deleteIcon.svg";
import WhitePlus from "../../../../assets/images/action/white-plus.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { API } from "../../../../utils/constants/variables/apiFields";
import { defaultValues } from "../../../../utils/constants/forms/defaultValues";
import { validation } from "../../../../utils/constants/forms/validation";
import SelectGame from "../../../../components/shared/SelectOptions/SelectGame";
import SelectApparatus from "../../../../components/shared/SelectOptions/SelectApparatus";
import SelectResponse from "../../../../components/shared/SelectOptions/SelectResponse";
import SelectIncident from "../../../../components/shared/SelectOptions/SelectIncident";
import SelectAssignment from "../../../../components/shared/SelectOptions/SelectAssignment";
import { useCreateScenarioAssignmentMutation, useUpdateScenarioAssignmentMutation } from "../../../../api/scenarioAssignments";
import { SelectStyle } from "../../../../components/shared/Options/styles/SelectStyle";

const appendFormValuesToFormData = (formData, allData) => {
    for (const key in allData) {
        if (allData.hasOwnProperty(key)) {
            if (key.includes("image")) {
                formData.append("image", allData[key]);
            } else {
                formData.append(key, allData[key]);
            }
        }
    }
};

const CreateEditScenarioAssignments = () => {
    const [selectApparatusId, setSelectApparatusId] = useState([]);
    const [selectGameFromTDGId, setSelectGameFromTDGId] = useState([]);
    const [selectResponseTypeId, setSelectResponseTypeId] = useState([]);
    const [selectIncidentTypeId, setSelectIncidentTypeId] = useState([]);
    const [selectAssignmentId, setSelectAssignmentId] = useState([]);
    const [selectedAudio, setSelectedAudio] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState([]);
    const [selectedImage, setSelectedImage] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const [createScenarioAssignments] = useCreateScenarioAssignmentMutation();
    const [updateScenarioAssignments] = useUpdateScenarioAssignmentMutation();

    const location = useLocation();
    const navigate = useNavigate();
    const isInitialMount = useRef(true);

    const scenarioAssignmentDetail = location.state?.scenarioAssignmentDetail;
    const scenarioAssignmentId = scenarioAssignmentDetail?.id;
    const audio = scenarioAssignmentDetail?.audio;
    const video = scenarioAssignmentDetail?.video;

    const handleResponseChange = (e) => {
        setSelectResponseTypeId(e);
        setValue("responseTypeId", e);
        setSelectIncidentTypeId([]);
        setValue("incidentTypeId", null);
        setSelectAssignmentId([]);
        setValue("assignmentId", null);
    };

    const handleIncidentChange = (e) => {
        setSelectIncidentTypeId(e);
        setValue("incidentTypeId", e);
        setSelectAssignmentId([]);
        setValue("assignmentId", null);
    };

    const handleAssignmentChange = (e) => {
        setSelectAssignmentId(e);
        setValue("assignmentId", e);
    };

    const handleApparatusChange = (e) => {
        setSelectApparatusId(e);
        setValue("apparatusId", e);
    };
    const handleGameFromTDG = (e) => {
        setSelectGameFromTDGId(e);
        setValue("gameFromTDG", e);
    };

    const handleImportAudio = (e) => {
        const file = e.target.files[0];
        setSelectedAudio(file);
    };

    const handleImportVideo = (e) => {
        const file = e.target.files[0];
        setSelectedVideo(file);
    };

    const handleImportImage = (e) => {
        const file = Array.from(e.target.files);
        setSelectedImage([...selectedImage, ...file]);
    };

    const removeImage = (index) => {
        // Create a copy of selectedImages without the image at the specified index
        const updatedImages = selectedImage.filter((_, i) => i !== index);
        setSelectedImage(updatedImages);
    };

    const form = useForm({
        defaultValues: defaultValues.SCENARIO_ASSIGNMENTS,
        resolver: yupResolver(validation.SCENARIO_ASSIGNMENTS),
    });

    const {
        handleSubmit,
        formState: { errors },
        register,
        control,
        setValue,
        watch,
    } = form;
    const { fields, append, remove } = useFieldArray({
        control,
        name: "bestNames", // should match with your backend field name
    });
    const handleAdd = () => {
        append({});
    };
    const handleRemove = (index) => {
        remove(index);
    };
    const selectTargateAudiance = [
        { value: "fireFighter", label: "Fire fighter" },
        { value: "officer", label: "Officer" },
        { value: "chiefOfficer", label: "Chief officer" },
        { value: "combinations", label: "Combinations" },
    ];

    useEffect(() => {
        const imageArray = scenarioAssignmentDetail?.images || [];
        const imageFiles = [];
        const fetchImageBlob = async (imageUrl) => {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            return blob;
        };
        const loadImageFiles = async () => {
            for (const imageObj of imageArray) {
                const imageUrl = imageObj.image;
                const fileName = imageUrl.split("/").pop(); // Extracts the file name from the URL
                const blob = await fetchImageBlob(imageUrl);
                if (blob) {
                    const file = new File([blob], fileName, { type: "image/*" });
                    imageFiles.push(file);
                }
            }
            setSelectedImage(imageFiles);
        };
        loadImageFiles();
    }, []);

    useEffect(() => {
        if (scenarioAssignmentId && isInitialMount.current) {
            setValue("scenarioAssignmentId", scenarioAssignmentDetail?.id);
            setValue("responseTypeId", scenarioAssignmentDetail?.responseTypeId);
            setValue("incidentTypeId", scenarioAssignmentDetail?.incidentTypeId);
            setValue("assignmentId", scenarioAssignmentDetail?.assignmentId);
            setValue("apparatusId", scenarioAssignmentDetail?.apparatusId);
            setValue("audio", scenarioAssignmentDetail?.audio);
            setValue("video", scenarioAssignmentDetail?.video);
            setValue("gameFromTDG", scenarioAssignmentDetail?.gameFromTDG);
            setValue("gameInfo", scenarioAssignmentDetail?.gameInfo);
            setValue("goalObjective", scenarioAssignmentDetail?.goalObjective);
            setValue("missionBriefing", scenarioAssignmentDetail?.missionBriefing);
            setValue(API.SCENARIO_ASSIGNMENTS.TARGET_AUDIANCE, scenarioAssignmentDetail?.targetAudience || []);

            const bestPracticesTdg = scenarioAssignmentDetail?.bestPractice || [];

            bestPracticesTdg.forEach((item, index) => {
                bestPracticesTdg[`bestNames[${index}]`] = item.name;
                append({ name: item.name });
            });
            isInitialMount.current = false;
        }
    }, [setValue, scenarioAssignmentDetail, scenarioAssignmentId]);
    const onSubmit = async (data) => {
        const bestNamesFiltered = data.bestNames?.filter((item) => item.name !== null && item.name.trim() !== "");
        bestNamesFiltered?.forEach((item, index) => {
            data[`bestNames[${index}]`] = item.name;
        });

        delete data.bestNames; //getting also bestNames array in formattedData so this is for remove that extra array field
        const selectedImages = Array.isArray(selectedImage) && selectedImage;
        const image = selectedImages.map((item) => item);
        image.forEach((image, index) => {
            data[`image[${index}]`] = image;
        });

        const allData = {
            ...data,
            audio: selectedAudio,
            video: selectedVideo,
            targetAudience: JSON.stringify(data.targetAudience),
        };
        const formData = new FormData();
        const scenarioAssignmentId = scenarioAssignmentDetail?.id;
        appendFormValuesToFormData(formData, allData);
        const response = scenarioAssignmentId ? await updateScenarioAssignments({ formData, scenarioAssignmentId }) : await createScenarioAssignments(formData);

        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            navigate(`/scenarioAssignments`);
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    return (
        <MainLayout className="me-0">
            <div className="overflow_scroll">
                <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-4 col-lg-4 col-12 my-2">
                            <div className="mb-1 d-flex align-items-center">
                                <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                    Response type
                                </Form.Label>
                            </div>
                            <div>
                                <Controller
                                    control={control}
                                    name={`responseTypeId`}
                                    render={({ field: { onChange, value } }) => {
                                        return (
                                            <>
                                                <SelectResponse
                                                    style={SelectStyle}
                                                    isClearable={true}
                                                    defaultResponse={value}
                                                    handleChange={(selectedOption) => {
                                                        onChange(selectedOption || null);
                                                        handleResponseChange(selectedOption);
                                                    }}
                                                />
                                            </>
                                        );
                                    }}
                                />
                            </div>
                            <div className="error-msg">{errors?.responseTypeId && <span className="text-danger">{errors?.responseTypeId?.message}</span>}</div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-12 my-2">
                            <div className="mb-1 d-flex align-items-center">
                                <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                    Incident type
                                </Form.Label>
                            </div>
                            <div>
                                <Controller
                                    control={control}
                                    name={`incidentTypeId`}
                                    render={({ field: { onChange, value } }) => {
                                        return (
                                            <>
                                                <SelectIncident
                                                    style={SelectStyle}
                                                    isClearable={true}
                                                    defaultIncident={value}
                                                    handleChange={(selectedOption) => {
                                                        onChange(selectedOption || null);
                                                        handleIncidentChange(selectedOption);
                                                    }}
                                                    responseId={
                                                        selectResponseTypeId?.length > 0 ? selectResponseTypeId : scenarioAssignmentDetail?.responseTypeId
                                                    }
                                                />
                                            </>
                                        );
                                    }}
                                />
                            </div>
                            <div className="error-msg">{errors?.incidentTypeId && <span className="text-danger">{errors?.incidentTypeId?.message}</span>}</div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-12 my-2">
                            <div className="mb-1 d-flex align-items-center">
                                <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                    Assignments
                                </Form.Label>
                            </div>
                            <div>
                                <Controller
                                    control={control}
                                    name={`assignmentId`}
                                    render={({ field: { onChange, value } }) => {
                                        return (
                                            <>
                                                <SelectAssignment
                                                    style={SelectStyle}
                                                    isClearable={true}
                                                    defaultAssignment={value}
                                                    handleChange={(selectedOption) => {
                                                        onChange(selectedOption || null);
                                                        handleAssignmentChange(selectedOption);
                                                    }}
                                                    incidentTypeId={
                                                        selectIncidentTypeId?.length > 0 ? selectIncidentTypeId : scenarioAssignmentDetail?.incidentTypeId
                                                    }
                                                />
                                            </>
                                        );
                                    }}
                                />
                            </div>
                            <div className="error-msg">{errors?.assignmentId && <span className="text-danger">{errors?.assignmentId?.message}</span>}</div>
                        </div>
                        <div className="col-12 my-2">
                            <div className="mb-1 d-flex align-items-center">
                                <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                    Apparatus
                                </Form.Label>
                            </div>
                            <div>
                                <Controller
                                    control={control}
                                    name={`apparatusId`}
                                    render={({ field: { onChange, value } }) => {
                                        return (
                                            <>
                                                <SelectApparatus
                                                    style={SelectStyle}
                                                    isClearable={true}
                                                    defaultApparatus={value}
                                                    handleChange={(selectedOption) => {
                                                        onChange(selectedOption || null);
                                                        handleApparatusChange(selectedOption);
                                                    }}
                                                />
                                            </>
                                        );
                                    }}
                                />
                            </div>
                            <div className="error-msg">{errors?.apparatusId && <span className="text-danger">{errors?.apparatusId?.message}</span>}</div>
                        </div>
                        <div className="col-12 my-2">
                            <div className="frm-input mb-2">
                                <div className="d-flex align-items-center">
                                    <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                    <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                        Series of situations
                                    </Form.Label>
                                </div>
                                <FileInput
                                    name={API.SCENARIO_ASSIGNMENTS.AUDIO}
                                    {...register(API.SCENARIO_ASSIGNMENTS.AUDIO)}
                                    type="file"
                                    accept="audio/*"
                                    onChange={handleImportAudio}
                                    className="my-2"
                                    media={audio}
                                    id="audio_input"
                                    placeholder="Audio"
                                    labelText="Choose Audio"
                                />
                                <FileInput
                                    name={API.SCENARIO_ASSIGNMENTS.VIDEO}
                                    {...register(API.SCENARIO_ASSIGNMENTS.VIDEO)}
                                    type="file"
                                    accept="video/*"
                                    onChange={handleImportVideo}
                                    className="my-2"
                                    media={video}
                                    id="video_input"
                                    placeholder="Video"
                                    labelText="Choose Video"
                                />
                                <FileInput
                                    name="image"
                                    type="file"
                                    accept="image/png ,image/jpg ,image/jpeg"
                                    onChange={handleImportImage}
                                    id="image_input"
                                    className="my-2"
                                    placeholder="Image"
                                    labelText="Choose Image"
                                    multiple
                                />
                            </div>
                        </div>
                        {selectedImage && (
                            <div className="col-12 d-flex flex-row gap-4 border-1 border-black">
                                {_.map(selectedImage, (item, index) => (
                                    <div key={index} style={{ height: "80px", width: "80px" }} className="position-relative">
                                        <img
                                            alt={`selectedImage-${index}`}
                                            src={URL.createObjectURL(item)}
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "contain",
                                            }}
                                        />
                                        <img className="custom-deleteIcon" alt="DeleteIcon" src={DeleteIcon} onClick={() => removeImage(index)} />
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className="col-12 my-2">
                            <div className="mb-1 d-flex align-items-center">
                                <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                    Game From TDG
                                </Form.Label>
                            </div>
                            <div>
                                <Controller
                                    control={control}
                                    name={`gameFromTDG`}
                                    render={({ field: { onChange, value } }) => {
                                        return (
                                            <>
                                                <SelectGame
                                                    style={SelectStyle}
                                                    defaultGame={value}
                                                    isClearable={true}
                                                    handleChange={(selectedOption) => {
                                                        onChange(selectedOption || null);
                                                        handleGameFromTDG(selectedOption);
                                                    }}
                                                    assignmentId={selectAssignmentId?.length > 0 ? selectAssignmentId : scenarioAssignmentDetail?.assignmentId}
                                                />
                                            </>
                                        );
                                    }}
                                />
                            </div>
                            <div className="error-msg">{errors?.gameFromTDG && <span className="text-danger">{errors?.gameFromTDG?.message}</span>}</div>
                        </div>
                        <div className="col-12 my-2">
                            <div className="frm-input mb-2">
                                <Form.Group>
                                    <div className="d-flex align-items-center">
                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                        <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                            Game Info
                                        </Form.Label>
                                    </div>
                                    <Form.Control
                                        name={API.SCENARIO_ASSIGNMENTS.GAME_INFO}
                                        {...register(API.SCENARIO_ASSIGNMENTS.GAME_INFO)}
                                        type="text"
                                        className="custom-input"
                                    />
                                </Form.Group>
                                <div className="error-msg">{errors?.gameInfo && <span className="text-danger">{errors?.gameInfo?.message}</span>}</div>
                            </div>
                        </div>
                        <div className="col-12 my-2">
                            <div className="frm-input mb-2">
                                <Form.Group>
                                    <div className="d-flex align-items-center">
                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                        <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                            Goal / Objective
                                        </Form.Label>
                                    </div>
                                    <Form.Control
                                        name={API.SCENARIO_ASSIGNMENTS.GOAL_OBJECTIVE}
                                        {...register(API.SCENARIO_ASSIGNMENTS.GOAL_OBJECTIVE)}
                                        type="text"
                                        className="custom-input"
                                    />
                                </Form.Group>
                                <div className="error-msg">
                                    {errors?.goalObjective && <span className="text-danger">{errors?.goalObjective?.message}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="frm-input mb-2">
                                <Form.Group>
                                    <div className="d-flex justify-content-between align-items-center mb-2">
                                        <div className="d-flex align-items-center">
                                            <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                            <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                                Best practices
                                            </Form.Label>
                                        </div>
                                        <div className="custom-add-icon" onClick={handleAdd}>
                                            <img src={WhitePlus} alt={"WhitePlus"} height={"30px"} width={"30px"} />
                                        </div>
                                    </div>
                                    {_.map(fields, (item, index) => (
                                        <div key={item.id} className="d-flex align-items-center mb-3">
                                            <Form.Control
                                                name={`bestNames[${index}].name`}
                                                {...register(`bestNames[${index}].name`)}
                                                type="text"
                                                className="custom-input me-3"
                                            />
                                            <div className="" onClick={() => handleRemove(index)}>
                                                <img src={DeleteIcon} alt={"DeleteIcon"} height={"42px"} width={"44px"} />
                                            </div>
                                        </div>
                                    ))}
                                </Form.Group>
                            </div>
                        </div>
                        <div className="col-12 my-2">
                            <div className="frm-input mb-2">
                                <Form.Group>
                                    <div className="d-flex align-items-center">
                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                        <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                            Mission Briefing
                                        </Form.Label>
                                    </div>
                                    <Form.Control
                                        name={API.SCENARIO_ASSIGNMENTS.MISSION_BRIEFING}
                                        {...register(API.SCENARIO_ASSIGNMENTS.MISSION_BRIEFING)}
                                        type="text"
                                        className="custom-input"
                                    />
                                </Form.Group>
                                <div className="error-msg">
                                    {errors?.missionBriefing && <span className="text-danger">{errors?.missionBriefing?.message}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 my-2">
                            <div className="frm-input mb-2">
                                <Form.Group>
                                    <div className="d-flex align-items-center">
                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                        <Form.Label className="mb-0 custom-frmlabel">Target audience</Form.Label>
                                    </div>
                                    <div>
                                        <Controller
                                            control={control}
                                            name={API.SCENARIO_ASSIGNMENTS.TARGET_AUDIANCE}
                                            render={({ field: { onChange, value } }) => {
                                                return (
                                                    <>
                                                        <Select
                                                            name={API.SCENARIO_ASSIGNMENTS.TARGET_AUDIANCE}
                                                            options={selectTargateAudiance}
                                                            styles={SelectStyle}
                                                            isMulti
                                                            components={{
                                                                IndicatorSeparator: () => null,
                                                            }}
                                                            value={
                                                                Array.isArray(value)
                                                                    ? selectTargateAudiance.filter((option) => value.includes(option.value))
                                                                    : []
                                                            }
                                                            onChange={(selectedOptions) => {
                                                                const selectedValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];
                                                                onChange(selectedValues);
                                                            }}
                                                        />
                                                        <div className="error-msg">
                                                            {errors?.targetAudience && <span className="text-danger">{errors?.targetAudience?.message}</span>}
                                                        </div>
                                                    </>
                                                );
                                            }}
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                        </div>

                        <div className="col-12 d-flex align-items-center justify-content-center my-3">
                            {!scenarioAssignmentId ? (
                                <button type="submit" className="btn-save m-2">
                                    Save
                                </button>
                            ) : (
                                <button type="submit" className="btn-save m-2">
                                    Update
                                </button>
                            )}
                            <button type="button" className="btn-cancel m-2" onClick={() => navigate(`/scenarioAssignments`)}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
        </MainLayout>
    );
};

export default CreateEditScenarioAssignments;
