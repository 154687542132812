import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { ROUTES } from "../../../../../utils/constants/variables/routes";
import * as _ from "lodash";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import Select from "react-select";

import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import MainLayout from "../../../../../components/layouts/MainLayout";
import Elips from "../../../../../assets/images/action/elips.svg";
import "./style.scss";
import WhiteArrow from "../../../../../assets/images/action/white-arrow.svg";
import WhitePlus from "../../../../../assets/images/action/white-plus.svg";
import DeleteIcon from "../../../../../assets/images/action/deleteIcon.svg";
import bulletIcon from "../../../../../assets/images/action/bulletIcon.svg";
import { SelectStyle } from "../../../../../components/shared/Options/styles/SelectStyle";

import SelectResponse from "../../../../../components/shared/SelectOptions/SelectResponse";
import SelectIncidentPriorites from "../../../../../components/shared/SelectOptions/SelectIncidentPriorities";
import SelectObjectives from "../../../../../components/shared/SelectOptions/SelectObjectives";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { API } from "../../../../../utils/constants/variables/apiFields";
import { defaultValues } from "../../../../../utils/constants/forms/defaultValues";
import { validation } from "../../../../../utils/constants/forms/validation";
import { ModifiedSelectStyle } from "../../../../../utils/constants/selectStyle";
import { useCreateThinkingPlanningMutation, useUpdateThinkingPlanningMutation } from "../../../../../api/thinkingPlanning";
import { BiCloudLightRain } from "react-icons/bi";
import { FaChevronDown } from "react-icons/fa";

const CreateEditThinkingPlanning = () => {
    const [selectResponseTypeId, setSelectResponseTypeId] = useState(null);
    const [selectIncidentPrioritiesTypeId, setSelectIncidentPrioritesTypeId] = useState(null);
    const [selectObjectivesId, setSelectObjectivesId] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const [radioAnsType, setRadioAnsType] = useState("");
    const [radioSliderType, setRadioSliderType] = useState("");

    const location = useLocation();
    const navigate = useNavigate();
    const isInitialMount = useRef(true);
    const params = useParams();
    const { responseType, incidentPriorities, objectives } = params;
    const [responseTypeName, responseTypeId] = responseType?.split(/[=]+/);
    const [incidentPrioritiesName, incidentPrioritiesId] = incidentPriorities?.split(/[=]+/);
    const [objectivesName, objectivesId] = objectives?.split(/[=]+/);
    const originalDataId = location?.state;

    const thinkingPlanningDetail = location.state;
    const thinkingPlanningId = thinkingPlanningDetail?.id;
    const [createThinkingPlanning] = useCreateThinkingPlanningMutation();
    const [updateThinkingPlanning] = useUpdateThinkingPlanningMutation();

    const handleAddSliderTypes = () => {
        appendAddSliderTypes({ name: "", position: watch("priorityType") ? watch("sliderTypes").length + 1 : 0 });
    };

    const handleRemoveSliderTypes = (index) => {
        const updatedFields = watch("sliderTypes");
        updatedFields.splice(index, 1);

        // Update the answerType array with the updated fields
        const updatedSliderTypes = updatedFields.map((item, idx) => ({
            ...item,
            position: idx + 1, // Reset positions in order
        }));

        setValue("sliderTypes", updatedSliderTypes);
    };

    const handlePriorityTypeChange = (e) => {
        const isChecked = e.target.checked;
        // Loop through addAnswerType and update position values accordingly
        const updatedAddSliderTypes = watch("sliderTypes").map((item, index) => {
            return {
                ...item,
                position: isChecked ? index + 1 : 0,
            };
        });
        setValue("sliderTypes", updatedAddSliderTypes);
    };
    const handleSliderDragStart = (e, index) => {
        e.dataTransfer.setData("index", index.toString());
    };

    // Function to handle drag over
    const handleSliderDragOver = (e) => {
        e.preventDefault();
    };

    // Function to handle drop
    const handleSliderDrop = (e, newIndex) => {
        e.preventDefault();
        const dragIndex = parseInt(e.dataTransfer.getData("index"));
        const items = watch("sliderTypes");
        const draggedItem = items[dragIndex];

        // Remove the item from its old position
        items.splice(dragIndex, 1);
        // Insert it into the new position
        items.splice(newIndex, 0, draggedItem);
        const updateIndexResponse = items.map((item, idx) => {
            return {
                ...item,
                index: idx + 1,
            };
        });

        // Update state with the reordered items
        setValue("sliderTypes", updateIndexResponse);
    };

    const handleTextDragStart = (e, index) => {
        e.dataTransfer.setData("index", index.toString());
    };

    // Function to handle drag over
    const handleTextDragOver = (e) => {
        e.preventDefault();
    };

    // Function to handle drop
    const handleTextDrop = (e, newIndex) => {
        e.preventDefault();
        const dragIndex = parseInt(e.dataTransfer.getData("index"));
        const items = watch("texts");
        const draggedItem = items[dragIndex];

        items.splice(dragIndex, 1);
        items.splice(newIndex, 0, draggedItem);
        const updateIndexResponse = items.map((item, idx) => {
            return {
                ...item,
                index: idx + 1,
            };
        });

        // Update state with the reordered items
        setValue("texts", updateIndexResponse);
    };

    const handleTexts1DragStart = (e, index) => {
        e.dataTransfer.setData("index", index.toString());
    };

    // Function to handle drag over
    const handleTexts1DragOver = (e) => {
        e.preventDefault();
    };

    // Function to handle drop
    const handleTexts1Drop = (e, newIndex) => {
        e.preventDefault();
        const dragIndex = parseInt(e.dataTransfer.getData("index"));
        const items = watch("texts1");
        const draggedItem = items[dragIndex];

        items.splice(dragIndex, 1);
        items.splice(newIndex, 0, draggedItem);
        const updateIndexResponse = items.map((item, idx) => {
            return {
                ...item,
                index: idx + 1,
            };
        });

        // Update state with the reordered items
        setValue("texts1", updateIndexResponse);
    };

    const handleAddTextsSliderText = () => {
        appendTextsSliderText({ text: "" });
    };

    const handleRemoveTextsSliderText = (index) => {
        removeTextsSliderText(index);
    };

    const handleAddTexts1SliderText = () => {
        appendTexts1SliderText({ text: "" });
    };

    const handleRemoveTexts1SliderText = (index) => {
        removeTexts1SliderText(index);
    };

    const form = useForm({
        defaultValues: defaultValues.THINKING_PLANNING,
        resolver: yupResolver(validation.THINKING_PLANNING(radioAnsType)),
    });

    const {
        handleSubmit,
        formState: { errors },
        register,
        control,
        setValue,
        watch,
        reset,
    } = form;

    const {
        fields: addSliderTypesFields,
        append: appendAddSliderTypes,
        remove: removeAddSliderTypes,
    } = useFieldArray({
        control,
        name: "sliderTypes", // should match with your backend field name
    });

    const {
        fields: addTextsSliderTextFields,
        append: appendTextsSliderText,
        remove: removeTextsSliderText,
    } = useFieldArray({
        control,
        name: "texts", // should match with your backend field name
    });

    const {
        fields: addTexts1SliderTextFields,
        append: appendTexts1SliderText,
        remove: removeTexts1SliderText,
    } = useFieldArray({
        control,
        name: "texts1", // should match with your backend field name
    });
    const selectNoOfSlider = [
        { value: "singleSlider", label: "Single Slider" },
        { value: "twoSlider", label: "Two Slider" },
    ];

    useEffect(() => {
        // Set default values for select boxes when component mounts
        setSelectResponseTypeId(location.state?.responseTypeId || null);
        setSelectIncidentPrioritesTypeId(location.state?.incidentPrioritiesId || null);
        setSelectObjectivesId(location.state?.objectivesId || null);
    }, [location.state]);

    useEffect(() => {
        if (thinkingPlanningDetail) {
            setValue("responseTypeId", thinkingPlanningDetail?.responseTypeId);
            setValue("incidentPrioritiesId", thinkingPlanningDetail?.incidentPrioritiesId);
            setValue("objectivesId", thinkingPlanningDetail?.objectivesId);
            setValue("name", thinkingPlanningDetail?.name);
            setValue("selectAnswerType", thinkingPlanningDetail?.selectAnswerType);
            setValue("selectSliderType", thinkingPlanningDetail?.selectSliderType);
            setValue("sliderTypes", thinkingPlanningDetail?.addSliderType);
            setValue("selectNumberOfSliders", thinkingPlanningDetail?.selectNumberOfSliders);
            setValue("minimumValue", thinkingPlanningDetail?.minimumValue);
            setValue("maximumValue", thinkingPlanningDetail?.maximumValue);
            setValue("minimumValue1", thinkingPlanningDetail?.minimumValue1);
            setValue("maximumValue1", thinkingPlanningDetail?.maximumValue1);
            setValue("publish", thinkingPlanningDetail?.publish);
            setValue("priorityType", thinkingPlanningDetail?.priorityType);

            const texts = thinkingPlanningDetail?.texts?.filter((item) => item?.slider === "left") || thinkingPlanningDetail?.texts;
            texts.forEach((item, index) => {
                texts[`texts[${index}]`] = item.texts;
                appendTextsSliderText({ text: item.text, slider: item.slider, position: item.position });
            });
            const texts1 = thinkingPlanningDetail?.texts?.filter((item) => item?.slider === "right") || [];
            texts1.forEach((item, index) => {
                texts1[`texts1[${index}]`] = item.texts1;
                appendTexts1SliderText({ text: item.text, slider: item.slider, position: item.position });
            });
            setValue("texts", texts);
            setValue("texts1", texts1);
        }
    }, [setValue, thinkingPlanningDetail, thinkingPlanningId]);

    const handleSelectNumberOfSlidersChange = (selectedOption) => {
        setValue(API.THINKING_PLANNING.SELECT_NUM_OF_SLIDERS, selectedOption?.value);
        setSelectNoOfSlider(selectedOption?.value);
    };

    const setSelectNoOfSlider = (selectedOption) => {
        if (selectedOption === "singleSlider") {
            setValue("minimumValue", null);
            setValue("maximumValue", null);
            setValue("minimumValue1", null);
            setValue("maximumValue1", null);
            setValue("texts", []);
            setValue("texts1", []);
        } else if (selectedOption === "twoSlider") {
            setValue("minimumValue", null);
            setValue("maximumValue", null);
            setValue("texts", []);
        }
    };

    const setValueOfRadio = (e) => {
        if (e === "list") {
            setValue("texts", []);
            setValue("texts1", []);
            setValue("selectNumberOfSliders", null);
            setValue("selectSliderType", null);
            setValue("minimumValue", null);
            setValue("maximumValue", null);
            setValue("minimumValue1", null);
            setValue("maximumValue1", null);
        } else if (e === "ratingScale") {
            setValue("sliderTypes", []);
            setValue("priorityType", false);
        } else if (e === "numeric") {
            setValue("texts", []);
            setValue("texts1", []);
        } else if (e === "text") {
            setValue("minimumValue", null);
            setValue("maximumValue", null);
            setValue("minimumValue1", null);
            setValue("maximumValue1", null);
        }
    };

    const onSubmit = async (data) => {
        const leftSlider = data?.texts?.map((item, idx) => {
            return { ...item, slider: "left", position: idx + 1 };
        });
        const rightSlider = data?.texts1?.map((item, idx) => {
            return { ...item, slider: "right", position: idx + 1 };
        });
        const addSlider = Array.isArray(data?.sliderTypes) ? data.sliderTypes.map((item) => ({ ...item })) : [];

        const allData = {
            ...data,
            responseTypeId: responseTypeId || thinkingPlanningDetail?.responseTypeId,
            incidentPrioritiesId: incidentPrioritiesId || thinkingPlanningDetail?.incidentPrioritiesId,
            objectivesId: objectivesId || thinkingPlanningDetail?.objectivesId,
            texts: leftSlider,
            texts1: rightSlider,
            sliderTypes: addSlider,
        };
        const response = thinkingPlanningId ? await updateThinkingPlanning({ thinkingPlanningId, allData }) : await createThinkingPlanning(allData);
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            navigate(
                `/gameFunnel/functionKeys/thinkingPlanning/${responseTypeName}=${responseTypeId}/${incidentPrioritiesName}=${incidentPrioritiesId}/${objectivesName}=${objectivesId}`
            );
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    return (
        <MainLayout className="me-0">
            <div className="overflow_scroll">
                <div className="row p-3">
                    <div>
                        <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex align-items-center float-end my-3 me-3">
                                        <Controller
                                            control={control}
                                            name={API.SCENARIO.PUBLISH}
                                            render={({ field: { onChange, value } }) => {
                                                return (
                                                    <Form.Check // prettier-ignore
                                                        type="switch"
                                                        id="custom-switch"
                                                        label="Publish"
                                                        className="customSwitch"
                                                        checked={value}
                                                        value={value}
                                                        onChange={onChange}
                                                        reverse
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-12 my-2">
                                    <div className="mb-1 d-flex align-items-center">
                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                        <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                            Response type
                                        </Form.Label>
                                    </div>

                                    <div className="item-list d-flex align-items-center justify-content-between">
                                        <div className="px-3">
                                            <span>{responseTypeName}</span>
                                        </div>
                                        <div className="right-rect d-flex justify-content-center align-items-center">
                                            <FaChevronDown className="text-white" />
                                        </div>
                                    </div>
                                    {/* <div>
                                        <Controller
                                            control={control}
                                            name={`responseTypeId`}
                                            render={({ field: { onChange, value } }) => {
                                                return (
                                                    <>
                                                        <SelectResponse style={SelectStyle} defaultResponse={responseTypeId} />
                                                    </>
                                                );
                                            }}
                                        />
                                    </div> */}
                                </div>
                                <div className="col-md-4 col-lg-4 col-12 my-2">
                                    <div className="mb-1 d-flex align-items-center">
                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                        <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                            Incident Priorities
                                        </Form.Label>
                                    </div>
                                    <div className="item-list d-flex align-items-center justify-content-between">
                                        <div className="px-3">
                                            <span>{incidentPrioritiesName}</span>
                                        </div>
                                        <div className="right-rect d-flex justify-content-center align-items-center">
                                            <FaChevronDown className="text-white" />
                                        </div>
                                    </div>
                                    {/* <div>
                                        <Controller
                                            control={control}
                                            name={`incidentPrioritiesId`}
                                            render={({ field: { onChange, value } }) => {
                                                return (
                                                    <>
                                                        <SelectIncidentPriorites
                                                            style={SelectStyle}
                                                            defaultIncidentPriorities={incidentPrioritiesId}
                                                            responseId={
                                                                selectResponseTypeId?.length > 0 ? selectResponseTypeId : thinkingPlanningDetail?.responseTypeId
                                                            }
                                                        />
                                                    </>
                                                );
                                            }}
                                        />
                                    </div> */}
                                </div>
                                <div className="col-md-4 col-lg-4 col-12 my-2">
                                    <div className="mb-1 d-flex align-items-center">
                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                        <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                            Objectives
                                        </Form.Label>
                                    </div>
                                    <div className="item-list d-flex align-items-center justify-content-between">
                                        <div className="px-3">
                                            <span>{objectivesName}</span>
                                        </div>
                                        <div className="right-rect d-flex justify-content-center align-items-center">
                                            <FaChevronDown className="text-white" />
                                        </div>
                                    </div>
                                    {/* <div>
                                        <Controller
                                            control={control}
                                            name={`objectivesId`}
                                            render={({ field: { onChange, value } }) => {
                                                return (
                                                    <>
                                                        <SelectObjectives
                                                            style={SelectStyle}
                                                            defaultObjectives={objectivesId}
                                                            incidentPrioritiesId={
                                                                selectIncidentPrioritiesTypeId?.length > 0
                                                                    ? selectIncidentPrioritiesTypeId
                                                                    : thinkingPlanningDetail?.incidentPrioritiesId
                                                            }
                                                        />
                                                    </>
                                                );
                                            }}
                                        />
                                    </div> */}
                                </div>
                                <div className="col-12">
                                    <div className="frm-input mb-2">
                                        <Form.Group>
                                            <div className="d-flex align-items-center">
                                                <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                                    Name
                                                </Form.Label>
                                            </div>
                                            <Form.Control
                                                name={API.THINKING_PLANNING.NAME}
                                                {...register(API.THINKING_PLANNING.NAME)}
                                                type="text"
                                                className="custom-input"
                                            />
                                        </Form.Group>
                                        <div className="error-msg">{errors?.name && <span className="text-danger">{errors?.name?.message}</span>}</div>
                                    </div>
                                </div>
                                <div className="col-12 custom-radio-checked">
                                    <div className="frm-input mb-3">
                                        <Form.Group>
                                            <div className="d-flex align-items-center">
                                                <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                <Form.Label htmlFor="basic-url" className="mb-0 me-4 custom-frmlabel">
                                                    Select answer type
                                                </Form.Label>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <Controller
                                                    control={control}
                                                    name={API.THINKING_PLANNING.ANSWER_TYPE}
                                                    render={({ field: { onChange, value } }) => {
                                                        return ["list", "ratingScale"].map((item, idx) => {
                                                            let customLabel = "";
                                                            switch (item) {
                                                                case "list":
                                                                    customLabel = "List";
                                                                    break;
                                                                case "ratingScale":
                                                                    customLabel = "Rating Scale";
                                                                    break;
                                                                default:
                                                                    break;
                                                            }
                                                            return (
                                                                <Form.Check
                                                                    key={item}
                                                                    type="radio"
                                                                    id={item}
                                                                    name={API.THINKING_PLANNING.ANSWER_TYPE}
                                                                    label={customLabel}
                                                                    value={item}
                                                                    checked={value === item}
                                                                    onChange={(e) => {
                                                                        onChange(e.target.value);
                                                                        setValueOfRadio(e.target.value);
                                                                        setRadioAnsType(e.target.value);
                                                                    }}
                                                                />
                                                            );
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="error-msg">
                                                {errors?.selectAnswerType && <span className="text-danger">{errors?.selectAnswerType?.message}</span>}
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                                {watch("selectAnswerType") === "list" && (
                                    <div className="col-12">
                                        <div className="frm-input mb-2">
                                            <Form.Group>
                                                <div className="d-flex justify-content-between align-items-center mb-2">
                                                    <div className="d-flex align-items-center">
                                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                        <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel me-3">
                                                            Add Slider type
                                                        </Form.Label>
                                                        <div className="add-priority">
                                                            <Controller
                                                                className="d-flex align-items-center"
                                                                control={control}
                                                                name={API.THINKING_PLANNING.PRIORITY_TYPE}
                                                                render={({ field: { onChange, value } }) => {
                                                                    return (
                                                                        <Form.Check // prettier-ignore
                                                                            type="checkbox"
                                                                            id="custom-check"
                                                                            label="Priority type"
                                                                            className="fs-5"
                                                                            checked={value}
                                                                            // value={value}
                                                                            onChange={(e) => {
                                                                                onChange(e);
                                                                                handlePriorityTypeChange(e);
                                                                            }}
                                                                            reverse
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="custom-add-icon" onClick={handleAddSliderTypes}>
                                                        <img src={WhitePlus} alt={"WhitePlus"} height={"30px"} width={"30px"} />
                                                    </div>
                                                </div>
                                                {_.map(addSliderTypesFields, (item, index) => (
                                                    <div
                                                        key={item.id}
                                                        className="d-flex align-items-center mb-3"
                                                        draggable
                                                        onDragStart={(e) => handleSliderDragStart(e, index)}
                                                        onDragOver={handleSliderDragOver}
                                                        onDrop={(e) => handleSliderDrop(e, index)}
                                                    >
                                                        <div className="me-3">
                                                            <img src={bulletIcon} alt={"bulletIcon"} height={"30px"} width={"30px"} />
                                                        </div>
                                                        <InputGroup className="me-3 mb-0 custom-ans-input">
                                                            <Form.Control
                                                                name={`sliderTypes[${index}].name`}
                                                                {...register(`sliderTypes[${index}].name`)}
                                                                type="text"
                                                                className="custom-frm-control ans-input rounded"
                                                                required
                                                            />
                                                            {watch("priorityType") && (
                                                                <Form.Control
                                                                    type="number"
                                                                    className="custom-grouptext index-input"
                                                                    {...register(`sliderTypes[${index}].position`)}
                                                                    disabled
                                                                />
                                                            )}
                                                        </InputGroup>
                                                        <div onClick={() => handleRemoveSliderTypes(index)}>
                                                            <img src={DeleteIcon} alt={"DeleteIcon"} height={"42px"} width={"44px"} />
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className="error-msg">
                                                    {errors?.sliderTypes && <span className="text-danger">{errors?.sliderTypes?.message}</span>}
                                                </div>
                                            </Form.Group>
                                        </div>
                                    </div>
                                )}
                                {watch("selectAnswerType") === "ratingScale" && (
                                    <div className="col-12 custom-radio-checked">
                                        <div className="frm-input mb-3">
                                            <Form.Group>
                                                <div className="mb-3 select-slider">
                                                    <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                    <Form.Label htmlFor="basic-url" className="mb-0 me-4 custom-frmlabel">
                                                        Select slider type
                                                    </Form.Label>
                                                    <div>
                                                        <Controller
                                                            control={control}
                                                            name={API.THINKING_PLANNING.SELECT_NUM_OF_SLIDERS}
                                                            render={({ field }) => {
                                                                return (
                                                                    <>
                                                                        <Select
                                                                            options={selectNoOfSlider}
                                                                            styles={ModifiedSelectStyle}
                                                                            placeholder="Select Number Of Slider"
                                                                            components={{
                                                                                IndicatorSeparator: () => null,
                                                                            }}
                                                                            defaultValue={selectNoOfSlider.find(
                                                                                (option) => option.value === thinkingPlanningDetail?.selectNumberOfSliders
                                                                            )}
                                                                            onChange={(selectedOption) => {
                                                                                field.onChange(selectedOption?.value);
                                                                                handleSelectNumberOfSlidersChange(selectedOption);
                                                                            }}
                                                                        />
                                                                    </>
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="error-msg my-3">
                                                    {errors?.selectNumberOfSliders && (
                                                        <span className="text-danger">{errors?.selectNumberOfSliders?.message}</span>
                                                    )}
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <Controller
                                                        control={control}
                                                        name={API.THINKING_PLANNING.SELECT_SLIDER_TYPE}
                                                        {...register(API.THINKING_PLANNING.SELECT_SLIDER_TYPE)}
                                                        render={({ field: { onChange, value } }) => {
                                                            return ["numeric", "text"].map((item, idx) => {
                                                                let customLabel = "";
                                                                switch (item) {
                                                                    case "numeric":
                                                                        customLabel = "Numeric";
                                                                        break;
                                                                    case "text":
                                                                        customLabel = "Text";
                                                                        break;
                                                                    default:
                                                                        break;
                                                                }
                                                                return (
                                                                    <Form.Check
                                                                        key={item}
                                                                        type="radio"
                                                                        id={item}
                                                                        name={API.THINKING_PLANNING.SELECT_SLIDER_TYPE}
                                                                        label={customLabel}
                                                                        value={item}
                                                                        checked={value === item}
                                                                        onChange={(e) => {
                                                                            onChange(e.target.value);
                                                                            setValueOfRadio(e.target.value);
                                                                        }}
                                                                    />
                                                                );
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </Form.Group>
                                            <div className="error-msg">
                                                {errors?.selectSliderType && <span className="text-danger">{errors?.selectSliderType?.message}</span>}
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {watch("selectNumberOfSliders") === "singleSlider" && watch("selectSliderType") === "numeric" ? (
                                    <div className="col-12 custom-radio-checked">
                                        <div className="frm-input mb-3">
                                            <Form.Group>
                                                <div className="d-flex align-items-center mb-3">
                                                    <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                    <Form.Label htmlFor="basic-url" className="mb-0 me-4 custom-frmlabel">
                                                        Single Slider
                                                    </Form.Label>
                                                </div>
                                                <div className="row align-items-center justify-content-between">
                                                    <div className="col-md-6 col-lg-6 col-12">
                                                        <Form.Control
                                                            {...register(API.THINKING_PLANNING.MIN_VALUE)}
                                                            type="number"
                                                            className="custom-input my-2"
                                                            placeholder="Minimum value"
                                                            required
                                                        />
                                                        <div className="error-msg">
                                                            {errors?.minimumValue && <span className="text-danger">{errors?.minimumValue?.message}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-6 col-12">
                                                        <Form.Control
                                                            {...register(API.THINKING_PLANNING.MAX_VALUE)}
                                                            type="number"
                                                            className="custom-input my-2"
                                                            placeholder="Maximum value"
                                                            required
                                                        />
                                                        <div className="error-msg">
                                                            {errors?.maximumValue && <span className="text-danger">{errors?.maximumValue?.message}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </div>
                                    </div>
                                ) : (
                                    watch("selectNumberOfSliders") === "twoSlider" &&
                                    watch("selectSliderType") === "numeric" && (
                                        <div className="col-12 custom-radio-checked">
                                            <div className="frm-input mb-3">
                                                <Form.Group>
                                                    <div className="d-flex align-items-center mb-3">
                                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                        <Form.Label htmlFor="basic-url" className="mb-0 me-4 custom-frmlabel">
                                                            Two Slider
                                                        </Form.Label>
                                                    </div>
                                                    <div className="row align-items-center justify-content-between">
                                                        <div className="col-md-6 col-lg-6 col-12">
                                                            <div className="mb-3">
                                                                <Form.Label htmlFor="basic-url" className="mb-1 me-4">
                                                                    Minimum value
                                                                </Form.Label>
                                                                <Form.Control
                                                                    // name={API.THINKING_PLANNING.MIN_VALUE}
                                                                    {...register(API.THINKING_PLANNING.MIN_VALUE)}
                                                                    type="number"
                                                                    className="custom-input my-2"
                                                                    placeholder="Minimum value"
                                                                    required
                                                                />
                                                                <div className="error-msg">
                                                                    {errors?.minimumValue && (
                                                                        <span className="text-danger">{errors?.minimumValue?.message}</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Form.Label htmlFor="basic-url" className="mb-1 me-4">
                                                                    Maximum value
                                                                </Form.Label>
                                                                <Form.Control
                                                                    // name={API.THINKING_PLANNING.MAX_VALUE}
                                                                    {...register(API.THINKING_PLANNING.MAX_VALUE)}
                                                                    type="number"
                                                                    className="custom-input my-2"
                                                                    placeholder="Maximum value"
                                                                    required
                                                                />
                                                                <div className="error-msg">
                                                                    {errors?.maximumValue && (
                                                                        <span className="text-danger">{errors?.maximumValue?.message}</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-6 col-12">
                                                            <div className="mb-3">
                                                                <Form.Label htmlFor="basic-url" className="mb-1 me-4">
                                                                    Minimum value
                                                                </Form.Label>
                                                                <Form.Control
                                                                    // name={API.THINKING_PLANNING.MIN_VALUE_1}
                                                                    {...register(API.THINKING_PLANNING.MIN_VALUE_1)}
                                                                    type="number"
                                                                    className="custom-input my-2"
                                                                    placeholder="Minimum value"
                                                                    required
                                                                />
                                                                <div className="error-msg">
                                                                    {errors?.minimumValue1 && (
                                                                        <span className="text-danger">{errors?.minimumValue1?.message}</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Form.Label htmlFor="basic-url" className="mb-1 me-4">
                                                                    Maximum value
                                                                </Form.Label>
                                                                <Form.Control
                                                                    // name={API.THINKING_PLANNING.MAX_VALUE_1}
                                                                    {...register(API.THINKING_PLANNING.MAX_VALUE_1)}
                                                                    type="number"
                                                                    className="custom-input my-2"
                                                                    placeholder="Maximum value"
                                                                    required
                                                                />
                                                                <div className="error-msg">
                                                                    {errors?.maximumValue1 && (
                                                                        <span className="text-danger">{errors?.maximumValue1?.message}</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    )
                                )}

                                {watch("selectNumberOfSliders") === "singleSlider" && watch("selectSliderType") === "text" ? (
                                    <div className="col-12">
                                        <div className="frm-input mb-2">
                                            <Form.Group>
                                                <div className="d-flex justify-content-between align-items-center mb-2">
                                                    <div className="d-flex align-items-center">
                                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                        <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                                            Single Slider
                                                        </Form.Label>
                                                    </div>
                                                    <div className="custom-add-icon my-3" onClick={handleAddTextsSliderText}>
                                                        <img src={WhitePlus} alt={"WhitePlus"} height={"30px"} width={"30px"} />
                                                    </div>
                                                </div>
                                                {_.map(addTextsSliderTextFields, (item, index) => (
                                                    <div
                                                        key={item.id}
                                                        className="d-flex align-items-center mb-3"
                                                        draggable
                                                        onDragStart={(e) => handleTextDragStart(e, index)}
                                                        onDragOver={handleTextDragOver}
                                                        onDrop={(e) => handleTextDrop(e, index)}
                                                    >
                                                        <div className="me-3">
                                                            <img src={bulletIcon} alt={"bulletIcon"} height={"30px"} width={"30px"} />
                                                        </div>
                                                        <InputGroup className="me-3 mb-0">
                                                            <Form.Control
                                                                name={`texts[${index}].text`}
                                                                {...register(`texts[${index}].text`)}
                                                                type="text"
                                                                className="custom-frm-control rounded"
                                                                required
                                                            />
                                                        </InputGroup>
                                                        <div className="" onClick={() => handleRemoveTextsSliderText(index)}>
                                                            <img src={DeleteIcon} alt={"DeleteIcon"} height={"42px"} width={"44px"} />
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className="error-msg">
                                                    {errors?.texts && <span className="text-danger">{errors?.texts?.message}</span>}
                                                </div>
                                            </Form.Group>
                                        </div>
                                    </div>
                                ) : (
                                    watch("selectNumberOfSliders") === "twoSlider" &&
                                    watch("selectSliderType") === "text" && (
                                        <div>
                                            <div className="d-flex align-items-center">
                                                <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                                    Two Slider
                                                </Form.Label>
                                            </div>
                                            <div className="col-12">
                                                <div className="frm-input mb-2">
                                                    <Form.Group>
                                                        <div className="row">
                                                            <div className="col-md-6 col-lg-6 col-12">
                                                                <div className="custom-add-icon my-3" onClick={handleAddTextsSliderText}>
                                                                    <img src={WhitePlus} alt={"WhitePlus"} height={"30px"} width={"30px"} />
                                                                </div>
                                                                {_.map(addTextsSliderTextFields, (item, index) => (
                                                                    <div
                                                                        key={item.id}
                                                                        className="d-flex align-items-center mb-3"
                                                                        draggable
                                                                        onDragStart={(e) => handleTextDragStart(e, index)}
                                                                        onDragOver={handleTextDragOver}
                                                                        onDrop={(e) => handleTextDrop(e, index)}
                                                                    >
                                                                        <div className="me-3">
                                                                            <img src={bulletIcon} alt={"bulletIcon"} height={"30px"} width={"30px"} />
                                                                        </div>
                                                                        <InputGroup className="me-3 mb-0">
                                                                            <Form.Control
                                                                                name={`texts[${index}].text`}
                                                                                {...register(`texts[${index}].text`)}
                                                                                type="text"
                                                                                className="custom-frm-control rounded"
                                                                                required
                                                                            />
                                                                        </InputGroup>
                                                                        <div className="" onClick={() => handleRemoveTextsSliderText(index)}>
                                                                            <img src={DeleteIcon} alt={"DeleteIcon"} height={"42px"} width={"44px"} />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                <div className="error-msg">
                                                                    {errors?.texts && <span className="text-danger">{errors?.texts?.message}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-lg-6 col-12">
                                                                <div className="custom-add-icon my-3" onClick={handleAddTexts1SliderText}>
                                                                    <img src={WhitePlus} alt={"WhitePlus"} height={"30px"} width={"30px"} />
                                                                </div>
                                                                {_.map(addTexts1SliderTextFields, (item, index) => (
                                                                    <div
                                                                        key={item.id}
                                                                        className="d-flex align-items-center mb-3"
                                                                        draggable
                                                                        onDragStart={(e) => handleTexts1DragStart(e, index)}
                                                                        onDragOver={handleTexts1DragOver}
                                                                        onDrop={(e) => handleTexts1Drop(e, index)}
                                                                    >
                                                                        <div className="me-3">
                                                                            <img src={bulletIcon} alt={"bulletIcon"} height={"30px"} width={"30px"} />
                                                                        </div>
                                                                        <InputGroup className="me-3 mb-0">
                                                                            <Form.Control
                                                                                name={`texts1[${index}].text`}
                                                                                {...register(`texts1[${index}].text`)}
                                                                                type="text"
                                                                                className="custom-frm-control rounded"
                                                                                required
                                                                            />
                                                                        </InputGroup>
                                                                        <div className="" onClick={() => handleRemoveTexts1SliderText(index)}>
                                                                            <img src={DeleteIcon} alt={"DeleteIcon"} height={"42px"} width={"44px"} />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                <div className="error-msg">
                                                                    {errors?.texts1 && <span className="text-danger">{errors?.texts1?.message}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>

                            <div className="col-12 d-flex align-items-center justify-content-center btn-fixed">
                                {!thinkingPlanningId ? (
                                    <button type="submit" className="btn-save m-2">
                                        Save
                                    </button>
                                ) : (
                                    <button type="submit" className="btn-save m-2">
                                        Update
                                    </button>
                                )}
                                <button
                                    type="button"
                                    className="btn-cancel m-2"
                                    onClick={() =>
                                        navigate(
                                            `/gameFunnel/functionKeys/thinkingPlanning/${responseTypeName}=${responseTypeId}/${incidentPrioritiesName}=${responseTypeId}/${objectivesName}=${objectivesId}`
                                        )
                                    }
                                >
                                    Cancel
                                </button>
                            </div>
                        </Form>{" "}
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default CreateEditThinkingPlanning;
