import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { API } from "../../../../../utils/constants/variables/apiFields";
import { useForm } from "react-hook-form";
import { defaultValues } from "../../../../../utils/constants/forms/defaultValues";
import { yupResolver } from "@hookform/resolvers/yup";
import { validation } from "../../../../../utils/constants/forms/validation";
import { useCreateResponseTypeMutation, useUpdateResponseTypeMutation, useDeleteResponseTypeMutation } from "../../../../../api/responseType";
import { toast } from "react-toastify";

const CreateEditResponseType = (props) => {
    const isEdit = props.showEditForm;
    const responseTypeDetail = props.responseTypeDetail;
    const responseTypeId = props.responseTypeDetail?.responseTypeId;
    console.log("responseTypeId=====", responseTypeId);
    const [createResponseType] = useCreateResponseTypeMutation();
    const [updateResponseType] = useUpdateResponseTypeMutation();
    const [deleteResponseType] = useDeleteResponseTypeMutation();

    const form = useForm({
        defaultValues: defaultValues.RESPONSE_TYPES,
        resolver: yupResolver(validation.RESPONSE_TYPES),
    });

    const {
        handleSubmit,
        formState: { errors },
        register,
        watch,
        setValue,
    } = form;

    useEffect(() => {
        if (responseTypeId) {
            setValue("name", responseTypeDetail?.name);
        }
    }, [setValue]);

    const onSubmit = async (data) => {
        const response = isEdit ? await updateResponseType({ data, responseTypeId }) : await createResponseType(data);
        if (response?.data?.status === "success") {
            props.onHide();
            props.refetchCreateEditResponseType();
            toast.success(response?.data?.message, { autoClose: 1000 });
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    const handleDelete = async (responseTypeId) => {
        const response = await deleteResponseType(responseTypeId);
        if (response?.data?.status === "success") {
            props.onHide();
            props.refetchCreateEditResponseType();
            toast.success(response?.data?.message, { autoClose: 1000 });
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    return (
        <div>
            <div className="response-type">
                <Modal {...props} size="md" centered aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Body className="p-3">
                        <div className="my-3 text-center">
                            <button className="btn-custom">{!isEdit ? "Create" : "Edit"}</button>
                        </div>
                        <div className="response-form">
                            <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                                <div className="frm-input my-4">
                                    <Form.Group>
                                        <Form.Label className="mb-0 text-fs">Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={API.RESPONSE_TYPES.NAME}
                                            {...register(API.RESPONSE_TYPES.NAME)}
                                            className="custom-input"
                                        />
                                    </Form.Group>

                                    <div className="error-msg">{errors?.name && <span className="text-danger">{errors?.name?.message}</span>}</div>
                                </div>
                                {!isEdit ? (
                                    <div className="w-100 text-center">
                                        <button type="submit" className="btn-save my-0">
                                            Save
                                        </button>
                                    </div>
                                ) : (
                                    <div className="d-flex align-items-center justify-content-center w-100 gap-2">
                                        <div className="text-center">
                                            <button type="submit" className="btn-save my-0">
                                                Update
                                            </button>
                                        </div>
                                        <div className="text-center">
                                            <button type="button" className="btn-delete my-0" onClick={() => handleDelete(responseTypeId)}>
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </Form>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
};

export default CreateEditResponseType;
