import React, { useState, forwardRef, useEffect } from "react";
import { FormControl } from "react-bootstrap";
import * as _ from "lodash";
import "./style.scss";

const FileInput = forwardRef((props, ref) => {
    const { type, onChange, id, className, accept, placeholder, image, media, multiple, labelText } = props;

    const [selectedFile, setSelectedFile] = useState();

    useEffect(() => {
        if (!_.isEmpty(image)) {
            const url = new URL(image);
            const imageUrl = url.pathname.split("/").pop();
            setSelectedFile(imageUrl);
        } else if (!_.isEmpty(media) && !media.startsWith("http")) {
            setSelectedFile(media);
        } else if (!_.isEmpty(media) && media.startsWith("http")) {
            const urlMedia = new URL(media);
            const mediaUrl = urlMedia.pathname.split("/").pop();
            setSelectedFile(mediaUrl);
        }   
    }, [image, media]);
    return (
        <div className={className}>
            <FormControl
                type={type}
                onChange={onChange}
                onChangeCapture={(e) => {
                    setSelectedFile(e.target.files[0].name);
                }}
                id={id}
                className="d-none"
                accept={accept}
                ref={ref}
                multiple={multiple}
            />
            <label htmlFor={id} className="file_input">
                <div className="text-muted text-fs text-truncate">{placeholder}</div>
                <div>{labelText || "Choose file"}</div>
            </label>
        </div>
    );
});

export default FileInput;
