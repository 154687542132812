import { createApi } from "@reduxjs/toolkit/query/react";
import { api } from "../utils/constants/url";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { showErrorToast } from "../utils/constants/api/toast";

const tagTypes = "Organization";
const type = "Objectives";
export const organizationApi = createApi({
    reducerPath: "organizationApi",
    baseQuery: baseQueryWithAuthInterceptor({
        baseUrl: `${api.baseURL}/organization`,
        prepareHeaders,
    }),
    tagTypes: [tagTypes],
    endpoints: (builder) => ({
        getOrganization: builder.query({
            query: () => ({
                url: "/getOrganization",
                method: "GET",
            }),
            providesTags: [type],
        }),
        createOrganization: builder.mutation({
            query: (body) => ({
                url: "/createOrganization",
                method: "POST",
                body,
            }),
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        updateOrganization: builder.mutation({
            query: (body) => {
                const { formData, organizationId } = body;
                return {
                    url: `/updateOrganization/${organizationId}`,
                    method: "PUT",
                    body: formData,
                };
            },
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        deleteOrganization: builder.mutation({
            query: (id) => {
                return {
                    url: `/deleteOrganization/${id}`,
                    method: "DELETE",
                };
            },
        }),
        updatePassword: builder.mutation({
            query: (payload) => {
                return {
                    url: `/updatePassword/${payload?.organizationId}`,
                    method: "PUT",
                    body: payload,
                };
            },
        }),
    }),
});

export const {
    useGetOrganizationQuery,
    useCreateOrganizationMutation,
    useUpdateOrganizationMutation,
    useDeleteOrganizationMutation,
    useUpdatePasswordMutation,
} = organizationApi;
