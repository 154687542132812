import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import BinIcon from "../../assets/images/action/binIcon.svg";

import { useDeleteOrganizationMutation } from "../../api/organization";
import { useDeleteTdgLibraryMutation } from "../../api/firegroundTasks";
import { useDeleteTacticalDecisionGameMutation } from "../../api/tacticalDecisionGame";
import { useDeleteThinkingPlanningMutation } from "../../api/thinkingPlanning";
import { useDeleteApparatusMutation } from "../../api/apparatus";
import { useDeleteScenarioMutation } from "../../api/scenario";
import { useDeleteProfileMutation } from "../../api/profile";
import { useDeleteScenarioAssignmentMutation } from "../../api/scenarioAssignments";
import "./style.scss";

const DeleteModal = (props) => {
    const { organization, tdgLibrary, tacticalDecisionGame, thinkingPlanning, apparatus, scenario, scenarioAssignment, profile } = props;
    const id = props?.deleteId;

    const [showConfirmation, setShowConfirmation] = useState();

    const [deleteOrganization] = useDeleteOrganizationMutation();
    const [deleteTdgLibrary] = useDeleteTdgLibraryMutation();
    const [deleteThinkingPlanning] = useDeleteThinkingPlanningMutation();
    const [deleteTacticalDecisionGame] = useDeleteTacticalDecisionGameMutation();
    const [deleteApparatus] = useDeleteApparatusMutation();
    const [deleteScenario] = useDeleteScenarioMutation();
    const [deleteScenarioAssignment] = useDeleteScenarioAssignmentMutation();
    const [deleteProfile] = useDeleteProfileMutation();

    const handleDelete = async (id) => {
        const data = thinkingPlanning
            ? await deleteThinkingPlanning(id)
            : organization
            ? await deleteOrganization(id)
            : tdgLibrary
            ? await deleteTdgLibrary(id)
            : apparatus
            ? await deleteApparatus(id)
            : profile
            ? await deleteProfile(id)
            : scenario
            ? await deleteScenario(id)
            : scenarioAssignment
            ? await deleteScenarioAssignment(id)
            : tacticalDecisionGame && (await deleteTacticalDecisionGame(id));
        if (data?.data?.status === "success") {
            props.onHide();
            props.refetch();
            toast("Record delete successfully", { autoClose: 1000 });
        } else {
            toast.error(data?.error?.data?.message, { autoClose: false });
        }
    };

    const handleCancel = () => {
        props.onHide();
    };

    return (
        <>
            {/* <div> */}
            <Modal {...props} size="md" className="custom-delete-modal m-8" centered aria-labelledby="contained-modal-title-vcenter ">
                <Modal.Body className="delete-modal">
                    <div className="row p-4 text-center">
                        <div className="col-12">
                            <div className="bin-icon">
                                <img src={BinIcon} alt={"BinIcon"} />
                            </div>
                            {organization || thinkingPlanning ? (
                                <div className="bg-white position-relative">
                                    <div className="my-5">
                                        <p className="m-0 fs-5 fw-bold">
                                            {organization ? "This will delete your Organization from catalog" : "This will delete your scenario from catalog"}
                                        </p>
                                        <p className="m-0 fs-5 fw-bold">Are you sure ?</p>
                                    </div>
                                    <div className="w-100">
                                        <button className="btn-save mx-3" onClick={handleCancel}>
                                            Cancel
                                        </button>
                                        <button className="btn-delete mx-3" onClick={() => handleDelete(id)}>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="bg-white position-relative">
                                    <div className="my-5">
                                        <p className="m-0 fs-3 fw-bold">
                                            {!showConfirmation && tdgLibrary
                                                ? "You’re going to delete game in TDG library?"
                                                : showConfirmation && tdgLibrary
                                                ? "Are you sure you want to delete game in TDG library? "
                                                : !showConfirmation && apparatus
                                                ? "You’re going to delete Apparatus? "
                                                : showConfirmation && apparatus
                                                ? "Are you sure you want to delete Apparatus? "
                                                : !showConfirmation && scenario
                                                ? "You’re going to delete Scenario? "
                                                : showConfirmation && scenario
                                                ? "Are you sure you want to delete Scenario? "
                                                : !showConfirmation && scenarioAssignment
                                                ? "You’re going to delete Scenario Assignment? "
                                                : showConfirmation && scenarioAssignment
                                                ? "Are you sure you want to delete Scenario Assignment? "
                                                : !showConfirmation && profile
                                                ? "You’re going to delete Profile? "
                                                : showConfirmation && profile
                                                ? "Are you sure you want to delete Profile? "
                                                : !showConfirmation && tacticalDecisionGame
                                                ? "You’re going to delete question in game? "
                                                : showConfirmation && tacticalDecisionGame
                                                ? "Are you sure you want to delete question in game? "
                                                : ""}
                                        </p>
                                        {showConfirmation && <p className="m-0 fs-5 fw-bold">Once deleted they cannot be recovered.</p>}
                                    </div>
                                    <div className="w-100 d-flex align-items-center  justify-content-center">
                                        <button className="btn-save mx-2" onClick={handleCancel}>
                                            {!showConfirmation ? "No" : "No,Keep it. "}
                                        </button>
                                        <button
                                            className="btn-delete mx-2"
                                            onClick={() => {
                                                setShowConfirmation(true);
                                                if (showConfirmation) {
                                                    handleDelete(id);
                                                }
                                            }}
                                        >
                                            {!showConfirmation ? "Yes" : "Yes,Delete !"}
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* </div> */}
        </>
    );
};
export default DeleteModal;
