import { createApi } from "@reduxjs/toolkit/query/react";
import { api } from "../utils/constants/url";
import { baseQueryWithAuthInterceptor } from "./util";
import { showErrorToast } from "../utils/constants/api/toast";

const tagTypes = "Auth";
const type = "Auth";

export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: baseQueryWithAuthInterceptor({
        baseUrl: api.baseURL,
    }),
    tagTypes: [tagTypes],
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (body) => ({
                url: "/login",
                method: "POST",
                body,
            }),
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        forgetPassword: builder.mutation({
            query: (body) => ({
                url: "/forgetPassword",
                method: "POST",
                body,
            }),
            invalidatesTags: [tagTypes],
        }),
        compareCode: builder.mutation({
            query: (body) => ({
                url: "/compareCode",
                method: "POST",
                body,
            }),
            invalidatesTags: [tagTypes],
        }),
        resetPassword: builder.mutation({
            query: (body) => ({
                url: "/resetPassword",
                method: "POST",
                body,
            }),
            invalidatesTags: [tagTypes],
        }),
        logout: builder.mutation({
            query: (body) => {
                return {
                    url: "/logout",
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${body?.authorization}`,
                    },
                    body,
                };
            },
            invalidatesTags: [tagTypes],
        }),
    }),
});

export const { useLoginMutation, useForgetPasswordMutation, useCompareCodeMutation, useResetPasswordMutation, useLogoutMutation } = authApi;
