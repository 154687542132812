import { createSlice } from "@reduxjs/toolkit";

const assignmentSlice = createSlice({
    name: "assignment",
    initialState: {
        assignmentData: {},
    },
    reducers: {
        setAssignment: (state, action) => {
            state.assignmentData = action.payload;
        },
    },
});

export const { setAssignment } = assignmentSlice.actions;
export default assignmentSlice.reducer;
