import { forwardRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

import { isValidArray } from "../../../utils/constants/validation/array";
// import { ModifiedSelectStyle } from "./styles/CreatableSelect";
import { useGetAssignmentQuery } from "../../../api/assignment";

const SelectAssignment = forwardRef(({ isError, defaultAssignment, disabled, handleChange, isLoading, clearFilter, style, incidentTypeId, ...props }, ref) => {
    const standard = useSelector((state) => state);

    const [assignmentList, setAssignmentList] = useState([]);

    const { data: assignmentResponse } = useGetAssignmentQuery(incidentTypeId, {
        refetchOnFocus: true,
        skip: !incidentTypeId,
    });

    useEffect(() => {
        if (assignmentResponse?.status !== "success") return;
        const assignmentOptions = assignmentResponse?.data?.map(({ name, assignmentId }) => {
            return { label: name, value: assignmentId };
        });
        setAssignmentList(assignmentOptions);
    }, [assignmentResponse]);

    return (
        <Select
            options={!defaultAssignment && assignmentList}
            value={defaultAssignment && isValidArray(assignmentList) && assignmentList?.find((option) => option?.value === defaultAssignment)}
            onChange={(selectedOption) => handleChange(selectedOption?.value)}
            isLoading={isLoading}
            ref={ref}
            {...props}
            styles={style}
        />
    );
});

export default SelectAssignment;
