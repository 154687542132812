export const ROUTES = {
    HOME: "/",
    AUTH: {
        LOGIN: "/auth/login",
        REGISTER: "/auth/register",
        FORGOT_PASSWORD: "/auth/forgot-password",
        VERIFY_OTP: "/auth/verify-otp",
        RESET_PASSWORD: "/auth/reset-password",
    },

    ORGANIZATION: "/organization",
    PROFILE: "/organization/profile/:organization/:station",
    GAMEFUNNEL: {
        RESPONSE: "/gameFunnel/responseTypes",
        INCIDENT: "/gameFunnel/incidentTypes/:responseType",
        INCIDENT_PRIORITIES: "/gameFunnel/functionKeys/incidentPriorities/:responseType",
        OBJECTIVES: "/gameFunnel/functionKeys/objectives/:responseType/:incidentPriorities",
        THINKING_PLANNING: "/gameFunnel/functionKeys/thinkingPlanning/:responseType/:incidentPriorities/:objectives",
        ASSIGNMENTS: "/gameFunnel/assignments/:responseType/:incidentType",
        FIREGROUND_TASKS: "/gameFunnel/firegroundTasks/:responseType/:incidentType/:assignmentType",
        CREATE_FIREGROUND_TASKS: "/gameFunnel/firegroundTasks/create/:responseType/:incidentType/:assignmentType",
        EDIT_FIREGROUND_TASKS: "/gameFunnel/firegroundTasks/edit/:responseType/:incidentType/:assignmentType",
        TACTICAL_DECISION_GAME: "/gameFunnel/tacticalDecisionGame/:responseType/:incidentType/:assignmentType/:firegroundTasks",
        CREATE_TACTICAL_DECISION_GAME: "/gameFunnel/tacticalDecisionGame/create/:responseType/:incidentType/:assignmentType/:firegroundTasks",
        EDIT_TACTICAL_DECISION_GAME: "/gameFunnel/tacticalDecisionGame/edit/:responseType/:incidentType/:assignmentType/:firegroundTasks",
        CREATE_THINKING_PLANNING: "/gameFunnel/thinkingPlanning/create/:responseType/:incidentPriorities/:objectives",
        EDIT_THINKING_PLANNING: "/gameFunnel/thinkingPlanning/edit/:responseType/:incidentPriorities/:objectives",
        ACTION_KEY: "/gameFunnel/functionKeys/actionKey/:responseType",
        ACTION_LIST: "/gameFunnel/actionList/:responseType/:actionKey",
    },
    USER_PROFILE: "/userProfile",
    APPARATUS: "/apparatus",
    SCENARIO: "/scenario",
    CREATE_SCENARIO: "/scenario/create",
    EDIT_SCENARIO: "/scenario/edit",
    SCENARIO_ASSIGNMENTS: "/scenarioAssignments",
    CREATE_SCENARIO_ASSIGNMENTS: "/scenarioAssignments/create",
    EDIT_SCENARIO_ASSIGNMENTS: "/scenarioAssignments/edit",
};
