import { createApi } from "@reduxjs/toolkit/query/react";
import { api } from "../utils/constants/url";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { showErrorToast } from "../utils/constants/api/toast";

const tagTypes = "Apparatus";
const type = "Apparatus";
export const apparatusApi = createApi({
    reducerPath: "apparatusApi",
    baseQuery: baseQueryWithAuthInterceptor({
        baseUrl: `${api.baseURL}/apparatus`,
        prepareHeaders,
    }),
    tagTypes: [tagTypes],
    endpoints: (builder) => ({
        getApparatus: builder.query({
            query: () => ({
                url: `/getApparatus`,
                method: "GET",
            }),
            providesTags: [type],
        }),
        createApparatus: builder.mutation({
            query: (body) => ({
                url: "/createApparatus",
                method: "POST",
                body,
            }),
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        updateApparatus: builder.mutation({
            query: (payload) => {
                const { apparatusId, data } = payload;
                return {
                    url: `/updateApparatus/${apparatusId}`,
                    method: "PUT",
                    body: data,
                };
            },
        }),
        deleteApparatus: builder.mutation({
            query: (apparatusId) => ({
                url: `/deleteApparatus/${apparatusId}`,
                method: "DELETE",
            }),
        }),
    }),
});

export const { useGetApparatusQuery, useCreateApparatusMutation, useUpdateApparatusMutation, useDeleteApparatusMutation } = apparatusApi;
