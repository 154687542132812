import { createApi } from "@reduxjs/toolkit/query/react";
import { api } from "../utils/constants/url";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { showErrorToast } from "../utils/constants/api/toast";

const type = "Assignment";
export const assignmentApi = createApi({
    reducerPath: "assignmentApi",
    baseQuery: baseQueryWithAuthInterceptor({
        baseUrl: `${api.baseURL}/assignment`,
        prepareHeaders,
    }),
    tagTypes: [type],
    endpoints: (builder) => ({
        getAssignment: builder.query({
            query: (incidentTypeId) => ({
                url: `/getAssignment/${incidentTypeId}`,
                method: "GET",
            }),
            providesTags: [type],
        }),
        createAssignment: builder.mutation({
            query: (body) => ({
                url: "/createAssignment",
                method: "POST",
                body,
            }),
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        updateAssignment: builder.mutation({
            query: (payload) => {
                const { data, assignmentId } = payload;
                return {
                    url: `/updateAssignment/${assignmentId}`,
                    method: "PUT",
                    body: data,
                };
            },
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        deleteAssignment: builder.mutation({
            query: (assignmentId) => ({
                url: `/deleteAssignment/${assignmentId}`,
                method: "DELETE",
            }),
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        updateIndex: builder.mutation({
            query: (payload) => {
                const { updateIndexResponse, responseTypeId } = payload;
                return {
                    url: `/updateIndex/${responseTypeId}`,
                    method: "POST",
                    body: updateIndexResponse,
                };
            },
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
    }),
});

export const { useGetAssignmentQuery, useCreateAssignmentMutation, useUpdateAssignmentMutation, useDeleteAssignmentMutation, useUpdateIndexMutation } =
    assignmentApi;
