import { createApi } from "@reduxjs/toolkit/query/react";
import { api } from "../utils/constants/url";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { showErrorToast } from "../utils/constants/api/toast";

const tagTypes = "Profile";
const type = "Profile";
export const profileApi = createApi({
    reducerPath: "profileApi",
    baseQuery: baseQueryWithAuthInterceptor({
        baseUrl: `${api.baseURL}/profile`,
        prepareHeaders,
    }),
    tagTypes: [tagTypes],
    endpoints: (builder) => ({
        getProfile: builder.query({
            query: (organizationId) => ({
                url: `/getProfile/${organizationId}`,
                method: "GET",
            }),
            providesTags: [type],
        }),
        createProfile: builder.mutation({
            query: (body) => ({
                url: "/createProfile",
                method: "POST",
                body,
            }),
        }),
        updateProfile: builder.mutation({
            query: (payload) => {
                const { profileId, formData } = payload;
                return {
                    url: `/updateProfile/${profileId}`,
                    method: "PUT",
                    body: formData,
                };
            },
        }),
        deleteProfile: builder.mutation({
            query: (id) => ({
                url: `/deleteProfile/${id}`,
                method: "DELETE",
            }),
        }),
        getProfileById: builder.query({
            query: () => ({
                url: "/getProfileById",
                method: "GET",
            }),
            providesTags: [type],
        }),
        updateProfileOfUser: builder.mutation({
            query: (body) => ({
                url: "/updateProfileOfUser",
                method: "PUT",
                body,
            }),
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        updatePasswordOfProfile: builder.mutation({
            query: (body) => ({
                url: "/updatePasswordOfProfile",
                method: "POST",
                body,
            }),
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        OPPasswordUpdate: builder.mutation({
            query: (body) => {
                return {
                    url: `/OPPasswordUpdate`,
                    method: "PUT",
                    body,
                };
            },
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
    }),
});

export const {
    useGetProfileQuery,
    useCreateProfileMutation,
    useUpdateProfileMutation,
    useDeleteProfileMutation,
    useGetProfileByIdQuery,
    useUpdateProfileOfUserMutation,
    useUpdatePasswordOfProfileMutation,
    useOPPasswordUpdateMutation,
} = profileApi;
