import React, { useState, useEffect, memo } from "react";
import { Offcanvas, CloseButton } from "react-bootstrap";
import AdminIcon from "../../assets/images/Sidebar/admin.svg";
import MenuList from "../../assets/images/Sidebar/menuList.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils/constants/variables/routes";
import Logo from "../../assets/images/Sidebar/logo.png";

import "./style.scss";

const Sidebar = memo(({ show, handleClose }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [userRole, setUserRole] = useState("");
    const getUserOrganizationId = localStorage.getItem("organizationId");
    const getUserStation = localStorage.getItem("station");
    const getUserStatus = localStorage.getItem("status");
    const getUserSubscriptionDate = localStorage.getItem("subscriptionDate");
    const getUserName = localStorage.getItem("name");
    const getUserExpireDate = localStorage.getItem("expireDate");
    useEffect(() => {
        const storedUserRole = localStorage.getItem("userRole");

        if (storedUserRole) {
            setUserRole(storedUserRole);
        }
    }, []);

    const handleTabClick = (route) => {
        navigate(route);
    };

    return (
        <>
            <Offcanvas show={show} onHide={handleClose} responsive="lg">
                <Offcanvas.Body className="d-block p-4 p-lg-0 w-100">
                    <CloseButton onClick={handleClose} className="float-end d-lg-none font-lg position-sticky top-0" />
                    <div className="p-3 text-center">
                        <div className="logo m-auto">
                            <img src={Logo} alt={"Logo"} height={"150px"} width={"150px"} />
                        </div>
                    </div>
                    <div className="side-menu p-3">
                        <ul>
                            <li
                                className={`list-item w-100 my-3 ${location.pathname.includes("organization") ? "active" : ""}`}
                                onClick={() => {
                                    if (userRole === "organization") {
                                        const organizationId = getUserOrganizationId;
                                        const station = getUserStation;
                                        const status = getUserStatus;
                                        const subscriptionDate = getUserSubscriptionDate;
                                        const expireDate = getUserExpireDate;
                                        const name = getUserName;
                                        navigate(`/organization/profile/${organizationId}/${station}`, {
                                            state: {  status, subscriptionDate, expireDate, name },
                                        });
                                    } else {
                                        handleTabClick(ROUTES.ORGANIZATION);
                                    }
                                }}
                            >
                                <div className="menu-item d-flex align-items-center">
                                    <div className="icon-svg me-3">
                                        <img src={MenuList} alt="MenuList" height="25px" width="25px" />
                                    </div>
                                    <div className="item-text">
                                        <span>Organization</span>
                                    </div>
                                </div>
                            </li>

                            <li
                                className={`list-item w-100 my-3 ${location.pathname.includes("gameFunnel") ? "active" : ""}`}
                                onClick={() => handleTabClick(ROUTES.GAMEFUNNEL.RESPONSE)}
                            >
                                <div className="menu-item d-flex align-items-center">
                                    <div className="icon-svg me-3">
                                        <img src={MenuList} alt="MenuList" height="25px" width="25px" />
                                    </div>
                                    <div className="item-text">
                                        <span>Game Funnel</span>
                                    </div>
                                </div>
                            </li>
                            <li
                                className={`list-item w-100 my-3 ${location.pathname.includes("apparatus") ? "active" : ""}`}
                                onClick={() => handleTabClick(ROUTES.APPARATUS)}
                            >
                                <div className="menu-item d-flex align-items-center">
                                    <div className="icon-svg me-3">
                                        <img src={MenuList} alt="MenuList" height="25px" width="25px" />
                                    </div>
                                    <div className="item-text">
                                        <span>Apparatus</span>
                                    </div>
                                </div>
                            </li>
                            <li
                                className={`list-item w-100 my-3 ${location.pathname.includes("scenario") ? "active" : ""}`}
                                onClick={() => handleTabClick(ROUTES.SCENARIO)}
                            >
                                <div className="menu-item d-flex align-items-center">
                                    <div className="icon-svg me-3">
                                        <img src={MenuList} alt="MenuList" height="25px" width="25px" />
                                    </div>
                                    <div className="item-text">
                                        <span>Scenario</span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div className="menu-item">
                            <div className="d-flex align-items-center"></div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
});

export default Sidebar;
