import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
// import "./style.scss";
import { useNavigate } from "react-router-dom";
import { useCreateApparatusMutation, useUpdateApparatusMutation, useDeleteApparatusMutation } from "../../../../api/apparatus";
import { defaultValues } from "../../../../utils/constants/forms/defaultValues";
import { validation } from "../../../../utils/constants/forms/validation";
import { API } from "../../../../utils/constants/variables/apiFields";

const CreateEditApparatus = (props) => {
    const isEdit = props.showEditForm;
    const apparatusDetail = props.apparatusDetail;
    const [createApparatus] = useCreateApparatusMutation();
    const [updateApparatus] = useUpdateApparatusMutation();
    const [deleteApparatus] = useDeleteApparatusMutation();

    const navigate = useNavigate();

    const form = useForm({
        defaultValues: defaultValues.APPARATUS,
        resolver: yupResolver(validation.APPARATUS),
    });

    const {
        handleSubmit,
        formState: { errors },
        register,
        watch,
        setValue,
    } = form;

    useEffect(() => {
        if (apparatusDetail?.id) {
            setValue("station", apparatusDetail?.station);
            setValue("totalApparatus", apparatusDetail?.totalApparatus);
            setValue("apparatusName", apparatusDetail?.apparatusName);
        }
    }, [setValue]);

    const onSubmit = async (data) => {
        const apparatusId = apparatusDetail?.id;
        const response = isEdit ? await updateApparatus({ data, apparatusId }) : await createApparatus(data);
        if (response?.data?.status === "success") {
            props.onHide();
            props.refetchCreateEditApparatus();
            toast.success(response?.data?.message, { autoClose: 1000 });
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    return (
        <div>
            <div className="create-edit-acconut">
                <Modal {...props} size="md" className="m-8 " centered aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Body className="p-3">
                        <div className="my-3 text-center">
                            <button className="btn-custom">{!isEdit ? "Create" : "Edit"}</button>
                        </div>
                        <div className="user-form">
                            <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                                <div className="frm-input">
                                    <Form.Group className="mb-3">
                                        <Form.Label className=" mb-0 text-fs">Station</Form.Label>
                                        <Form.Control className="custom-input" type="text" name={API.APPARATUS.STATION} {...register(API.APPARATUS.STATION)} />
                                    </Form.Group>

                                    <div className="error-msg">{errors?.station && <span className="text-danger">{errors?.station?.message}</span>}</div>
                                </div>
                                <div className="frm-input">
                                    <Form.Group className="mb-3">
                                        <Form.Label className=" mb-0 text-fs">Total apparatus</Form.Label>
                                        <Form.Control
                                            className="custom-input"
                                            type="number"
                                            name={API.APPARATUS.TOTALAPPARATUS}
                                            {...register(API.APPARATUS.TOTALAPPARATUS)}
                                        />
                                    </Form.Group>

                                    <div className="error-msg">
                                        {errors?.totalApparatus && <span className="text-danger">{errors?.totalApparatus?.message}</span>}
                                    </div>
                                </div>
                                <div className="frm-input">
                                    <Form.Group className="mb-3">
                                        <Form.Label className=" mb-0 text-fs">Apparatus name</Form.Label>
                                        <Form.Control
                                            className="custom-input"
                                            type="text"
                                            name={API.APPARATUS.APPARATUSNAME}
                                            {...register(API.APPARATUS.APPARATUSNAME)}
                                        />
                                    </Form.Group>

                                    <div className="error-msg">
                                        {errors?.apparatusName && <span className="text-danger">{errors?.apparatusName?.message}</span>}
                                    </div>
                                </div>

                                {!isEdit ? (
                                    <div>
                                        <div className="w-100 text-center mt-4">
                                            <button type="submit" className="btn-save">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="m-0 w-100 d-flex justify-content-center">
                                            <button type="submit" className="btn-save">
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </Form>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
};

export default CreateEditApparatus;
