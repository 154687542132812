import { Controller, useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import OTPInput from "react-otp-input";

import "./style.scss";
import otpimgbackground from "../../../assets/images/otpbackground.svg";
import { ROUTES } from "../../../utils/constants/variables/routes";
import { validation } from "../../../utils/constants/forms/validation";
import { defaultValues } from "../../../utils/constants/forms/defaultValues";
import { API } from "../../../utils/constants/variables/apiFields";
import { useCompareCodeMutation } from "../../../api/auth";
import { toast } from "react-toastify";
import Logo from "../../../assets/images/Sidebar/logo.png";

const { VERIFICATION_CODE } = API.VERIFY_OTP;

const VerifyOTP = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const [verifyOTP, { isLoading }] = useCompareCodeMutation();

    const form = useForm({
        defaultValues: defaultValues.VERIFY_OTP,
        resolver: yupResolver(validation.VERIFY_OTP),
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = form;

    const onSubmit = async (data) => {
        const response = await verifyOTP({ ...data, email: state?.email });

        if (["success", "Success", 200, 201, 202].includes(response?.data?.status)) {
            navigate(ROUTES.AUTH.RESET_PASSWORD, { state: { email: state?.email } });
            toast.success(response?.data?.message, { autoClose: 1000 });
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    return (
        <>
            <div className="verifyOTP">
                <div className="container-fluid d-flex">
                    <div className="row verify-otp">
                        <div className="col-12 col-lg-6 col-md-12">
                            <div className="image">
                                <img src={otpimgbackground} alt="as" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-md-12">
                            <div className="content">
                                <div className="user-img">
                                    <img src={Logo} alt={"Logo"} />
                                </div>
                                <div className="user-content">
                                    <div className="title">
                                        <span>Enter your verification code.</span>
                                    </div>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <div>
                                            <div className="otp-input">
                                                <Controller
                                                    control={control}
                                                    name={VERIFICATION_CODE}
                                                    render={({ field }) => (
                                                        <OTPInput
                                                            inputStyle={{
                                                                width: "60px",
                                                                height: "60px",
                                                                borderRadius: "10px",
                                                                background: "#fff",
                                                                border: "2px solid #c9ced3",
                                                                boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.25)",
                                                                fontWeight: "bold",
                                                                fontSize: "1.5em",
                                                            }}
                                                            containerStyle={{
                                                                display: "flex",
                                                            }}
                                                            value={field.value}
                                                            onChange={field.onChange}
                                                            numInputs={6}
                                                            renderSeparator={<span></span>}
                                                            renderInput={(props) => <input type name={VERIFICATION_CODE} {...props} />}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            {errors?.verificationCode && <span className="text-danger bg-white">{errors?.verificationCode?.message}</span>}
                                        </div>
                                        <div className="discription">
                                            <p>We have sent verification code to your email address.</p>
                                        </div>
                                        <div className="btn-login text-center">
                                            <button disabled={isLoading} type="submit">
                                                VERIFY
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VerifyOTP;
