import React, { useState, forwardRef, useEffect } from "react";
import { FormControl } from "react-bootstrap";
import "./style.scss";
import BlackSearch from "../../../assets/images/action/blackSearch.svg";

const SearchFileInput = forwardRef((props, ref) => {
    const { type, onChange, id, className, accept, incidentPrioritiesDetail } = props;
    const icon = incidentPrioritiesDetail?.icon;

    const [selectedFile, setSelectedFile] = useState();
    useEffect(() => {
        if (incidentPrioritiesDetail && icon) {
            const url = new URL(icon);
            const iconUrl = url.pathname.split("/").pop();
            setSelectedFile(iconUrl);
        } else {
            const url = icon || "";
            setSelectedFile(url);
        }
    }, [incidentPrioritiesDetail]);

    return (
        <div className={className}>
            <FormControl
                type={type}
                onChange={onChange}
                onChangeCapture={(e) => {
                    setSelectedFile(e.target.files[0].name);
                }}
                id={id}
                className="d-none"
                accept={accept}
                ref={ref}
            />
            <label htmlFor={id} className="search_file_input p-2">
                <div className="text-muted text-fs text-truncate">{selectedFile}</div>
                <div>
                    <img alt="BlackSearch" src={BlackSearch} />
                </div>
            </label>
        </div>
    );
});

export default SearchFileInput;
