import React from "react";
import DeleteIcon from "../../../assets/images/action/deleteIcon.svg";

const CreateEditImageDisplay = ({ selectedImage, onRemoveImage }) => {
    const imageUrl = selectedImage instanceof Blob ? URL.createObjectURL(selectedImage) : selectedImage;

    const handleRemoveImage = () => {
        // Call the onRemoveImage function to remove the selected image
        onRemoveImage();
    };

    return (
        selectedImage && (
            <div className="col-12 d-flex flex-row gap-4 border-1 border-black">
                <div style={{ height: "80px", width: "80px" }} className="position-relative">
                    <img
                        alt="Selected Image"
                        src={imageUrl}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                        }}
                    />
                    {/* Call handleRemoveImage when delete icon is clicked */}
                    <img className="custom-deleteIcon" alt="DeleteIcon" src={DeleteIcon} onClick={handleRemoveImage} />
                </div>
            </div>
        )
    );
};

export default CreateEditImageDisplay;
