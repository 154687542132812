import React from "react";
import { forwardRef, useState, useEffect } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";

import { useGetResponseTypeQuery } from "../../../api/responseType";
import { isValidArray } from "../../../utils/constants/validation/array";

const SelectResponse = forwardRef(({ isError, disabled, defaultResponse, handleChange, clearFilter, style, ...props }, ref) => {
    const [selectResponseType, setSelectResponseType] = useState([]);

    const { data: selectResponse } = useGetResponseTypeQuery(null, { refetchOnFocus: true });
    useEffect(() => {
        if (selectResponse?.status !== "success") return;
        const selectOptions = selectResponse?.data?.map(({ name, responseTypeId }) => {
            return { label: name, value: responseTypeId };
        });
        setSelectResponseType(selectOptions);

        
    }, [selectResponse]);
    return (
        <Select
            options= { !defaultResponse && selectResponseType}
            value={defaultResponse && isValidArray(selectResponseType) && selectResponseType?.find((option) => option?.value === defaultResponse)}
            onChange={(selectedOption) => handleChange(selectedOption?.value)}
            ref={ref}
            {...props}
            styles={style}
        />
    );
});

export default SelectResponse;
