// import "./App.css";
import { Fragment, useEffect } from "react";
// import { Fragment, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RouterWrapper from "./Routes";
import "bootstrap/dist/css/bootstrap.min.css";
import Auth from "./components/Auth";

function App() {
    return (
        <Fragment>
            {/* ROUTER WRAPPER COMPONENT */}
            <Auth />
            <RouterWrapper />

            {/* MODAL COMPONENT */}
            {/* <Suspense>{modal?.delete?.open && <DeleteModal data={modal?.delete?.data} />}</Suspense> */}

            {/* GLOBAL TOASTER COMPONENT */}
            <ToastContainer newestOnTop={true} autoClose={false} icon={true} />
        </Fragment>
    );
}

export default App;
