import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { BiSolidUser } from "react-icons/bi";
import { Form, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";

import "./style.scss";

import emailBackGround from "../../../assets/images/emailbackground.svg";
import { validation } from "../../../utils/constants/forms/validation";
import { defaultValues } from "../../../utils/constants/forms/defaultValues";
import { useForgetPasswordMutation } from "../../../api/auth";
import { ROUTES } from "../../../utils/constants/variables/routes";
import { API } from "../../../utils/constants/variables/apiFields";
import Logo from "../../../assets/images/Sidebar/logo.png";

const { EMAIL } = API.FORGOT_PASSWORD;

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [forgetPassword] = useForgetPasswordMutation();

    const form = useForm({
        defaultValues: defaultValues.FORGOT_PASSWORD,
        resolver: yupResolver(validation.FORGOT_PASSWORD),
    });

    const {
        handleSubmit,
        formState: { errors },
        register,
    } = form;

    const onSubmit = async (data) => {
        const response = await forgetPassword(data);
        if (["success", "Success", 200, 201, 202].includes(response?.data?.status)) {
            navigate(ROUTES.AUTH.VERIFY_OTP, { state: data });
            toast.success(response?.data?.message, { autoClose: 1000 });
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    return (
        <div className="forgotPassword">
            <div className="container-fluid d-flex p-sm-0">
                <div className="row verify-email">
                    <div className="col-12 col-lg-6 col-md-6 col-sm-6">
                        <div className="image">
                            <img src={emailBackGround} alt="as" />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 col-md-6 col-sm-6">
                        <div className="content">
                            <div className="user-img">
                                <img src={Logo} alt={"Logo"} />
                            </div>
                            <div className="user-content">
                                <div className="title fs-3 fw-bold">
                                    Please enter the verify <br />
                                    Email Address.
                                </div>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <div>
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <BiSolidUser />
                                            </InputGroup.Text>
                                            <Form.Control type="email" name={EMAIL} {...register(EMAIL)} placeholder="Enter Email Address" />
                                        </InputGroup>
                                        {errors?.email && <span className="text-danger">{errors?.email?.message}</span>}
                                    </div>
                                    <div className="discription">
                                        <p>We will send you the 6 digit verification code on this email address.</p>
                                    </div>
                                    <div className="btn-login text-center">
                                        <button className="emailbtn" type="submit">
                                            GENERATE OTP
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
