import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { parse, isValid, format } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import "./style.scss";
import { useCreateOrganizationMutation } from "../../../../api/organization";
import { useUpdateOrganizationMutation } from "../../../../api/organization";
import { useDeleteOrganizationMutation } from "../../../../api/organization";
import { useUpdatePasswordMutation } from "../../../../api/organization";
import { defaultValues } from "../../../../utils/constants/forms/defaultValues";
import { validation } from "../../../../utils/constants/forms/validation";
import { API } from "../../../../utils/constants/variables/apiFields";
import { useNavigate } from "react-router-dom";
import { FaRegUser } from "react-icons/fa";

const appendFormValuesToFormData = (formData, data) => {
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            formData.append(key, data[key]);
        }
    }
};

const CreateEditOrganization = (props) => {
    const organizationDetail = props.organizationDetail;
    const isEdit = props.showEditForm;
    const navigate = useNavigate();
    const [createOrganization] = useCreateOrganizationMutation();
    const [updateOrganization] = useUpdateOrganizationMutation();
    const [deleteOrganization] = useDeleteOrganizationMutation();
    const [updatePassword] = useUpdatePasswordMutation();
    const [selectedPhoto, setSelectedPhoto] = useState(null);

    const handlePhotoSelect = (event) => {
        const file = event.target.files[0];
        setSelectedPhoto(file);
        setValue("logo", file);
    };
    const isDefaultLogo = (logo) => {
        return logo && logo.toLowerCase().includes("null");
    };
    const form = useForm({
        defaultValues: defaultValues.ORGANIZATION,
        resolver: yupResolver(validation.ORGANIZATION(isEdit)),
    });
    const formPassword = useForm({
        defaultValues: defaultValues.ORGANIZATION,
        resolver: yupResolver(validation.ORGANIZATION_PASSWORD),
    });

    const {
        handleSubmit,
        formState: { errors },
        register,
        watch,
        setValue,
    } = form;
    const {
        handleSubmit: onPasswordSubmit,
        formState: { errors: passwordError },
        register: passwordRagister,
        watch: passwordWatch,
        setValue: passwordSetValue,
    } = formPassword;
    useEffect(() => {
        if (organizationDetail?.id) {
            setValue("name", organizationDetail?.name);
            setValue("email", organizationDetail?.email);
            setValue("station", organizationDetail?.station);
            setValue("contactName", organizationDetail?.contactName);
            setValue("contactNumber", organizationDetail?.contactNumber);

            const subscriptionDate = organizationDetail?.subscriptionDate;
            const parsedDate = parse(subscriptionDate, "dd/MM/yyyy", new Date());

            if (isValid(parsedDate)) {
                const formattedDate = `${parsedDate.getFullYear()}-${(parsedDate.getMonth() + 1).toString().padStart(2, "0")}-${parsedDate
                    .getDate()
                    .toString()
                    .padStart(2, "0")}`;
                setValue(API.ORGANIZATION.SUBSCRIPTION_DATE, formattedDate);
            }

            const logo = organizationDetail?.logo;
            setSelectedPhoto(isDefaultLogo(logo) ? null : logo);
        }
    }, [setValue, organizationDetail]);
    console.log("watch====", watch());
    console.log("errors====", errors);
    const handleUpdatePassword = async (data) => {
        const organizationId = organizationDetail?.id;
        const allData = {
            organizationId: organizationId,
            password: data?.password,
        };
        const response = await updatePassword(allData);
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    const onSubmit = async (data) => {
        const formData = new FormData();
        appendFormValuesToFormData(formData, data);
        const organizationId = organizationDetail?.id;
        const response = isEdit ? await updateOrganization({ formData, organizationId }) : await createOrganization(formData);
        if (response?.data?.status === "success") {
            props.onHide();
            props.refecthCreateEditOrganization();
            toast.success(response?.data?.message, { autoClose: 1000 });
        } else {
            toast.error(response?.error?.data?.message, {
                autoClose: false, // This will ensure the error toast does not close automatically
            });
        }
    };
    return (
        <div>
            <div className="create-edit-organization">
                <Modal {...props} size="lg" className="m-8 " centered aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Body className="p-3">
                        {!isEdit ? (
                            <div>
                                <div className="my-3 text-center">
                                    <button className="btn-custom">Add detail</button>
                                </div>
                                <div className="user-form">
                                    <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-4 col-lg-4 my-2 d-flex align-items-center justify-content-center">
                                                <div className="select_photo">
                                                    <label htmlFor="photoInput" className="logo-image d-flex align-items-center justify-content-center">
                                                        {selectedPhoto ? (
                                                            <img
                                                                src={typeof selectedPhoto === "string" ? selectedPhoto : URL.createObjectURL(selectedPhoto)}
                                                                alt="Selected_logo"
                                                                className="selected_photo"
                                                            />
                                                        ) : (
                                                            <>
                                                                <FaRegUser size={48} color="#999" />
                                                            </>
                                                        )}
                                                    </label>
                                                    <input
                                                        id="photoInput"
                                                        type="file"
                                                        accept="image/png ,image/jpg ,image/jpeg"
                                                        {...register("logo")}
                                                        onChange={handlePhotoSelect}
                                                        className="d-none"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-8 col-lg-8 my-2">
                                                <div className="frm-input">
                                                    <Form.Group className="my-2">
                                                        <Form.Label className=" mb-0">Organization name</Form.Label>
                                                        <Form.Control
                                                            className="custom-input"
                                                            type="text"
                                                            name={API.ORGANIZATION.NAME}
                                                            {...register(API.ORGANIZATION.NAME)}
                                                        />
                                                    </Form.Group>
                                                    <div className="error-msg">
                                                        {errors?.name && <span className="text-danger">{errors?.name?.message}</span>}
                                                    </div>

                                                    <Form.Group className="my-2">
                                                        <Form.Label className=" mb-0">Station</Form.Label>
                                                        <Form.Control
                                                            className="custom-input"
                                                            type="text"
                                                            name={API.ORGANIZATION.STATION}
                                                            {...register(API.ORGANIZATION.STATION)}
                                                        />
                                                    </Form.Group>
                                                    <div className="error-msg">
                                                        {errors?.station && <span className="text-danger">{errors?.station?.message}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 my-2">
                                                <div className="frm-input">
                                                    <Form.Group>
                                                        <Form.Label className=" mb-0">Name</Form.Label>
                                                        <Form.Control
                                                            className="custom-input"
                                                            type="text"
                                                            name={API.ORGANIZATION.CONTACT_NAME}
                                                            {...register(API.ORGANIZATION.CONTACT_NAME)}
                                                        />
                                                    </Form.Group>
                                                    <div className="error-msg">
                                                        {errors?.contactName && <span className="text-danger">{errors?.contactName?.message}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 my-2">
                                                <div className="frm-input">
                                                    <Form.Group>
                                                        <Form.Label className=" mb-0">Number</Form.Label>
                                                        <Form.Control
                                                            className="custom-input"
                                                            type="number"
                                                            name={API.ORGANIZATION.CONTACT_NUMBER}
                                                            {...register(API.ORGANIZATION.CONTACT_NUMBER)}
                                                        />
                                                    </Form.Group>
                                                    <div className="error-msg">
                                                        {errors?.contactNumber && <span className="text-danger">{errors?.contactNumber?.message}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 my-2">
                                                <div className="frm-input">
                                                    <Form.Group>
                                                        <Form.Label className=" mb-0">Email</Form.Label>
                                                        <Form.Control
                                                            className="custom-input"
                                                            type="email"
                                                            name={API.ORGANIZATION.EMAIL}
                                                            {...register(API.ORGANIZATION.EMAIL)}
                                                        />
                                                    </Form.Group>
                                                    <div className="error-msg">
                                                        {errors?.email && <span className="text-danger">{errors?.email?.message}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 my-2">
                                                <div className="frm-input">
                                                    <Form.Group>
                                                        <Form.Label className=" mb-0">Subscription date</Form.Label>
                                                        <Form.Control
                                                            className="custom-input"
                                                            type="date"
                                                            name={API.ORGANIZATION.SUBSCRIPTION_DATE}
                                                            {...register(API.ORGANIZATION.SUBSCRIPTION_DATE)}
                                                        />
                                                    </Form.Group>
                                                    <div className="error-msg">
                                                        {errors?.subscriptionDate && <span className="text-danger">{errors?.subscriptionDate?.message}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 my-2 shift-select">
                                                <Form.Group>
                                                    <Form.Label className=" mb-0">Shift</Form.Label>
                                                    <Form.Control
                                                        className="custom-input"
                                                        as="select"
                                                        name={API.ORGANIZATION.SHIFT}
                                                        {...register(API.ORGANIZATION.SHIFT)}
                                                    >
                                                        <option value="A">A</option>
                                                        <option value="B">B</option>
                                                        <option value="C">C</option>
                                                        <option value="D">D</option>
                                                    </Form.Control>
                                                </Form.Group>
                                                <div className="error-msg">
                                                    {errors?.shift && <span className="text-danger">{errors?.shift?.message}</span>}
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 my-2">
                                                <div className="frm-input">
                                                    <Form.Group>
                                                        <Form.Label className=" mb-0">Rank</Form.Label>
                                                        <Form.Control
                                                            className="custom-input"
                                                            as="select"
                                                            name={API.ORGANIZATION.RANK}
                                                            {...register(API.ORGANIZATION.RANK)}
                                                        >
                                                            <option value="fireFighter">Fire Fighter</option>
                                                            <option value="officer">Officer</option>
                                                            <option value="chiefOfficer">Chief Officer</option>
                                                            <option value="Combinations">Combinations</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <div className="error-msg">
                                                        {errors?.rank && <span className="text-danger">{errors?.rank?.message}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 my-2">
                                                <div className="frm-input">
                                                    <Form.Group>
                                                        <Form.Label className=" mb-0">Password</Form.Label>
                                                        <Form.Control
                                                            className="custom-input"
                                                            type="password"
                                                            name={API.ORGANIZATION.PASSWORD}
                                                            {...register(API.ORGANIZATION.PASSWORD)}
                                                        />
                                                    </Form.Group>
                                                    <div className="error-msg">
                                                        {errors?.password && <span className="text-danger">{errors?.password?.message}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-100 text-center my-3">
                                                <button type="submit" className="btn-save">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="my-3 text-center">
                                    <button className="btn-custom">Edit detail</button>
                                </div>
                                <div className="user-form">
                                    <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-4 col-lg-4 my-2 d-flex align-items-center justify-content-center">
                                                <div className="select_photo">
                                                    <label htmlFor="photoInput" className="logo-image d-flex align-items-center justify-content-center">
                                                        {selectedPhoto ? (
                                                            <img
                                                                src={typeof selectedPhoto === "string" ? selectedPhoto : URL.createObjectURL(selectedPhoto)}
                                                                alt="Selected_logo"
                                                                className="selected_photo"
                                                            />
                                                        ) : (
                                                            <>
                                                                <FaRegUser size={48} color="#999" />
                                                                {/* <p className="m-0">Select Logo</p> */}
                                                            </>
                                                        )}
                                                    </label>
                                                    <input
                                                        id="photoInput"
                                                        type="file"
                                                        accept="image/png ,image/jpg ,image/jpeg"
                                                        {...register("logo")}
                                                        onChange={handlePhotoSelect}
                                                        className="d-none"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-8 col-lg-8 my-2">
                                                <div className="frm-input">
                                                    <Form.Group className="my-2">
                                                        <Form.Label className=" mb-0">Organization name</Form.Label>
                                                        <Form.Control
                                                            className="custom-input"
                                                            type="text"
                                                            name={API.ORGANIZATION.NAME}
                                                            {...register(API.ORGANIZATION.NAME)}
                                                        />
                                                    </Form.Group>
                                                    <div className="error-msg">
                                                        {errors?.name && <span className="text-danger">{errors?.name?.message}</span>}
                                                    </div>

                                                    <Form.Group className="my-2">
                                                        <Form.Label className=" mb-0">Station</Form.Label>
                                                        <Form.Control
                                                            className="custom-input"
                                                            type="text"
                                                            name={API.ORGANIZATION.STATION}
                                                            {...register(API.ORGANIZATION.STATION)}
                                                        />
                                                    </Form.Group>
                                                    <div className="error-msg">
                                                        {errors?.station && <span className="text-danger">{errors?.station?.message}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 my-2">
                                                <div className="frm-input">
                                                    <Form.Group>
                                                        <Form.Label className=" mb-0">Name</Form.Label>
                                                        <Form.Control
                                                            className="custom-input"
                                                            type="text"
                                                            name={API.ORGANIZATION.CONTACT_NAME}
                                                            {...register(API.ORGANIZATION.CONTACT_NAME)}
                                                        />
                                                    </Form.Group>
                                                    <div className="error-msg">
                                                        {errors?.contactName && <span className="text-danger">{errors?.contactName?.message}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 my-2">
                                                <div className="frm-input">
                                                    <Form.Group>
                                                        <Form.Label className=" mb-0">Number</Form.Label>
                                                        <Form.Control
                                                            className="custom-input"
                                                            type="number"
                                                            name={API.ORGANIZATION.CONTACT_NUMBER}
                                                            {...register(API.ORGANIZATION.CONTACT_NUMBER)}
                                                        />
                                                    </Form.Group>
                                                    <div className="error-msg">
                                                        {errors?.contactNumber && <span className="text-danger">{errors?.contactNumber?.message}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 my-2">
                                                <div className="frm-input">
                                                    <Form.Group>
                                                        <Form.Label className=" mb-0">Email</Form.Label>
                                                        <Form.Control
                                                            className="custom-input"
                                                            type="email"
                                                            name={API.ORGANIZATION.EMAIL}
                                                            {...register(API.ORGANIZATION.EMAIL)}
                                                        />
                                                    </Form.Group>
                                                    <div className="error-msg">
                                                        {errors?.email && <span className="text-danger">{errors?.email?.message}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 my-2">
                                                <div className="frm-input">
                                                    <Form.Group>
                                                        <Form.Label className=" mb-0">Subscription date</Form.Label>
                                                        <Form.Control
                                                            className="custom-input"
                                                            type="date"
                                                            name={API.ORGANIZATION.SUBSCRIPTION_DATE}
                                                            {...register(API.ORGANIZATION.SUBSCRIPTION_DATE)}
                                                        />
                                                    </Form.Group>
                                                    <div className="error-msg">
                                                        {errors?.subscriptionDate && <span className="text-danger">{errors?.subscriptionDate?.message}</span>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="w-100 text-center my-3 d-flex align-items-center justify-content-center">
                                                <button type="submit" className="btn-save mx-2">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>

                                <div className="user-form">
                                    <Form role="form" onSubmit={onPasswordSubmit(handleUpdatePassword)}>
                                        <div className="frm-input row update-password">
                                            <div className="col-8 password-input">
                                                <Form.Group>
                                                    <Form.Control
                                                        className="custom-input"
                                                        type="password"
                                                        name={API.ORGANIZATION.PASSWORD}
                                                        {...passwordRagister(API.ORGANIZATION.PASSWORD)}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-4 update-btn">
                                                <button type="submit" className="btn-default">
                                                    Update Password
                                                </button>
                                            </div>
                                        </div>
                                        <div className="error-msg">
                                            {passwordError?.password && <span className="text-danger">{passwordError?.password?.message}</span>}
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
};

export default CreateEditOrganization;
