import React, { useState, useEffect } from "react";
import "../../../views/styles/app.scss";
import { useNavigate } from "react-router-dom";
import { Col, Container, Navbar, Row } from "react-bootstrap";
import { ROUTES } from "../../../utils/constants/variables/routes";
import ToggleIcon from "../../../assets/images/Sidebar/toggle.svg";
import Sidebar from "../../Sidebar";
import { useGetProfileByIdQuery } from "../../../api/profile";
import { FaRegUser } from "react-icons/fa";

const MainLayout = ({ children }) => {
    const [show, setShow] = useState(false);
    const [profileImage, setProfileImage] = useState("");
    const [profileName, setProfileName] = useState("");
    const handleToggle = () => setShow(!show);
    const navigate = useNavigate();

    const { data: userProfile, refetch } = useGetProfileByIdQuery();
    const storedUserRole = userProfile?.data?.userRole;

    useEffect(() => {
        refetch();
        if (storedUserRole === "organization") {
            setProfileImage(userProfile?.data?.data?.logo);
            setProfileName(userProfile?.data?.data?.contactName);
            
        }
        if (storedUserRole === "superAdmin") {
            setProfileImage(userProfile?.data?.profileImage);
            setProfileName(userProfile?.data?.profileName);
        }
    }, [refetch, userProfile]);

    return (
        <Container fluid className="px-0">
            <Row className="w-100 mx-0">
                <Col className="sidebar vh-100 d-none  d-lg-block d-xl-block d-xxl-block overflow-hidden" xxl={2} xl={3} lg={2}>
                    <Sidebar show={show} handleClose={handleToggle} />
                </Col>
                <Col className="top-navbar" xxl={10} xl={9} lg={10}>
                    <Navbar className="p-3 pe-0">
                        <div className="btn-toggle">
                            <div onClick={handleToggle} className="me-3 d-block  d-lg-none">
                                <img src={ToggleIcon} alt={"ToggleIcon"} height={"25px"} width={"25px"} />
                            </div>
                        </div>
                        <div className="search-bar d-flex w-100 align-items-center justify-content-between">
                            <div className="search-content d-flex align-items-center justify-content-lg-start justify-content-md-end justify-content-sm-end w-100"></div>
                            <div className="admin-profile">
                                <div className="d-flex align-items-center float-end" onClick={() => navigate(ROUTES.USER_PROFILE)}>
                                    {profileImage ? (
                                        <img src={profileImage} alt={"Profile"} className="rounded-circle profile-img me-2 p-0" />
                                    ) : (
                                        <div className="rounded-circle profile-img me-2 p-0 d-flex align-items-center justify-content-center">
                                            <FaRegUser size={25} className="user-svg" />
                                        </div>
                                    )}

                                    <span className="d-none d-lg-block d-xl-block d-xxl-block">{profileName}</span>
                                </div>
                            </div>
                        </div>
                        <div></div>
                    </Navbar>
                    {children}
                </Col>
            </Row>
        </Container>
    );
};

export default MainLayout;
