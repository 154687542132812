import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { BiSolidUser } from "react-icons/bi";
import { AiFillLock } from "react-icons/ai";

import "./style.scss";
import { useLoginMutation } from "../../../api/auth";
import { validation } from "../../../utils/constants/forms/validation";
import { defaultValues } from "../../../utils/constants/forms/defaultValues";
import { ROUTES } from "../../../utils/constants/variables/routes";
import { API } from "../../../utils/constants/variables/apiFields";
import Logo from "../../../assets/images/Sidebar/logo.png";

const Login = () => {
    const navigate = useNavigate();

    const [login, refetch] = useLoginMutation();

    const form = useForm({
        defaultValues: defaultValues.LOGIN,
        resolver: yupResolver(validation.LOGIN),    
    });

    const {
        handleSubmit,
        formState: { errors },
        register,
    } = form;

    const onSubmit = async (data) => {
        const response = await login(data);
        localStorage.setItem("userRole", response?.data?.userRole);
        localStorage.setItem("token", response?.data?.token);
        localStorage.setItem("refreshToken", response?.data?.refreshToken);

        if (response?.data?.status === 202) {
            console.log("response====", response);
            if (response?.data?.userRole === "organization") {
                const organizationId = response?.data?.data?.organizationId;
                const station = response?.data?.data?.station;
                const name = response?.data?.data?.name;
                const status = response?.data?.data?.status;
                const subscriptionDate = response?.data?.data?.subscriptionDate;
                const expireDate = response?.data?.data?.expireDate;
                localStorage.setItem("organizationId", response?.data?.data?.organizationId);
                localStorage.setItem("name", response?.data?.data?.name);
                localStorage.setItem("status", response?.data?.data?.status);
                localStorage.setItem("subscriptionDate", response?.data?.data?.subscriptionDate);
                localStorage.setItem("expireDate", response?.data?.data?.expireDate);
                localStorage.setItem("station", response?.data?.data?.station);

                navigate(`/organization/profile/${organizationId}/${station}`);

                refetch();
            } else {
                navigate(ROUTES.ORGANIZATION);
                refetch();
            }
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    return (
        <>
            <div className="login">
                <div className="container-fluid">
                    <div className="row">
                        <div className="user-login">
                            <div className="user-img">
                                <img src={Logo} alt={"Logo"} />
                            </div>
                            <div className="user-form">
                                <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="frm-input">
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <BiSolidUser />
                                            </InputGroup.Text>
                                            <Form.Control name={API.LOGIN.EMAIL} {...register(API.LOGIN.EMAIL)} placeholder="Username" />
                                        </InputGroup>
                                        <div className="error-msg">{errors?.email && <span className="text-danger">{errors?.email?.message}</span>}</div>
                                    </div>
                                    <div className="frm-input">
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <AiFillLock />
                                            </InputGroup.Text>
                                            <Form.Control
                                                name="password"
                                                {...register("password")}
                                                type="password"
                                                placeholder="Password"
                                                aria-label="password"
                                                aria-describedby="basic-addon1"
                                            />
                                        </InputGroup>
                                        <div className="error-msg">{errors?.password && <span className="text-danger">{errors?.password?.message}</span>}</div>
                                    </div>
                                    <div className="cursor-pointer">
                                        <p className="fs-6 text-white" onClick={() => navigate(ROUTES.AUTH.FORGOT_PASSWORD)}>
                                            Forgot Password?
                                        </p>
                                    </div>
                                    <div className="btn-login w-100 text-center">
                                        <button>LOGIN</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
