import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetTokenVerifyMutation, useRefreshTokenMutation } from "../../api/tokenVerify";
import { ROUTES } from "../../utils/constants/variables/routes";

const Auth = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    useEffect(() => {
        const token = localStorage.getItem("token");
        const refreshToken = localStorage.getItem("refreshToken");
        const userRole = localStorage.getItem("userRole");
        if (token && refreshToken && pathname === ROUTES.AUTH.LOGIN) {
            if (userRole === "superAdmin") {
                navigate(ROUTES.ORGANIZATION);
            } else if (userRole === "organization") {
                const organizationId = localStorage.getItem("organizationId");
                const station = localStorage.getItem("station");
                navigate(`/organization/profile/${organizationId}/${station}`);
            }
        }
    }, [pathname, navigate]);

    return null;
};

export default Auth;
