import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useImportMutation } from "../../../../../api/importData";

const ImportFunctionKeyModal = (props) => {
    const type = props.type;
    const tdgLibraryId = props.tdgLibraryId;
    const arrayOfIds = props.arrayOfIds;
    const [importData, refetch] = useImportMutation();
    const [ImportTacticalDecisionGame, setImportTacticalDecisionGame] = useState([]);
    const handleImport = async () => {
        const payload = { type, tdgLibraryId, arrayOfIds, isForce: true };
        const response = await importData(payload);
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            props.onHide();
            props?.firstModalHide();
            props.refetchData();
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };
    const handleCancel = () => {
        props.onHide();
        props?.firstModalHide();
    };

    return (
        <>
            {/* <div> */}
            <Modal {...props} size="md" className="d-flex align-items-center">
                <Modal.Body>
                    <div className="row p-4 text-center">
                        <div className="col-12">
                            <div className="bg-white">
                                <div className="my-3 mb-5">
                                    <p className="m-0 fs-5 fw-bold">Action Key or Function Key are not found in your organization's. Please import it first.</p>
                                </div>
                                <div className="w-100 d-flex align-align-items-center justify-content-center">
                                    <button className="btn-save mx-3" onClick={handleImport}>
                                        Ok
                                    </button>
                                    <button className="btn-delete mx-3" onClick={handleCancel}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* </div> */}
        </>
    );
};
export default ImportFunctionKeyModal;
