import React from "react";
import "./style.scss";

const DataNotFound = (props) => {
    const texts = props.texts;
    return (
        <div className="d-flex align-items-center justify-content-center m-auto">
            <div className="col-12">
                <div className="data-notfound">
                    <div>
                        <span className="text-notfound">No data found !</span>
                        <p className="text-notfound">Create a {texts} to get started.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DataNotFound;
