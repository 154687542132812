import _ from "lodash";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";

import { authApi } from "../api/auth";
import { organizationApi } from "../api/organization";
import authReducer from "../redux/authSlice";
import userProfileReducer from "../redux/userProfileSlice";
import responseTypeReducer from "../redux/responseTypeSlice";
import incidentTypeReducer from "../redux/incidentTypeSlice";
import assignmentReducer from "../redux/assignmentSlice";
import firegroundTasksReducer from "./firegroundTasksSlice";
import tacticalDecisionGameReducer from "../redux/tacticalDecisionGameSlice";
import incidentPrioritiesReducer from "../redux/incidentPrioritiesSlice";
import actionKeyReducer from "../redux/actionKeySlice";
import actionListReducer from "../redux/actionListSlice";
import objectivesReducer from "../redux/objectivesSlice";
import thinkingPlanningReducer from "../redux/thinkingPlanningSlice";
import apparatusReducer from "../redux/apparatusSlice";
import scenarioReducer from "../redux/scenarioSlice";
import scenarioAssignmentsReducer from "../redux/scenarioAssignmentsSlice";
import profileReducer from "../redux/profileSlice";
import { responseTypeApi } from "../api/responseType";
import { incidentTypeApi } from "../api/incidentType";
import { assignmentApi } from "../api/assignment";
import { firegroundTasksApi } from "../api/firegroundTasks";
import { tacticalDecisionGameApi } from "../api/tacticalDecisionGame";
import { incidentPrioritiesApi } from "../api/incidentPriorities";
import { actionKeyApi } from "../api/actionKey";
import { actionListApi } from "../api/actionList";
import { objectivesApi } from "../api/objectives";
import { thinkingPlanningApi } from "../api/thinkingPlanning";
import { apparatusApi } from "../api/apparatus";
import { scenarioApi } from "../api/scenario";
import { scenarioAssignmentsApi } from "../api/scenarioAssignments";
import { profileApi } from "../api/profile";
import { tokenVerifyApi } from "../api/tokenVerify";
import { importDataApi } from "../api/importData";

const store = configureStore({
    reducer: {
        auth: authReducer,
        responseType: responseTypeReducer,
        incidentType: incidentTypeReducer,
        assignment: assignmentReducer,
        tdgLibrary: firegroundTasksReducer,
        tacticalDecisionGame: tacticalDecisionGameReducer,
        incidentPriorities: incidentPrioritiesReducer,
        actionKey: actionKeyReducer,
        actionList: actionListReducer,
        objectives: objectivesReducer,
        thinkingPlanning: thinkingPlanningReducer,
        apparatus: apparatusReducer,
        userProfile: userProfileReducer,
        scenario: scenarioReducer,
        profile: profileReducer,
        scenarioAssignments: scenarioAssignmentsReducer,
        [authApi.reducerPath]: authApi.reducer,
        [organizationApi.reducerPath]: organizationApi.reducer,
        [responseTypeApi.reducerPath]: responseTypeApi.reducer,
        [incidentTypeApi.reducerPath]: incidentTypeApi.reducer,
        [assignmentApi.reducerPath]: assignmentApi.reducer,
        [firegroundTasksApi.reducerPath]: firegroundTasksApi.reducer,
        [tacticalDecisionGameApi.reducerPath]: tacticalDecisionGameApi.reducer,
        [incidentPrioritiesApi.reducerPath]: incidentPrioritiesApi.reducer,
        [actionKeyApi.reducerPath]: actionKeyApi.reducer,
        [actionListApi.reducerPath]: actionListApi.reducer,
        [objectivesApi.reducerPath]: objectivesApi.reducer,
        [thinkingPlanningApi.reducerPath]: thinkingPlanningApi.reducer,
        [apparatusApi.reducerPath]: apparatusApi.reducer,
        [scenarioApi.reducerPath]: scenarioApi.reducer,
        [profileApi.reducerPath]: profileApi.reducer,
        [scenarioAssignmentsApi.reducerPath]: scenarioAssignmentsApi.reducer,
        [tokenVerifyApi.reducerPath]: tokenVerifyApi.reducer,
        [importDataApi.reducerPath]: importDataApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(authApi.middleware)
            .concat(organizationApi.middleware)
            .concat(responseTypeApi.middleware)
            .concat(incidentTypeApi.middleware)
            .concat(assignmentApi.middleware)
            .concat(firegroundTasksApi.middleware)
            .concat(incidentPrioritiesApi.middleware)
            .concat(tacticalDecisionGameApi.middleware)
            .concat(actionKeyApi.middleware)
            .concat(actionListApi.middleware)
            .concat(objectivesApi.middleware)
            .concat(thinkingPlanningApi.middleware)
            .concat(apparatusApi.middleware)
            .concat(scenarioApi.middleware)
            .concat(scenarioAssignmentsApi.middleware)
            .concat(profileApi.middleware)
            .concat(importDataApi.middleware)
            .concat(tokenVerifyApi.middleware),
});

setupListeners(store.dispatch);

export default store;
