import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import * as _ from "lodash";
import { ROUTES } from "../../../../../utils/constants/variables/routes";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { defaultValues } from "../../../../../utils/constants/forms/defaultValues";
import { API } from "../../../../../utils/constants/variables/apiFields";
import Elips from "../../../../../assets/images/action/elips.svg";
import WhitePlus from "../../../../../assets/images/action/white-plus.svg";
import { FaChevronDown } from "react-icons/fa";

const ViewTacticalDecisionGame = (props) => {
    const navigate = useNavigate();
    const tacticalDecisionGameData = props.tacticalDecisionGameData;

    console.log("tacticalDecisionGameData=========", tacticalDecisionGameData);
    return (
        <>
            <div>
                <div className="tactical-decision-game">
                    <Modal {...props} centered aria-labelledby="contained-modal-title-vcenter" fullscreen className="view-modal">
                        <Modal.Header className="border-bottom-0" closeButton>
                            <Modal.Title>
                                <button className="btn-custom">Tactical Decision Game</button>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <div className="row">
                                    <div className="col-12  my-2">
                                        <div className="float-end form-check form-switch d-flex align-items-center">
                                            <div className="me-3">
                                                <label className="mb-0 custom-frmlabel">Publish</label>
                                            </div>
                                            <div>
                                                <input
                                                    class="form-check-input m-0"
                                                    type="checkbox"
                                                    role="switch"
                                                    id="flexSwitchCheckChecked"
                                                    checked={tacticalDecisionGameData?.publish === true}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 my-2">
                                        <div className="d-flex align-items-center my-2">
                                            <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                            <label className="mb-0 custom-frmlabel">Tactical decision game</label>
                                        </div>
                                        <div className="view-custom-input">
                                            <p className="mb-0">{tacticalDecisionGameData?.text}</p>
                                        </div>
                                    </div>
                                    <div className="col-12 my-2">
                                        <div className="d-flex align-items-center my-2">
                                            <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                            <label className="mb-0 custom-frmlabel">Image / Audio</label>
                                        </div>
                                        <div className="row d-flex align-items-center">
                                            {tacticalDecisionGameData?.image?.map((item, index) => (
                                                <div className="col-sm-12 col-md-6 my-2" key={index}>
                                                    <div className="select-audio-image">
                                                        {item.image && (
                                                            <div className="tactical_game_image">
                                                                <img
                                                                    src={item.image}
                                                                    alt={`Image ${index}`}
                                                                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                                                />
                                                            </div>
                                                        )}
                                                        {item.audio ? (
                                                            <div className="tactical_game_audio me-2">
                                                                <audio controls className="mt-1">
                                                                    <source src={item.audio} type="audio/mpeg" />
                                                                    Your browser does not support the audio element.
                                                                </audio>
                                                            </div>
                                                        ) : (
                                                            <label className="audio-input d-flex align-items-center justify-content-center me-3">
                                                                <div>+ Add audio</div>
                                                            </label>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <div className="d-flex align-items-center my-2">
                                                            <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                            <label className="mb-0 custom-frmlabel">Correct answer</label>
                                                        </div>
                                                        <div className="view-custom-input">
                                                            <p className="mb-0">{item.answer}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-12 my-2">
                                        <div className="d-flex align-items-center my-2">
                                            <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                            <label className="mb-0 custom-frmlabel">Target audience</label>
                                        </div>
                                        <div className="view-custom-input">
                                            {tacticalDecisionGameData?.selectTargetAudience?.map((audience, index) => (
                                                <span className="me-2 targate-audience" key={index}>
                                                    {audience}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-12 my-2">
                                        <div className="d-flex align-items-center justify-content-between my-2">
                                            <div className="d-flex align-items-center">
                                                <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                <label className="mb-0 custom-frmlabel">Best practices</label>
                                            </div>
                                            <div className="custom-add-icon">
                                                <img src={WhitePlus} alt={"WhitePlus"} height={"30px"} width={"30px"} />
                                            </div>
                                        </div>

                                        {tacticalDecisionGameData?.bestNames?.map((bestPractice, index) => (
                                            <div className="view-custom-input my-2">
                                                <p className="mb-0" key={index}>
                                                    {bestPractice.name}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-12 my-2">
                                        <div className="d-flex align-items-center my-2">
                                            <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                            <label className="mb-0 custom-frmlabel">Time Limit</label>
                                        </div>
                                        <div className="view-custom-input">
                                            <p className="mb-0">{tacticalDecisionGameData?.timeLimit}</p>
                                        </div>
                                    </div>
                                    <div className="col-12 my-2">
                                        <div className="d-flex align-items-center my-2">
                                            <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                            <label className="mb-0 custom-frmlabel">Select answer type</label>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="custom-radio-checked">
                                                <input type="radio" checked={tacticalDecisionGameData?.selectAnswerType === "list"} readOnly />
                                                <label className="form-check-label">List</label>
                                            </div>
                                            <div className="custom-radio-checked">
                                                <input type="radio" checked={tacticalDecisionGameData?.selectAnswerType === "ratingScale"} readOnly />
                                                <label className="form-check-label">Rating Scale</label>
                                            </div>
                                            <div className="custom-radio-checked">
                                                <input type="radio" checked={tacticalDecisionGameData?.selectAnswerType === "voiceToText"} readOnly />
                                                <label className="form-check-label">Voice to Text</label>
                                            </div>
                                            <div className="custom-radio-checked">
                                                <input type="radio" checked={tacticalDecisionGameData?.selectAnswerType === "functionKeys"} readOnly />
                                                <label className="form-check-label">Function Keys</label>
                                            </div>
                                        </div>
                                    </div>

                                    {tacticalDecisionGameData?.selectAnswerType === "list" && (
                                        <>
                                            <div className="col-12 my-2">
                                                <div className="d-flex align-items-center justify-content-between my-2">
                                                    <div className="d-flex align-items-center">
                                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                        <label className="mb-0 custom-frmlabel me-3">Add answer type</label>
                                                        <div className="custom-check-input">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input mt-0"
                                                                checked={tacticalDecisionGameData?.priorityType === true}
                                                                readOnly
                                                            />
                                                            <label className="form-check-label">Priority type</label>
                                                        </div>
                                                    </div>
                                                    <div className="custom-add-icon">
                                                        <img src={WhitePlus} alt={"WhitePlus"} height={"30px"} width={"30px"} />
                                                    </div>
                                                </div>

                                                {tacticalDecisionGameData?.addAnswerTypes?.map((item, index) => (
                                                    <div className="view-custom-input d-flex justify-content-between my-2 pe-0" key={index}>
                                                        <p className="mb-0">{item.answer}</p>
                                                        {tacticalDecisionGameData?.priorityType === true && (
                                                            <p className="custom-grouptext index-input">{item.position}</p>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    )}

                                    {tacticalDecisionGameData?.selectAnswerType === "ratingScale" && (
                                        <>
                                            <div className="col-12 my-2">
                                                <div className="d-flex align-items-center my-2">
                                                    <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                    <label className="mb-0 custom-frmlabel me-3">Select slider type</label>
                                                    <div className="btn-custom-slider">
                                                        <label className="form-check-label me-5">
                                                            {tacticalDecisionGameData?.selectNumberOfSliders === "twoSlider" ? "Two Slider" : "Single Slider"}
                                                        </label>
                                                        <FaChevronDown />
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center my-4">
                                                    <div className="custom-radio-checked me-5">
                                                        <input type="radio" checked={tacticalDecisionGameData?.numeric === "true"} readOnly />
                                                        <label className="form-check-label">Numeric</label>
                                                    </div>
                                                    <div className="custom-radio-checked">
                                                        <input type="radio" checked={tacticalDecisionGameData?.texting === "true"} />
                                                        <label className="form-check-label">Text</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {tacticalDecisionGameData?.selectAnswerType === "functionKeys" && (
                                        <>
                                            <div className="col-12 my-2">
                                                <div className="d-flex align-items-center my-2">
                                                    <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                    <label className="mb-0 custom-frmlabel me-3">Incident priorities</label>
                                                </div>
                                                <div className="row d-flex align-items-center">
                                                    {tacticalDecisionGameData?.tacticalFunctionWithObjectivesIncident?.map((item, index) => (
                                                        <div className="col-sm-12 col-md-4 my-2" key={index}>
                                                            <div className="view-custom-input d-flex justify-content-between my-2 pe-0">
                                                                <p className="mb-0">{item?.incidentPrioritiesName?.name}</p>
                                                                <p className="custom-grouptext index-input">
                                                                    <FaChevronDown />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="col-12 my-2">
                                                <div className="d-flex align-items-center my-2">
                                                    <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                    <label className="mb-0 custom-frmlabel me-3">Action Keys</label>
                                                </div>
                                                <div className="row d-flex align-items-center">
                                                    {tacticalDecisionGameData?.tacticalFunctionWithObjectivesAction?.map((item, index) => (
                                                        <div className="col-sm-12 col-md-4 my-2" key={index}>
                                                            <div className="view-custom-input d-flex justify-content-between my-2 pe-0">
                                                                <p className="mb-0">{item?.actionKeyName?.name}</p>
                                                                <p className="custom-grouptext index-input">
                                                                    <FaChevronDown />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {tacticalDecisionGameData?.numeric === "true" && (
                                        <div className="col-12 my-2">
                                            {tacticalDecisionGameData?.selectNumberOfSliders === "twoSlider" ? (
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <div className="view-custom-input my-2">
                                                            <p className="mb-0">{tacticalDecisionGameData?.minimumValue}</p>
                                                        </div>
                                                        <div className="view-custom-input my-2">
                                                            <p className="mb-0">{tacticalDecisionGameData?.maximumValue}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="view-custom-input my-2">
                                                            <p className="mb-0">{tacticalDecisionGameData?.minimumValue1}</p>
                                                        </div>
                                                        <div className="view-custom-input my-2">
                                                            <p className="mb-0">{tacticalDecisionGameData?.maximumValue1}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <div className="view-custom-input my-2">
                                                            <p className="mb-0">{tacticalDecisionGameData?.minimumValue}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="view-custom-input my-2">
                                                            <p className="mb-0">{tacticalDecisionGameData?.maximumValue}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {tacticalDecisionGameData?.texting === "true" && (
                                        <div className="col-12 my-2">
                                            {tacticalDecisionGameData?.selectNumberOfSliders === "twoSlider" ? (
                                                <>
                                                    <div className="d-flex align-items-center justify-content-between my-2">
                                                        <div className="d-flex align-items-center">
                                                            <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                            <label className="mb-0 custom-frmlabel">Two Slider</label>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-12 col-md-6">
                                                            <div>
                                                                <div className="custom-add-icon">
                                                                    <img src={WhitePlus} alt={"WhitePlus"} height={"30px"} width={"30px"} />
                                                                </div>
                                                            </div>
                                                            {tacticalDecisionGameData?.texts?.map((item, index) => (
                                                                <div className="view-custom-input my-2">
                                                                    <p className="mb-0" key={index}>
                                                                        {item.ratingScaleText}
                                                                    </p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div>
                                                                <div className="custom-add-icon">
                                                                    <img src={WhitePlus} alt={"WhitePlus"} height={"30px"} width={"30px"} />
                                                                </div>
                                                            </div>
                                                            {tacticalDecisionGameData?.texts1?.map((item, index) => (
                                                                <div className="view-custom-input my-2">
                                                                    <p className="mb-0" key={index}>
                                                                        {item.ratingScaleText}
                                                                    </p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="col-12 my-2">
                                                    <div className="d-flex align-items-center justify-content-between my-2">
                                                        <div className="d-flex align-items-center">
                                                            <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                            <label className="mb-0 custom-frmlabel">Single Slider</label>
                                                        </div>
                                                        <div className="custom-add-icon">
                                                            <img src={WhitePlus} alt={"WhitePlus"} height={"30px"} width={"30px"} />
                                                        </div>
                                                    </div>

                                                    {tacticalDecisionGameData?.texts?.map((item, index) => (
                                                        <div className="view-custom-input my-2">
                                                            <p className="mb-0" key={index}>
                                                                {item.ratingScaleText}
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default ViewTacticalDecisionGame;
