import React, { useState, useEffect } from "react";
import MainLayout from "../../../../components/layouts/MainLayout";
// import Modal from "react-bootstrap/Modal";
import UpdatePassword from "./UpdatePassword";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import { API } from "../../../../utils/constants/variables/apiFields";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { defaultValues } from "../../../../utils/constants/forms/defaultValues";
import { yupResolver } from "@hookform/resolvers/yup";
import { MdOutlineAddAPhoto } from "react-icons/md";
import { validation } from "../../../../utils/constants/forms/validation";
import { useGetProfileByIdQuery, useUpdateProfileOfUserMutation } from "../../../../api/profile";
import { setUserProfile } from "../../../../redux/userProfileSlice";
import { ROUTES } from "../../../../utils/constants/variables/routes";
import { MdLogout } from "react-icons/md";
import { useLogoutMutation } from "../../../../api/auth";

const appendFormValuesToFormData = (formData, data) => {
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            formData.append(key, data[key]);
        }
    }
};

const UserProfile = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showUpdatePassword, setShowUpdatePassword] = useState(false);
    const [userProfileData, setUserProfileData] = useState([]);
    const [selectedPhoto, setSelectedPhoto] = useState(null);

    const getUserOrganizationId = localStorage.getItem("organizationId");
    const getUserStation = localStorage.getItem("station");
    const getUserStatus = localStorage.getItem("status");
    const getUserSubscriptionDate = localStorage.getItem("subscriptionDate");
    const getUserName = localStorage.getItem("name");
    const getUserExpireDate = localStorage.getItem("expireDate");

    const { data: userProfileDetails, refetch } = useGetProfileByIdQuery();
    const [updateProfile] = useUpdateProfileOfUserMutation();
    const [logout] = useLogoutMutation();

    const handlePhotoSelect = (event) => {
        const file = event.target.files[0];
        setSelectedPhoto(file);
        setValue("profileImage", file);
    };
    const isDefaultProfile = (profileImage) => {
        return profileImage && profileImage.toLowerCase().includes("null");
    };

    const form = useForm({
        defaultValues: defaultValues.USER_PROFILE,
        resolver: yupResolver(validation.USER_PROFILE),
    });

    const {
        handleSubmit,
        formState: { errors },
        register,
        watch,
        setValue,
    } = form;

    useEffect(() => {
        refetch();
        if (userProfileDetails?.status === "success") {
            return;
        } else {
            toast.error(userProfileDetails?.error?.data?.message, { autoClose: false });
        }
        if (userProfileDetails) {
            setUserProfileData(userProfileDetails?.data);
            dispatch(setUserProfile(userProfileDetails?.data));
        } else {
            setUserProfileData([]);
        }
    }, [userProfileDetails, refetch]);

    useEffect(() => {
        refetch();
        if (userProfileDetails?.status === "success") {
            const userData = userProfileDetails?.data;

            setValue(API.USER_PROFILE.PROFILE_NAME, userData?.profileName);
            setValue(API.USER_PROFILE.PROFILE_IMAGE, userData?.profileImage);
            setValue(API.USER_PROFILE.EMAIL, userData?.email);

            const profileImage = userData?.profileImage;
            setSelectedPhoto(isDefaultProfile(profileImage) ? null : profileImage);
        } else {
            toast.error(userProfileDetails?.error?.data?.message, { autoClose: false });
        }
    }, [setValue, userProfileDetails]);
    const logOut = async () => {
        const authorization = localStorage.getItem("token");
        const payload = { authorization };
        const response = await logout(payload);
        console.log("response =======", response);
        if (response?.data?.status === 200) {
            toast.success(response?.data?.message, { autoClose: 1000 });
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("token");
            localStorage.removeItem("userRole");
            localStorage.removeItem("organizationId");
            localStorage.removeItem("name");
            localStorage.removeItem("subscriptionDate");
            localStorage.removeItem("expireDate");
            localStorage.removeItem("status");
            localStorage.removeItem("station");
            navigate(ROUTES.AUTH.LOGIN);
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    const onSubmit = async (data) => {
        const formData = new FormData();
        appendFormValuesToFormData(formData, data);
        const response = await updateProfile(formData);
        if (response?.data?.status === "success") {
            if (response?.data?.data?.userRole === "organization") {
                const organizationId = getUserOrganizationId;
                const station = getUserStation;
                const status = getUserStatus;
                const subscriptionDate = getUserSubscriptionDate;
                const expireDate = getUserExpireDate;
                const name = getUserName;
                toast.success(response?.data?.message, { autoClose: 1000 });
                navigate(`/organization/profile/${organizationId}/${station}`, {
                    state: { status, subscriptionDate, expireDate, name },
                });
                refetch();
            } else {
                navigate(ROUTES.ORGANIZATION);
                refetch();
            }
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    return (
        <div>
            <MainLayout>
                <div className="row">
                    <div className="col-12 d-flex align-items-center justify-content-between my-3">
                        <button className="btn-custom ">Profile</button>
                        <div className="btn-custom" onClick={() => logOut()}>
                            <MdLogout className="ico-logout me-2" />
                            <span>LogOut</span>
                        </div>
                    </div>
                    <div className="user-form">
                        <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center mb-3">
                                    <div className="user-profile">
                                        <label htmlFor="photoInput" className="profile-image">
                                            {selectedPhoto ? (
                                                <img
                                                    src={typeof selectedPhoto === "string" ? selectedPhoto : URL.createObjectURL(selectedPhoto)}
                                                    alt="Selected_logo"
                                                    className="selected_photo"
                                                />
                                            ) : (
                                                <>
                                                    <MdOutlineAddAPhoto className="add-photo-icon" />
                                                </>
                                            )}
                                        </label>
                                        <input
                                            id="photoInput"
                                            type="file"
                                            accept="image/png ,image/jpg ,image/jpeg"
                                            {...register("profileImage")}
                                            onChange={handlePhotoSelect}
                                            className="d-none"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="frm-input">
                                        <Form.Group className="my-2">
                                            <Form.Label className=" mb-0">Name</Form.Label>
                                            <Form.Control
                                                className="custom-input"
                                                type="text"
                                                name={API.USER_PROFILE.PROFILE_NAME}
                                                {...register(API.USER_PROFILE.PROFILE_NAME)}
                                            />
                                        </Form.Group>
                                        <div className="error-msg">
                                            {errors?.profileName && <span className="text-danger">{errors?.profileName?.message}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="frm-input">
                                        <Form.Group className="my-2">
                                            <Form.Label className=" mb-0">Email</Form.Label>
                                            <Form.Control
                                                className="custom-input"
                                                type="email"
                                                name={API.USER_PROFILE.EMAIL}
                                                {...register(API.USER_PROFILE.EMAIL)}
                                            />
                                        </Form.Group>
                                        <div className="error-msg">{errors?.email && <span className="text-danger">{errors?.email?.message}</span>}</div>
                                    </div>
                                </div>

                                <div className="col-12 w-100 gap-2 bottom-fixed profile-button">
                                    <div>
                                        <button type="submit" className="btn-save">
                                            Save
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            type="button"
                                            className="btn-update"
                                            onClick={() => {
                                                setShowUpdatePassword(true);
                                            }}
                                        >
                                            Update Password
                                        </button>
                                    </div>
                                    <div>
                                        <button type="button" className="btn-cancel" onClick={() => navigate(`/organization`)}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </MainLayout>

            {showUpdatePassword && (
                <UpdatePassword
                    showUpdatePassword={showUpdatePassword}
                    show={showUpdatePassword}
                    onHide={() => setShowUpdatePassword(false)}
                    refecthUpdatePassword={refetch}
                />
            )}
        </div>
    );
};

export default UserProfile;
