import React, { useEffect, useState } from "react";
import MainLayout from "../../../../../components/layouts/MainLayout";
import DeleteModal from "../../../../../components/delete/DeleteModal";
import { useGetThinkingPlanningQuery, useUpdateThinkingPlanningPublishMutation } from "../../../../../api/thinkingPlanning";
import { useGetAllDataMutation, useSyncDataMutation } from "../../../../../api/importData";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import * as _ from "lodash";
import Form from "react-bootstrap/Form";
import { ROUTES } from "../../../../../utils/constants/variables/routes";
import DataNotFound from "../../../../../components/shared/DataNotFound/DataNotFound";
import WhitePlus from "../../../../../assets/images/action/white-plus.svg";
import RightArrow from "../../../../../assets/images/action/right-arrow.svg";
import MsgVector from "../../../../../assets/images/msgVector.svg";
import PluseIcon from "../../../../../assets/images/action/plusIcon.svg";
import Elips from "../../../../../assets/images/action/elips.svg";
import bulletIcon from "../../../../../assets/images/action/bulletIcon.svg";
import EditIcon from "../../../../../assets/images/action/editIcon.svg";
import DeleteIcon from "../../../../../assets/images/action/deleteIcon.svg";
import ImportThinkingPlanning from "./ImportThinkingPlanning";
import { Controller, useForm } from "react-hook-form";
import { defaultValues } from "../../../../../utils/constants/forms/defaultValues";
import { API } from "../../../../../utils/constants/variables/apiFields";
import { BiImport } from "react-icons/bi";
import { LiaSyncAltSolid } from "react-icons/lia";

const ThinkingPlanning = () => {
    const navigate = useNavigate();
    // const dispatch = useDispatch();
    const location = useLocation();

    const params = useParams();
    const { responseType, incidentPriorities, objectives } = params;
    const [responseTypeName, responseTypeId] = responseType?.split(/[=]+/);
    const [incidentPrioritiesName, incidentPrioritiesId] = incidentPriorities?.split(/[=]+/);
    const [objectivesName, objectivesId] = objectives?.split(/[=]+/);
    const originalDataId = location?.state;

    const [userRole, setUserRole] = useState("");
    const [thinkingPlanningData, setThinkingPlanningData] = useState([]);
    const [importThinkingPlanningData, setImportThinkingPlanningData] = useState([]);
    const [showImportModal, setShowImportModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteThinkingPlanningId, setdeleteThinkingPlanningId] = useState("");
    const [type, setType] = useState("thinkingPlaning");

    const { data: thinkingPlanning, refetch } = useGetThinkingPlanningQuery(objectivesId);
    const [updatePublish] = useUpdateThinkingPlanningPublishMutation();
    const [getImportThinkingPlannig] = useGetAllDataMutation();
    const [suyncData] = useSyncDataMutation();

    useEffect(() => {
        const getUserRole = localStorage.getItem("userRole");
        if (getUserRole) {
            setUserRole(getUserRole);
        }
    }, []);
    const form = useForm({
        defaultValues: defaultValues.THINKING_PLANNING,
    });
    const { control } = form;

    useEffect(() => {
        refetch();
        if (thinkingPlanning?.data?.status === "success") {
            return;
        } else {
            toast.error(thinkingPlanning?.error?.data?.message, { autoClose: false });
        }
        if (thinkingPlanning) {
            setThinkingPlanningData(thinkingPlanning?.data);
            // dispatch(setThinkingPlanningData(thinkingPlanning?.data));
        } else {
            setThinkingPlanningData([]);
        }
    }, [thinkingPlanning, refetch]);

    const handleSyncData = async (currentDataId) => {
        const payload = { type, currentDataId };
        const response = await suyncData(payload);
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            refetch();
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };
    const handleImportData = async () => {
        const payload = { type, objectivesId };
        const response = await getImportThinkingPlannig(payload);
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            setShowImportModal(true);
            setImportThinkingPlanningData(response?.data?.data);
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    const rows =
        thinkingPlanningData &&
        _.map(thinkingPlanningData, (item, index) => {
            return {
                no: index + 1,
                id: _.get(item, "thinkingPlanningId", ""),
                responseTypeId: _.get(item, "responseTypeId", ""),
                incidentPrioritiesId: _.get(item, "incidentPrioritiesId", ""),
                objectivesId: _.get(item, "objectivesId", ""),
                name: _.get(item, "name", ""),
                selectAnswerType: _.get(item, "selectAnswerType", ""),
                addSliderType: _.get(item, "addSliderType", ""),
                selectSliderType: _.get(item, "selectSliderType", ""),
                selectNumberOfSliders: _.get(item, "selectNumberOfSliders", ""),
                minimumValue: _.get(item, "minimumValue", ""),
                maximumValue: _.get(item, "maximumValue", ""),
                minimumValue1: _.get(item, "minimumValue1", ""),
                maximumValue1: _.get(item, "maximumValue1", ""),
                texts: _.get(item, "texts", ""),
                texts1: _.get(item, "texts1", ""),
                publish: _.get(item, "publish", ""),
                priorityType: _.get(item, "priorityType", ""),
                isMatch: _.get(item, "isMatch", ""),
            };
        });
    const columns = [
        {
            headerName: "#",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>#</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-center w-100">
                        <div>
                            <div>
                                <img src={bulletIcon} alt={"bulletIcon"} height={"20px"} width={"20px"} />
                            </div>
                            {params.row.isMatch === false && userRole == "organization" && (
                                <LiaSyncAltSolid
                                    className="mt-2 icon-sync"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleSyncData(params.row.id);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                );
            },
            width: "lg" ? 100 : "sm" && 20,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "no",
            headerName: "No",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>No</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.no}</span>
                    </div>
                );
            },
            width: 150,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },

        {
            field: "publish",
            headerName: "Publish",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Publish</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                const thinkingPlanningData = params.row;
                return (
                    <div
                        className="fs-item"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Controller
                            control={control}
                            name={API.THINKING_PLANNING.PUBLISH}
                            render={({ field: { value } }) => {
                                return (
                                    <Form.Check
                                        type="switch"
                                        id={`custom-switch-${thinkingPlanningData.id}`}
                                        className="customSwitch p-0 m-0"
                                        defaultChecked={thinkingPlanningData.publish}
                                        value={value}
                                        onChange={(e) => handlePublishChange(e, thinkingPlanningData.id)}
                                        reverse
                                    />
                                );
                            }}
                        />
                    </div>
                );
            },
            width: "lg" ? 150 : "sm" && 20,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "action",
            headerName: "Action",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Action</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                const thinkingPlanningDetail = params.row;
                return (
                    <div className="d-flex align-items-center justify-content-center gap-2 w-100">
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(
                                    `/gameFunnel/thinkingPlanning/edit/${responseTypeName}=${responseTypeId}/${incidentPrioritiesName}=${incidentPrioritiesId}/${objectivesName}=${objectivesId}`,
                                    { state: thinkingPlanningDetail }
                                );
                            }}
                        >
                            <img src={EditIcon} alt={"EditIcon"} height={"20px"} width={"20px"} />
                        </div>
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowDeleteModal(true);
                                setdeleteThinkingPlanningId(thinkingPlanningDetail?.id);
                            }}
                        >
                            <img src={DeleteIcon} alt={"DeleteIcon"} height={"20px"} width={"20px"} />
                        </div>
                    </div>
                );
            },
            width: "lg" ? 150 : "sm" && 20,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "selectAnswerType",
            headerName: "Answer type",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Answer type</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                const answerTypeWithSpacesAndCapitalized = params.row.selectAnswerType
                    ? params.row.selectAnswerType
                          .replace(/([A-Z])/g, " $1") // Add spaces before capital letters
                          .split(" ") // Split by spaces
                          .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
                          .join(" ") // Join words back together
                    : "-";

                return (
                    <div className="fs-item">
                        <span>{answerTypeWithSpacesAndCapitalized}.</span>
                    </div>
                );
            },
            width: 300,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },

        {
            field: "name",
            headerName: "Decisive points",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Decisive points</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.name || "-"}</span>
                    </div>
                );
            },
            width: 300,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "selectNumberOfSliders",
            headerName: "Number Of Slider",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Number Of Slider</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.selectNumberOfSliders || "-"}</span>
                    </div>
                );
            },
            width: 300,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
    ];

    const handlePublishChange = async (e, id) => {
        const isChecked = e.target.checked;
        const stringId = id;
        const response = await updatePublish({ id: stringId, publish: isChecked });
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            refetch();
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    return (
        <div>
            <MainLayout className="me-0">
                <div className="row">
                    <div className="col-12 my-2">
                        <div className="bread-crumbs">
                            <div className="d-flex align-items-center me-1">
                                <span className="text-breadcrumb me-1" onClick={() => navigate(ROUTES.GAMEFUNNEL.RESPONSE)}>
                                    {responseTypeName}
                                </span>
                                <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                                <span
                                    className="text-breadcrumb me-1"
                                    onClick={() => {
                                        navigate(`/gameFunnel/incidentTypes/${responseTypeName}=${responseTypeId}`);
                                    }}
                                >
                                    Function keys
                                </span>
                                <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                                <span
                                    className="text-breadcrumb me-1"
                                    onClick={() => navigate(`/gameFunnel/functionKeys/incidentPriorities/${responseTypeName}=${responseTypeId}`)}
                                >
                                    {incidentPrioritiesName}
                                </span>
                                <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                                <span
                                    className="text-breadcrumb me-1"
                                    onClick={() =>
                                        navigate(
                                            `/gameFunnel/functionKeys/objectives/${responseTypeName}=${responseTypeId}/${incidentPrioritiesName}=${incidentPrioritiesId}`
                                        )
                                    }
                                >
                                    {objectivesName}
                                </span>
                                <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-4 d-flex align-items-center justify-content-between mb-3 hr-bottom">
                        <div>
                            <button className="btn-custom me-3 float-end">Thinking / Planning</button>
                        </div>
                        {userRole == "organization" && originalDataId !== null && (
                            <div>
                                <button className="btn-import d-flex align-items-center" onClick={handleImportData}>
                                    <span className="me-2" style={{ fontWeight: "700" }}>
                                        <BiImport />
                                    </span>
                                    <span>Import</span>
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="dataList-table">
                        {!_.isEmpty(thinkingPlanningData) ? (
                            <DataGrid
                                className="my-4"
                                rows={rows}
                                columns={columns}
                                autoHeight
                                hideFooterPagination={true}
                                rowHeight={60}
                                rowSelection={false}
                            />
                        ) : (
                            <DataNotFound texts={"Thinking Planning"} />
                        )}
                    </div>
                </div>
                <div
                    className="btn-add d-none d-md-block d-lg-block"
                    onClick={() => {
                        navigate(
                            `/gameFunnel/thinkingPlanning/create/${responseTypeName}=${responseTypeId}/${incidentPrioritiesName}=${incidentPrioritiesId}/${objectivesName}=${objectivesId}`
                        );
                    }}
                >
                    <button className="btn-custom float-end">
                        <img alt="WhitePlus" src={WhitePlus} className="me-3" />
                        Add Size up point
                    </button>
                </div>

                <div className="customAdd d-block d-sm-block d-md-none">
                    <div
                        className="add"
                        onClick={() => {
                            navigate(
                                `/gameFunnel/thinkingPlanning/create/${responseTypeName}=${responseTypeId}/${incidentPrioritiesName}=${incidentPrioritiesId}/${objectivesName}=${objectivesId}`
                            );
                        }}
                    >
                        <img src={PluseIcon} alt={"PluseIcon"} height={"50px"} width={"50px"} />
                    </div>
                </div>
            </MainLayout>

            {showDeleteModal && (
                <DeleteModal
                    deleteId={deleteThinkingPlanningId}
                    thinkingPlanning={showDeleteModal}
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    refetch={refetch}
                />
            )}
            {showImportModal && (
                <ImportThinkingPlanning
                    importThinkingPlanningData={importThinkingPlanningData}
                    responseTypeName={responseTypeName}
                    incidentPrioritiesName={incidentPrioritiesName}
                    objectivesName={objectivesName}
                    showImportModal={showImportModal}
                    show={showImportModal}
                    objectivesId={objectivesId}
                    onHide={() => setShowImportModal(false)}
                    refetchImportData={refetch}
                />
            )}
        </div>
    );
};

export default ThinkingPlanning;
