export const SelectStyle = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "white" : "white",
        color: state.isDisabled ? "#A9A9A9" : "#000", // Adjust color based on disabled state
        "&:hover": {
            background: state.isDisabled ? "inherit" : state.isSelected ? "#ffffff" : "#4195F5",
            color: state.isDisabled ? "#A9A9A9" : "#000",
            transition: "all 0.5s",
        },
    }),
    control: (styles) => ({
        ...styles,
        border: "none",
        backgroundColor: "#f8ede6",
        color: "#000000",
        borderRadius: "10px",
        boxShadow: "none",
        "&:hover": {
            borderColor: "#a0a293",
        },
        fontWeight: "510",
        fontSize: "18px",
    }),
    menu: (base) => ({
        ...base,
        borderRadius: 0,
        borderColor: "2px solid #fff",
        zIndex: "1000",
    }),
    menuList: (base) => ({
        ...base,
        cursor: "pointer",
    }),
    placeholder: (base) => ({
        ...base,
        fontSize: "18px",
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";
        const color = "#000000";
        return { ...provided, transition, color, opacity };
    },

    indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
    }),

    indicatorsContainer: (provided) => ({
        ...provided,
    }),

    dropdownIndicator: (provided) => ({
        ...provided,
        height: "50px",
        width: "50px",
        backgroundColor: "#B54708",
        borderRadius: "0px 10px 10px 0px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        "&:hover": {
            color: "#fff",
        },
    }),

    clearIndicator: (provided) => ({
        ...provided,
        color: "#000",
        "&:hover": {
            color: "#000",
        },
    }),
};
