import { createApi } from "@reduxjs/toolkit/query/react";
import { api } from "../utils/constants/url";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { showErrorToast } from "../utils/constants/api/toast";

const tagTypes = "IncidentPriorities";
const type = "IncidentPriorities";
export const incidentPrioritiesApi = createApi({
    reducerPath: "incidentPrioritiesApi",
    baseQuery: baseQueryWithAuthInterceptor({
        baseUrl: `${api.baseURL}/incidentPriorities`,
        prepareHeaders,
    }),
    tagTypes: [tagTypes],
    endpoints: (builder) => ({
        getIncidentPriorities: builder.query({
            query: (responseTypeId) => ({
                url: `/getIncidentPriorities/${responseTypeId}`,
                method: "GET",
            }),
            providesTags: [type],
        }),
        createIncidentPriorities: builder.mutation({
            query: (body) => ({
                url: "/createIncidentPriorities",
                method: "POST",
                body,
            }),
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        updateIncidentPriorities: builder.mutation({
            query: (payload) => {
                const { formData, incidentPrioritiesId } = payload;
                return {
                    url: `/updateIncidentPriorities/${incidentPrioritiesId}`,
                    method: "PUT",
                    body: formData,
                };
            },
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        deleteIncidentPriorities: builder.mutation({
            query: (incidentPrioritiesId) => ({
                url: `/deleteIncidentPriorities/${incidentPrioritiesId}`,
                method: "DELETE",
            }),
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        updateIndex: builder.mutation({
            query: (payload) => {
                const { updateIndexResponse, responseTypeId } = payload;
                return {
                    url: `/updateIndex/${responseTypeId}`,
                    method: "POST",
                    body: updateIndexResponse,
                };
            },
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
    }),
});

export const {
    useGetIncidentPrioritiesQuery,
    useCreateIncidentPrioritiesMutation,
    useUpdateIncidentPrioritiesMutation,
    useDeleteIncidentPrioritiesMutation,
    useUpdateIndexMutation,
} = incidentPrioritiesApi;
