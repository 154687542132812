import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import * as _ from "lodash";
import { useForm, Controller } from "react-hook-form";
import { defaultValues } from "../../../../../utils/constants/forms/defaultValues";
import { toast } from "react-toastify";
import DataNotFound from "../../../../../components/shared/DataNotFound/DataNotFound";
import { BiImport } from "react-icons/bi";
import { useImportMutation } from "../../../../../api/importData";

const ImportFiregroundTasks = (props) => {
    const importTdgLibraryData = props.importTdgLibraryData;
    const assignmentId = props.assignmentId;
    const [importData] = useImportMutation();
    const [importTdgLibrary, setImportTdgLibrary] = useState([]);
    const [arrayOfIds, srtArrayOfIds] = useState([]);
    const [type, setType] = useState("tdgLibraries");

    const form = useForm({
        defaultValues: defaultValues.RESPONSE_TYPES,
    });

    const { control } = form;

    const rows =
        importTdgLibraryData &&
        _.map(importTdgLibraryData, (item) => {
            return {
                id: _.get(item, "tdgLibraryId", ""),
                assignmentId: _.get(item, "assignmentId", ""),
                responseTypeId: _.get(item, "responseTypeId", ""),
                incidentTypeId: _.get(item, "incidentTypeId", ""),
                audio: _.get(item, "audio", ""),
                name: _.get(item, "name", ""),
                goalObjective: _.get(item, "goalObjective", ""),
                image: _.get(item, "image", ""),
                missionBriefing: _.get(item, "missionBriefing", ""),
                text: _.get(item, "text", ""),
                targetAudience: _.get(item, "targetAudience", ""),
                bestPracticesTdg: _.get(item, "bestPracticesTdg", ""),
                publish: _.get(item, "publish", ""),
                recordExists: _.get(item, "recordExists", ""),
            };
        });

    const columns = [
        {
            field: "no",
            headerName: "#",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <Form.Check // prettier-ignore
                            type="checkbox"
                            className="fs-5 custom-check p-0 check-border-color"
                            disabled
                            reverse
                        />
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="d-flex align-items-center justify-content-center w-100">
                        <Controller
                            className="d-flex align-items-center"
                            control={control}
                            name="tdgLibraryId"
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <Form.Check // prettier-ignore
                                        type="checkbox"
                                        id="custom-check"
                                        className="fs-5 custom-check check-border-color p-0"
                                        disabled={params.row.recordExists}
                                        checked={params.row.recordExists || arrayOfIds.includes(params.row.id)}
                                        onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            if (isChecked) {
                                                srtArrayOfIds((prevItems) => [...prevItems, params.row.id]);
                                            } else {
                                                srtArrayOfIds((prevItems) => prevItems.filter((itemId) => itemId !== params.row.id));
                                            }
                                            onChange(e);
                                        }}
                                        reverse
                                    />
                                );
                            }}
                        />
                    </div>
                );
            },
            width: 80,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "name",
            headerName: "Name",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <span>Name</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.name || "-"}</span>
                    </div>
                );
            },
            width: 150,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "goalObjective",
            headerName: "Goal / Objective",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <span>Goal / Objective</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.goalObjective || "-"}</span>
                    </div>
                );
            },
            width: 200,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "bestPracticesTdg",
            headerName: "Best Practices",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <span>Best Practices</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                const firstItem = params.row.bestPracticesTdg[0];
                const name = firstItem ? firstItem.name : "-";
                return (
                    <div className="fs-item">
                        <span>{name}</span>
                    </div>
                );
            },
            width: 200,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "missionBriefing",
            headerName: "Task Briefing",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <span>Task Briefing</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.missionBriefing || "-"}</span>
                    </div>
                );
            },
            width: 200,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
    ];

    const handleImportData = async () => {
        const payload = { type, assignmentId, arrayOfIds };
        const response = await importData(payload);
        if (response?.data?.status === "success") {
            setImportTdgLibrary(response?.data?.data);
            props.onHide();
            props.refetchImportData();
            toast.success(response?.data?.message, { autoClose: 1000 });
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    return (
        <div>
            <div className="tdg-library">
                <Modal {...props} size="lg" centered aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Body className="p-3">
                        <div className="my-2 text-center">
                            <button className="btn-custom">Tdg Library</button>
                        </div>
                        <div className="dataList-table">
                            {!_.isEmpty(importTdgLibraryData) ? (
                                <DataGrid
                                    className="my-2 "
                                    // onRowClick={handleRowClick}
                                    rows={rows}
                                    columns={columns}
                                    autoHeight
                                    hideFooterPagination={true}
                                    rowHeight={50}
                                    rowSelection={false}
                                />
                            ) : (
                                <DataNotFound texts={"TDG"} />
                            )}
                        </div>
                        <div className="my-4 d-flex justify-content-center">
                            <button type="button" className="btn-custom d-flex align-items-center" onClick={handleImportData}>
                                <span className="me-2" style={{ fontWeight: "700" }}>
                                    <BiImport />
                                </span>
                                <span>Import</span>
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
};

export default ImportFiregroundTasks;
