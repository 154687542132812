import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import * as _ from "lodash";
import { useForm, Controller } from "react-hook-form";
import { defaultValues } from "../../../../../utils/constants/forms/defaultValues";
import { toast } from "react-toastify";
import DataNotFound from "../../../../../components/shared/DataNotFound/DataNotFound";
import { BiImport } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import ViewThinkingPlanningDetail from "./ViewThinkingPlanningDetail";
import { useImportMutation } from "../../../../../api/importData";

const ImportThinkingPlanning = (props) => {
    const importThinkingPlanningData = props.importThinkingPlanningData;
    const objectivesId = props.objectivesId;
    const responseTypeName = props.responseTypeName;
    const incidentPrioritiesName = props.incidentPrioritiesName;
    const objectivesName = props.objectivesName;
    const [importData, refetch] = useImportMutation();
    const [showViewModal, setShowViewModal] = useState(false);
    const [ImportThinkingPlanningData, setImportTacticalDecisionGameData] = useState([]);
    const [thinkingPlannimngData, setThinkingPlannimngData] = useState([]);

    const [arrayOfIds, srtArrayOfIds] = useState([]);
    const [type, setType] = useState("thinkingPlaning");

    const form = useForm({
        defaultValues: defaultValues.RESPONSE_TYPES,
    });

    const { control } = form;

    const rows =
        importThinkingPlanningData &&
        _.map(importThinkingPlanningData, (item, index) => {
            return {
                no: index + 1,
                id: _.get(item, "thinkingPlanningId", ""),
                responseTypeId: _.get(item, "responseTypeId", ""),
                incidentPrioritiesId: _.get(item, "incidentPrioritiesId", ""),
                objectivesId: _.get(item, "objectivesId", ""),
                name: _.get(item, "name", ""),
                selectAnswerType: _.get(item, "selectAnswerType", ""),
                addSliderType: _.get(item, "addSliderType", ""),
                selectSliderType: _.get(item, "selectSliderType", ""),
                selectNumberOfSliders: _.get(item, "selectNumberOfSliders", ""),
                minimumValue: _.get(item, "minimumValue", ""),
                maximumValue: _.get(item, "maximumValue", ""),
                minimumValue1: _.get(item, "minimumValue1", ""),
                maximumValue1: _.get(item, "maximumValue1", ""),
                texts: _.get(item, "texts", ""),
                texts1: _.get(item, "texts1", ""),
                publish: _.get(item, "publish", ""),
                priorityType: _.get(item, "priorityType", ""),
                recordExists: _.get(item, "recordExists", ""),
            };
        });
    const columns = [
        {
            headerName: "#",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <Form.Check // prettier-ignore
                            type="checkbox"
                            className="fs-5 custom-check p-0 check-border-color"
                            disabled
                            reverse
                        />
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="d-flex align-items-center justify-content-center w-100">
                        <Controller
                            className="d-flex align-items-center"
                            control={control}
                            name="thinkingPlaningId"
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <Form.Check // prettier-ignore
                                        type="checkbox"
                                        id="custom-check"
                                        className="fs-5 custom-check check-border-color p-0"
                                        disabled={params.row.recordExists}
                                        checked={params.row.recordExists || arrayOfIds.includes(params.row.id)}
                                        onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            if (isChecked) {
                                                srtArrayOfIds((prevItems) => [...prevItems, params.row.id]);
                                            } else {
                                                srtArrayOfIds((prevItems) => prevItems.filter((itemId) => itemId !== params.row.id));
                                            }
                                            onChange(e);
                                        }}
                                        reverse
                                    />
                                );
                            }}
                        />
                    </div>
                );
            },
            width: "lg" ? 100 : "sm" && 20,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "action",
            headerName: "Action",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <span>Action</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                const thinkingPlanningDetail = params.row;
                return (
                    <div className="d-flex align-items-center justify-content-center gap-2 w-100">
                        <div
                            onClick={() => {
                                setShowViewModal(true);
                                setThinkingPlannimngData(thinkingPlanningDetail);
                            }}
                        >
                            <FaEye className="icon-view" />
                        </div>
                    </div>
                );
            },
            width: "lg" ? 100 : "sm" && 20,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "selectAnswerType",
            headerName: "Answer type",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <span>Answer type</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                const answerTypeWithSpacesAndCapitalized = params.row.selectAnswerType
                    ? params.row.selectAnswerType
                          .replace(/([A-Z])/g, " $1") // Add spaces before capital letters
                          .split(" ") // Split by spaces
                          .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
                          .join(" ") // Join words back together
                    : "-";

                return (
                    <div className="fs-item">
                        <span>{answerTypeWithSpacesAndCapitalized}.</span>
                    </div>
                );
            },
            width: 200,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },

        {
            field: "name",
            headerName: "Decisive points",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <span>Decisive points</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.name || "-"}</span>
                    </div>
                );
            },
            width: 200,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "selectNumberOfSliders",
            headerName: "Number Of Slider",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <span>Number Of Slider</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.selectNumberOfSliders || "-"}</span>
                    </div>
                );
            },
            width: 200,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
    ];

    const handleImportData = async () => {
        const payload = { type, objectivesId, arrayOfIds };
        const response = await importData(payload);
        if (response?.data?.status === "success") {
            setImportTacticalDecisionGameData(response?.data?.data);
            props.onHide();
            props.refetchImportData();
            toast.success(response?.data?.message, { autoClose: 1000 });
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    return (
        <div>
            <div className="response-type">
                <Modal {...props} size="lg" centered aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Body className="p-3">
                        <div className="my-2 text-center">
                            <button className="btn-custom">Thinking planning</button>
                        </div>
                        <div className="dataList-table">
                            {!_.isEmpty(importThinkingPlanningData) ? (
                                <DataGrid
                                    className="my-2 "
                                    rows={rows}
                                    columns={columns}
                                    autoHeight
                                    hideFooterPagination={true}
                                    rowHeight={40}
                                    rowSelection={false}
                                />
                            ) : (
                                <DataNotFound texts={"Thinking Plannig"} />
                            )}
                        </div>
                        <div className="my-4 d-flex justify-content-center">
                            <button type="button" className="btn-custom d-flex align-items-center" onClick={handleImportData}>
                                <span className="me-2" style={{ fontWeight: "700" }}>
                                    <BiImport />
                                </span>
                                <span>Import</span>
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

            {showViewModal && (
                <ViewThinkingPlanningDetail
                    thinkingPlannimngData={thinkingPlannimngData}
                    responseTypeName={responseTypeName}
                    incidentPrioritiesName={incidentPrioritiesName}
                    objectivesName={objectivesName}
                    showViewModal={showViewModal}
                    show={showViewModal}
                    onHide={() => setShowViewModal(false)}
                    refetchViewData={refetch}
                />
            )}
        </div>
    );
};

export default ImportThinkingPlanning;
