import { createSlice } from "@reduxjs/toolkit";

const firegroundTasksSlice = createSlice({
    name: "tdgLibrary",
    initialState: {
        tdgLibraryData: {},
    },
    reducers: {
        setTdgLibrary: (state, action) => {
            state.tdgLibraryData = action.payload;
        },
    },
});

export const { setTdgLibrary } = firegroundTasksSlice.actions;
export default firegroundTasksSlice.reducer;
