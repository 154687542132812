import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { API } from "../../../../../utils/constants/variables/apiFields";
import { useForm } from "react-hook-form";
import { defaultValues } from "../../../../../utils/constants/forms/defaultValues";
import { yupResolver } from "@hookform/resolvers/yup";
import { validation } from "../../../../../utils/constants/forms/validation";
import { useCreateIncidentTypeMutation, useUpdateIncidentTypeMutation, useDeleteIncidentTypeMutation } from "../../../../../api/incidentType";
import { toast } from "react-toastify";

const CreateEditIncidentType = (props) => {
    const responseTypeId = props.responseTypeId;
    const isEdit = props.showEditForm;
    const incidentTypeDetail = props.incidentTypeDetail;
    const incidentTypeId = incidentTypeDetail?.incidentTypeId;
    console.log("responseTypeId======", responseTypeId);
    const [createIncidentType] = useCreateIncidentTypeMutation();
    const [updateIncidentType] = useUpdateIncidentTypeMutation();
    const [deleteIncidentType] = useDeleteIncidentTypeMutation();

    const form = useForm({
        defaultValues: defaultValues.INCIDENT_TYPES,
        resolver: yupResolver(validation.INCIDENT_TYPES),
    });

    const {
        handleSubmit,
        formState: { errors },
        register,
        watch,
        setValue,
    } = form;

    useEffect(() => {
        if (incidentTypeId) {
            setValue("name", incidentTypeDetail?.name);
        }
    }, [setValue]);

    const onSubmit = async (data) => {
        const allData = {
            ...data,
            responseTypeId: responseTypeId,
        };
        const response = isEdit ? await updateIncidentType({ data, incidentTypeId }) : await createIncidentType(allData);
        if (response?.data?.status === "success") {
            props.onHide();
            props.refetchCreateEditIncidentType();
            toast.success(response?.data?.message, { autoClose: 1000 });
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    const handleDelete = async (incidentTypeId) => {
        const response = await deleteIncidentType(incidentTypeId);
        if (response?.data?.status === "success") {
            props.onHide();
            props.refetchCreateEditIncidentType();
            toast.success(response?.data?.message, { autoClose: 1000 });
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    return (
        <div>
            <div className="response-type">
                <Modal {...props} size="md" centered aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Body className="p-3">
                        <div className="my-3 text-center">
                            <button className="btn-custom">{!isEdit ? "Create" : "Edit"}</button>
                        </div>
                        <div className="response-form">
                            <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                                <div className="frm-input my-4">
                                    <Form.Group>
                                        <Form.Label className="mb-0 text-fs">Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={API.INCIDENT_TYPES.NAME}
                                            {...register(API.INCIDENT_TYPES.NAME)}
                                            className="custom-input"
                                        />
                                    </Form.Group>

                                    <div className="error-msg">{errors?.name && <span className="text-danger">{errors?.name?.message}</span>}</div>
                                </div>
                                {!isEdit ? (
                                    <div className="w-100 text-center">
                                        <button type="submit" className="btn-save my-0">
                                            Save
                                        </button>
                                    </div>
                                ) : (
                                    <div className="d-flex align-items-center justify-content-center w-100 gap-3">
                                        <div className="text-center">
                                            <button type="submit" className="btn-save my-0">
                                                Update
                                            </button>
                                        </div>
                                        <div className="text-center">
                                            <button type="button" className="btn-delete my-0" onClick={() => handleDelete(incidentTypeId)}>
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </Form>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
};

export default CreateEditIncidentType;
