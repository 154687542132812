import { createApi } from "@reduxjs/toolkit/query/react";
import { api } from "../utils/constants/url";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { showErrorToast } from "../utils/constants/api/toast";

const tagTypes = "tdgLibrary";
const type = "tdgLibrary";
export const firegroundTasksApi = createApi({
    reducerPath: "firegroundTasksApi",
    baseQuery: baseQueryWithAuthInterceptor({
        baseUrl: `${api.baseURL}/tdgLibrary`,
        prepareHeaders,
    }),
    tagTypes: [tagTypes],
    endpoints: (builder) => ({
        getTdgLibrary: builder.query({
            query: (assignmentId) => ({
                url: `/getTdgLibrary/${assignmentId}`,
                method: "GET",
            }),
            providesTags: [type],
        }),
        createTdgLibrary: builder.mutation({
            query: (body) => ({
                url: "/createTdgLibrary",
                method: "POST",
                body,
            }),
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        updateTdgLibrary: builder.mutation({
            query: (payload) => {
                const { tdgLibraryId, formData } = payload;
                return {
                    url: `/updateTdgLibrary/${tdgLibraryId}`,
                    method: "PUT",
                    body: formData,
                };
            },
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        deleteTdgLibrary: builder.mutation({
            query: (id) => ({
                url: `/deleteTdgLibrary/${id}`,
                method: "DELETE",
            }),
        }),
        updateTdgPublish: builder.mutation({
            query: (data) => {
                const { id, ...body } = data;
                return {
                    url: `/updateTdgPublish/${id}`,
                    method: "PUT",
                    body,
                };
            },
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
    }),
});

export const { useGetTdgLibraryQuery, useCreateTdgLibraryMutation, useUpdateTdgLibraryMutation, useDeleteTdgLibraryMutation, useUpdateTdgPublishMutation } =
    firegroundTasksApi;
