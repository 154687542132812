import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: "auth",

    initialState: {
        currentUser: null,
        token: null,
        // email: null,
    },

    reducers: {
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload;
        },

        setToken: (state, action) => {
            state.token = action.payload;
        },

        setEmail: (state, action) => {
            return action.payload;
        },
    },
});

export const { setCurrentUser, setEmail } = authSlice.actions;

export default authSlice.reducer;
