import { createApi } from "@reduxjs/toolkit/query/react";
import { api } from "../utils/constants/url";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { showErrorToast } from "../utils/constants/api/toast";

const tagTypes = "ActionKey";
const type = "ActionKey";
export const actionKeyApi = createApi({
    reducerPath: "actionKeyApi",
    baseQuery: baseQueryWithAuthInterceptor({
        baseUrl: `${api.baseURL}/actionKey`,
        prepareHeaders,
    }),
    tagTypes: [tagTypes],
    endpoints: (builder) => ({
        getActionKeys: builder.query({
            query: (responseTypeId) => ({
                url: `/getActionKeys/${responseTypeId}`,
                method: "GET",
            }),
            providesTags: [type],
        }),
        createActionKeys: builder.mutation({
            query: (body) => ({
                url: "/createActionKeys",
                method: "POST",
                body,
            }),
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        updateActionKeys: builder.mutation({
            query: (payload) => {
                const { formData, actionKeyId } = payload;
                return {
                    url: `/updateActionKeys/${actionKeyId}`,
                    method: "PUT",
                    body: formData,
                };
            },
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        deleteActionKeys: builder.mutation({
            query: (actionKeyId) => ({
                url: `/deleteActionKeys/${actionKeyId}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        updateIndex: builder.mutation({
            query: (payload) => {
                const { updateIndexResponse, responseTypeId } = payload;
                return {
                    url: `/updateIndex/${responseTypeId}`,
                    method: "POST",
                    body: updateIndexResponse,
                };
            },
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
    }),
});

export const { useGetActionKeysQuery, useCreateActionKeysMutation, useUpdateActionKeysMutation, useDeleteActionKeysMutation, useUpdateIndexMutation } =
    actionKeyApi;
