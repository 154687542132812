import { forwardRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

import { useGetIncidentPrioritiesQuery } from "../../../api/incidentPriorities";
import { isValidArray } from "../../../utils/constants/validation/array";

const SelectIncidentPriorities = forwardRef(
    ({ isError, defaultIncidentPriorities, disabled, handleChange, isLoading, clearFilter, style, responseId, optionDisable = [], ...props }, ref) => {
        const [incidentPrioritiesList, setIncidentPrioritiesList] = useState([]);
        const { data: incidentPrioritiesResponse } = useGetIncidentPrioritiesQuery(responseId, {
            refetchOnMountOrArgChange: true,
            skip: !responseId,
        });
        useEffect(() => {
            if (incidentPrioritiesResponse?.status !== "success") return;
            const optionsWithDisabled = incidentPrioritiesResponse?.data?.map(({ name, incidentPrioritiesId }) => ({
                label: name,
                value: incidentPrioritiesId,
                isDisabled: optionDisable.includes(incidentPrioritiesId),
            }));
            setIncidentPrioritiesList(optionsWithDisabled);
        }, [incidentPrioritiesResponse, optionDisable]);
    

        return (
            <Select
                options={incidentPrioritiesList}
                value={isValidArray(incidentPrioritiesList) ? incidentPrioritiesList.find((option) => option.value === defaultIncidentPriorities) : null}
                onChange={(selectedOption) => handleChange(selectedOption?.value)}
                isLoading={isLoading}
                ref={ref}
                {...props}
                styles={style}
            />
        );
    }
);

export default SelectIncidentPriorities;
