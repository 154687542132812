import React from "react";
import { forwardRef, useState, useEffect } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";

import { useGetTdgLibraryQuery } from "../../../api/firegroundTasks";
import { isValidArray } from "../../../utils/constants/validation/array";

const selectGame = forwardRef(({ isError, disabled, isLoading, defaultGame, handleChange, clearFilter, style, assignmentId, ...props }, ref) => {
    const standard = useSelector((state) => state);
    const [selectGameFromTDG, setSelectGameFromTDG] = useState([]);

    const { data: selectGame } = useGetTdgLibraryQuery(assignmentId, { refetchOnFocus: true, skip: !assignmentId });

    useEffect(() => {
        if (selectGame?.status !== "success") return;
        const selectOptions = selectGame?.data?.map(({ name, tdgLibraryId }) => {
            return { label: name, value: tdgLibraryId };
        });
        setSelectGameFromTDG(selectOptions);
    }, [selectGame]);

    return (
        <Select
            options={selectGameFromTDG}
            value={defaultGame && isValidArray(selectGameFromTDG) && selectGameFromTDG?.find((option) => option?.value === defaultGame)}
            onChange={(selectedOption) => handleChange(selectedOption?.value)}
            isLoading={isLoading}
            ref={ref}
            {...props}
            styles={style}
        />
    );
});

export default selectGame;
