import { createSlice } from "@reduxjs/toolkit";

const scenarioSlice = createSlice({
    name: "scenario",
    initialState: {
        scenarioData: {},
    },
    reducers: {
        setScenario: (state, action) => {
            state.scenarioData = action.payload;
        },
    },
});

export const { setScenario } = scenarioSlice.actions;
export default scenarioSlice.reducer;
