import React, { useEffect, useState } from "react";
import MainLayout from "../../../../../components/layouts/MainLayout";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import * as _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../../../utils/constants/variables/routes";
import RightArrow from "../../../../../assets/images/action/right-arrow.svg";
import PluseIcon from "../../../../../assets/images/action/plusIcon.svg";
import WhitePlus from "../../../../../assets/images/action/white-plus.svg";
import { useGetIncidentPrioritiesQuery, useUpdateIndexMutation } from "../../../../../api/incidentPriorities";
import { useGetAllDataMutation, useSyncDataMutation } from "../../../../../api/importData";
import { useDispatch } from "react-redux";
import CreateEditIncidentPriorities from "./CreateEditIncidentPriorities";
import ImportIncidentPriorities from "./ImportIncidentPriorities";
import DataNotFound from "../../../../../components/shared/DataNotFound/DataNotFound";
import { BiImport } from "react-icons/bi";
import { LiaSyncAltSolid } from "react-icons/lia";
import { RiEdit2Fill } from "react-icons/ri";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const IncidentPriorities = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();
    const { responseType } = params;
    const [responseTypeName, responseTypeId] = responseType?.split(/[=]+/);
    const originalDataId = location.state?.originalDataId;

    const [userRole, setUserRole] = useState("");
    const [showImportModal, setShowImportModal] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [importIncidentPrioritiesData, setImportIncidentPrioritiesData] = useState([]);
    const [incidentPrioritiesData, setIncidentPrioritiesData] = useState([]);
    const [incidentPrioritiesDetail, setIncidentPrioritiesDetail] = useState("");
    const [type, setType] = useState("incidentPriorities");

    const { data: incidentPriorities, refetch } = useGetIncidentPrioritiesQuery(responseTypeId);
    const [importIncidentPriorities] = useGetAllDataMutation();
    const [suyncData] = useSyncDataMutation();
    const [updateIndex] = useUpdateIndexMutation();

    useEffect(() => {
        const getUserRole = localStorage.getItem("userRole");
        if (getUserRole) {
            setUserRole(getUserRole);
        }
    }, []);

    useEffect(() => {
        refetch();
        if (incidentPriorities?.status === "success") {
            setIncidentPrioritiesData(incidentPriorities?.data);
        } else {
            toast.error(incidentPriorities?.error?.data?.message, { autoClose: false });
        }
    }, [incidentPriorities, refetch]);

    const handleDragStart = (e, index) => {
        e.dataTransfer.setData("index", index.toString());
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = async (e, newIndex) => {
        e.preventDefault();
        const dragIndex = parseInt(e.dataTransfer.getData("index"));
        const items = [...incidentPrioritiesData];
        const draggedItem = items[dragIndex];

        // Remove the item from its old position
        items.splice(dragIndex, 1);
        // Insert it into the new position
        items.splice(newIndex, 0, draggedItem);
        const updateIndexResponse = _.map(items, (itemList, idx) => {
            return {
                incidentPrioritiesId: _.get(itemList, "incidentPrioritiesId", ""),
                index: idx + 1,
            };
        });

        const response = await updateIndex({ updateIndexResponse: updateIndexResponse, responseTypeId: responseTypeId });
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            refetch();
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
        setIncidentPrioritiesData(response?.data?.data);
    };

    const handleSyncData = async (currentDataId) => {
        const payload = { type, currentDataId };
        const response = await suyncData(payload);
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            refetch();
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    const handleImportData = async () => {
        const payload = { type, responseTypeId };
        const response = await importIncidentPriorities(payload);
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            setShowImportModal(true);
            setImportIncidentPrioritiesData(response?.data?.data);
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };
    return (
        <div>
            <MainLayout className="me-0">
                <div className="row">
                    <div className="col-12 my-2">
                        <div className="bread-crumbs">
                            <div className="d-flex align-items-center me-1">
                                <span className="text-breadcrumb me-1" onClick={() => navigate(ROUTES.GAMEFUNNEL.RESPONSE)}>
                                    {responseTypeName}
                                </span>
                                <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                                <span
                                    className="text-breadcrumb me-1"
                                    onClick={() => {
                                        navigate(`/gameFunnel/incidentTypes/${responseTypeName}=${responseTypeId}`);
                                    }}
                                >
                                    Function keys
                                </span>
                                <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                                <span className="text-breadcrumb me-1">Incident Priorities</span>
                                <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-3 d-flex align-items-center justify-content-between hr-bottom">
                        <div className="d-flex align-items-center">
                            <button
                                className="btn-light me-3"
                                onClick={() => {
                                    navigate(`/gameFunnel/incidentTypes/${responseTypeName}=${responseTypeId}`);
                                }}
                            >
                                Incident types
                            </button>
                            <button className="btn-custom">Function keys</button>
                        </div>

                        {userRole == "organization" && originalDataId !== null && (
                            <div>
                                <button className="btn-import d-flex align-items-center" onClick={handleImportData}>
                                    <span className="me-2" style={{ fontWeight: "700" }}>
                                        <BiImport />
                                    </span>
                                    <span>Import</span>
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="col-12 mb-2 d-flex">
                        <button className="btn-custom me-3">Incident Priorities</button>
                        <button
                            className="btn-light"
                            onClick={() =>
                                navigate(`/gameFunnel/functionKeys/actionKey/${responseTypeName}=${responseTypeId}`, {
                                    state: originalDataId,
                                })
                            }
                        >
                            Action keys
                        </button>
                    </div>
                    <div className="col-12 dataList">
                        {!_.isEmpty(incidentPrioritiesData) ? (
                            <div className="row">
                                <div className="col-12 mb-4">
                                    <span className="title-text">List</span>
                                </div>
                                {_.map(incidentPrioritiesData, (item, index) => {
                                    var incidentPrioritiesId = item?.incidentPrioritiesId;
                                    var incidentPrioritiesName = item?.name;
                                    var originalDataId = item?.originalDataId;
                                    return (
                                        <div
                                            key={item.incidentPrioritiesId}
                                            className="col-12 col-sm-6 my-2"
                                            draggable
                                            onDragStart={(e) => handleDragStart(e, index)}
                                            onDragOver={handleDragOver}
                                            onDrop={(e) => handleDrop(e, index)}
                                        >
                                            <div
                                                className="item-list"
                                                onClick={() =>
                                                    navigate(
                                                        `/gameFunnel/functionKeys/objectives/${responseTypeName}=${responseTypeId}/${item?.name}=${item?.incidentPrioritiesId}`,
                                                        {
                                                            state: originalDataId,
                                                        }
                                                    )
                                                }
                                            >
                                                <div class="d-flex gap-2 align-items-center">
                                                    <div class="p-2 left-rect"></div>
                                                    <div class="p-2 flex-grow-1 d-block text-truncate">
                                                        {item.isMatch === false && userRole == "organization" && (
                                                            <LiaSyncAltSolid
                                                                className="me-2 icon-sync"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleSyncData(incidentPrioritiesId);
                                                                }}
                                                            />
                                                        )}
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`} className="custom-tooltip">
                                                                    {item.name}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span className="text-fs">{item.name}</span>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div class="ms-auto">
                                                        <div className="action-icon d-flex flex-nowrap align-item-center">
                                                            <RiEdit2Fill
                                                                className="ico-edit"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setShowEditForm(true);
                                                                    setIncidentPrioritiesDetail(item);
                                                                }}
                                                            />
                                                            <MdOutlineKeyboardArrowRight className="ico-arrow mx-2" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <DataNotFound texts={"Incident Priorities"} />
                        )}
                    </div>
                </div>
                <div className="btn-add d-none d-md-block d-lg-block" onClick={() => setShowCreateForm(true)}>
                    <button className="btn-custom">
                        <img alt="WhitePlus" src={WhitePlus} className="me-3" />
                        Add Priority
                    </button>
                </div>

                <div className="customAdd d-block d-sm-block d-md-none">
                    <div className="add" onClick={() => setShowCreateForm(true)}>
                        <img src={PluseIcon} alt={"PluseIcon"} height={"50px"} width={"50px"} />
                    </div>
                </div>
            </MainLayout>
            {showCreateForm && (
                <CreateEditIncidentPriorities
                    show={showCreateForm}
                    onHide={() => setShowCreateForm(false)}
                    responseTypeId={responseTypeId}
                    refetchCreateEditIncidentPriorities={refetch}
                />
            )}
            {showEditForm && (
                <CreateEditIncidentPriorities
                    incidentPrioritiesDetail={incidentPrioritiesDetail}
                    show={showEditForm}
                    showEditForm={showEditForm}
                    onHide={() => setShowEditForm(false)}
                    refetchCreateEditIncidentPriorities={refetch}
                />
            )}
            {showImportModal && (
                <ImportIncidentPriorities
                    importIncidentPrioritiesData={importIncidentPrioritiesData}
                    showImportModal={showImportModal}
                    show={showImportModal}
                    responseTypeId={responseTypeId}
                    onHide={() => setShowImportModal(false)}
                    refetchImportData={refetch}
                />
            )}
        </div>
    );
};

export default IncidentPriorities;
