import React, { useState, useEffect } from "react";
import * as _ from "lodash";
import { DataGrid } from "@mui/x-data-grid";
import "./style.scss";
import Form from "react-bootstrap/Form";
import MainLayout from "../../../../components/layouts/MainLayout";
import WhitePlus from "../../../../assets/images/action/white-plus.svg";
import { ROUTES } from "../../../../utils/constants/variables/routes";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import bulletIcon from "../../../../assets/images/action/bulletIcon.svg";
import PluseIcon from "../../../../assets/images/action/plusIcon.svg";
import Elips from "../../../../assets/images/action/elips.svg";
import DataNotFound from "../../../../components/shared/DataNotFound/DataNotFound";
import { setScenario } from "../../../../redux/scenarioSlice";
import { useGetScenarioQuery, useUpdateScenarioPublishMutation } from "../../../../api/scenario";
import { toast } from "react-toastify";
import EditIcon from "../../../../assets/images/action/editIcon.svg";
import DeleteIcon from "../../../../assets/images/action/deleteIcon.svg";
import DeleteModal from "../../../../components/delete/DeleteModal";
import { Controller, useForm } from "react-hook-form";
import { defaultValues } from "../../../../utils/constants/forms/defaultValues";
import { API } from "../../../../utils/constants/variables/apiFields";

const Scenario = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [scenarioData, setScenarioData] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteScenarioId, setdeletescenarioId] = useState("");
    const { data: scenario, refetch } = useGetScenarioQuery();
    const [updatePublish] = useUpdateScenarioPublishMutation();

    const form = useForm({
        defaultValues: defaultValues.SCENARIO,
    });
    const { control } = form;
    useEffect(() => {
        refetch();
        if (scenario?.data?.status === "success") {
            return;
        } else {
            toast.error(scenario?.error?.data?.message, { autoClose: false });
        }
        if (scenario) {
            setScenarioData(scenario?.data);
            dispatch(setScenario(scenario?.data));
        } else {
            setScenarioData([]);
        }
    }, [scenario, refetch]);

    const rows =
        scenarioData &&
        _.map(scenarioData, (item, index) => {
            return {
                no: index + 1,
                id: _.get(item, "scenarioId", ""),
                responseTypeId: _.get(item, "responseTypeId", ""),
                incidentTypeId: _.get(item, "incidentTypeId", ""),
                assignmentId: _.get(item, "assignmentId", ""),
                responseTypeName: _.get(item, "responseTypeName", ""),
                incidentTypeName: _.get(item, "incidentTypeName", ""),
                assignmentName: _.get(item, "assignmentName", ""),
                scenarioProjectLead: _.get(item, "scenarioProjectLead", ""),
                goal: _.get(item, "goal", ""),
                incidentAddress: _.get(item, "incidentAddress", ""),
                locationAndExtentOfTheFire: _.get(item, "locationAndExtentOfTheFire", ""),
                burningRegimeAndExposures: _.get(item, "burningRegimeAndExposures", ""),
                building: _.get(item, "building", ""),
                weather: _.get(item, "weather", ""),
                communicationDispatch: _.get(item, "communicationDispatch", ""),
                resources: _.get(item, "resources", ""),
                selectApparatus: _.get(item, "selectApparatus", ""),
                apparatusName: _.get(item, "apparatusName", ""),
                missionBriefing: _.get(item, "missionBriefing", ""),
                occupantStatus: _.get(item, "occupantStatus", ""),
                narrative: _.get(item, "narrative", ""),
                publish: _.get(item, "publish", ""),
            };
        });
    const columns = [
        {
            field: "#",
            headerName: "#",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>#</span>
                    </div>
                </div>
            ),
            renderCell: () => {
                return (
                    <div className="d-flex align-items-center justify-content-center w-100">
                        <div>
                            <img src={bulletIcon} alt={"bulletIcon"} height={"20px"} width={"20px"} />
                        </div>
                    </div>
                );
            },
            width: "lg" ? 80 : "sm" && 20,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "publish",
            headerName: "Publish",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Publish</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                const scenarioDetail = params.row;
                return (
                    <div
                        className="fs-item"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Controller
                            control={control}
                            name={API.SCENARIO.PUBLISH}
                            render={({ field: { value } }) => {
                                return (
                                    <Form.Check
                                        type="switch"
                                        id={`custom-switch-${scenarioDetail.id}`}
                                        className="customSwitch p-0 m-0"
                                        defaultChecked={scenarioDetail.publish}
                                        value={value}
                                        onChange={(e) => handlePublishChange(e, scenarioDetail.id)}
                                        reverse
                                    />
                                );
                            }}
                        />
                    </div>
                );
            },

            width: "lg" ? 150 : "sm" && 20,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "action",
            headerName: "Action",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Action</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                const scenarioDetail = params.row;
                return (
                    <div className="d-flex align-items-center justify-content-center gap-2 w-100">
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(ROUTES.EDIT_SCENARIO, {
                                    state: {
                                        scenarioDetail,
                                    },
                                });
                            }}
                        >
                            <img src={EditIcon} alt={"EditIcon"} height={"20px"} width={"20px"} />
                        </div>
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowDeleteModal(true);
                                setdeletescenarioId(scenarioDetail?.id);
                            }}
                        >
                            <img src={DeleteIcon} alt={"DeleteIcon"} height={"20px"} width={"20px"} />
                        </div>
                    </div>
                );
            },
            width: "lg" ? 150 : "sm" && 20,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "no",
            headerName: "No",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>No</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.no}</span>
                    </div>
                );
            },
            width: "lg" ? 80 : "sm" && 20,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "scenarioProjectLead",
            headerName: "Scenario Project Lead",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Scenario Project Lead</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.scenarioProjectLead || "-"}</span>
                    </div>
                );
            },
            width: 200,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "goal",
            headerName: "Goal",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Goal</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.goal || "-"}</span>
                    </div>
                );
            },
            width: 200,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "incidentAddress",
            headerName: "Incident Address",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Incident Address</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.incidentAddress || "-"}</span>
                    </div>
                );
            },
            width: 200,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "building",
            headerName: "Building",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Building</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.building || "-"}</span>
                    </div>
                );
            },
            width: 200,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "weather",
            headerName: "Weather",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Weather</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.weather || "-"}</span>
                    </div>
                );
            },
            width: 200,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "communicationDispatch",
            headerName: "Communication Dispatch",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Communication Dispatch</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.communicationDispatch || "-"}</span>
                    </div>
                );
            },
            width: 200,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "resources",
            headerName: "Resources",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Resources</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.resources || "-"}</span>
                    </div>
                );
            },
            width: 200,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "selectApparatus",
            headerName: "SelectApparatus",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>SelectApparatus</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.apparatusName || "-"}</span>
                    </div>
                );
            },
            width: 200,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "missionBriefing",
            headerName: "Mission Briefing",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Mission Briefing</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.missionBriefing || "-"}</span>
                    </div>
                );
            },
            width: 200,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
    ];
    const handlePublishChange = async (e, id) => {
        const isChecked = e.target.checked;
        const stringId = id;
        const response = await updatePublish({ id: stringId, publish: isChecked });
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            refetch();
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    return (
        <div>
            <MainLayout>
                <div className="row">
                    <div className="col-12 my-3 d-flex hr-bottom">
                        <button className="btn-custom me-3">Scenario</button>
                        <button className="btn-light" onClick={() => navigate(ROUTES.SCENARIO_ASSIGNMENTS)}>
                            Assignments
                        </button>
                    </div>
                </div>

                <div className="dataList-table">
                    {!_.isEmpty(scenarioData) ? (
                        <DataGrid className="my-4v" rows={rows} columns={columns} hideFooterPagination={true} rowHeight={40} rowSelection={false} />
                    ) : (
                        <DataNotFound texts={"Scenario"} />
                    )}
                </div>

                <div className="btn-add d-none d-md-block d-lg-block" onClick={() => navigate(ROUTES.CREATE_SCENARIO)}>
                    <button className="btn-custom">
                        <img src={WhitePlus} alt="WhitePlus" className="me-3" />
                        Add Scenario
                    </button>
                </div>

                <div className="customAdd d-block d-sm-block d-md-none">
                    <div className="add" onClick={() => navigate(ROUTES.CREATE_SCENARIO)}>
                        <img src={PluseIcon} alt={"PluseIcon"} height={"50px"} width={"50px"} />
                    </div>
                </div>
            </MainLayout>

            {showDeleteModal && (
                <DeleteModal
                    deleteId={deleteScenarioId}
                    scenario={showDeleteModal}
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    refetch={refetch}
                />
            )}
        </div>
    );
};

export default Scenario;
