import { createApi } from "@reduxjs/toolkit/query/react";
import { api } from "../utils/constants/url";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { showErrorToast } from "../utils/constants/api/toast";

const tagTypes = "ResponseType";
const type = "ResponseType";
export const responseTypeApi = createApi({
    reducerPath: "responseTypeApi",
    baseQuery: baseQueryWithAuthInterceptor({
        baseUrl: `${api.baseURL}/responseType`,
        prepareHeaders,
    }),
    tagTypes: [tagTypes],
    endpoints: (builder) => ({
        getResponseType: builder.query({
            query: () => ({
                url: "/getResponseType",
                method: "GET",
            }),
            providesTags: [type],
        }),
        createResponseType: builder.mutation({
            query: (body) => ({
                url: "/createResponseType",
                method: "POST",
                body,
            }),
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        updateResponseType: builder.mutation({
            query: (payload) => {
                const { data, responseTypeId } = payload;
                return {
                    url: `/updateResponseType/${responseTypeId}`,
                    method: "PUT",
                    body: data,
                };
            },
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        deleteResponseType: builder.mutation({
            query: (responseTypeId) => {
                return {
                    url: `/deleteResponseType/${responseTypeId}`,
                    method: "DELETE",
                };
            },
        }),
        updateIndex: builder.mutation({
            query: (body) => {
                return {
                    url: `/updateIndex`,
                    method: "POST",
                    body,
                };
            },
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
    }),
});

export const { useGetResponseTypeQuery, useCreateResponseTypeMutation, useUpdateResponseTypeMutation, useDeleteResponseTypeMutation, useUpdateIndexMutation } =
    responseTypeApi;
