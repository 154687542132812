import { BrowserRouter, Routes, Route, Outlet, Navigate } from "react-router-dom";
import { ROUTES } from "./utils/constants/variables/routes";
import CreatePassword from "./views/pages/ResetPassword";
import Login from "./views/pages/Login";
import ForgotPassword from "./views/pages/ForgotPassword";
import VerifyOTP from "./views/pages/VerifyOTP";
import UserProfile from "./views/pages/masterAdmin/userProfile/UserProfile";
import Organization from "./views/pages/masterAdmin/Organization/Organization";
import ResponseTypes from "./views/pages/masterAdmin/gameFunnel/response/ResponseTypes";
import IncidentTypes from "./views/pages/masterAdmin/gameFunnel/incident/IncidentTypes";
import Assignments from "./views/pages/masterAdmin/gameFunnel/assignment/Assignments";
import FiregroundTasks from "./views/pages/masterAdmin/gameFunnel/firegroundTasks/FiregroundTasks";
import CreateEditFiregroundTasks from "./views/pages/masterAdmin/gameFunnel/firegroundTasks/CreateEditFiregroundTasks";
import TacticalDecisionGame from "./views/pages/masterAdmin/gameFunnel/tacticalDecisionGame/TacticalDecisionGame";
import Profile from "./views/pages/masterAdmin/Profile/Profile";
import CreateEditTacticalDecisionGame from "./views/pages/masterAdmin/gameFunnel/tacticalDecisionGame/CreateEditTacticalDecisionGame";
import IncidentPriorities from "./views/pages/masterAdmin/gameFunnel/incidentPriorities/IncidentPriorities";
import Objectives from "./views/pages/masterAdmin/gameFunnel/objectives/Objectives";
import ThinkingPlanning from "./views/pages/masterAdmin/gameFunnel/thinkingPlanning/ThinkingPlanning";
import CreateEditThinkingPlanning from "./views/pages/masterAdmin/gameFunnel/thinkingPlanning/CreateEditThinkingPlanning";
import ActionKey from "./views/pages/masterAdmin/gameFunnel/actionKey/ActionKey";
import ActionList from "./views/pages/masterAdmin/gameFunnel/actionList/ActionList";
import Apparatus from "./views/pages/masterAdmin/apparatus/apparatus";
import Scenario from "./views/pages/masterAdmin/scenario/Scenario";
import CreateEditScenario from "./views/pages/masterAdmin/scenario/CreateEditScenario";
import ScenarioAssignments from "./views/pages/masterAdmin/scenarioAssignments/ScenarioAssignments";
import CreateEditScenarioAssignments from "./views/pages/masterAdmin/scenarioAssignments/CreateEditScenarioAssignments";

const RouterWrapper = () => {
    /**
     *
     * @param {*} routes
     * @returns Routes & Nested Route Component recursively
     */
    function generateRoutes(routes) {
        return routes.map((route, index) => {
            const { path, element, children } = route;
            return (
                <Route key={index} path={path} element={element}>
                    {children && children.length > 0 && generateRoutes(children)}
                </Route>
            );
        });
    }

    return <Routes>{generateRoutes(routeConfig)}</Routes>;
};

export default RouterWrapper;

const authRoutes = [
    {
        path: "/auth",
        element: <Outlet />,
        children: [
            {
                path: ROUTES.AUTH.LOGIN,
                element: <Login />,
            },
            {
                path: ROUTES.AUTH.FORGOT_PASSWORD,
                element: <ForgotPassword />,
            },
            {
                path: ROUTES.AUTH.VERIFY_OTP,
                element: <VerifyOTP />,
            },
            {
                path: ROUTES.AUTH.RESET_PASSWORD,
                element: <CreatePassword />,
            },
        ],
    },
];

const superAdminRoutes = [
    {
        path: "/admin",
        element: <Outlet />,
        // children: [
        //     {
        //         path: ROUTES.ADMIN.ACCOUNT,
        //         element: <Account />,
        //     },
        // ],
    },
];

const masterAdminRoutes = [
    {
        path: ROUTES.ORGANIZATION,
        element: <Organization />,
    },
    {
        path: ROUTES.PROFILE,
        element: <Profile />,
    },
    {
        path: "/gameFunnel",
        element: <Outlet />,
        children: [
            {
                path: ROUTES.GAMEFUNNEL.RESPONSE,
                element: <ResponseTypes />,
            },
            {
                path: ROUTES.GAMEFUNNEL.INCIDENT,
                element: <IncidentTypes />,
            },
            {
                path: ROUTES.GAMEFUNNEL.INCIDENT_PRIORITIES,
                element: <IncidentPriorities />,
            },
            {
                path: ROUTES.GAMEFUNNEL.OBJECTIVES,
                element: <Objectives />,
            },
            {
                path: ROUTES.GAMEFUNNEL.THINKING_PLANNING,
                element: <ThinkingPlanning />,
            },
            {
                path: ROUTES.GAMEFUNNEL.CREATE_THINKING_PLANNING,
                element: <CreateEditThinkingPlanning />,
            },
            {
                path: ROUTES.GAMEFUNNEL.EDIT_THINKING_PLANNING,
                element: <CreateEditThinkingPlanning />,
            },
            {
                path: ROUTES.GAMEFUNNEL.ASSIGNMENTS,
                element: <Assignments />,
            },
            {
                path: ROUTES.GAMEFUNNEL.FIREGROUND_TASKS,
                element: <FiregroundTasks />,
            },
            {
                path: ROUTES.GAMEFUNNEL.CREATE_FIREGROUND_TASKS,
                element: <CreateEditFiregroundTasks />,
            },
            {
                path: ROUTES.GAMEFUNNEL.EDIT_FIREGROUND_TASKS,
                element: <CreateEditFiregroundTasks />,
            },
            {
                path: ROUTES.GAMEFUNNEL.TACTICAL_DECISION_GAME,
                element: <TacticalDecisionGame />,
            },
            {
                path: ROUTES.GAMEFUNNEL.CREATE_TACTICAL_DECISION_GAME,
                element: <CreateEditTacticalDecisionGame />,
            },
            {
                path: ROUTES.GAMEFUNNEL.EDIT_TACTICAL_DECISION_GAME,
                element: <CreateEditTacticalDecisionGame />,
            },
            {
                path: ROUTES.GAMEFUNNEL.ACTION_KEY,
                element: <ActionKey />,
            },
            {
                path: ROUTES.GAMEFUNNEL.ACTION_LIST,
                element: <ActionList />,
            },
        ],
    },
    {
        path: ROUTES.USER_PROFILE,
        element: <UserProfile />,
    },
    {
        path: ROUTES.APPARATUS,
        element: <Apparatus />,
    },
    {
        path: ROUTES.SCENARIO,
        element: <Scenario />,
    },
    {
        path: ROUTES.CREATE_SCENARIO,
        element: <CreateEditScenario />,
    },
    {
        path: ROUTES.EDIT_SCENARIO,
        element: <CreateEditScenario />,
    },
    {
        path: ROUTES.SCENARIO_ASSIGNMENTS,
        element: <ScenarioAssignments />,
    },
    {
        path: ROUTES.CREATE_SCENARIO_ASSIGNMENTS,
        element: <CreateEditScenarioAssignments />,
    },
    {
        path: ROUTES.EDIT_SCENARIO_ASSIGNMENTS,
        element: <CreateEditScenarioAssignments />,
    },
];

const routeConfig = [...authRoutes, ...superAdminRoutes, ...masterAdminRoutes, { path: "*", element: <Navigate to={ROUTES.AUTH.LOGIN} replace /> }];
