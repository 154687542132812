import { createSlice } from "@reduxjs/toolkit";

const scenarioAssignmentsSlice = createSlice({
    name: "scenarioAssignments",
    initialState: {
        scenarioAssignmentsData: {},
    },
    reducers: {
        setScenario: (state, action) => {
            state.scenarioAssignmentsData = action.payload;
        },
    },
});

export const { setScenarioAssignments } = scenarioAssignmentsSlice.actions;
export default scenarioAssignmentsSlice.reducer;
