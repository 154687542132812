import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useUpdatePasswordOfProfileMutation } from "../../../../api/profile";
import { defaultValues } from "../../../../utils/constants/forms/defaultValues";
import { validation } from "../../../../utils/constants/forms/validation";
import { API } from "../../../../utils/constants/variables/apiFields";
// import { useNavigate } from "react-router-dom";

const UpdatePassword = (props) => {
    // const navigate = useNavigate();

    const [updatePassword] = useUpdatePasswordOfProfileMutation();

    const form = useForm({
        defaultValues: defaultValues.UPDATE_PASSWORD,
        resolver: yupResolver(validation.UPDATE_PASSWORD),
    });

    const {
        handleSubmit,
        formState: { errors },
        register,
        watch,
        setValue,
    } = form;

    const onSubmit = async (data) => {
        const allData = {
            password: data?.password,
        };
        const response = await updatePassword(allData);
        if (response?.data?.status === "success") {
            props.onHide();
            props.refecthUpdatePassword();
            toast.success(response?.data?.message, { autoClose: 1000 });
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };
    return (
        <div>
            <div className="update-password">
                <Modal {...props} size="md" className="m-8 " centered aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Body className="p-3">
                        <div>
                            <div className="my-3 text-center">
                                <button className="btn-custom">Change Password</button>
                            </div>
                            <div className="user-form">
                                <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        <div className="col-12 my-2">
                                            <div className="frm-input">
                                                <Form.Group>
                                                    <Form.Label className=" mb-0">New Password</Form.Label>
                                                    <Form.Control
                                                        className="custom-input"
                                                        type="password"
                                                        name={API.UPDATE_PASSWORD.PASSWORD}
                                                        {...register(API.UPDATE_PASSWORD.PASSWORD)}
                                                    />
                                                </Form.Group>
                                                <div className="error-msg">
                                                    {errors?.password && <span className="text-danger">{errors?.password?.message}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 my-2">
                                            <div className="frm-input">
                                                <Form.Group>
                                                    <Form.Label className=" mb-0">Confirm Password</Form.Label>
                                                    <Form.Control
                                                        className="custom-input"
                                                        type="password"
                                                        name={API.UPDATE_PASSWORD.CONFIRM_PASSWORD}
                                                        {...register(API.UPDATE_PASSWORD.CONFIRM_PASSWORD)}
                                                    />
                                                </Form.Group>
                                                <div className="error-msg">
                                                    {errors?.confirmPassword && <span className="text-danger">{errors?.confirmPassword?.message}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-100 text-center d-flex justify-content-center gap-2 my-3">
                                            <button type="submit" className="btn-save">
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
};

export default UpdatePassword;
