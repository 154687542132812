export const API = {
    LOGIN: {
        EMAIL: "email",
        PASS: "password",
        NOTIFICATION_TOKEN: "notificationToken",
        DEVICE_NAME: "deviceName",
        PLATFORM: "platform",
    },
    VERIFY_OTP: {
        EMAIL: "email",
        VERIFICATION_CODE: "verificationCode",
    },
    FORGOT_PASSWORD: {
        EMAIL: "email",
    },
    RESET_PASSWORD: {
        EMAIL: "email",
        PASS: "password",
        CONFIRM_PASS: "confirmPassword",
    },
    ORGANIZATION: {
        NAME: "name",
        EMAIL: "email",
        RANK: "rank",
        STATION: "station",
        SHIFT: "shift",
        CONTACT_NAME: "contactName",
        CONTACT_NUMBER: "contactNumber",
        LOGO: "logo",
        SUBSCRIPTION_DATE: "subscriptionDate",
        PASSWORD: "password",
    },
    PROFILE: {
        EMAIL: "email",
        PASSWORD: "password",
        PROFILE_NAME: "profileName",
        PROFILE_IMAGE: "profileImage",
        RANK: "rank",
        SHIFT: "shift",
        STATION: "station",
    },
    USER_PROFILE: {
        EMAIL: "email",
        PROFILE_NAME: "profileName",
        PROFILE_IMAGE: "profileImage",
    },
    UPDATE_PASSWORD: {
        PASSWORD: "password",
        CONFIRM_PASSWORD: "confirmPassword",
    },
    RESPONSE_TYPES: {
        NAME: "name",
    },
    INCIDENT_TYPES: {
        NAME: "name",
    },
    ASSIGNMENTS: {
        NAME: "name",
    },
    FIREGROUND_TASKS: {
        RESPONSE_TYPE: "responseTypeId",
        INCIDENT_TYPE: "incidentTypeId",
        ASSIGNMENT: "assignmentId",
        NAME: "name",
        GOAL_OBJECTIVE: "goalObjective",
        MISSION_BRIEFING: "missionBriefing",
        TEXT: "text",
        AUDIO: "audio",
        IMAGE: "image",
        TARGET_AUDIANCE: "targetAudience",
        BEST_NAMES: "bestNames",
        PUBLISH: "publish",
    },
    TACTICAL_DECISION_GAME: {
        TEXT: "text",
        IMAGE: "image",
        SELECT_TARGET_AUDIANCE: "selectTargetAudience",
        BEST_NAMES: "bestNames",
        TIME_LIMIT: "timeLimit",
        SELECT_ANS_TYPE: "selectAnswerType",
        SELECT_NUM_OF_SLIDERS: "selectNumberOfSliders",
        INCIDENT_PRIORITIES_ID1: "incidentPrioritiesId1",
        INCIDENT_PRIORITIES_ID2: "incidentPrioritiesId2",
        INCIDENT_PRIORITIES_ID0: "incidentPrioritiesId0",
        ACTION_KEY_ID0: "actionKeyId0",
        ACTION_KEY_ID1: "actionKeyId1",
        ACTION_KEY_ID2: "actionKeyId2",
        ADD_ANS_TYPES: "addAnswerType",
        PRIORITY_TYPE: "priorityType",
        NUMERIC: "numeric",
        TEXTING: "texting",
        MIN_VALUE: "minimumValue",
        MAX_VALUE: "maximumValue",
        MIN_VALUE_1: "minimumValue1",
        MAX_VALUE_1: "maximumValue1",
        CORRECT_ANS: "correctAnswer",
        TEXTS: "texts",
        TEXTS_1: "texts1",
        PUBLISH: "publish",
    },
    INCIDENT_PRIORITIES: {
        NAME: "name",
        ICON: "icon",
        COLOR: "color",
    },
    OBJECTIVES: {
        NAME: "name",
    },
    THINKING_PLANNING: {
        RESPONSE_TYPE: "responseTypeId",
        INCIDENT_PRIORITIES: "incidentPrioritiesId",
        OBJECTIVES: "objectivesId",
        NAME: "name",
        ANSWER_TYPE: "selectAnswerType",
        SLIDER_TYPE: "sliderTypes",
        SELECT_NUM_OF_SLIDERS: "selectNumberOfSliders",
        SELECT_SLIDER_TYPE: "selectSliderType",
        MIN_VALUE: "minimumValue",
        MAX_VALUE: "maximumValue",
        MIN_VALUE_1: "minimumValue1",
        MAX_VALUE_1: "maximumValue1",
        PRIORITY_TYPE: "priorityType",
        TEXTS: "texts",
        TEXTS_1: "texts1",
        PUBLISH: "publish",
    },
    ACTION_KEY: {
        NAME: "name",
        ICON: "icon",
        COLOR: "color",
    },
    ACTION_LIST: {
        NAME: "name",
    },
    ACTION_LIST: {
        NAME: "name",
    },
    APPARATUS: {
        APPARATUSID: "apparatusId",
        ORGANIZATIONID: "organizationId",
        STATION: "station",
        TOTALAPPARATUS: "totalApparatus",
        APPARATUSNAME: "apparatusName",
    },
    SCENARIO: {
        RESPONSE_TYPE: "responseTypeId",
        INCIDENT_TYPE: "incidentTypeId",
        ASSIGNMENT: "assignmentId",
        SCENARIO_PROJECT_LEAD: "scenarioProjectLead",
        GOAL: "goal",
        INCIDENT_ADDRESS: "incidentAddress",
        OCCUPANT_STATUS: "occupantStatus",
        LOCATION_AND_EXTENT_OF_THE_FIRE: "locationAndExtentOfTheFire",
        BURNING_REGIME_AND_EXPOSURES: "burningRegimeAndExposures",
        BUILDING: "building",
        WEATHER: "weather",
        NARRATIVE: "narrative",
        COMMUNICATION_DISPATCH: "communicationDispatch",
        RESOURCES: "resources",
        SELECT_APPARATUS: "selectApparatus",
        MISSION_BRIEFING: "missionBriefing",
        PUBLISH: "publish",
    },
    SCENARIO_ASSIGNMENTS: {
        RESPONSE_TYPE: "responseTypeId",
        INCIDENT_TYPE: "incidentTypeId",
        ASSIGNMENT: "assignmentId",
        APPARATUS_NAME: "apparatusName",
        AUDIO: "audio",
        VIDEO: "video",
        GAME_FROM_TDG: "gameFromTDG",
        TDG_NAME: "tdgName",
        GAME_INFO: "gameInfo",
        GOAL_OBJECTIVE: "goalObjective",
        MISSION_BRIEFING: "missionBriefing",
        TARGET_AUDIANCE: "targetAudience",
        BEST_NAMES: "bestNames",
        SCENARIO: "scenarioId",
        APPARATUS_ID: "apparatusId",
    },
};
