import React, { useEffect, useState } from "react";
import MainLayout from "../../../../../components/layouts/MainLayout";
import * as _ from "lodash";
import { toast } from "react-toastify";
import RightArrow from "../../../../../assets/images/action/right-arrow.svg";
import WhitePlus from "../../../../../assets/images/action/white-plus.svg";
import PluseIcon from "../../../../../assets/images/action/plusIcon.svg";
import CreateEditAssignments from "./CreateEditAssignments";
import ImportAssignment from "./ImportAssignment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../../../utils/constants/variables/routes";
import { useGetAssignmentQuery, useUpdateIndexMutation } from "../../../../../api/assignment";
import { useGetAllDataMutation, useSyncDataMutation } from "../../../../../api/importData";
import { useDispatch } from "react-redux";
import { setAssignment } from "../../../../../redux/assignmentSlice";
import DataNotFound from "../../../../../components/shared/DataNotFound/DataNotFound";
import { BiImport } from "react-icons/bi";
import { LiaSyncAltSolid } from "react-icons/lia";
import { RiEdit2Fill } from "react-icons/ri";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Assignments = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { responseType, incidentType } = params;
    const [responseTypeName, responseTypeId] = responseType?.split(/[=]+/);
    const [incidentTypeName, incidentTypeId] = incidentType?.split(/[=]+/);
    const originalDataId = location.state?.originalDataId;
    const [userRole, setUserRole] = useState("");
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showImportModal, setShowImportModal] = useState(false);
    const [assignmentData, setAssignmentData] = useState([]);
    const [importAssignmentData, setImportAssignmentData] = useState([]);
    const [assignmentDetail, setAssignmentDetail] = useState("");
    const [type, setType] = useState("assignments");

    const { data: assignment, refetch } = useGetAssignmentQuery(incidentTypeId);
    const [importAssignment] = useGetAllDataMutation();
    const [suyncData] = useSyncDataMutation();

    const [updateIndex] = useUpdateIndexMutation();

    useEffect(() => {
        const getUserRole = localStorage.getItem("userRole");
        if (getUserRole) {
            setUserRole(getUserRole);
        }
    }, []);

    useEffect(() => {
        refetch();
        if (assignment?.data?.status === "success") {
            return;
        } else {
            toast.error(assignment?.error?.data?.message, { autoClose: false });
        }

        if (assignment) {
            setAssignmentData(assignment?.data);
            dispatch(setAssignment(assignment?.data));
        } else {
            setAssignmentData([]);
        }
    }, [assignment, refetch]);

    const handleDragStart = (e, index) => {
        e.dataTransfer.setData("index", index.toString());
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = async (e, newIndex) => {
        e.preventDefault();
        const dragIndex = parseInt(e.dataTransfer.getData("index"));
        const items = [...assignmentData];
        const draggedItem = items[dragIndex];

        items.splice(dragIndex, 1);
        items.splice(newIndex, 0, draggedItem);

        const updateIndexResponse = _.map(items, (itemList, idx) => {
            return {
                assignmentId: _.get(itemList, "assignmentId", ""),
                index: idx + 1,
            };
        });

        const response = await updateIndex({ updateIndexResponse: updateIndexResponse, responseTypeId: responseTypeId });
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            refetch();
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };
    const handleSyncData = async (currentDataId) => {
        const payload = { type, currentDataId };
        const response = await suyncData(payload);
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            refetch();
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };
    const handleImportData = async () => {
        const payload = { type, responseTypeId };
        const response = await importAssignment(payload);
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            setShowImportModal(true);
            setImportAssignmentData(response?.data?.data);
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };
    const allAssignmentData = _.map(assignmentData, (item) => {
        return {
            assignmentId: _.get(item, "assignmentId", ""),
            name: _.get(item, "name", ""),
            incidentTypeId: _.get(item, "incidentTypeId", ""),
            incidentTypeName: _.get(item, "incidentTypeName", ""),
            responseTypeId: _.get(item, "responseTypeId", ""),
            responseTypeName: _.get(item, "responseTypeName", ""),
            originalDataId: _.get(item, "originalDataId", ""),
            isMatch: _.get(item, "isMatch", ""),
        };
    });

    return (
        <div>
            <MainLayout className="me-0">
                <div className="row">
                    <div className="col-12 my-2">
                        <div className="bread-crumbs d-flex align-items-center">
                            <div className="d-flex align-items-center me-1">
                                <span className="text-breadcrumb me-1" onClick={() => navigate(ROUTES.GAMEFUNNEL.RESPONSE)}>
                                    {responseTypeName}
                                </span>
                                <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                            </div>
                            <div className="d-flex align-items-center me-1">
                                <span
                                    className="text-breadcrumb me-1"
                                    onClick={() => {
                                        navigate(`/gameFunnel/incidentTypes/${responseTypeName}=${responseTypeId}`);
                                    }}
                                >
                                    {incidentTypeName}
                                </span>
                                <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 hr-bottom d-flex align-items-center justify-content-between">
                        <div>
                            <button className="btn-custom">Incident types</button>
                        </div>
                        {userRole == "organization" && originalDataId !== null && (
                            <div>
                                <button className="btn-import d-flex align-items-center" onClick={handleImportData}>
                                    <span className="me-2" style={{ fontWeight: "700" }}>
                                        <BiImport />
                                    </span>
                                    <span>Import</span>
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="col-12 my-3">
                        <button className="btn-custom">Assignments</button>
                    </div>
                    <div className="col-12 dataList">
                        {!_.isEmpty(allAssignmentData) ? (
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <span className="title-text">List</span>
                                </div>
                                {console.log("allAssignmentData=====", allAssignmentData)}
                                {_.map(allAssignmentData, (item, index) => {
                                    const assignmentId = item.assignmentId || index;
                                    const assignmentName = item.name;
                                    const originalDataId = item.originalDataId;
                                    return (
                                        <div
                                            key={assignmentId}
                                            className="col-12 col-sm-6 my-2"
                                            draggable
                                            onDragStart={(e) => handleDragStart(e, index)}
                                            onDragOver={handleDragOver}
                                            onDrop={(e) => handleDrop(e, index)}
                                        >
                                            <div
                                                className="item-list"
                                                onClick={() => {
                                                    navigate(
                                                        `/gameFunnel/firegroundTasks/${responseTypeName}=${responseTypeId}/${incidentTypeName}=${incidentTypeId}/${item.name}=${item.assignmentId}`,
                                                        {
                                                            state: { originalDataId },
                                                        }
                                                    );
                                                }}
                                            >
                                                <div class="d-flex gap-2 align-items-center">
                                                    <div class="p-2 left-rect"></div>
                                                    <div class="p-2 flex-grow-1 d-block text-truncate">
                                                        {item.isMatch === false && userRole == "organization" && (
                                                            <LiaSyncAltSolid
                                                                className="me-2 icon-sync"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleSyncData(assignmentId);
                                                                }}
                                                            />
                                                        )}
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`} className="custom-tooltip">
                                                                    {item.name}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span className="text-fs">{item.name}</span>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div class="ms-auto">
                                                        <div className="action-icon d-flex flex-nowrap align-item-center">
                                                            <RiEdit2Fill
                                                                className="ico-edit"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setShowEditForm(true);
                                                                    setAssignmentDetail(item);
                                                                }}
                                                            />
                                                            <MdOutlineKeyboardArrowRight className="ico-arrow mx-2" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <DataNotFound texts={"Assignments"} />
                        )}
                    </div>
                </div>
                <div className="btn-add d-none d-md-block d-lg-block" onClick={() => setShowCreateForm(true)}>
                    <button className="btn-custom">
                        <img alt="WhitePlus" src={WhitePlus} className="me-3" />
                        Add Assignment
                    </button>
                </div>
                <div className="customAdd d-block d-sm-block d-md-none">
                    <div className="add" onClick={() => setShowCreateForm(true)}>
                        <img src={PluseIcon} alt={"PluseIcon"} height={"50px"} width={"50px"} />
                    </div>
                </div>
            </MainLayout>
            {showCreateForm && (
                <CreateEditAssignments
                    show={showCreateForm}
                    onHide={() => setShowCreateForm(false)}
                    responseTypeId={responseTypeId}
                    incidentTypeId={incidentTypeId}
                    refetchCreateEditAssignment={refetch}
                />
            )}
            {showEditForm && (
                <CreateEditAssignments
                    assignmentDetail={assignmentDetail}
                    showEditForm={showEditForm}
                    show={showEditForm}
                    onHide={() => setShowEditForm(false)}
                    refetchCreateEditAssignment={refetch}
                />
            )}
            {showImportModal && (
                <ImportAssignment
                    importAssignmentData={importAssignmentData}
                    showImportModal={showImportModal}
                    show={showImportModal}
                    responseTypeId={responseTypeId}
                    incidentTypeId={incidentTypeId}
                    onHide={() => setShowImportModal(false)}
                    refetchImportData={refetch}
                />
            )}
        </div>
    );
};

export default Assignments;
