import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BiSolidUser } from "react-icons/bi";
import { Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.scss";
import { useResetPasswordMutation } from "../../../api/auth";
import { validation } from "../../../utils/constants/forms/validation";
import confirmPassword from "../../../assets/images/conformpasswordbackground.svg";
import { API } from "../../../utils/constants/variables/apiFields";
import { defaultValues } from "../../../utils/constants/forms/defaultValues";
import { ROUTES } from "../../../utils/constants/variables/routes";
import { useEffect } from "react";
import Logo from "../../../assets/images/Sidebar/logo.png";

const { CONFIRM_PASS, PASS } = API.RESET_PASSWORD;

const ResetPassword = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const [resetPassword] = useResetPasswordMutation();

    const form = useForm({
        defaultValues: defaultValues.RESET_PASSWORD,
        resolver: yupResolver(validation.RESET_PASSWORD),
    });

    const { handleSubmit, setValue, register } = form;

    useEffect(() => {
        setValue("email", state?.email);
    }, [state, setValue]);

    const onSubmit = async (data) => {
        const response = await resetPassword(data);
        if (["success", "Success", 200, 201, 202].includes(response?.data?.status)) navigate(ROUTES.AUTH.LOGIN);
    };
    return (
        <>
            <div className="resetPassword">
                <div className="container-fluid d-flex">
                    <div className="row new-password">
                        <div className="col-12 col-lg-6 col-md-12">
                            <div className="image">
                                <img src={confirmPassword} alt="as" srcset="" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-md-12">
                            <div className="content">
                                <div className="user-img">
                                    <img src={Logo} alt={"Logo"} />
                                </div>
                                <div className="user-content">
                                    <div className="title">
                                        <span>Create new password.</span>
                                    </div>
                                    <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                                        <div>
                                            <div>
                                                <InputGroup>
                                                    <InputGroup.Text>
                                                        <BiSolidUser />
                                                    </InputGroup.Text>
                                                    <Form.Control {...register(PASS)} name={PASS} type="password" placeholder="Password" />
                                                </InputGroup>
                                            </div>
                                            <div>
                                                <InputGroup>
                                                    <InputGroup.Text>
                                                        <BiSolidUser />
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        {...register(CONFIRM_PASS)}
                                                        name={CONFIRM_PASS}
                                                        type="password"
                                                        placeholder="ConfirmPassword"
                                                    />
                                                </InputGroup>
                                            </div>
                                            <div className="btn-login text-center">
                                                <button>save</button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;
