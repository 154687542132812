import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import * as _ from "lodash";
import { ROUTES } from "../../../../../utils/constants/variables/routes";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { defaultValues } from "../../../../../utils/constants/forms/defaultValues";
import { toast } from "react-toastify";
import DataNotFound from "../../../../../components/shared/DataNotFound/DataNotFound";
import { BiImport } from "react-icons/bi";
import { useImportMutation } from "../../../../../api/importData";
import { FaEye } from "react-icons/fa";
import ViewTacticalDecisionGame from "./ViewTacticalDecisionGame";
import ImportFunctionKeyModal from "./ImportFunctionKeyModal";

const ImportTacticalDecisionGame = (props) => {
    const navigate = useNavigate();
    const importTacticalDecisionGameData = props.importTacticalDecisionGameData;
    const tdgLibraryId = props.tdgLibraryId;

    const [showViewModal, setShowViewModal] = useState(false);
    const [showImportModal, setShowImportModal] = useState(false);
    const [importData, refetch] = useImportMutation();
    const [ImportTacticalDecisionGame, setImportTacticalDecisionGame] = useState([]);
    const [tacticalDecisionGameData, setTacticalDecisionGameData] = useState([]);
    const [arrayOfIds, srtArrayOfIds] = useState([]);
    const [type, setType] = useState("tacticalDecisionGames");

    const form = useForm({
        defaultValues: defaultValues.RESPONSE_TYPES,
    });

    const { control } = form;

    const rows =
        importTacticalDecisionGameData &&
        _.map(importTacticalDecisionGameData, (item, index) => {
            return {
                no: index + 1,
                id: _.get(item, "tacticalDecisionGameId", ""),
                tdgLibraryId: _.get(item, "tdgLibraryId", ""),
                addAnswerTypes: _.get(item, "addAnswerTypes", ""),
                bestNames: _.get(item, "bestNames", ""),
                text: _.get(item, "text", ""),
                image: _.get(item, "image", ""),
                selectAnswerType: _.get(item, "selectAnswerType", ""),
                selectTargetAudience: _.get(item, "selectTargetAudience", ""),
                selectNumberOfSliders: _.get(item, "selectNumberOfSliders", ""),
                timeLimit: _.get(item, "timeLimit", ""),
                minimumValue: _.get(item, "minimumValue", ""),
                maximumValue: _.get(item, "maximumValue", ""),
                minimumValue1: _.get(item, "minimumValue1", ""),
                maximumValue1: _.get(item, "maximumValue1", ""),
                texts: _.get(item, "texts", ""),
                texts1: _.get(item, "texts1", ""),
                correctAnswer: _.get(item, "correctAnswer", ""),
                numeric: _.get(item, "numeric", ""),
                goalObjective: _.get(item, "goalObjective", ""),
                missionBriefing: _.get(item, "missionBriefing", ""),
                tacticalDecisionGameId: _.get(item, "tacticalDecisionGameId", ""),
                texting: _.get(item, "texting", ""),
                isVoiceToText: _.get(item, "isVoiceToText", ""),
                tacticalFunctionWithObjectivesAction: _.get(item, "tacticalFunctionWithObjectivesAction", ""),
                tacticalFunctionWithObjectivesIncident: _.get(item, "tacticalFunctionWithObjectivesIncident", ""),
                priorityType: _.get(item, "priorityType", ""),
                publish: _.get(item, "publish", ""),
                recordExists: _.get(item, "recordExists", ""),
            };
        });
    const columns = [
        {
            field: "no",
            headerName: "#",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <Form.Check // prettier-ignore
                            type="checkbox"
                            className="fs-5 custom-check p-0 check-border-color"
                            disabled
                            reverse
                        />
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="d-flex align-items-center justify-content-center w-100">
                        <Controller
                            className="d-flex align-items-center"
                            control={control}
                            name="tacticalDecisionGameId"
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <Form.Check // prettier-ignore
                                        type="checkbox"
                                        id="custom-check"
                                        className="fs-5 custom-check check-border-color p-0"
                                        disabled={params.row.recordExists}
                                        checked={params.row.recordExists || arrayOfIds.includes(params.row.id)}
                                        onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            if (isChecked) {
                                                srtArrayOfIds((prevItems) => [...prevItems, params.row.id]);
                                            } else {
                                                srtArrayOfIds((prevItems) => prevItems.filter((itemId) => itemId !== params.row.id));
                                            }
                                            onChange(e);
                                        }}
                                        reverse
                                    />
                                );
                            }}
                        />
                    </div>
                );
            },
            width: 80,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "action",
            headerName: "Action",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <span>Action</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                const tacticalDecisionGameDetail = params.row;
                return (
                    <div className="d-flex align-items-center justify-content-center gap-2 w-100">
                        <div
                            onClick={() => {
                                setShowViewModal(true);
                                setTacticalDecisionGameData(tacticalDecisionGameDetail);
                            }}
                        >
                            <FaEye className="icon-view" />
                        </div>
                    </div>
                );
            },
            width: "lg" ? 100 : "sm" && 20,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "selectAnswerType",
            headerName: "AnswerType",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <span>Answer Type</span>
                </div>
            ),
            renderCell: (params) => {
                const answerTypeWithSpacesAndCapitalized = params.row.selectAnswerType
                    ? params.row.selectAnswerType
                          .replace(/([A-Z])/g, " $1") // Add spaces before capital letters
                          .split(" ") // Split by spaces
                          .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
                          .join(" ") // Join words back together
                    : "-";

                return (
                    <div className="fs-item">
                        <span>{answerTypeWithSpacesAndCapitalized}.</span>
                    </div>
                );
            },
            width: 250,
            height: 300,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "image",
            headerName: "Image",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <span>Image</span>
                </div>
            ),
            renderCell: (params) => {
                const { image } = params.row;
                const firstFourImages = image.slice(0, 4);

                return (
                    <div className="d-flex flex-column fs-item justify-content-center align-items-center gap-1 w-100">
                        <span className="text-center">{params.row.text}</span>
                        {image &&
                            image.length > 0 && ( // Check if image array exists and is not empty
                                <div className="w-100 text-center">
                                    {firstFourImages.map((item, index) => (
                                        <div key={index} className="mb-2 d-flex align-items-center justify-content-center">
                                            <div className="col-2">
                                                {item.image && (
                                                    <img src={item.image} alt={`Image${index}`} style={{ width: "50px", height: "50px", objectFit: "cover" }} />
                                                )}
                                            </div>
                                            <div className="col-10  ">
                                                {item.audio && ( // Check if audio exists for the current image
                                                    <audio controls className="mt-1">
                                                        <source src={item.audio} type="audio/mpeg" />
                                                    </audio>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                    </div>
                );
            },

            width: 500,
            height: 300,
            sortable: false,
            disableColumnMenu: true,
        },
    ];

    const handleImportData = async () => {
        const payload = { type, tdgLibraryId, arrayOfIds };
        const response = await importData(payload);
        if (response?.data?.status === "success") {
            setImportTacticalDecisionGame(response?.data?.data);
            props.onHide();
            props.refetchImportData();
            toast.success(response?.data?.message, { autoClose: 1000 });
        } else if (response?.data?.status === 202) {
            setShowImportModal(true);
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    return (
        <div>
            <div className="tactical-decision-game">
                <Modal {...props} size="lg" centered aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Body className="p-3">
                        <div className="my-2 text-center">
                            <button className="btn-custom">Tactical Decision Game</button>
                        </div>
                        <div className="dataList-table overflow-y-hidden">
                            {!_.isEmpty(importTacticalDecisionGameData) ? (
                                <DataGrid
                                    className="my-4"
                                    getRowHeight={() => "auto"}
                                    rows={rows}
                                    columns={columns}
                                    hideFooterPagination={true}
                                    rowSelection={false}
                                />
                            ) : (
                                <DataNotFound texts={"Game"}/>
                            )}
                        </div>
                        <div className="my-4 d-flex justify-content-center">
                            <button type="button" className="btn-custom d-flex align-items-center" onClick={handleImportData}>
                                <span className="me-2" style={{ fontWeight: "700" }}>
                                    <BiImport />
                                </span>
                                <span>Import</span>
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            {showImportModal && (
                <ImportFunctionKeyModal
                    type={type}
                    tdgLibraryId={tdgLibraryId}
                    arrayOfIds={arrayOfIds}
                    showImportModal={showImportModal}
                    show={showImportModal}
                    onHide={() => setShowImportModal(false)}
                    refetchData={props?.refetchImportData}
                    firstModalHide={props?.onHide}
                />
            )}
            {showViewModal && (
                <ViewTacticalDecisionGame
                    tacticalDecisionGameData={tacticalDecisionGameData}
                    showViewModal={showViewModal}
                    show={showViewModal}
                    onHide={() => setShowViewModal(false)}
                    refetchViewData={refetch}
                />
            )}
        </div>
    );
};

export default ImportTacticalDecisionGame;
