import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useCreateProfileMutation, useUpdateProfileMutation, useDeleteProfileMutation, useOPPasswordUpdateMutation } from "../../../../api/profile";
import { defaultValues } from "../../../../utils/constants/forms/defaultValues";
import { validation } from "../../../../utils/constants/forms/validation";
import { API } from "../../../../utils/constants/variables/apiFields";
import { RiImageAddFill } from "react-icons/ri";

const appendFormValuesToFormData = (formData, data) => {
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            formData.append(key, data[key]);
        }
    }
};

const CreateEditProfile = (props) => {
    const station = props.station;
    const isEdit = props.showEditForm;
    const profileDetail = props.profileDetail;
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [createProfile] = useCreateProfileMutation();
    const [updateProfile] = useUpdateProfileMutation();
    const [updateOPPassword] = useOPPasswordUpdateMutation();
    const [deleteProfile] = useDeleteProfileMutation();
    console.log("props====", props);

    const form = useForm({
        defaultValues: {
            ...defaultValues.PROFILE,
            station: station, // Set default value for station
        },
        resolver: yupResolver(validation.PROFILE(isEdit)),
    });

    const handlePhotoSelect = (event) => {
        const file = event.target.files[0];
        setSelectedPhoto(file);
        setValue("profileImage", file);
    };

    const isDefaultImage = (profileImage) => {
        return profileImage && profileImage.toLowerCase().includes("null");
    };
    const {
        handleSubmit,
        formState: { errors },
        register,
        watch,
        setValue,
    } = form;

    useEffect(() => {
        if (profileDetail?.id) {
            setValue("profileName", profileDetail?.profileName);
            setValue("station", profileDetail?.station || station);
            setValue("rank", profileDetail?.rank);
            setValue("shift", profileDetail?.shift);
            setValue("email", profileDetail?.email);

            const profileImage = profileDetail?.profileImage;
            setSelectedPhoto(isDefaultImage(profileImage) ? null : profileImage);
        }
    }, [setValue, profileDetail, station]);

    const onSubmit = async (data) => {
        const profileId = profileDetail?.id;
        const organizationId = props?.organizationId;
        const allData = {
            ...data,
            organizationId: organizationId,
            station: station,
        };
        const formData = new FormData();
        appendFormValuesToFormData(formData, allData);
        const response = isEdit ? await updateProfile({ formData, profileId }) : await createProfile(formData);
        if (response?.data?.status === "success") {
            props.onHide();
            props.refetchCreateEditProfile();
            toast.success(response?.data?.message, { autoClose: 1000 });
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    const handleUpdatePassword = async (data) => {
        const userId = profileDetail?.userId;
        const allData = {
            userId: userId,
            password: data?.password,
        };
        const response = await updateOPPassword(allData);
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    return (
        <div>
            <div className="create-edit-acconut">
                <Modal {...props} size="lg" className="m-8 " centered aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Body className="p-3">
                        {!isEdit ? (
                            <div>
                                <div className="my-3 text-center">
                                    <button className="btn-custom">Add Detail</button>
                                </div>
                                <div className="user-form">
                                    <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-4 col-lg-4 my-2 d-flex align-items-center justify-content-center">
                                                <div className="select_photo">
                                                    <label htmlFor="photoInput" className="logo-image d-flex align-items-center justify-content-center">
                                                        {selectedPhoto ? (
                                                            <img
                                                                src={typeof selectedPhoto === "string" ? selectedPhoto : URL.createObjectURL(selectedPhoto)}
                                                                alt="Profile_logo"
                                                                className="selected_photo"
                                                            />
                                                        ) : (
                                                            <>
                                                                <RiImageAddFill size={48} color="#999" />
                                                            </>
                                                        )}
                                                    </label>
                                                    <input
                                                        id="photoInput"
                                                        type="file"
                                                        accept="image/png ,image/jpg ,image/jpeg"
                                                        {...register("profileImage")}
                                                        onChange={handlePhotoSelect}
                                                        className="d-none"
                                                    />
                                                </div>
                                                <div className="error-msg">
                                                    {errors?.profileImage && <span className="text-danger">{errors?.profileImage?.message}</span>}
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-8 col-lg-8 my-2">
                                                <div className="frm-input">
                                                    <Form.Group className="my-2">
                                                        <Form.Label className=" mb-0">Name</Form.Label>
                                                        <Form.Control
                                                            className="custom-input"
                                                            type="text"
                                                            name={API.PROFILE.PROFILE_NAME}
                                                            {...register(API.PROFILE.PROFILE_NAME)}
                                                        />
                                                    </Form.Group>
                                                    <div className="error-msg">
                                                        {errors?.profileName && <span className="text-danger">{errors?.profileName?.message}</span>}
                                                    </div>

                                                    <Form.Group className="my-2">
                                                        <Form.Label className=" mb-0">Email</Form.Label>
                                                        <Form.Control
                                                            className="custom-input"
                                                            type="email"
                                                            name={API.PROFILE.EMAIL}
                                                            {...register(API.PROFILE.EMAIL)}
                                                        />
                                                    </Form.Group>
                                                    <div className="error-msg">
                                                        {errors?.email && <span className="text-danger">{errors?.email?.message}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 my-2">
                                                <div className="frm-input">
                                                    <Form.Group>
                                                        <Form.Label className=" mb-0">Password</Form.Label>
                                                        <Form.Control
                                                            className="custom-input"
                                                            type="password"
                                                            name={API.PROFILE.PASSWORD}
                                                            {...register(API.PROFILE.PASSWORD)}
                                                        />
                                                    </Form.Group>
                                                    <div className="error-msg">
                                                        {errors?.password && <span className="text-danger">{errors?.password?.message}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 my-2">
                                                <div className="frm-input">
                                                    <Form.Group>
                                                        <Form.Label className=" mb-0">Station</Form.Label>
                                                        <Form.Control
                                                            className="custom-input"
                                                            type="text"
                                                            name={API.PROFILE.STATION}
                                                            value={station}
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                    <div className="error-msg">
                                                        {errors?.station && <span className="text-danger">{errors?.station?.message}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 my-2 shift-select">
                                                <Form.Group>
                                                    <Form.Label className=" mb-0">Shift</Form.Label>
                                                    <Form.Control
                                                        className="custom-input"
                                                        as="select"
                                                        name={API.PROFILE.SHIFT}
                                                        {...register(API.PROFILE.SHIFT)}
                                                    >
                                                        <option value="A">A</option>
                                                        <option value="B">B</option>
                                                        <option value="C">C</option>
                                                        <option value="D">D</option>
                                                    </Form.Control>
                                                </Form.Group>
                                                <div className="error-msg">
                                                    {errors?.shift && <span className="text-danger">{errors?.shift?.message}</span>}
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 my-2">
                                                <div className="frm-input">
                                                    <Form.Group>
                                                        <Form.Label className=" mb-0">Rank</Form.Label>
                                                        <Form.Control
                                                            className="custom-input"
                                                            as="select"
                                                            name={API.PROFILE.RANK}
                                                            {...register(API.PROFILE.RANK)}
                                                        >
                                                            <option value="fireFighter">Fire Fighter</option>
                                                            <option value="officer">Officer</option>
                                                            <option value="chiefOfficer">Chief Officer</option>
                                                            <option value="Combinations">Combinations</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <div className="error-msg">
                                                        {errors?.rank && <span className="text-danger">{errors?.rank?.message}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-100 text-center my-3">
                                                <button type="submit" className="btn-save">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="my-3 text-center">
                                    <button className="btn-custom">Edit Detail</button>
                                </div>
                                <div className="user-form">
                                    <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-4 col-lg-4 my-2 d-flex align-items-center justify-content-center">
                                                <div className="select_photo">
                                                    <label htmlFor="photoInput" className="logo-image d-flex align-items-center justify-content-center">
                                                        {selectedPhoto ? (
                                                            <img
                                                                src={typeof selectedPhoto === "string" ? selectedPhoto : URL.createObjectURL(selectedPhoto)}
                                                                alt="Profile_logo"
                                                                className="selected_photo"
                                                            />
                                                        ) : (
                                                            <>
                                                                <RiImageAddFill size={48} color="#999" />
                                                            </>
                                                        )}
                                                    </label>
                                                    <input
                                                        id="photoInput"
                                                        type="file"
                                                        accept="image/png ,image/jpg ,image/jpeg"
                                                        {...register("profileImage")}
                                                        onChange={handlePhotoSelect}
                                                        className="d-none"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-8 col-lg-8 my-2">
                                                <div className="frm-input">
                                                    <Form.Group className="my-2">
                                                        <Form.Label className=" mb-0">Name</Form.Label>
                                                        <Form.Control
                                                            className="custom-input"
                                                            type="text"
                                                            name={API.PROFILE.PROFILE_NAME}
                                                            {...register(API.PROFILE.PROFILE_NAME)}
                                                        />
                                                    </Form.Group>
                                                    <div className="error-msg">
                                                        {errors?.profileName && <span className="text-danger">{errors?.profileName?.message}</span>}
                                                    </div>

                                                    <Form.Group>
                                                        <Form.Label className=" mb-0">Station</Form.Label>
                                                        <Form.Control
                                                            className="custom-input"
                                                            type="text"
                                                            name={API.PROFILE.STATION}
                                                            {...register(API.PROFILE.STATION)}
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                    <div className="error-msg">
                                                        {errors?.station && <span className="text-danger">{errors?.contactNumber?.message}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 my-2 shift-select">
                                                <div className="frm-input">
                                                    <Form.Group>
                                                        <Form.Label className=" mb-0">Shift</Form.Label>
                                                        <Form.Control
                                                            className="custom-input"
                                                            as="select"
                                                            name={API.PROFILE.SHIFT}
                                                            {...register(API.PROFILE.SHIFT)}
                                                        >
                                                            <option value="A">A</option>
                                                            <option value="B">B</option>
                                                            <option value="C">C</option>
                                                            <option value="D">D</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <div className="error-msg">
                                                        {errors?.shift && <span className="text-danger">{errors?.shift?.message}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 my-2">
                                                <div className="frm-input">
                                                    <Form.Group>
                                                        <Form.Label className=" mb-0">Rank</Form.Label>
                                                        <Form.Control
                                                            className="custom-input"
                                                            as="select"
                                                            name={API.PROFILE.RANK}
                                                            {...register(API.PROFILE.RANK)}
                                                        >
                                                            <option value="fireFighter">Fire Fighter</option>
                                                            <option value="officer">Officer</option>
                                                            <option value="chiefOfficer">Chief Officer</option>
                                                            <option value="Combinations">Combinations</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <div className="error-msg">
                                                        {errors?.rank && <span className="text-danger">{errors?.rank?.message}</span>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 my-2">
                                                <div className="frm-input">
                                                    <Form.Group>
                                                        <Form.Label className=" mb-0">Email</Form.Label>
                                                        <Form.Control
                                                            className="custom-input"
                                                            type="email"
                                                            name={API.PROFILE.EMAIL}
                                                            {...register(API.PROFILE.EMAIL)}
                                                        />
                                                    </Form.Group>
                                                    <div className="error-msg">
                                                        {errors?.email && <span className="text-danger">{errors?.email?.message}</span>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="w-100 d-flex align-content-center justify-content-center my-3">
                                                <button type="submit" className="btn-save mx-4">
                                                    Update
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>

                                <div className="user-form">
                                    <Form role="form" onSubmit={handleSubmit(handleUpdatePassword)}>
                                        <div className="frm-input row update-password">
                                            <div className="col-8 password-input">
                                                <Form.Group>
                                                    <Form.Control
                                                        className="custom-input"
                                                        type="password"
                                                        name={API.PROFILE.PASSWORD}
                                                        {...register(API.PROFILE.PASSWORD)}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-4 update-btn">
                                                <button type="submit" className="btn-default">
                                                    Update Password
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
};

export default CreateEditProfile;
