import { createSlice } from "@reduxjs/toolkit";

const tacticalDecisionGameSlice = createSlice({
    name: "tacticalDecisionGame",
    initialState: {
        tacticalDecisionGameData: {},
    },
    reducers: {
        setTacticalDecisionGame: (state, action) => {
            state.tacticalDecisionGameData = action.payload;
        },
    },
});

export const { setTacticalDecisionGame } = tacticalDecisionGameSlice.actions;
export default tacticalDecisionGameSlice.reducer;
