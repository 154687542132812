import React from "react";
import DeleteIcon from "../../../assets/images/action/deleteIcon.svg";

const CreateEditAudioDisplay = ({ selectedAudio, onRemoveAudio }) => {
    const audioUrl = selectedAudio instanceof Blob ? URL.createObjectURL(selectedAudio) : selectedAudio;

    return (
        selectedAudio && (
            <div className="col-12 d-flex flex-row gap-4 border-1 border-black">
                <div style={{ height: "80px", width: "300px" }} className="position-relative">
                    <audio controls src={audioUrl} type="audio/*" style={{ width: "100%", height: "100%", objectFit: "contain" }}></audio>

                    <img className="custom-deleteIcon" alt="DeleteIcon" src={DeleteIcon} onClick={onRemoveAudio} />
                </div>
            </div>
        )
    );
};

export default CreateEditAudioDisplay;
