import React, { useEffect, useState } from "react";
import MainLayout from "../../../../../components/layouts/MainLayout";
import * as _ from "lodash";
import { toast } from "react-toastify";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ROUTES } from "../../../../../utils/constants/variables/routes";
import RightArrow from "../../../../../assets/images/action/right-arrow.svg";
import WhitePlus from "../../../../../assets/images/action/white-plus.svg";
import PluseIcon from "../../../../../assets/images/action/plusIcon.svg";
import { useGetActionKeysQuery, useUpdateIndexMutation } from "../../../../../api/actionKey";
import { useGetAllDataMutation, useSyncDataMutation } from "../../../../../api/importData";
import { useDispatch } from "react-redux";
import { setActionKey } from "../../../../../redux/actionKeySlice";
import CreateEditActionKey from "./CreateEditActionKey";
import DataNotFound from "../../../../../components/shared/DataNotFound/DataNotFound";
import ImportActionKey from "./ImportActionKey";
import { BiImport } from "react-icons/bi";
import { LiaSyncAltSolid } from "react-icons/lia";
import { RiEdit2Fill } from "react-icons/ri";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ActionKey = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const params = useParams();
    const { responseType } = params;
    const [responseTypeName, responseTypeId] = responseType?.split(/[=]+/);

    const originalDataId = location.state?.originalDataId;

    const [userRole, setUserRole] = useState("");
    const [actionKeyData, setActionKeyData] = useState([]);
    const [importActionKeyData, setImportActionKeyData] = useState([]);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showImportModal, setShowImportModal] = useState(false);
    const [actionKeyDetail, setActionKeyDetail] = useState("");
    const [type, setType] = useState("actionKeys");

    const { data: actionKey, refetch } = useGetActionKeysQuery(responseTypeId);
    const [updateIndex] = useUpdateIndexMutation();
    const [importACtionKey] = useGetAllDataMutation();
    const [suyncData] = useSyncDataMutation();

    useEffect(() => {
        const getUserRole = localStorage.getItem("userRole");
        if (getUserRole) {
            setUserRole(getUserRole);
        }
    }, []);

    useEffect(() => {
        refetch();
        if (actionKey?.data?.status === "success") {
            return;
        } else {
            toast.error(actionKey?.error?.data?.message, { autoClose: false });
        }
        if (actionKey) {
            setActionKeyData(actionKey?.data);
            dispatch(setActionKey(actionKey?.data));
        } else {
            setActionKeyData([]);
        }
    }, [actionKey, refetch]);
    const handleDragStart = (e, index) => {
        e.dataTransfer.setData("index", index.toString());
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };
    const handleDrop = async (e, newIndex) => {
        e.preventDefault();
        const dragIndex = parseInt(e.dataTransfer.getData("index"));
        const items = [...actionKeyData];
        const draggedItem = items[dragIndex];

        // Remove the item from its old position
        items.splice(dragIndex, 1);
        // Insert it into the new position
        items.splice(newIndex, 0, draggedItem);
        const updateIndexResponse = _.map(items, (itemList, idx) => {
            return {
                actionKeyId: _.get(itemList, "actionKeyId", ""),
                index: idx + 1,
            };
        });
        const response = await updateIndex({ updateIndexResponse: updateIndexResponse, responseTypeId: responseTypeId });
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            refetch();
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
        setActionKeyData(response?.data?.data);
    };

    const handleImportData = async () => {
        const payload = { type, responseTypeId };
        const response = await importACtionKey(payload);
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            setShowImportModal(true);
            setImportActionKeyData(response?.data?.data);
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };
    const handleSyncData = async (currentDataId) => {
        const payload = { type, currentDataId };
        const response = await suyncData(payload);
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            refetch();
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };
    const allActionKeyData = _.map(actionKeyData, (item) => {
        return {
            actionKeyId: _.get(item, "actionKeyId", ""),
            responseTypeId: _.get(item, "responseTypeId", ""),
            originalDataId: _.get(item, "originalDataId", ""),
            name: _.get(item, "name", ""),
            color: _.get(item, "color", ""),
            icon: _.get(item, "icon", ""),
            isMatch: _.get(item, "isMatch", ""),
        };
    });

    return (
        <div>
            <MainLayout className="me-0">
                <div className="row">
                    <div className="col-12 my-2">
                        <div className="bread-crumbs">
                            <div className="d-flex align-items-center me-1">
                                <span className="text-breadcrumb me-1" onClick={() => navigate(ROUTES.GAMEFUNNEL.RESPONSE)}>
                                    {responseTypeName}
                                </span>
                                <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                                <span
                                    className="text-breadcrumb me-1"
                                    onClick={() => navigate(`/gameFunnel/functionKeys/incidentPriorities/${responseTypeName}=${responseTypeId}`)}
                                >
                                    Function keys
                                </span>
                                <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                                <span className="text-breadcrumb me-1">Action keys</span>
                                <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-3 d-flex align-items-center justify-content-between hr-bottom">
                        <div className="d-flex align-items-center">
                            <button className="btn-light me-3" onClick={() => navigate(`/gameFunnel/incidentTypes/${responseTypeName}=${responseTypeId}`)}>
                                Incident types
                            </button>
                            <button
                                className="btn-custom"
                                onClick={() => navigate(`/gameFunnel/functionKeys/incidentPriorities/${responseTypeName}=${responseTypeId}`)}
                            >
                                Function keys
                            </button>
                        </div>
                        {userRole == "organization" && originalDataId !== null && (
                            <div>
                                <button className="btn-import d-flex align-items-center" onClick={handleImportData}>
                                    <span className="me-2" style={{ fontWeight: "700" }}>
                                        <BiImport />
                                    </span>
                                    <span>Import</span>
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="col-12 mb-2 d-flex">
                        <button
                            className="btn-light me-3"
                            onClick={() => navigate(`/gameFunnel/functionKeys/incidentPriorities/${responseTypeName}=${responseTypeId}`)}
                        >
                            Incident Priorities
                        </button>
                        <button className="btn-custom">Action keys</button>
                    </div>
                    <div className="col-12 dataList">
                        {!_.isEmpty(allActionKeyData) ? (
                            <div>
                                <div className="col-12 mb-4">
                                    <span className="title-text">List</span>
                                </div>
                                <div className="row">
                                    {_.map(allActionKeyData, (item, index) => {
                                        const actionKeyId = item.actionKeyId;
                                        const actionKeyName = item.name;
                                        const originalDataId = item.originalDataId;
                                        return (
                                            <div
                                                key={item.assignmentId}
                                                className="col-12 col-sm-6 my-2"
                                                draggable
                                                onDragStart={(e) => handleDragStart(e, index)}
                                                onDragOver={handleDragOver}
                                                onDrop={(e) => handleDrop(e, index)}
                                            >
                                                <div
                                                    className="item-list"
                                                    onClick={() => {
                                                        navigate(
                                                            `/gameFunnel/actionList/${responseTypeName}=${responseTypeId}/${item.name}=${item.actionKeyId}`,
                                                            {
                                                                state: originalDataId,
                                                            }
                                                        );
                                                    }}
                                                >
                                                    <div class="d-flex gap-2 align-items-center">
                                                        <div class="p-2 left-rect"></div>
                                                        <div class="p-2 flex-grow-1 d-block text-truncate">
                                                            {item.isMatch === false && userRole == "organization" && (
                                                                <LiaSyncAltSolid
                                                                    className="me-2 icon-sync"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handleSyncData(actionKeyId);
                                                                    }}
                                                                />
                                                            )}
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                overlay={
                                                                    <Tooltip id={`tooltip-bottom`} className="custom-tooltip">
                                                                        {item.name}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="text-fs">{item.name}</span>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <div class="ms-auto">
                                                            <div className="action-icon d-flex flex-nowrap align-item-center">
                                                                <RiEdit2Fill
                                                                    className="ico-edit"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setShowEditForm(true);
                                                                        setActionKeyDetail(item);
                                                                    }}
                                                                />
                                                                <MdOutlineKeyboardArrowRight className="ico-arrow mx-2" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : (
                            <DataNotFound texts={"Action Key"} />
                        )}
                    </div>
                </div>
                <div className="btn-add d-none d-md-block d-lg-block" onClick={() => setShowCreateForm(true)}>
                    <button className="btn-custom">
                        <img alt="WhitePlus" src={WhitePlus} className="me-3" />
                        Add Action Keys
                    </button>
                </div>

                <div className="customAdd d-block d-sm-block d-md-none">
                    <div className="add" onClick={() => setShowCreateForm(true)}>
                        <img src={PluseIcon} alt={"PluseIcon"} height={"50px"} width={"50px"} />
                    </div>
                </div>
            </MainLayout>

            {showCreateForm && (
                <CreateEditActionKey
                    show={showCreateForm}
                    onHide={() => setShowCreateForm(false)}
                    responseTypeId={responseTypeId}
                    refetchCreateEditActionKey={refetch}
                />
            )}
            {showEditForm && (
                <CreateEditActionKey
                    incidentPrioritiesDetail={actionKeyDetail}
                    show={showEditForm}
                    showEditForm={showEditForm}
                    onHide={() => setShowEditForm(false)}
                    refetchCreateEditActionKey={refetch}
                />
            )}
            {showImportModal && (
                <ImportActionKey
                    importActionKeyData={importActionKeyData}
                    showImportModal={showImportModal}
                    show={showImportModal}
                    responseTypeId={responseTypeId}
                    onHide={() => setShowImportModal(false)}
                    refetchImportData={refetch}
                />
            )}
        </div>
    );
};

export default ActionKey;
