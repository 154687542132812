import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import InputGroup from "react-bootstrap/InputGroup";
import { ROUTES } from "../../../../../utils/constants/variables/routes";
import * as _ from "lodash";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import MainLayout from "../../../../../components/layouts/MainLayout";
import Elips from "../../../../../assets/images/action/elips.svg";
import Switch from "../../../../../assets/images/action/switch.svg";
import WhiteArrow from "../../../../../assets/images/action/white-arrow.svg";
import RightArrow from "../../../../../assets/images/action/right-arrow.svg";
import WhitePlus from "../../../../../assets/images/action/white-plus.svg";
import DeleteIcon from "../../../../../assets/images/action/deleteIcon.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FileInput from "../../../../../components/shared/FileInput/FileInput";
import "./style.scss";
import CreateEditAudioDisplay from "../../../../../components/shared/DisplayFileInput/CreateEditAudioDisplay";
import CreateEditImageDisplay from "../../../../../components/shared/DisplayFileInput/CreateEditImageDisplay";
import { SelectStyle } from "../../../../../components/shared/Options/styles/SelectStyle";
import { API } from "../../../../../utils/constants/variables/apiFields";
import { defaultValues } from "../../../../../utils/constants/forms/defaultValues";
import { validation } from "../../../../../utils/constants/forms/validation";
import { useCreateTdgLibraryMutation, useUpdateTdgLibraryMutation } from "../../../../../api/firegroundTasks";
import FileUpload from "../../../../../components/shared/FileInput/FileInput";
import { FaChevronDown } from "react-icons/fa";

const appendFormValuesToFormData = (formData, allData) => {
    for (const key in allData) {
        if (allData.hasOwnProperty(key)) {
            formData.append(key, allData[key]);
        }
    }
};

const CreateEditFiregroundTasks = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const isInitialMount = useRef(true);

    const params = useParams();
    const { responseType, incidentType, assignmentType } = params;
    const [responseTypeName, responseTypeId] = responseType?.split(/[=]+/);
    const [incidentTypeName, incidentTypeId] = incidentType?.split(/[=]+/);
    const [assignmentName, assignmentId] = assignmentType?.split(/[=]+/);
    const [selectResponseTypeId, setSelectResponseTypeId] = useState(null);
    const [selectIncidentTypeId, setSelectIncidentTypeId] = useState(null);
    const [selectAssignmentId, setSelectAssignmentId] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedAudio, setSelectedAudio] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);

    const [createTdgLibrary] = useCreateTdgLibraryMutation();
    const [updateTdgLibrary] = useUpdateTdgLibraryMutation();

    const tdgLibraryDetail = location.state;
    const tdgLibraryId = tdgLibraryDetail?.id;
    const image = tdgLibraryDetail?.image;
    const audio = tdgLibraryDetail?.audio;

    const handleImportAudio = (e) => {
        const file = e.target.files[0];
        setSelectedAudio(file);
    };

    const handleImportImage = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
        setValue(API.FIREGROUND_TASKS.IMAGE, file);
    };

    const removeImage = () => {
        setValue(API.FIREGROUND_TASKS.IMAGE, null);
    };
    const removeAudio = () => {
        setSelectedAudio(null);
        setValue(API.FIREGROUND_TASKS.AUDIO, null);
    };

    const form = useForm({
        defaultValues: defaultValues.FIREGROUND_TASKS,
        resolver: yupResolver(validation.FIREGROUND_TASKS),
    });

    const {
        handleSubmit,
        formState: { errors },
        register,
        control,
        setValue,
        reset,
        watch,
    } = form;

    const { fields, append, remove } = useFieldArray({
        control,
        name: "bestNames", // should match with your backend field name
    });

    const handleAdd = () => {
        append({});
    };

    const handleRemove = (index) => {
        remove(index);
    };

    const selectTargateAudiance = [
        { value: "fireFighter", label: "Fire fighter" },
        { value: "officer", label: "Officer" },
        { value: "chiefOfficer", label: "Chief officer" },
        { value: "combinations", label: "Combinations" },
    ];

    useEffect(() => {
        console.log("tdgLibraryDetail===", tdgLibraryDetail);
        if (tdgLibraryId && isInitialMount.current) {
            setValue("responseTypeId", tdgLibraryDetail?.responseTypeId);
            setValue("incidentTypeId", tdgLibraryDetail?.incidentTypeId);
            setValue("assignmentId", tdgLibraryDetail?.assignmentId);
            setValue("name", tdgLibraryDetail?.name);
            setValue("goalObjective", tdgLibraryDetail?.goalObjective);
            setValue("missionBriefing", tdgLibraryDetail?.missionBriefing);
            setValue("text", tdgLibraryDetail?.text);
            setValue("publish", tdgLibraryDetail?.publish);
            setValue("image", tdgLibraryDetail?.image);
            setValue(API.FIREGROUND_TASKS.TARGET_AUDIANCE, tdgLibraryDetail?.targetAudience || []);

            if (tdgLibraryDetail?.audio) {
                setSelectedAudio(tdgLibraryDetail?.audio);
            }

            const bestPracticesTdg = tdgLibraryDetail?.bestPracticesTdg || [];
            bestPracticesTdg.forEach((item, index) => {
                bestPracticesTdg[`bestNames[${index}]`] = item.name;
                append({ name: item.name });
            });
            isInitialMount.current = false; //use this because in update form values of bestPracticesTdg are showing repeatedly
        }
    }, [setValue, tdgLibraryDetail, tdgLibraryId]);

    const onSubmit = async (data) => {
        const bestNamesFiltered = data.bestNames?.filter((item) => item.name !== null && item.name.trim() !== "");
        bestNamesFiltered?.forEach((item, index) => {
            data[`bestNames[${index}]`] = item.name;
        });

        delete data.bestNames; //getting also bestNames array in formattedData so this is for remove that extra array field
        const allData = {
            ...data,
            responseTypeId: responseTypeId || tdgLibraryDetail?.responseTypeId,
            incidentTypeId: incidentTypeId || tdgLibraryDetail?.incidentTypeId,
            assignmentId: assignmentId || tdgLibraryDetail?.assignmentId,
            audio: selectedAudio,
            targetAudience: JSON.stringify(data?.targetAudience),
        };
        const formData = new FormData();
        appendFormValuesToFormData(formData, allData);
        const response = tdgLibraryId ? await updateTdgLibrary({ tdgLibraryId, formData }) : await createTdgLibrary(formData);
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            navigate(
                `/gameFunnel/firegroundTasks/${responseTypeName}=${responseTypeId}/${incidentTypeName}=${incidentTypeId}/${assignmentName}=${assignmentId}`
            );
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    return (
        <MainLayout className="me-0">
            <div className="overflow_scroll">
                <div className="row">
                    <div className="col-12 my-2">
                        <div className="col-bread-crumbs">
                            <div className="bread-crumbs d-flex align-items-center">
                                <div className="d-flex align-items-center me-1">
                                    <span className="text-breadcrumb me-1" onClick={() => navigate(ROUTES.GAMEFUNNEL.RESPONSE)}>
                                        {responseTypeName}
                                    </span>
                                    <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                                </div>
                                <div className="d-flex align-items-center me-1">
                                    <span
                                        className="text-breadcrumb me-1"
                                        onClick={() => {
                                            navigate(`/gameFunnel/incidentTypes/${responseTypeName}=${responseTypeId}`);
                                        }}
                                    >
                                        {incidentTypeName}
                                    </span>
                                    <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                                </div>
                                <div className="d-flex align-items-center me-1">
                                    <span
                                        className="text-breadcrumb me-1"
                                        onClick={() => {
                                            navigate(`/gameFunnel/assignments/${responseTypeName}=${responseTypeId}/${incidentTypeName}=${incidentTypeId}`);
                                        }}
                                    >
                                        {assignmentName}
                                    </span>
                                    <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                                </div>
                            </div>
                            <div className="my-3 me-3">
                                <Controller
                                    control={control}
                                    name={API.FIREGROUND_TASKS.PUBLISH}
                                    render={({ field: { onChange, value } }) => {
                                        return (
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="custom-switch"
                                                label="Publish"
                                                className="customSwitch"
                                                checked={value}
                                                value={value}
                                                onChange={onChange}
                                                reverse
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-4 col-lg-4 col-12 my-2">
                                <div className="mb-1 d-flex align-items-center">
                                    <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                    <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                        Response type
                                    </Form.Label>
                                </div>
                                <div className="item-list d-flex align-items-center justify-content-between">
                                    <div className="px-3">
                                        <span>{responseTypeName}</span>
                                    </div>
                                    <div className="right-rect d-flex justify-content-center align-items-center">
                                        <FaChevronDown className="text-white" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 col-12 my-2">
                                <div className="mb-1 d-flex align-items-center">
                                    <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                    <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                        Incident type
                                    </Form.Label>
                                </div>

                                <div className="item-list d-flex align-items-center justify-content-between">
                                    <div className="px-3">
                                        <span>{incidentTypeName}</span>
                                    </div>
                                    <div className="right-rect d-flex justify-content-center align-items-center">
                                        <FaChevronDown className="text-white" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 col-12 my-2">
                                <div className="mb-1 d-flex align-items-center">
                                    <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                    <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                        Assignments
                                    </Form.Label>
                                </div>
                                <div className="item-list d-flex align-items-center justify-content-between">
                                    <div className="px-3">
                                        <span>{assignmentName}</span>
                                    </div>
                                    <div className="right-rect d-flex justify-content-center align-items-center">
                                        <FaChevronDown className="text-white" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="frm-input mb-2">
                                    <Form.Group>
                                        <div className="d-flex align-items-center">
                                            <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                            <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                                Name
                                            </Form.Label>
                                        </div>
                                        <Form.Control
                                            name={API.FIREGROUND_TASKS.NAME}
                                            {...register(API.FIREGROUND_TASKS.NAME)}
                                            type="text"
                                            className="custom-input"
                                        />
                                    </Form.Group>
                                    <div className="error-msg">{errors?.name && <span className="text-danger">{errors?.name?.message}</span>}</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="frm-input mb-2">
                                    <Form.Group>
                                        <div className="d-flex align-items-center">
                                            <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                            <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                                Goal / Objective
                                            </Form.Label>
                                        </div>
                                        <Form.Control
                                            name={API.FIREGROUND_TASKS.GOAL_OBJECTIVE}
                                            {...register(API.FIREGROUND_TASKS.GOAL_OBJECTIVE)}
                                            type="text"
                                            className="custom-input"
                                        />
                                    </Form.Group>
                                    <div className="error-msg">
                                        {errors?.goalObjective && <span className="text-danger">{errors?.goalObjective?.message}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="frm-input mb-2">
                                    <Form.Group>
                                        <div className="d-flex align-items-center">
                                            <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                            <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                                Task Briefing
                                            </Form.Label>
                                        </div>
                                        <Form.Control
                                            name={API.FIREGROUND_TASKS.MISSION_BRIEFING}
                                            {...register(API.FIREGROUND_TASKS.MISSION_BRIEFING)}
                                            type="text"
                                            className="custom-input"
                                        />
                                    </Form.Group>
                                    <div className="error-msg">
                                        {errors?.missionBriefing && <span className="text-danger">{errors?.missionBriefing?.message}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="frm-input mb-3">
                                    <Form.Group>
                                        <div className="d-flex align-items-center">
                                            <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                            <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                                Tactical decision game
                                            </Form.Label>
                                        </div>
                                        <Form.Control
                                            name={API.FIREGROUND_TASKS.TEXT}
                                            {...register(API.FIREGROUND_TASKS.TEXT)}
                                            type="text"
                                            className="custom-input"
                                            placeholder="Text*"
                                        />
                                    </Form.Group>
                                    <div className="error-msg">{errors?.text && <span className="text-danger">{errors?.text?.message}</span>}</div>
                                </div>
                            </div>
                            <div className="col-12 mb-2">
                                <div className="frm-input mb-2">
                                    <FileInput
                                        name={API.FIREGROUND_TASKS.AUDIO}
                                        {...register(API.FIREGROUND_TASKS.AUDIO)}
                                        type="file"
                                        accept="audio/*"
                                        onChange={handleImportAudio}
                                        media={selectedAudio}
                                        id="First_arriving_image_input"
                                        placeholder="Audio"
                                        labelText="Choose Audio"
                                    />
                                    <div>{selectedAudio && <CreateEditAudioDisplay selectedAudio={selectedAudio} onRemoveAudio={removeAudio} />}</div>
                                </div>
                                <div>
                                    <Controller
                                        name={API.FIREGROUND_TASKS.IMAGE}
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <>
                                                <FileInput
                                                    name={API.FIREGROUND_TASKS.IMAGE}
                                                    type="file"
                                                    accept="image/png ,image/jpg ,image/jpeg"
                                                    onChange={(e) => onChange(e.target.files[0])}
                                                    image={value}
                                                    id="First_arriving_image_input_1"
                                                    className="mt-3"
                                                    placeholder="Image"
                                                    labelText="Choose Image"
                                                />

                                                <div className="error-msg">
                                                    {errors?.image && <span className="text-danger">{errors?.image?.message}</span>}
                                                </div>
                                                <div>{value && <CreateEditImageDisplay selectedImage={value} onRemoveImage={removeImage} />}</div>
                                            </>
                                        )}
                                    />
                                </div>

                                {console.log("errors", errors)}
                            </div>

                            <div className="col-12">
                                <div className="frm-input mb-2">
                                    <Form.Group>
                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                            <div className="d-flex align-items-center">
                                                <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                                    Best practices
                                                </Form.Label>
                                            </div>
                                            <div className="custom-add-icon" onClick={handleAdd}>
                                                <img src={WhitePlus} alt={"WhitePlus"} height={"30px"} width={"30px"} />
                                            </div>
                                        </div>
                                        {_.map(fields, (item, index) => (
                                            <div key={item.id} className="d-flex align-items-center mb-3">
                                                <Form.Control
                                                    name={`bestNames[${index}].name`}
                                                    {...register(`bestNames[${index}].name`)}
                                                    type="text"
                                                    className="custom-input me-3"
                                                />
                                                <div className="" onClick={() => handleRemove(index)}>
                                                    <img src={DeleteIcon} alt={"DeleteIcon"} height={"42px"} width={"44px"} />
                                                </div>
                                            </div>
                                        ))}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="frm-input mb-2">
                                    <Form.Group>
                                        <div className="d-flex align-items-center">
                                            <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                            <Form.Label className="mb-0 custom-frmlabel">Target audience</Form.Label>
                                        </div>
                                        <div>
                                            <Controller
                                                control={control}
                                                name={API.FIREGROUND_TASKS.TARGET_AUDIANCE}
                                                render={({ field: { onChange, value } }) => {
                                                    return (
                                                        <>
                                                            <Select
                                                                name={API.FIREGROUND_TASKS.TARGET_AUDIANCE}
                                                                options={selectTargateAudiance}
                                                                styles={SelectStyle}
                                                                isMulti
                                                                components={{
                                                                    IndicatorSeparator: () => null,
                                                                }}
                                                                value={
                                                                    Array.isArray(value)
                                                                        ? selectTargateAudiance.filter((option) => value.includes(option.value))
                                                                        : []
                                                                }
                                                                onChange={(selectedOptions) => {
                                                                    const selectedValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];
                                                                    onChange(selectedValues);
                                                                }}
                                                            />
                                                            <div className="error-msg">
                                                                {errors?.targetAudience && (
                                                                    <span className="text-danger">{errors?.targetAudience?.message}</span>
                                                                )}
                                                            </div>
                                                        </>
                                                    );
                                                }}
                                            />
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className="col-12 d-flex align-items-center justify-content-center">
                                {!tdgLibraryId ? (
                                    <button type="submit" className="btn-save m-2">
                                        Save
                                    </button>
                                ) : (
                                    <button type="submit" className="btn-save m-2">
                                        Update
                                    </button>
                                )}
                                <button
                                    type="button"
                                    className="btn-cancel m-2"
                                    onClick={() =>
                                        navigate(
                                            `/gameFunnel/firegroundTasks/${responseTypeName}=${responseTypeId}/${incidentTypeName}=${incidentTypeId}/${assignmentName}=${assignmentId}`
                                        )
                                    }
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </MainLayout>
    );
};

export default CreateEditFiregroundTasks;
