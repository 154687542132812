import { createApi } from "@reduxjs/toolkit/query/react";
import { api } from "../utils/constants/url";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { showErrorToast } from "../utils/constants/api/toast";

const tagTypes = "ThinkingPlanning";
const type = "TdgLibrary";
export const thinkingPlanningApi = createApi({
    reducerPath: "thinkingPlanningApi",
    baseQuery: baseQueryWithAuthInterceptor({
        baseUrl: `${api.baseURL}/thinkingPlanning`,
        prepareHeaders,
    }),
    tagTypes: [tagTypes],
    endpoints: (builder) => ({
        getThinkingPlanning: builder.query({
            query: (objectivesId) => ({
                url: `/getThinkingPlanning/${objectivesId}`,
                method: "GET",
            }),
            providesTags: [type],
        }),
        createThinkingPlanning: builder.mutation({
            query: (body) => ({
                url: "/createThinkingPlanning",
                method: "POST",
                body,
            }),
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        updateThinkingPlanning: builder.mutation({
            query: (payload) => {
                const { thinkingPlanningId, allData } = payload;
                return {
                    url: `/updateThinkingPlanning/${thinkingPlanningId}`,
                    method: "PUT",
                    body: allData,
                };
            },
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        deleteThinkingPlanning: builder.mutation({
            query: (id) => ({
                url: `/deleteThinkingPlanning/${id}`,
                method: "DELETE",
            }),
        }),
        updateThinkingPlanningPublish: builder.mutation({
            query: (data) => {
                const { id, ...body } = data;
                return {
                    url: `/updateThinkingPlanningPublish/${id}`,
                    method: "PUT",
                    body,
                };
            },
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
    }),
});

export const {
    useGetThinkingPlanningQuery,
    useCreateThinkingPlanningMutation,
    useUpdateThinkingPlanningMutation,
    useDeleteThinkingPlanningMutation,
    useUpdateThinkingPlanningPublishMutation,
} = thinkingPlanningApi;
