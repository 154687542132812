import React, { useEffect, useState } from "react";
import MainLayout from "../../../../../components/layouts/MainLayout";
import { useGetResponseTypeQuery, useUpdateIndexMutation } from "../../../../../api/responseType";
import { useGetAllDataMutation, useSyncDataMutation } from "../../../../../api/importData";
import CreateEditResponseType from "./CreateEditResponseType";
import ImportResponseType from "./ImportResponseType";
import PluseIcon from "../../../../../assets/images/action/plusIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as _ from "lodash";
import { useDispatch } from "react-redux";
import { setResponseType } from "../../../../../redux/responseTypeSlice";
import WhitePlus from "../../../../../assets/images/action/white-plus.svg";
import DataNotFound from "../../../../../components/shared/DataNotFound/DataNotFound";
import { RiEdit2Fill } from "react-icons/ri";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { BiImport } from "react-icons/bi";
import { LiaSyncAltSolid } from "react-icons/lia";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ResponseTypes = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showImportModal, setShowImportModal] = useState(false);
    const [importResponseTypeData, setImportResponseTypeData] = useState([]);
    const [responseTypeData, setResponseTypeData] = useState([]);
    const [responseTypeDetail, setResponseTypeDetail] = useState("");
    const [userRole, setUserRole] = useState("");
    const [updateIndex] = useUpdateIndexMutation();
    const [type, setType] = useState("responseTypes");

    const { data: responseType, refetch } = useGetResponseTypeQuery();
    const [importResponseType] = useGetAllDataMutation();
    const [suyncData] = useSyncDataMutation();

    const handleImportData = async () => {
        const payload = { type };
        const response = await importResponseType(payload);
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            setShowImportModal(true);
            setImportResponseTypeData(response?.data?.data);
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    const handleSyncData = async (currentDataId) => {
        const payload = { type, currentDataId };
        const response = await suyncData(payload);
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            refetch();
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        const getUserRole = localStorage.getItem("userRole");
        if (getUserRole) {
            setUserRole(getUserRole);
        }
    }, []);
    useEffect(() => {
        refetch();
        if (responseType?.data?.status === "success") {
            return;
        } else {
            toast.error(responseType?.error?.data?.message, { autoClose: false });
        }
        if (responseType) {
            setResponseTypeData(responseType?.data);
            dispatch(setResponseType(responseType?.data));
        } else {
            setResponseTypeData([]);
        }
    }, [responseType, refetch]);

    const handleDragStart = (e, index) => {
        e.dataTransfer.setData("index", index.toString());
        e.dataTransfer.setData("responseTypeId", responseTypeData[index].responseTypeId); // Add this line
    };

    const handleDrop = async (e, newIndex) => {
        e.preventDefault();
        const dragIndex = parseInt(e.dataTransfer.getData("index"));
        const dragResponseTypeId = e.dataTransfer.getData("responseTypeId"); // Add this line

        if (isNaN(dragIndex) || dragIndex < 0 || dragIndex >= responseTypeData.length || !dragResponseTypeId) {
            toast.error("Invalid drag index", { autoClose: false });
            return;
        }

        const updatedItems = updateItemOrder(responseTypeData, dragIndex, newIndex);
        const updateIndexResponse = generateUpdateIndexResponse(updatedItems);

        try {
            const response = await updateIndex(updateIndexResponse);
            if (response?.data?.status === "success") {
                toast.success(response?.data?.message, { autoClose: 1000 });
                refetch();
            } else {
                toast.error(response?.error?.data?.message || "Failed to update index", { autoClose: false });
            }
            setResponseTypeData(updatedItems);
        } catch (error) {
            toast.error("An error occurred while updating the index", { autoClose: false });
        }
    };

    const updateItemOrder = (items, fromIndex, toIndex) => {
        const updatedItems = [...items];
        const [movedItem] = updatedItems.splice(fromIndex, 1);
        updatedItems.splice(toIndex, 0, movedItem);
        return updatedItems;
    };
    const generateUpdateIndexResponse = (items) => {
        return items
            .map((item, index) => {
                if (!item.responseTypeId) {
                    console.error("Missing responseTypeId for item:", item);
                    return null; // Handle missing responseTypeId appropriately
                }
                return {
                    responseTypeId: item.responseTypeId,
                    index: index + 1,
                };
            })
            .filter(Boolean); // Filter out any null items
    };

    const allResponseTypeData = _.map(responseTypeData, (item) => {
        return {
            responseTypeId: _.get(item, "responseTypeId", ""),
            originalDataId: _.get(item, "originalDataId", ""),
            name: _.get(item, "name", ""),
            isMatch: _.get(item, "isMatch", ""),
        };
    });

    return (
        <div>
            <>
                <MainLayout>
                    <div className="row">
                        <div className="col-12 my-4 d-flex justify-content-between align-items-center">
                            <div>
                                <button className="btn-custom">Response types</button>
                            </div>
                            {userRole == "organization" && (
                                <div>
                                    <button className="btn-import d-flex align-items-center" onClick={handleImportData}>
                                        <span className="me-2" style={{ fontWeight: "700" }}>
                                            <BiImport />
                                        </span>
                                        <span>Import</span>
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="col-12 dataList">
                            {!_.isEmpty(allResponseTypeData) ? (
                                <div className="row">
                                    <div className="col-12 mb-4">
                                        <span className="title-text">List</span>
                                    </div>
                                    {console.log("allResponseTypeData====", allResponseTypeData)}
                                    {allResponseTypeData.map((item, index) => (
                                        <div
                                            key={item.responseTypeId}
                                            className="col-12 col-sm-6 my-2"
                                            draggable
                                            onDragStart={(e) => handleDragStart(e, index)}
                                            onDragOver={handleDragOver}
                                            onDrop={(e) => handleDrop(e, index)}
                                        >
                                            <div
                                                className="item-list"
                                                onClick={() => {
                                                    navigate(`/gameFunnel/incidentTypes/${item.name}=${item.responseTypeId}`, { state: item });
                                                }}
                                            >
                                                <div class="d-flex gap-2 align-items-center">
                                                    <div class="p-2 left-rect"></div>
                                                    <div class="p-2 flex-grow-1 d-block text-truncate">
                                                        {item.isMatch === false && userRole == "organization" && (
                                                            <LiaSyncAltSolid
                                                                className="me-2 icon-sync"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleSyncData(item.responseTypeId);
                                                                }}
                                                            />
                                                        )}
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`} className="custom-tooltip">
                                                                    {item.name}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span className="text-fs">{item.name}</span>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div class="ms-auto">
                                                        <div className="action-icon d-flex flex-nowrap align-item-center">
                                                            <RiEdit2Fill
                                                                className="ico-edit"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setShowEditForm(true);
                                                                    setResponseTypeDetail(item);
                                                                }}
                                                            />
                                                            <MdOutlineKeyboardArrowRight className="ico-arrow mx-2" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <DataNotFound texts={"Response Type"} />
                            )}
                        </div>
                    </div>
                    <div className="btn-add d-none d-md-block d-lg-block" onClick={() => setShowCreateForm(true)}>
                        <button className="btn-custom">
                            <img alt="WhitePlus" src={WhitePlus} className="me-3" />
                            Add response type
                        </button>
                    </div>
                    <div className="customAdd d-block d-sm-block d-md-none">
                        <div className="add" onClick={() => setShowCreateForm(true)}>
                            <img src={PluseIcon} alt={"PluseIcon"} height={"50px"} width={"50px"} />
                        </div>
                    </div>
                </MainLayout>
                {showCreateForm && (
                    <CreateEditResponseType show={showCreateForm} onHide={() => setShowCreateForm(false)} refetchCreateEditResponseType={refetch} />
                )}
                {showEditForm && (
                    <CreateEditResponseType
                        responseTypeDetail={responseTypeDetail}
                        showEditForm={showEditForm}
                        show={showEditForm}
                        onHide={() => setShowEditForm(false)}
                        refetchCreateEditResponseType={refetch}
                    />
                )}
                {showImportModal && (
                    <ImportResponseType
                        importResponseTypeData={importResponseTypeData}
                        showImportModal={showImportModal}
                        show={showImportModal}
                        onHide={() => setShowImportModal(false)}
                        refetchImportData={refetch}
                    />
                )}
            </>
        </div>
    );
};

export default ResponseTypes;
