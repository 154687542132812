import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import MainLayout from "../../../../components/layouts/MainLayout";
import DeleteModal from "../../../../components/delete/DeleteModal";
import { useGetProfileQuery, useGetProfileByIdQuery } from "../../../../api/profile";
import { toast } from "react-toastify";
import { ROUTES } from "../../../../utils/constants/variables/routes";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import PluseIcon from "../../../../assets/images/action/plusIcon.svg";
import EditIcon from "../../../../assets/images/action/editIcon.svg";
import DeleteIcon from "../../../../assets/images/action/deleteIcon.svg";
import LeftArrow from "../../../../assets/images/action/arrow-left.svg";
import WhitePlus from "../../../../assets/images/action/white-plus.svg";
import CreateEditProfile from "./CreateEditProfile";
import DataNotFound from "../../../../components/shared/DataNotFound/DataNotFound";
import { useDispatch } from "react-redux";
import { setProfile } from "../../../../redux/profileSlice";
import { BiBorderRadius } from "react-icons/bi";
import { FaUserCircle } from "react-icons/fa";

const Profile = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { organization, station } = params;
    const organizationId = organization || localStorage.getItem("organizationId");
    const [profileData, setProfileData] = useState([]);
    const [organizationData, setOrganizationData] = useState([]);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [profileDetail, setProfileDetail] = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteProfileId, setDeleteProfileId] = useState("");
    const [userRole, setUserRole] = useState("");

    const { data: profile, refetch } = useGetProfileQuery(organizationId);
    const { data: getProfile } = useGetProfileByIdQuery();
    console.log("location==========", location);
    console.log("getProfile==========", getProfile);

    useEffect(() => {
        const storedUserRole = localStorage.getItem("userRole");
        if (storedUserRole) {
            setUserRole(storedUserRole);
        }
    }, []);

    useEffect(() => {
        if (getProfile?.status === "success") {
            setOrganizationData(getProfile?.data);
        } else {
            toast.error(getProfile?.error?.data?.message, { autoClose: false });
        }
    }, [getProfile, refetch]);

    const organizationName = organizationData?.data?.name;
    const expireDate = organizationData?.data?.expireDate;
    const subscriptionDate = organizationData?.data?.subscriptionDate;
    const status = organizationData?.data?.status;

    const getStatusDetails = (status) => {
        let statusText, statusColor;
        if (status === "green") {
            statusText = "Active";
            statusColor = "text-success";
        } else if (status === "yellow") {
            statusText = "Active";
            statusColor = "text-warning";
        } else if (status === "red") {
            statusText = "Inactive";
            statusColor = "text-danger";
        }
        return { statusText, statusColor };
    };

    const { statusText, statusColor } = getStatusDetails(status);

    console.log("organizationData==========", organizationData);
    useEffect(() => {
        refetch();
        if (profile?.data?.status === "success") {
            return;
        } else {
            toast.error(profile?.error?.data?.message, { autoClose: false });
        }
        if (profile) {
            setProfileData(profile?.data);
            dispatch(setProfile(profile?.data));
        } else {
            setProfileData([]);
        }
    }, [profile, refetch]);
    const rows =
        profileData &&
        profileData?.map((item, index) => {
            return {
                no: index + 1,
                id: _.get(item, "profileId", ""),
                organizationId: _.get(item, "organizationId", ""),
                userId: _.get(item, "userId", ""),
                rank: _.get(item, "rank", ""),
                station: _.get(item, "station", ""),
                shift: _.get(item, "shift", ""),
                email: _.get(item, "email", ""),
                profileName: _.get(item, "profileName", ""),
                profileImage: _.get(item, "profileImage", ""),
            };
        });

    const columns = [
        {
            field: "no",
            headerName: "No",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <span>No</span>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.no}</span>
                    </div>
                );
            },
            width: "lg" ? 100 : "sm" && 20,
            height: 70,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "action",
            headerName: "Action",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <span>Action</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="d-flex align-items-center justify-content-center gap-2 w-100">
                        <div>
                            <img
                                src={EditIcon}
                                alt={"EditIcon"}
                                onClick={() => {
                                    setShowEditForm(true);
                                    setProfileDetail(params.row);
                                }}
                                height={"20px"}
                                width={"20px"}
                            />
                        </div>
                        <div>
                            <img
                                src={DeleteIcon}
                                alt={"DeleteIcon"}
                                onClick={() => {
                                    setShowDeleteModal(true);
                                    setDeleteProfileId(params.row.id);
                                }}
                                height={"20px"}
                                width={"20px"}
                            />
                        </div>
                    </div>
                );
            },
            width: "lg" ? 150 : "sm" && 20,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "profileImage",
            headerName: "Image",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <span>Image</span>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="py-2 d-flex flex-column fs-item justify-content-center align-items-center gap-1 w-100">
                        {params.row.profileImage ? (
                            <img
                                src={params.row.profileImage}
                                alt="profileImage"
                                style={{ width: "50px", height: "50px", objectFit: "cover", borderRadius: "50%" }}
                            />
                        ) : (
                            <FaUserCircle style={{ width: "50px", height: "50px", color: "#c0c0c0" }} />
                        )}
                    </div>
                );
            },

            width: 100,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "profileName",
            headerName: "Name",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <span>Name</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.profileName || "-"}</span>
                    </div>
                );
            },
            width: 250,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "email",
            headerName: "Email",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <span>Email</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.email || "-"}</span>
                    </div>
                );
            },
            width: 250,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "station",
            headerName: "Station",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <span>Station</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{station || "-"}</span>
                    </div>
                );
            },
            width: 250,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "rank",
            headerName: "Rank",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <span>Rank</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.rank || "-"}</span>
                    </div>
                );
            },
            width: 200,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "shift",
            headerName: "Shift",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <span>Shift</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.shift || "-"}</span>
                    </div>
                );
            },
            width: 200,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
    ];

    return (
        <div>
            <MainLayout className="me-3">
                <div className="W-100">
                    {userRole == "organization" && (
                        <div>
                            <div className=" mx-2 my-3 px-3 organization-login-detail d-flex align-items-center">
                                <div>
                                    <p className="mb-0">
                                        Organization name - <span>{organizationName}</span>
                                    </p>
                                </div>
                            </div>
                            <div className=" mx-2 my-3 px-3 organization-login-detail d-sm-block d-md-flex align-items-center justify-content-between">
                                <div>
                                    <p className="mb-0">
                                        Subscription date - <span>{subscriptionDate}</span>
                                    </p>
                                </div>
                                <div>
                                    <p className="mb-0">
                                        Expire date - <span>{expireDate}</span>
                                    </p>
                                </div>
                                <div>
                                    <p className="mb-0">
                                        Status -{" "}
                                        <span className={statusColor} style={{ fontWeight: 600 }}>
                                            {statusText}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                    {userRole !== "organization" && (
                        <div className=" mx-2 my-3">
                            <div className="d-flex align-items-center">
                                  <div className="me-4">
                                    <img src={LeftArrow} alt={"LeftArrow"} height={"50px"} width={"50px"} onClick={() => navigate(ROUTES.ORGANIZATION)} />
                                </div>

                                <div>
                                    <button className="btn-custom">{station}</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="row">
                    <div className="col-12 dataList-table my-3">
                        {!_.isEmpty(profileData) ? (
                            <DataGrid
                                className="my-2 "
                                rows={rows}
                                columns={columns}
                                getRowHeight={() => "auto"}
                                hideFooterPagination={true}
                                rowSelection={false}
                            />
                        ) : (
                            <DataNotFound texts={"Employee"} />
                        )}
                    </div>
                    <div>
                        <div className="btn-add d-none d-md-block d-lg-block" onClick={() => setShowCreateForm(true)}>
                            <button className="btn-custom">
                                <img alt="WhitePlus" src={WhitePlus} className="me-3" />
                                Add
                            </button>
                        </div>
                        <div className="customAdd d-block d-sm-block d-md-none">
                            <div className="add" onClick={() => setShowCreateForm(true)}>
                                <img src={PluseIcon} alt={"PluseIcon"} height={"50px"} width={"50px"} />
                            </div>
                        </div>
                    </div>
                </div>
            </MainLayout>
            {showCreateForm && (
                <CreateEditProfile
                    show={showCreateForm}
                    onHide={() => setShowCreateForm(false)}
                    refetchCreateEditProfile={refetch}
                    organizationId={organizationId}
                    station={station}
                />
            )}
            {showEditForm && (
                <CreateEditProfile
                    show={showEditForm}
                    showEditForm={showEditForm}
                    onHide={() => setShowEditForm(false)}
                    profileDetail={profileDetail}
                    refetchCreateEditProfile={refetch}
                />
            )}
            {showDeleteModal && (
                <DeleteModal
                    deleteId={deleteProfileId}
                    profile={showDeleteModal}
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    refetch={refetch}
                />
            )}
        </div>
    );
};

export default Profile;
