import React, { useEffect, useState } from "react";
import MainLayout from "../../../../../components/layouts/MainLayout";
import RightArrow from "../../../../../assets/images/action/right-arrow.svg";
import WhitePlus from "../../../../../assets/images/action/white-plus.svg";
import EditWhiteIcon from "../../../../../assets/images/action/edit-white.svg";
import PluseIcon from "../../../../../assets/images/action/plusIcon.svg";
import CreateEditActionList from "./CreateEditActionList";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useGetActionListQuery, useUpdateIndexMutation } from "../../../../../api/actionList";
import { useGetAllDataMutation, useSyncDataMutation } from "../../../../../api/importData";
import { ROUTES } from "../../../../../utils/constants/variables/routes";
import * as _ from "lodash";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import DataNotFound from "../../../../../components/shared/DataNotFound/DataNotFound";
import { setActionList } from "../../../../../redux/actionListSlice";
import ImportActionList from "./ImportActionList";
import { BiImport } from "react-icons/bi";
import { LiaSyncAltSolid } from "react-icons/lia";
import { RiEdit2Fill } from "react-icons/ri";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ActionList = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { responseType, actionKey } = params;
    const [responseTypeName, responseTypeId] = responseType?.split(/[=]+/);
    const [actionKeyName, actionKeyId] = actionKey?.split(/[=]+/);
    const originalDataId = location.state?.originalDataId;

    const [userRole, setUserRole] = useState("");
    const [importActionListData, setImportActionListData] = useState([]);
    const [actionListData, setActionListData] = useState([]);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showImportModal, setShowImportModal] = useState(false);
    const [actionListDetail, setActionListDetail] = useState("");
    const [type, setType] = useState("actionList");

    const { data: actionList, refetch } = useGetActionListQuery(actionKeyId);
    const [updateIndex] = useUpdateIndexMutation();
    const [importACtionList] = useGetAllDataMutation();
    const [suyncData] = useSyncDataMutation();

    useEffect(() => {
        const getUserRole = localStorage.getItem("userRole");
        if (getUserRole) {
            setUserRole(getUserRole);
        }
    }, []);

    useEffect(() => {
        refetch();
        if (actionList?.data?.status === "success") {
            return;
        } else {
            toast.error(actionList?.error?.data?.message, { autoClose: false });
        }
        if (actionList) {
            setActionListData(actionList?.data);
            dispatch(setActionList(actionList?.data));
        } else {
            setActionListData([]);
        }
    }, [actionList, refetch]);

    const handleDragStart = (e, index) => {
        e.dataTransfer.setData("index", index.toString());
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = async (e, newIndex) => {
        e.preventDefault();
        const dragIndex = parseInt(e.dataTransfer.getData("index"));
        const items = [...actionListData];
        const draggedItem = items[dragIndex];

        // Remove the item from its old position
        items.splice(dragIndex, 1);
        // Insert it into the new position
        items.splice(newIndex, 0, draggedItem);
        const updateIndexResponse = _.map(items, (itemList, idx) => {
            return {
                actionListId: _.get(itemList, "actionListId", ""),
                index: idx + 1,
            };
        });

        const response = await updateIndex({ updateIndexResponse: updateIndexResponse, actionKeyId: actionKeyId });
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            refetch();
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
        setActionListData(response?.data?.data);
    };
    const handleImportData = async () => {
        const payload = { type, actionKeyId };
        const response = await importACtionList(payload);
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            setShowImportModal(true);
            setImportActionListData(response?.data?.data);
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    const handleSyncData = async (currentDataId) => {
        const payload = { type, currentDataId };
        const response = await suyncData(payload);
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            refetch();
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };
    const allActionListData = _.map(actionListData, (item) => {
        return {
            actionListId: _.get(item, "actionListId", ""),
            actionKeyId: _.get(item, "actionKeyId", ""),
            name: _.get(item, "name", ""),
            originalDataId: _.get(item, "originalDataId", ""),
            isMatch: _.get(item, "isMatch", ""),
        };
    });

    return (
        <div>
            <MainLayout className="me-0">
                <div className="row">
                    <div className="col-12 my-2">
                        <div className="bread-crumbs">
                            <div className="d-flex align-items-center me-1">
                                <span className="text-breadcrumb me-1" onClick={() => navigate(ROUTES.GAMEFUNNEL.RESPONSE)}>
                                    {responseTypeName}
                                </span>
                                <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                                <span
                                    className="text-breadcrumb me-1"
                                    onClick={() => navigate(`/gameFunnel/functionKeys/incidentPriorities/${responseTypeName}=${responseTypeId}`)}
                                >
                                    Function keys
                                </span>
                                <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />

                                <span
                                    className="text-breadcrumb me-1"
                                    onClick={() => navigate(`/gameFunnel/functionKeys/actionKey/${responseTypeName}=${responseTypeId}`)}
                                >
                                    {actionKeyName}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-4 d-flex align-items-center justify-content-between hr-bottom">
                        <div className="d-flex align-items-center">
                            <button className="btn-custom" onClick={() => navigate(`/gameFunnel/functionKeys/actionKey/${responseTypeName}=${responseTypeId}`)}>
                                Action Keys
                            </button>
                        </div>
                        {userRole == "organization" && originalDataId !== null && (
                            <div>
                                <button className="btn-import d-flex align-items-center" onClick={handleImportData}>
                                    <span className="me-2" style={{ fontWeight: "700" }}>
                                        <BiImport />
                                    </span>
                                    <span>Import</span>
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="col-12 mb-2 d-flex">
                        <button className="btn-custom">Action List</button>
                    </div>
                    <div className="col-12 dataList">
                        {!_.isEmpty(allActionListData) ? (
                            <div className="row">
                                <div className="col-12 mb-4">
                                    <span className="title-text">List</span>
                                </div>
                                {_.map(allActionListData, (item, index) => {
                                    const actionListId = item.actionListId;
                                    return (
                                        <div
                                            key={item.assignmentId}
                                            className="col-12 col-sm-6 my-2"
                                            draggable
                                            onDragStart={(e) => handleDragStart(e, index)}
                                            onDragOver={handleDragOver}
                                            onDrop={(e) => handleDrop(e, index)}
                                        >
                                            <div className="item-list">
                                                <div class="d-flex gap-2 align-items-center">
                                                    <div class="p-2 left-rect"></div>
                                                    <div class="p-2 flex-grow-1 d-block text-truncate">
                                                        {item.isMatch === false && userRole == "organization" && (
                                                            <LiaSyncAltSolid
                                                                className="me-2 icon-sync"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleSyncData(actionListId);
                                                                }}
                                                            />
                                                        )}
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`} className="custom-tooltip">
                                                                    {item.name}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span className="text-fs">{item.name}</span>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div class="ms-auto">
                                                        <div className="action-icon d-flex flex-nowrap align-item-center">
                                                            <RiEdit2Fill
                                                                className="ico-edit"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setShowEditForm(true);
                                                                    setActionListDetail(item);
                                                                }}
                                                            />
                                                            <MdOutlineKeyboardArrowRight className="ico-arrow mx-2" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <DataNotFound texts={"Action List"} />
                        )}
                    </div>
                </div>
                <div className="btn-add d-none d-md-block d-lg-block" onClick={() => setShowCreateForm(true)}>
                    <button className="btn-custom">
                        <img alt="WhitePlus" src={WhitePlus} className="me-3" />
                        Add Action List
                    </button>
                </div>

                <div className="customAdd d-block d-sm-block d-md-none">
                    <div className="add" onClick={() => setShowCreateForm(true)}>
                        <img src={PluseIcon} alt={"PluseIcon"} height={"50px"} width={"50px"} />
                    </div>
                </div>
            </MainLayout>

            {showCreateForm && (
                <CreateEditActionList
                    show={showCreateForm}
                    onHide={() => setShowCreateForm(false)}
                    actionKeyId={actionKeyId}
                    refetchCreateEditActionList={refetch}
                />
            )}
            {showEditForm && (
                <CreateEditActionList
                    actionListDetail={actionListDetail}
                    show={showEditForm}
                    showEditForm={showEditForm}
                    onHide={() => setShowEditForm(false)}
                    refetchCreateEditActionList={refetch}
                />
            )}
            {showImportModal && (
                <ImportActionList
                    importActionListData={importActionListData}
                    showImportModal={showImportModal}
                    show={showImportModal}
                    actionKeyId={actionKeyId}
                    onHide={() => setShowImportModal(false)}
                    refetchImportData={refetch}
                />
            )}
        </div>
    );
};

export default ActionList;
