import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import * as _ from "lodash";
import { ROUTES } from "../../../../../utils/constants/variables/routes";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { defaultValues } from "../../../../../utils/constants/forms/defaultValues";
import { API } from "../../../../../utils/constants/variables/apiFields";
import Elips from "../../../../../assets/images/action/elips.svg";
import WhitePlus from "../../../../../assets/images/action/white-plus.svg";
import { FaChevronDown } from "react-icons/fa";

const ViewTacticalDecisionGame = (props) => {
    const navigate = useNavigate();
    const thinkingPlannimngData = props.thinkingPlannimngData;
    const responseTypeName = props.responseTypeName;
    const incidentPrioritiesName = props.incidentPrioritiesName;
    const objectivesName = props.objectivesName;
    console.log("thinkingPlannimngData=====", thinkingPlannimngData);

    return (
        <>
            <div>
                <div className="tactical-decision-game">
                    <Modal {...props} centered aria-labelledby="contained-modal-title-vcenter" fullscreen className="view-modal">
                        <Modal.Header className="border-bottom-0" closeButton>
                            <Modal.Title>
                                <button className="btn-custom">Thinking Planning</button>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <div className="row">
                                    <div className="col-12  my-2">
                                        <div className="float-end form-check form-switch d-flex align-items-center">
                                            <div className="me-3">
                                                <label className="mb-0 custom-frmlabel">Publish</label>
                                            </div>
                                            <div>
                                                <input
                                                    class="form-check-input m-0"
                                                    type="checkbox"
                                                    role="switch"
                                                    id="flexSwitchCheckChecked"
                                                    checked={thinkingPlannimngData?.publish === true}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 my-2">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-4 my-2">
                                                <div className="d-flex align-items-center my-2">
                                                    <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                    <label className="mb-0 custom-frmlabel me-3">Response type</label>
                                                </div>
                                                <div className="view-custom-input d-flex justify-content-between my-2 pe-0">
                                                    <p className="mb-0">{responseTypeName}</p>
                                                    <p className="custom-grouptext index-input">
                                                        <FaChevronDown />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-4 my-2">
                                                <div className="d-flex align-items-center my-2">
                                                    <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                    <label className="mb-0 custom-frmlabel me-3">Incident Priorities</label>
                                                </div>
                                                <div className="view-custom-input d-flex justify-content-between my-2 pe-0">
                                                    <p className="mb-0">{incidentPrioritiesName}</p>
                                                    <p className="custom-grouptext index-input">
                                                        <FaChevronDown />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-4 my-2">
                                                <div className="d-flex align-items-center my-2">
                                                    <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                    <label className="mb-0 custom-frmlabel me-3">Objectives</label>
                                                </div>
                                                <div className="view-custom-input d-flex justify-content-between my-2 pe-0">
                                                    <p className="mb-0">{objectivesName}</p>
                                                    <p className="custom-grouptext index-input">
                                                        <FaChevronDown />
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 my-2">
                                        <div className="d-flex align-items-center my-2">
                                            <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                            <label className="mb-0 custom-frmlabel">Name</label>
                                        </div>
                                        <div className="view-custom-input">
                                            <p className="mb-0">{thinkingPlannimngData?.name}</p>
                                        </div>
                                    </div>

                                    <div className="col-12 my-2">
                                        <div className="d-flex align-items-center my-2">
                                            <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                            <label className="mb-0 custom-frmlabel">Select answer type</label>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="custom-radio-checked me-5">
                                                <input type="radio" checked={thinkingPlannimngData?.selectAnswerType === "list"} readOnly />
                                                <label className="form-check-label">List</label>
                                            </div>
                                            <div className="custom-radio-checked ms-5">
                                                <input type="radio" checked={thinkingPlannimngData?.selectAnswerType === "ratingScale"} readOnly />
                                                <label className="form-check-label">Rating Scale</label>
                                            </div>
                                        </div>
                                    </div>

                                    {thinkingPlannimngData?.selectAnswerType === "list" && (
                                        <>
                                            <div className="col-12 my-2">
                                                <div className="d-flex align-items-center justify-content-between my-2">
                                                    <div className="d-flex align-items-center">
                                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                        <label className="mb-0 custom-frmlabel me-3">Add answer type</label>
                                                        <div className="custom-check-input">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input mt-0"
                                                                checked={thinkingPlannimngData?.priorityType === true}
                                                                readOnly
                                                            />
                                                            <label className="form-check-label">Priority type</label>
                                                        </div>
                                                    </div>
                                                    <div className="custom-add-icon">
                                                        <img src={WhitePlus} alt={"WhitePlus"} height={"30px"} width={"30px"} />
                                                    </div>
                                                </div>

                                                {thinkingPlannimngData?.addSliderType?.map((item, index) => (
                                                    <div className="view-custom-input d-flex justify-content-between my-2 pe-0" key={index}>
                                                        <p className="mb-0">{item.name}</p>
                                                        {thinkingPlannimngData?.priorityType === true && (
                                                            <p className="custom-grouptext index-input">{item.position}</p>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    )}

                                    {thinkingPlannimngData?.selectAnswerType === "ratingScale" && (
                                        <>
                                            <div className="col-12 my-2">
                                                <div className="d-flex align-items-center my-2">
                                                    <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                    <label className="mb-0 custom-frmlabel me-3">Select slider type</label>
                                                    <div className="btn-custom-slider">
                                                        <label className="form-check-label me-5">
                                                            {thinkingPlannimngData?.selectNumberOfSliders === "twoSlider" ? "Two Slider" : "Single Slider"}
                                                        </label>
                                                        <FaChevronDown />
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center my-4">
                                                    <div className="custom-radio-checked me-5">
                                                        <input type="radio" checked={thinkingPlannimngData?.selectSliderType === "numeric"} readOnly />
                                                        <label className="form-check-label">Numeric</label>
                                                    </div>
                                                    <div className="custom-radio-checked">
                                                        <input type="radio" checked={thinkingPlannimngData?.selectSliderType === "text"} />
                                                        <label className="form-check-label">Text</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {thinkingPlannimngData?.selectSliderType === "numeric" && (
                                        <div className="col-12 my-2">
                                            {thinkingPlannimngData?.selectNumberOfSliders === "twoSlider" ? (
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <div className="view-custom-input my-2">
                                                            <p className="mb-0">{thinkingPlannimngData?.minimumValue}</p>
                                                        </div>
                                                        <div className="view-custom-input my-2">
                                                            <p className="mb-0">{thinkingPlannimngData?.maximumValue}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="view-custom-input my-2">
                                                            <p className="mb-0">{thinkingPlannimngData?.minimumValue1}</p>
                                                        </div>
                                                        <div className="view-custom-input my-2">
                                                            <p className="mb-0">{thinkingPlannimngData?.maximumValue1}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <div className="view-custom-input my-2">
                                                            <p className="mb-0">{thinkingPlannimngData?.minimumValue}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="view-custom-input my-2">
                                                            <p className="mb-0">{thinkingPlannimngData?.maximumValue}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {thinkingPlannimngData?.selectSliderType === "text" && (
                                        <div className="col-12 my-2">
                                            {thinkingPlannimngData?.selectNumberOfSliders === "twoSlider" ? (
                                                <>
                                                    <div className="d-flex align-items-center justify-content-between my-2">
                                                        <div className="d-flex align-items-center">
                                                            <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                            <label className="mb-0 custom-frmlabel">Two Slider</label>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-12 col-md-6">
                                                            <div>
                                                                <div className="custom-add-icon">
                                                                    <img src={WhitePlus} alt={"WhitePlus"} height={"30px"} width={"30px"} />
                                                                </div>
                                                            </div>
                                                            {thinkingPlannimngData?.texts?.map((item, index) => (
                                                                <div className="view-custom-input my-2">
                                                                    <p className="mb-0" key={index}>
                                                                        {item.text}
                                                                    </p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div>
                                                                <div className="custom-add-icon">
                                                                    <img src={WhitePlus} alt={"WhitePlus"} height={"30px"} width={"30px"} />
                                                                </div>
                                                            </div>
                                                            {thinkingPlannimngData?.texts1?.map((item, index) => (
                                                                <div className="view-custom-input my-2">
                                                                    <p className="mb-0" key={index}>
                                                                        {item.text}
                                                                    </p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="col-12 my-2">
                                                    <div className="d-flex align-items-center justify-content-between my-2">
                                                        <div className="d-flex align-items-center">
                                                            <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                            <label className="mb-0 custom-frmlabel">Single Slider</label>
                                                        </div>
                                                        <div className="custom-add-icon">
                                                            <img src={WhitePlus} alt={"WhitePlus"} height={"30px"} width={"30px"} />
                                                        </div>
                                                    </div>

                                                    {thinkingPlannimngData?.texts?.map((item, index) => (
                                                        <div className="view-custom-input my-2">
                                                            <p className="mb-0" key={index}>
                                                                {item.text}
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default ViewTacticalDecisionGame;
