import React, { useEffect, useState, useRef } from "react";
import * as _ from "lodash";
import Form from "react-bootstrap/Form";
import MainLayout from "../../../../../components/layouts/MainLayout";
import { ROUTES } from "../../../../../utils/constants/variables/routes";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RightArrow from "../../../../../assets/images/action/right-arrow.svg";
import { useGetTacticalDecisionGameQuery, useUpdateTacticalDecisionGamePublishMutation } from "../../../../../api/tacticalDecisionGame";
import { useGetAllDataMutation, useSyncDataMutation } from "../../../../../api/importData";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { setTacticalDecisionGame } from "../../../../../redux/tacticalDecisionGameSlice";
import DataNotFound from "../../../../../components/shared/DataNotFound/DataNotFound";
import bulletIcon from "../../../../../assets/images/action/bulletIcon.svg";
import Elips from "../../../../../assets/images/action/elips.svg";
import EditIcon from "../../../../../assets/images/action/editIcon.svg";
import PluseIcon from "../../../../../assets/images/action/plusIcon.svg";
import DeleteIcon from "../../../../../assets/images/action/deleteIcon.svg";
import WhitePlus from "../../../../../assets/images/action/white-plus.svg";
import ImportTacticalDecisionGame from "./ImportTacticalDecisionGame";
import DeleteModal from "../../../../../components/delete/DeleteModal";
import { API } from "../../../../../utils/constants/variables/apiFields";
import { defaultValues } from "../../../../../utils/constants/forms/defaultValues";
import { BiImport } from "react-icons/bi";
import { LiaSyncAltSolid } from "react-icons/lia";
import SyncFunctionKeyModal from "./SyncFunctionKeyModal";

const TacticalDecisionGame = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    console.log("location=====", location);
    const originalDataId = location.state;
    const params = useParams();
    console.log("params", params);
    const { responseType, incidentType, assignmentType, firegroundTasks } = params;
    const [responseTypeName, responseTypeId] = responseType?.split(/[=]+/);
    const [incidentTypeName, incidentTypeId] = incidentType?.split(/[=]+/);
    const [assignmentName, assignmentId] = assignmentType?.split(/[=]+/);
    const [tdgLibraryName, tdgLibraryId] = firegroundTasks?.split(/[=]+/);

    const [userRole, setUserRole] = useState("");
    const [showImportModal, setShowImportModal] = useState(false);
    const [showImportSyncModal, setImportShowSyncModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [currentDataId, setCurrentDataId] = useState(null);
    const [importTacticalDecisionGameData, setImportTacticalDecisionGameData] = useState([]);
    const [tacticalDecisionGameData, setTacticalDecisionGameData] = useState([]);
    const [deleteTacticalDecisionGameId, setDeleteTacticalDecisionGameId] = useState("");
    const [type, setType] = useState("tacticalDecisionGames");

    const { data: tacticalDecisionGame, refetch } = useGetTacticalDecisionGameQuery(tdgLibraryId);
    const [updatePublish] = useUpdateTacticalDecisionGamePublishMutation();
    const [importTacticalDecisionGame] = useGetAllDataMutation();
    const [suyncData] = useSyncDataMutation();

    const form = useForm({
        defaultValues: defaultValues.TACTICAL_DECISION_GAME,
    });

    useEffect(() => {
        const getUserRole = localStorage.getItem("userRole");
        if (getUserRole) {
            setUserRole(getUserRole);
        }
    }, []);
    const { control } = form;
    useEffect(() => {
        refetch();
        if (tacticalDecisionGame?.data?.status === "success") {
            return;
        } else {
            toast.error(tacticalDecisionGame?.error?.data?.message, { autoClose: false });
        }
        if (tacticalDecisionGame) {
            setTacticalDecisionGameData(tacticalDecisionGame?.data);
            dispatch(setTacticalDecisionGame(tacticalDecisionGame?.data));
        } else {
            setTacticalDecisionGameData([]);
        }
    }, [tacticalDecisionGame, refetch]);

    const handleSyncData = async (currentDataId) => {
        const payload = { type, currentDataId };
        const response = await suyncData(payload);
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            refetch();
        }
        if (response?.data?.status === 202) {
            setCurrentDataId(currentDataId);
            setImportShowSyncModal(true);
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };
    const handleImportData = async () => {
        const payload = { type, tdgLibraryId };
        const response = await importTacticalDecisionGame(payload);
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            setShowImportModal(true);
            setImportTacticalDecisionGameData(response?.data?.data);
            refetch();
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    const rows =
        tacticalDecisionGameData &&
        _.map(tacticalDecisionGameData, (item, index) => {
            console.log("tacticalDecisionGameData=====", tacticalDecisionGameData);
            return {
                no: index + 1,
                id: _.get(item, "tacticalDecisionGameId", ""),
                tdgLibraryId: _.get(item, "tdgLibraryId", ""),
                addAnswerTypes: _.get(item, "addAnswerTypes", ""),
                bestNames: _.get(item, "bestNames", ""),
                text: _.get(item, "text", ""),
                image: _.get(item, "image", ""),
                selectAnswerType: _.get(item, "selectAnswerType", ""),
                selectTargetAudience: _.get(item, "selectTargetAudience", ""),
                selectNumberOfSliders: _.get(item, "selectNumberOfSliders", ""),
                timeLimit: _.get(item, "timeLimit", ""),
                minimumValue: _.get(item, "minimumValue", ""),
                maximumValue: _.get(item, "maximumValue", ""),
                minimumValue1: _.get(item, "minimumValue1", ""),
                maximumValue1: _.get(item, "maximumValue1", ""),
                texts: _.get(item, "texts", ""),
                texts1: _.get(item, "texts1", ""),
                correctAnswer: _.get(item, "correctAnswer", ""),
                numeric: _.get(item, "numeric", ""),
                goalObjective: _.get(item, "goalObjective", ""),
                missionBriefing: _.get(item, "missionBriefing", ""),
                tacticalDecisionGameId: _.get(item, "tacticalDecisionGameId", ""),
                texting: _.get(item, "texting", ""),
                isVoiceToText: _.get(item, "isVoiceToText", ""),
                tacticalFunctionWithObjectivesAction: _.get(item, "tacticalFunctionWithObjectivesAction", ""),
                tacticalFunctionWithObjectivesIncident: _.get(item, "tacticalFunctionWithObjectivesIncident", ""),
                priorityType: _.get(item, "priorityType", ""),
                publish: _.get(item, "publish", ""),
                isMatch: _.get(item, "isMatch", ""),
            };
        });
    const columns = [
        {
            field: "#",
            headerName: "#",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>#</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="d-flex align-items-center justify-content-center w-100">
                        <div>
                            <div>
                                <img src={bulletIcon} alt={"bulletIcon"} height={"20px"} width={"20px"} />
                            </div>
                            {params.row.isMatch === false && userRole == "organization" && (
                                <LiaSyncAltSolid
                                    className="mt-3 icon-sync"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleSyncData(params.row.id);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                );
            },
            width: "lg" ? 80 : "sm" && 20,
            height: 300,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "no",
            headerName: "No",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>No</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.no}.</span>
                    </div>
                );
            },
            width: "lg" ? 80 : "sm" && 20,
            height: 300,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "publish",
            headerName: "Publish",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Publish</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                const tacticalDecisionGameDetail = params.row;
                return (
                    <div
                        className="fs-item"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Controller
                            control={control}
                            name={API.TACTICAL_DECISION_GAME.PUBLISH}
                            render={({ field: { value } }) => {
                                return (
                                    <Form.Check
                                        type="switch"
                                        id={`custom-switch-${tacticalDecisionGameDetail.id}`}
                                        className="customSwitch p-0 m-0"
                                        defaultChecked={tacticalDecisionGameDetail.publish}
                                        value={value}
                                        onChange={(e) => handlePublishChange(e, tacticalDecisionGameDetail.id)}
                                        reverse
                                    />
                                );
                            }}
                        />
                    </div>
                );
            },
            width: "lg" ? 150 : "sm" && 20,
            height: 300,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "action",
            headerName: "Action",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Action</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                const tacticalDecisionGameDetail = params.row;
                return (
                    <div className="d-flex align-items-center justify-content-center gap-2 w-100">
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(
                                    `/gameFunnel/tacticalDecisionGame/edit/${responseTypeName}=${responseTypeId}/${incidentTypeName}=${incidentTypeId}/${assignmentName}=${assignmentId}/${tdgLibraryName}=${tdgLibraryId}`,
                                    { state: tacticalDecisionGameDetail }
                                );
                            }}
                        >
                            <img src={EditIcon} alt={"EditIcon"} height={"20px"} width={"20px"} />
                        </div>
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowDeleteModal(true);
                                setDeleteTacticalDecisionGameId(tacticalDecisionGameDetail?.id);
                            }}
                        >
                            <img src={DeleteIcon} alt={"DeleteIcon"} height={"20px"} width={"20px"} />
                        </div>
                    </div>
                );
            },
            width: "lg" ? 150 : "sm" && 20,
            height: 300,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "selectAnswerType",
            headerName: "AnswerType",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Answer Type</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                const answerTypeWithSpacesAndCapitalized = params.row.selectAnswerType
                    ? params.row.selectAnswerType
                          .replace(/([A-Z])/g, " $1") // Add spaces before capital letters
                          .split(" ") // Split by spaces
                          .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
                          .join(" ") // Join words back together
                    : "-";

                return (
                    <div className="fs-item">
                        <span>{answerTypeWithSpacesAndCapitalized}.</span>
                    </div>
                );
            },
            width: 250,
            height: 300,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "image",
            headerName: "Image",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Image</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                const { image } = params.row;
                const firstFourImages = image.slice(0, 4);
                const handlePlay = (currentAudio) => {
                    const audios = document.querySelectorAll("audio");
                    audios.forEach((audio) => {
                        if (audio !== currentAudio) {
                            audio.pause();
                        }
                    });
                };
                return (
                    <div className="d-flex flex-column fs-item justify-content-center align-items-center gap-1 w-100">
                        <span className="text-center">{params.row.text}</span>
                        {image &&
                            image.length > 0 && ( // Check if image array exists and is not empty
                                <div className="w-100 text-center">
                                    {firstFourImages.map((item, index) => (
                                        <div key={index} className="mb-2 d-flex align-items-center justify-content-center">
                                            <div className="col-2">
                                                {item.image && (
                                                    <img src={item.image} alt={`Image${index}`} style={{ width: "50px", height: "50px", objectFit: "cover" }} />
                                                )}
                                            </div>
                                            <div className="col-10">
                                                {item.audio && ( // Check if audio exists for the current image
                                                    <audio controls className="mt-1" onPlay={(e) => handlePlay(e.target)}>
                                                        <source src={item.audio} type="audio/mpeg" />
                                                    </audio>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                    </div>
                );
            },

            width: 500,
            height: 300,
            sortable: false,
            disableColumnMenu: true,
        },
    ];

    const handlePublishChange = async (e, id) => {
        const isChecked = e.target.checked;
        const stringId = String(id);
        const response = await updatePublish({ id: stringId, publish: isChecked });
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            refetch();
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    return (
        <div>
            <MainLayout className="me-0">
                <div className="row">
                    <div className="col-12 my-2">
                        <div className="bread-crumbs d-flex align-items-center">
                            <div className="d-flex align-items-center me-1">
                                <span className="text-breadcrumb me-1" onClick={() => navigate(ROUTES.GAMEFUNNEL.RESPONSE)}>
                                    {responseTypeName}
                                </span>
                                <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                            </div>
                            <div className="d-flex align-items-center me-1">
                                <span
                                    className="text-breadcrumb me-1"
                                    onClick={() => {
                                        navigate(`/gameFunnel/incidentTypes/${responseTypeName}=${responseTypeId}`);
                                    }}
                                >
                                    {incidentTypeName}
                                </span>
                                <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                            </div>
                            <div className="d-flex align-items-center me-1">
                                <span
                                    className="text-breadcrumb me-1"
                                    onClick={() => {
                                        navigate(`/gameFunnel/assignments/${responseTypeName}=${responseTypeId}/${incidentTypeName}=${incidentTypeId}`);
                                    }}
                                >
                                    {assignmentName}
                                </span>
                                <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                            </div>
                            <div className="d-flex align-items-center me-1">
                                <span
                                    className="text-breadcrumb me-1"
                                    onClick={() =>
                                        navigate(
                                            `/gameFunnel/firegroundTasks/${responseTypeName}=${responseTypeId}/${incidentTypeName}=${incidentTypeId}/${assignmentName}=${assignmentId}`
                                        )
                                    }
                                >
                                    {tdgLibraryName}
                                </span>
                                <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 hr-bottom d-flex align-items-center justify-content-between mb-3">
                        <div>
                            <button className="btn-custom">Tactical decision game</button>
                        </div>
                        {userRole == "organization" && originalDataId !== null && (
                            <div>
                                <button className="btn-import d-flex align-items-center" onClick={handleImportData}>
                                    <span className="me-2" style={{ fontWeight: "700" }}>
                                        <BiImport />
                                    </span>
                                    <span>Import</span>
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="dataList-table">
                        {!_.isEmpty(tacticalDecisionGameData) ? (
                            <DataGrid
                                className="my-4"
                                getRowHeight={() => "auto"}
                                rows={rows}
                                columns={columns}
                                hideFooterPagination={true}
                                rowSelection={false}
                            />
                        ) : (
                            <DataNotFound texts={"Game"} />
                        )}
                    </div>
                </div>

                <div>
                    <div
                        className="btn-add d-none d-md-block d-lg-block"
                        onClick={() => {
                            navigate(
                                `/gameFunnel/tacticalDecisionGame/create/${responseTypeName}=${responseTypeId}/${incidentTypeName}=${incidentTypeId}/${assignmentName}=${assignmentId}/${tdgLibraryName}=${tdgLibraryId}`
                            );
                        }}
                    >
                        <button className="btn-custom float-end">
                            <img alt="WhitePlus" src={WhitePlus} className="me-3" />
                            Add Game
                        </button>
                    </div>

                    <div className="customAdd d-block d-sm-block d-md-none">
                        <div
                            className="add"
                            onClick={() => {
                                navigate(
                                    `/gameFunnel/tacticalDecisionGame/create/${responseTypeName}=${responseTypeId}/${incidentTypeName}=${incidentTypeId}/${assignmentName}=${assignmentId}/${tdgLibraryName}=${tdgLibraryId}`
                                );
                            }}
                        >
                            <img src={PluseIcon} alt={"PluseIcon"} height={"50px"} width={"50px"} />
                        </div>
                    </div>
                </div>
            </MainLayout>
            {showDeleteModal && (
                <DeleteModal
                    deleteId={deleteTacticalDecisionGameId}
                    tacticalDecisionGame={showDeleteModal}
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    refetch={refetch}
                />
            )}
            {showImportModal && (
                <ImportTacticalDecisionGame
                    importTacticalDecisionGameData={importTacticalDecisionGameData}
                    showImportModal={showImportModal}
                    show={showImportModal}
                    tdgLibraryId={tdgLibraryId}
                    onHide={() => setShowImportModal(false)}
                    refetchImportData={refetch}
                />
            )}
            {showImportSyncModal && (
                <SyncFunctionKeyModal
                    type={type}
                    currentDataId={currentDataId}
                    showImportSyncModal={showImportSyncModal}
                    show={showImportSyncModal}
                    onHide={() => setImportShowSyncModal(false)}
                    refetchData={refetch}
                />
            )}
        </div>
    );
};

export default TacticalDecisionGame;
