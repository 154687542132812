import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import SearchFileInput from "../../../../../components/shared/SearchFileInput/SearchFileInput";
import ColorPickerInput from "../../../../../components/shared/ColorPickerInput/ColorPickerInput";
import {
    useCreateIncidentPrioritiesMutation,
    useUpdateIncidentPrioritiesMutation,
    useDeleteIncidentPrioritiesMutation,
} from "../../../../../api/incidentPriorities";
import { useForm, Controller } from "react-hook-form";
import { defaultValues } from "../../../../../utils/constants/forms/defaultValues";
import { API } from "../../../../../utils/constants/variables/apiFields";
import { validation } from "../../../../../utils/constants/forms/validation";

const appendFormValuesToFormData = (formData, allData) => {
    for (const key in allData) {
        if (allData.hasOwnProperty(key)) {
            formData.append(key, allData[key]);
        }
    }
};

const CreateEditIncidentPriorities = (props) => {
    const responseTypeId = props?.responseTypeId || props?.incidentPrioritiesDetail?.responseTypeId;
    const isEdit = props.showEditForm;
    const incidentPrioritiesDetail = props?.incidentPrioritiesDetail;
    const incidentPrioritiesId = incidentPrioritiesDetail?.incidentPrioritiesId;
    const icon = incidentPrioritiesDetail?.icon;

    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedColor, setSelectedColor] = useState("#FFFFFF");
    const [hasChanged, setHasChanged] = useState(false);

    const [createIncidentPriorities] = useCreateIncidentPrioritiesMutation();
    const [updateIncidentPriorities] = useUpdateIncidentPrioritiesMutation();
    const [deleteIncidentPriorities] = useDeleteIncidentPrioritiesMutation();

    const fetchFileFromUrl = async (icon) => {
        const response = await fetch(icon);
        const blob = await response.blob();
        const fileName = icon.substring(icon.lastIndexOf("/") + 1);
        return new File([blob], fileName, { type: blob.type });
    };

    const handleImportImage = async (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
    };

    const handleColorChange = (color) => {
        setSelectedColor(color);
        setHasChanged(true);
    };

    const form = useForm({
        defaultValues: defaultValues.INCIDENT_PRIORITIES,
        resolver: yupResolver(validation.INCIDENT_PRIORITIES),
    });

    const {
        handleSubmit,
        formState: { errors },
        register,
        watch,
        control,
        setValue,
    } = form;

    useEffect(() => {
        if (incidentPrioritiesId) {
            setValue("name", incidentPrioritiesDetail?.name);
            setValue("color", incidentPrioritiesDetail?.color);
            setValue("icon", incidentPrioritiesDetail?.icon);
        }
    }, [setValue, icon]);

    const onSubmit = async (data) => {
        const colorToUse = hasChanged ? selectedColor : incidentPrioritiesDetail?.color || "#FFFFFF";
        const allData = {
            ...data,
            color: colorToUse,
            icon: selectedImage,
            responseTypeId: responseTypeId,
        };
        setHasChanged(false);
        const formData = new FormData();
        appendFormValuesToFormData(formData, allData);
        const response = isEdit ? await updateIncidentPriorities({ incidentPrioritiesId, formData }) : await createIncidentPriorities(formData);
        if (response?.data?.status === "success") {
            props.onHide();
            props.refetchCreateEditIncidentPriorities();
            toast.success(response?.data?.message, { autoClose: 1000 });
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    const handleDelete = async (incidentPrioritiesId) => {
        const response = await deleteIncidentPriorities(incidentPrioritiesId);
        if (response?.data?.status === "success") {
            props.onHide();
            props.refetchCreateEditIncidentPriorities();
            toast.success(response?.data?.message, { autoClose: 1000 });
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    return (
        <div>
            <div className="response-type">
                <Modal {...props} size="md" centered aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Body className="p-3">
                        <div className="my-3 text-center">
                            <button className="btn-custom">{!isEdit ? "Create" : "Edit"}</button>
                        </div>
                        <div className="response-form">
                            <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                                <div className="frm-input my-4">
                                    <Form.Group>
                                        <Form.Label className="mb-0 text-fs">Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={API.INCIDENT_PRIORITIES.NAME}
                                            {...register(API.INCIDENT_PRIORITIES.NAME)}
                                            className="custom-input"
                                        />
                                    </Form.Group>
                                    <div className="error-msg">{errors?.name && <span className="text-danger">{errors?.name?.message}</span>}</div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="frm-input mb-2">
                                                <Form.Label className="mb-0 text-fs">Icon</Form.Label>

                                                <Controller
                                                    name={API.INCIDENT_PRIORITIES.ICON}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <SearchFileInput
                                                                {...field}
                                                                type="file"
                                                                id="icon"
                                                                accept="image/png ,image/jpg ,image/jpeg"
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    handleImportImage(e);
                                                                }}
                                                                incidentPrioritiesDetail={incidentPrioritiesDetail}
                                                            />
                                                            {/* If you need to display selected image, you can use setSelectedImage here */}
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className="error-msg">{errors?.icon && <span className="text-danger">{errors?.icon?.message}</span>}</div>
                                        </div>
                                        <div className="col-6">
                                            <Form.Label className="mb-0 text-fs">Color</Form.Label>
                                            <ColorPickerInput onChange={handleColorChange} incidentPrioritiesDetail={incidentPrioritiesDetail} />
                                        </div>
                                    </div>
                                </div>
                                {!isEdit ? (
                                    <div className="w-100 text-center">
                                        <button type="submit" className="btn-save my-0">
                                            Save
                                        </button>
                                    </div>
                                ) : (
                                    <div className="d-flex align-items-center justify-content-center w-100 gap-2">
                                        <div className="text-center">
                                            <button type="submit" className="btn-save my-0">
                                                Update
                                            </button>
                                        </div>
                                        <div className="text-center">
                                            <button type="button" className="btn-delete my-0" onClick={() => handleDelete(incidentPrioritiesId)}>
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </Form>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
};

export default CreateEditIncidentPriorities;
