import * as Yup from "yup";
import { API } from "../variables/apiFields";

export const MSG = {
    REQUIRED: "This Field is required",
    MIN_PASSWORD: "Password must be 6 Characters long",
};
const required_str = Yup.string().trim(MSG.REQUIRED)?.required(MSG.REQUIRED);
const numberValidation = Yup.string().required("This field is required").min(10, "Must be add only 10 digits").max(10, "Must be add only 10 digits");
const str = Yup.string();

export const validation = {
    LOGIN: Yup.object().shape({
        [API.LOGIN.EMAIL]: required_str,
        [API.LOGIN.PASS]: required_str,
    }),
    FORGOT_PASSWORD: Yup.object().shape({
        [API.FORGOT_PASSWORD.EMAIL]: Yup.string().email("Please enter valid email").required(MSG.REQUIRED),
    }),
    VERIFY_OTP: Yup.object().shape({
        [API.VERIFY_OTP.VERIFICATION_CODE]: required_str,
    }),
    RESET_PASSWORD: Yup.object().shape({
        [API.RESET_PASSWORD.EMAIL]: required_str,
        [API.RESET_PASSWORD.PASS]: required_str,
        [API.RESET_PASSWORD.CONFIRM_PASS]: required_str,
    }),

    USER_PROFILE: Yup.object().shape({
        [API.USER_PROFILE.PROFILE_NAME]: required_str,
        [API.USER_PROFILE.EMAIL]: Yup.string()
            .required(MSG.REQUIRED)
            .matches(/\S+@\S+\.\S+/, "Email is invalid"),
    }),

    UPDATE_PASSWORD: Yup.object().shape({
        [API.UPDATE_PASSWORD.PASSWORD]: required_str,
        [API.UPDATE_PASSWORD.CONFIRM_PASSWORD]: Yup.string()
            .required("Confirm Password is required")
            .oneOf([Yup.ref(API.UPDATE_PASSWORD.PASSWORD)], "Password not match"),
    }),
    RESPONSE_TYPES: Yup.object().shape({
        [API.RESPONSE_TYPES.NAME]: required_str,
    }),
    INCIDENT_TYPES: Yup.object().shape({
        [API.INCIDENT_TYPES.NAME]: required_str,
    }),
    ASSIGNMENTS: Yup.object().shape({
        [API.ASSIGNMENTS.NAME]: required_str,
    }),
    FIREGROUND_TASKS: Yup.object().shape({
        [API.FIREGROUND_TASKS.IMAGE]: Yup.mixed().required(MSG.REQUIRED),
        [API.FIREGROUND_TASKS.NAME]: required_str,
        [API.FIREGROUND_TASKS.GOAL_OBJECTIVE]: required_str,
        [API.FIREGROUND_TASKS.MISSION_BRIEFING]: required_str,
        [API.FIREGROUND_TASKS.TEXT]: required_str,
        [API.FIREGROUND_TASKS.TARGET_AUDIANCE]: Yup.array().required(MSG.REQUIRED).min(1, "Please select any one target audiance"),
    }),

    ORGANIZATION: (isEdit) => {
        const baseSchema = {
            [API.ORGANIZATION.EMAIL]: Yup.string()
                .required(MSG.REQUIRED)
                .matches(/\S+@\S+\.\S+/, "Email must be invalid"),
            [API.ORGANIZATION.NAME]: required_str,
            [API.ORGANIZATION.CONTACT_NAME]: required_str,
            [API.ORGANIZATION.STATION]: required_str,
            [API.ORGANIZATION.CONTACT_NUMBER]: numberValidation,
            [API.ORGANIZATION.SUBSCRIPTION_DATE]: required_str,
        };

        // Include password validation only during create (not during edit)
        if (!isEdit) {
            baseSchema[API.ORGANIZATION.PASSWORD] = required_str;
            baseSchema[API.ORGANIZATION.RANK] = required_str;
            baseSchema[API.ORGANIZATION.SHIFT] = required_str;
        }

        return Yup.object().shape(baseSchema);
    },
    ORGANIZATION_PASSWORD: Yup.object().shape({
        [API.ORGANIZATION.PASSWORD]: required_str,
    }),
    PROFILE: (isEdit) => {
        const baseSchema = {
            [API.PROFILE.EMAIL]: Yup.string()
                .required(MSG.REQUIRED)
                .matches(/\S+@\S+\.\S+/, "Email must be invalid"),
            [API.PROFILE.PROFILE_NAME]: required_str,

            [API.PROFILE.RANK]: required_str,
            [API.PROFILE.SHIFT]: required_str,
            [API.PROFILE.STATION]: required_str,
        };

        // Include password validation only during create (not during edit)
        if (!isEdit) {
            baseSchema[API.PROFILE.PASSWORD] = required_str;
        }

        return Yup.object().shape(baseSchema);
    },

    TACTICAL_DECISION_GAME: (Keys) => {
        console.log("Keys >>>>>>:", Keys);
        const baseSchema = {
            [API.TACTICAL_DECISION_GAME.TEXT]: required_str,
            [API.TACTICAL_DECISION_GAME.TIME_LIMIT]: required_str,
            [API.TACTICAL_DECISION_GAME.SELECT_TARGET_AUDIANCE]: Yup.array().required(MSG.REQUIRED).min(1, "Please select any one target audiance"),
            [API.TACTICAL_DECISION_GAME.IMAGE]: Yup.array()
                .min(1, "Please select at least one image")
                .of(
                    Yup.object().shape({
                        image: Yup.mixed().required("image is required"),
                    })
                ),
            [API.TACTICAL_DECISION_GAME.SELECT_ANS_TYPE]: Yup.string().required(MSG.REQUIRED),

            [API.TACTICAL_DECISION_GAME.ADD_ANS_TYPES]: Yup.array().test("answerTypeValidation", "Add minimum 2 answer types are required", function (value) {
                const selectAnswerType = this.parent[API.TACTICAL_DECISION_GAME.SELECT_ANS_TYPE];
                if (selectAnswerType === "list") {
                    return value && value.length >= 2;
                }
                return true;
            }),
            [API.TACTICAL_DECISION_GAME.TEXTS]: Yup.array().test("textValidation", "Add minimum 2 texts are required.", function (value) {
                const sliderType = this.parent[API.TACTICAL_DECISION_GAME.TEXTING];
                const numOfSlider = this.parent[API.TACTICAL_DECISION_GAME.SELECT_NUM_OF_SLIDERS];
                if (sliderType === "true" && numOfSlider === "singleSlider") {
                    return value && value.length >= 2;
                }
                if (sliderType === "true" && numOfSlider === "twoSlider") {
                    return value && value.length >= 2;
                }
                return true;
            }),
            [API.TACTICAL_DECISION_GAME.TEXTS_1]: Yup.array().test("textValidation", "Add minimum 2 texts are required.", function (value) {
                const sliderType = this.parent[API.TACTICAL_DECISION_GAME.TEXTING];
                const numOfSlider = this.parent[API.TACTICAL_DECISION_GAME.SELECT_NUM_OF_SLIDERS];

                if (sliderType === "true" && numOfSlider === "twoSlider") {
                    return value && value.length >= 2;
                }
                return true;
            }),
        };
        console.log("Keys <><><><>", Keys);
        if (Keys === "functionKeys" || null || "") {
            baseSchema[API.TACTICAL_DECISION_GAME.INCIDENT_PRIORITIES_ID0] = required_str;
            baseSchema[API.TACTICAL_DECISION_GAME.ACTION_KEY_ID0] = required_str;
        }
        if (Keys === "ratingScale" || null || "") {
            baseSchema[API.TACTICAL_DECISION_GAME.SELECT_NUM_OF_SLIDERS] = required_str;
            baseSchema[API.TACTICAL_DECISION_GAME.TEXTING] = required_str;
            baseSchema[API.TACTICAL_DECISION_GAME.NUMERIC] = required_str;
        }

        return Yup.object().shape(baseSchema);
    },

    INCIDENT_PRIORITIES: Yup.object().shape({
        [API.INCIDENT_PRIORITIES.NAME]: required_str,
        [API.INCIDENT_PRIORITIES.ICON]: Yup.mixed().required(MSG.REQUIRED),
    }),
    OBJECTIVES: Yup.object().shape({
        [API.OBJECTIVES.NAME]: required_str,
    }),
    THINKING_PLANNING: (ansType) => {
        const baseSchema = {
            [API.THINKING_PLANNING.NAME]: required_str,
            [API.THINKING_PLANNING.ANSWER_TYPE]: Yup.string().required(MSG.REQUIRED),
            [API.THINKING_PLANNING.SLIDER_TYPE]: Yup.array().test("sliderTypeValidation", "Add minimum 2 slider types are required.", function (value) {
                const selectAnswerType = this.parent[API.THINKING_PLANNING.ANSWER_TYPE];
                if (selectAnswerType === "list") {
                    return value && value.length >= 2;
                }
                return true;
            }),
            [API.THINKING_PLANNING.TEXTS]: Yup.array().test("textValidation", "Add minimum 2 texts are required.", function (value) {
                const sliderType = this.parent[API.THINKING_PLANNING.SELECT_SLIDER_TYPE];
                const numOfSlider = this.parent[API.THINKING_PLANNING.SELECT_NUM_OF_SLIDERS];
                if (sliderType === "text" && numOfSlider === "singleSlider") {
                    return value && value.length >= 2;
                }
                if (sliderType === "text" && numOfSlider === "twoSlider") {
                    return value && value.length >= 2;
                }
                return true;
            }),
            [API.THINKING_PLANNING.TEXTS_1]: Yup.array().test("textValidation", "Add minimum 2 texts are required.", function (value) {
                const sliderType = this.parent[API.THINKING_PLANNING.SELECT_SLIDER_TYPE];
                const numOfSlider = this.parent[API.THINKING_PLANNING.SELECT_NUM_OF_SLIDERS];

                if (sliderType === "text" && numOfSlider === "twoSlider") {
                    return value && value.length >= 2;
                }
                return true;
            }),
        };
        if (ansType === "ratingScale" || null || "") {
            baseSchema[API.THINKING_PLANNING.SELECT_SLIDER_TYPE] = required_str;
            baseSchema[API.THINKING_PLANNING.SELECT_NUM_OF_SLIDERS] = required_str;
        }

        return Yup.object().shape(baseSchema);
    },

    ACTION_KEY: Yup.object().shape({
        [API.ACTION_KEY.NAME]: required_str,
        [API.ACTION_KEY.ICON]: Yup.mixed().required(MSG.REQUIRED),
    }),
    ACTION_LIST: Yup.object().shape({
        [API.ACTION_LIST.NAME]: required_str,
    }),
    APPARATUS: Yup.object().shape({
        [API.APPARATUS.STATION]: required_str,
        [API.APPARATUS.TOTALAPPARATUS]: required_str,
        [API.APPARATUS.APPARATUSNAME]: required_str,
    }),

    SCENARIO: Yup.object().shape({
        [API.SCENARIO.RESPONSE_TYPE]: required_str,
        [API.SCENARIO.INCIDENT_TYPE]: required_str,
        [API.SCENARIO.ASSIGNMENT]: required_str,
        [API.SCENARIO.SELECT_APPARATUS]: required_str,
        [API.SCENARIO.SCENARIO_PROJECT_LEAD]: required_str,
        [API.SCENARIO.INCIDENT_ADDRESS]: required_str,
        [API.SCENARIO.LOCATION_AND_EXTENT_OF_THE_FIRE]: required_str,
        [API.SCENARIO.WEATHER]: required_str,
    }),
    SCENARIO_ASSIGNMENTS: Yup.object().shape({
        [API.SCENARIO_ASSIGNMENTS.RESPONSE_TYPE]: required_str,
        [API.SCENARIO_ASSIGNMENTS.INCIDENT_TYPE]: required_str,
        [API.SCENARIO_ASSIGNMENTS.ASSIGNMENT]: required_str,
        [API.SCENARIO_ASSIGNMENTS.APPARATUS_ID]: required_str,
        [API.SCENARIO_ASSIGNMENTS.GAME_FROM_TDG]: required_str,
        [API.SCENARIO_ASSIGNMENTS.TARGET_AUDIANCE]: required_str,
        [API.SCENARIO_ASSIGNMENTS.TARGET_AUDIANCE]: Yup.array().required(MSG.REQUIRED).min(1, "Please select any one target audiance"),
    }),
};
