import { createSlice } from "@reduxjs/toolkit";

const objectivesSlice = createSlice({
    name: "objectives",
    initialState: {
        objectivesData: {},
    },
    reducers: {
        setObjectives: (state, action) => {
            state.objectivesData = action.payload;
        },
    },
});

export const { setObjectives } = objectivesSlice.actions;
export default objectivesSlice.reducer;
