import { createApi } from "@reduxjs/toolkit/query/react";
import { api } from "../utils/constants/url";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { showErrorToast } from "../utils/constants/api/toast";

const tagTypes = "ActionList";
const type = "ActionList";
export const actionListApi = createApi({
    reducerPath: "actionListApi",
    baseQuery: baseQueryWithAuthInterceptor({
        baseUrl: `${api.baseURL}/actionList`,
        prepareHeaders,
    }),
    tagTypes: [tagTypes],
    endpoints: (builder) => ({
        getActionList: builder.query({
            query: (actionKeyId) => ({
                url: `/getActionList/${actionKeyId}`,
                method: "GET",
            }),
            providesTags: [type],
        }),
        createActionList: builder.mutation({
            query: (body) => ({
                url: "/createActionList",
                method: "POST",
                body,
            }),
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        updateActionList: builder.mutation({
            query: (payload) => {
                const { data, actionListId } = payload;
                return {
                    url: `/updateActionList/${actionListId}`,
                    method: "PUT",
                    body: data,
                };
            },
        }),
        deleteActionList: builder.mutation({
            query: (actionListId) => ({
                url: `/deleteActionList/${actionListId}`,
                method: "DELETE",
            }),
        }),
        updateIndex: builder.mutation({
            query: (payload) => {
                const { updateIndexResponse, actionKeyId } = payload;
                return {
                    url: `/updateIndex/${actionKeyId}`,
                    method: "POST",
                    body: updateIndexResponse,
                };
            },
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
    }),
});

export const { useGetActionListQuery, useCreateActionListMutation, useUpdateActionListMutation, useDeleteActionListMutation, useUpdateIndexMutation } =
    actionListApi;
