import React from "react";
import { forwardRef, useState, useEffect } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";

import { useGetApparatusQuery } from "../../../api/apparatus";
import { isValidArray } from "../../../utils/constants/validation/array";

const selectApparatus = forwardRef(({ isError, disabled, defaultApparatus, handleChange, clearFilter, style, ...props }, ref) => {
    const [selectApparatusType, setSelectApparatusType] = useState([]);

    const { data: selectApparatus } = useGetApparatusQuery(null, { refetchOnFocus: true });

    useEffect(() => {
        if (selectApparatus?.status !== "success") return;
        const selectOptions = selectApparatus?.data?.map(({ apparatusName, apparatusId }) => {
            return { label: apparatusName, value: apparatusId };
        });
        setSelectApparatusType(selectOptions);
    }, [selectApparatus]);

    return (
        <Select
            options={selectApparatusType}
            value={defaultApparatus && isValidArray(selectApparatusType) && selectApparatusType?.find((option) => option?.value === defaultApparatus)}
            onChange={(selectedOption) => handleChange(selectedOption?.value)}
            ref={ref}
            {...props}
            styles={style}
        />
    );
});

export default selectApparatus;
