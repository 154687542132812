import { createSlice } from "@reduxjs/toolkit";

const incidentPrioritiesSlice = createSlice({
    name: "incidentPriorities",
    initialState: {
        incidentPrioritiesData: {},
    },
    reducers: {
        setIncidentPriorities: (state, action) => {
            state.incidentPrioritiesData = action.payload;
        },
    },
});

export const { setIncidentPriorities } = incidentPrioritiesSlice.actions;
export default incidentPrioritiesSlice.reducer;
