import { createSlice } from "@reduxjs/toolkit";

const thinkingPlanningSlice = createSlice({
    name: "thinkingPlanning",
    initialState: {
        thinkingPlanningData: {},
    },
    reducers: {
        setThinkingPlanningData: (state, action) => {
            state.thinkingPlanningData = action.payload;
        },
    },
});

export const { setThinkingPlanningData } = thinkingPlanningSlice.actions;
export default thinkingPlanningSlice.reducer;
