import React, { useState, useEffect } from "react";
import * as _ from "lodash";
import MainLayout from "../../../../components/layouts/MainLayout";
import WhitePlus from "../../../../assets/images/action/white-plus.svg";
import PluseIcon from "../../../../assets/images/action/plusIcon.svg";
import EditIcon from "../../../../assets/images/action/editIcon.svg";
import DeleteIcon from "../../../../assets/images/action/deleteIcon.svg";
import CreateEditApparatus from "./CreateEditApparatus";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetApparatusQuery } from "../../../../api/apparatus";
import { setApparatus } from "../../../../redux/apparatusSlice";
import DeleteModal from "../../../../components/delete/DeleteModal";
import { toast } from "react-toastify";
import DataNotFound from "../../../../components/shared/DataNotFound/DataNotFound";

const Apparatus = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [apparatusData, setApparatusData] = useState([]);
    const [deleteApparatusId, setDeleteApparatusId] = useState("");
    const [apparatusDetail, setApparatusDetail] = useState("");

    const { data: apparatus, refetch } = useGetApparatusQuery();

    useEffect(() => {
        refetch();
        if (apparatus?.data?.status === "success") {
            return;
        } else {
            toast.error(apparatus?.error?.data?.message, { autoClose: false });
        }
        if (apparatus) {
            setApparatusData(apparatus?.data);
            dispatch(setApparatus(apparatus?.data));
        } else {
            setApparatusData([]);
        }
    }, [apparatus, refetch]);

    const rows =
        apparatusData &&
        _.map(apparatusData, (item, index) => {
            return {
                no: index + 1,
                id: _.get(item, "apparatusId", ""),
                organizationId: _.get(item, "organizationId", ""),
                station: _.get(item, "station", ""),
                totalApparatus: _.get(item, "totalApparatus", ""),
                apparatusName: _.get(item, "apparatusName", ""),
            };
        });

    const columns = [
        {
            field: "no",
            headerName: "No",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <span>No</span>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.no}</span>
                    </div>
                );
            },
            width: "lg" ? 150 : "sm" && 20,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "action",
            headerName: "Action",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <span>Action</span>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="d-flex align-items-center justify-content-center gap-2 w-100">
                        <div>
                            <img
                                src={EditIcon}
                                alt={"EditIcon"}
                                onClick={() => {
                                    setShowEditForm(true);
                                    setApparatusDetail(params.row);
                                }}
                                height={"20px"}
                                width={"20px"}
                            />
                        </div>
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowDeleteModal(true);
                                setDeleteApparatusId(params.row.id);
                            }}
                        >
                            <img src={DeleteIcon} alt={"DeleteIcon"} height={"20px"} width={"20px"} />
                        </div>
                    </div>
                );
            },
            width: "lg" ? 100 : "sm" && 20,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "station",
            headerName: "Station",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <span>Station</span>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.station || "-"}</span>
                    </div>
                );
            },
            width: "lg" ? 200 : "sm" && 20,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "totalApparatus",
            headerName: "totalApparatus",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <span>Total Apparatus</span>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.totalApparatus || "-"}</span>
                    </div>
                );
            },
            width: "lg" ? 200 : "sm" && 20,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "apparatusName",
            headerName: "apparatusName",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <span>Apparatus</span>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.apparatusName || "-"}</span>
                    </div>
                );
            },
            width: "lg" ? 250 : "sm" && 20,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
    ];

    return (
        <div>
            <MainLayout>
                {!_.isEmpty(apparatusData) ? (
                    <DataGrid className="my-4" rows={rows} columns={columns} autoHeight hideFooterPagination={true} rowHeight={40} rowSelection={false} />
                ) : (
                    <DataNotFound texts={"Apparatus"} />
                )}

                <div className="btn-add d-none d-md-block d-lg-block">
                    <button className="btn-custom" onClick={() => setShowCreateForm(true)}>
                        <img src={WhitePlus} alt="WhitePlus" className="me-3" />
                        Add Apparatus
                    </button>
                </div>

                <div className="customAdd d-block d-sm-block d-md-none">
                    <div className="add" onClick={() => setShowCreateForm(true)}>
                        <img src={PluseIcon} alt={"PluseIcon"} height={"50px"} width={"50px"} />
                    </div>
                </div>
            </MainLayout>
            {showCreateForm && <CreateEditApparatus show={showCreateForm} onHide={() => setShowCreateForm(false)} refetchCreateEditApparatus={refetch} />}
            {showEditForm && (
                <CreateEditApparatus
                    show={showEditForm}
                    showEditForm={showEditForm}
                    onHide={() => setShowEditForm(false)}
                    apparatusDetail={apparatusDetail}
                    refetchCreateEditApparatus={refetch}
                />
            )}

            {showDeleteModal && (
                <DeleteModal
                    deleteId={deleteApparatusId}
                    apparatus={showDeleteModal}
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    refetch={refetch}
                />
            )}
        </div>
    );
};

export default Apparatus;
