import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import { API } from "../../../../../utils/constants/variables/apiFields";
import { useForm, Controller } from "react-hook-form";
import { defaultValues } from "../../../../../utils/constants/forms/defaultValues";
import { yupResolver } from "@hookform/resolvers/yup";
import { validation } from "../../../../../utils/constants/forms/validation";
import { useCreateActionKeysMutation, useUpdateActionKeysMutation, useDeleteActionKeysMutation } from "../../../../../api/actionKey";
import SearchFileInput from "../../../../../components/shared/SearchFileInput/SearchFileInput";
import ColorPickerInput from "../../../../../components/shared/ColorPickerInput/ColorPickerInput";

const appendFormValuesToFormData = (formData, allData) => {
    for (const key in allData) {
        if (allData.hasOwnProperty(key)) {
            formData.append(key, allData[key]);
        }
    }
};

const CreateEditActionKey = (props) => {
    const isEdit = props.showEditForm;
    const responseTypeId = props.responseTypeId || props?.incidentPrioritiesDetail?.responseTypeId;
    const actionKeyDetail = props?.incidentPrioritiesDetail;
    const actionKeyId = actionKeyDetail?.actionKeyId;
    const icon = actionKeyDetail?.icon;

    const [selectedImage, setSelectedImage] = useState([]);
    const [selectedColor, setSelectedColor] = useState("#FFFFFF");
    const [hasChanged, setHasChanged] = useState(false);

    const [createActionkey] = useCreateActionKeysMutation();
    const [updateActionKey] = useUpdateActionKeysMutation();
    const [deleteActionKey] = useDeleteActionKeysMutation();

    const fetchFileFromUrl = async (icon) => {
        const response = await fetch(icon);
        const blob = await response.blob();
        const fileName = icon.substring(icon.lastIndexOf("/") + 1);
        return new File([blob], fileName, { type: blob.type });
    };

    const handleColorChange = (color) => {
        setSelectedColor(color);
        setHasChanged(true);
    };

    const handleImportImage = async (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
    };

    const form = useForm({
        defaultValues: defaultValues.ACTION_KEY,
        resolver: yupResolver(validation.ACTION_KEY),
    });

    const {
        handleSubmit,
        formState: { errors },
        register,
        watch,
        control,
        setValue,
    } = form;

    useEffect(() => {
        if (actionKeyId) {
            setValue("name", actionKeyDetail?.name);
            setValue("color", actionKeyDetail?.color);
            setValue("icon", actionKeyDetail?.icon);
        }
    }, [setValue, icon]);

    const onSubmit = async (data) => {
        const allData = {
            ...data,
            color: hasChanged ? selectedColor : actionKeyDetail?.color || "#FFFFFF",
            icon: selectedImage?.length >= 0 ? actionKeyDetail?.icon : selectedImage,
            responseTypeId: responseTypeId,
        };
        setHasChanged(false);
        const formData = new FormData();
        appendFormValuesToFormData(formData, allData);
        const response = isEdit ? await updateActionKey({ actionKeyId, formData }) : await createActionkey(formData);
        if (response?.data?.status === "success") {
            props.onHide();
            props.refetchCreateEditActionKey();
            toast.success(response?.data?.message, { autoClose: 1000 });
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    const handleDelete = async (actionKeyId) => {
        const response = await deleteActionKey(actionKeyId);
        if (response?.data?.status === "success") {
            props.onHide();
            props.refetchCreateEditActionKey();
            toast.success(response?.data?.message, { autoClose: 1000 });
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };
    return (
        <div>
            <div className="response-type">
                <Modal {...props} size="md" centered aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Body className="p-3">
                        <div className="my-3 text-center">
                            <button className="btn-custom">{!isEdit ? "Create" : "Edit"}</button>
                        </div>
                        <div className="response-form">
                            <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                                <div className="frm-input my-4">
                                    <div className="mb-3">
                                        <Form.Group>
                                            <Form.Label className="mb-0 text-fs">Name</Form.Label>
                                            <Form.Control type="text" name={API.ACTION_KEY.NAME} {...register(API.ACTION_KEY.NAME)} className="custom-input" />
                                        </Form.Group>
                                        <div className="error-msg">{errors?.name && <span className="text-danger">{errors?.name?.message}</span>}</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className="frm-input mb-2">
                                                <Form.Label className="mb-0 text-fs">Icon</Form.Label>
                                                <Controller
                                                    name={API.ACTION_KEY.ICON}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <SearchFileInput
                                                                {...field}
                                                                type="file"
                                                                id="icon"
                                                                accept="image/png ,image/jpg ,image/jpeg"
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    handleImportImage(e);
                                                                }}
                                                                incidentPrioritiesDetail={actionKeyDetail}
                                                            />
                                                            {/* If you need to display selected image, you can use setSelectedImage here */}
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className="error-msg">{errors?.icon && <span className="text-danger">{errors?.icon?.message}</span>}</div>
                                        </div>
                                        <div className="col-6">
                                            <Form.Label className="mb-0 text-fs">Color</Form.Label>
                                            <ColorPickerInput onChange={handleColorChange} incidentPrioritiesDetail={actionKeyDetail} />
                                        </div>
                                    </div>
                                </div>
                                {!isEdit ? (
                                    <div className="w-100 text-center">
                                        <button type="submit" className="btn-save my-0">
                                            Save
                                        </button>
                                    </div>
                                ) : (
                                    <div className="d-flex align-items-center justify-content-center w-100 gap-2">
                                        <div className="text-center">
                                            <button type="submit" className="btn-save my-0">
                                                Update
                                            </button>
                                        </div>
                                        <div className="text-center">
                                            <button type="button" className="btn-delete my-0" onClick={() => handleDelete(actionKeyId)}>
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </Form>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
};

export default CreateEditActionKey;
