import { forwardRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

// import { ModifiedSelectStyle } from "./styles/CreatableSelect";
import { useGetIncidentTypeQuery } from "../../../api/incidentType";
import { isValidArray } from "../../../utils/constants/validation/array";

const SelectIncident = forwardRef(({ isError, defaultIncident, disabled, handleChange, isLoading, clearFilter, style, responseId, ...props }, ref) => {
    const [incidentList, setIncidentList] = useState([]);
    const { data: incidentResponse } = useGetIncidentTypeQuery(responseId, {
        refetchOnFocus: true,
        skip: !responseId,
    });

    useEffect(() => {
        if (incidentResponse?.status !== "success") return;
        const incidentOptions = incidentResponse?.data?.map(({ name, incidentTypeId }) => {
            return { label: name, value: incidentTypeId };
        });
        setIncidentList(incidentOptions);
    }, [incidentResponse]);
    return (
        <Select
            options={!defaultIncident && incidentList}
            value={defaultIncident && isValidArray(incidentList) && incidentList?.find((option) => option?.value === defaultIncident)}
            onChange={(selectedOption) => handleChange(selectedOption?.value)}
            isLoading={isLoading}
            ref={ref}
            {...props}
            styles={style}
        />
    );
});

export default SelectIncident;
