import { createSlice } from "@reduxjs/toolkit";

const userProfileSlice = createSlice({
    name: "userProfile",
    initialState: {
        userProfileData: {},
    },
    reducers: {
        setUserProfile: (state, action) => {
            state.userProfileData = action.payload;
        },
    },
});

export const { setUserProfile } = userProfileSlice.actions;
export default userProfileSlice.reducer;
