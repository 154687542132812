export const ModifiedSelectStyle = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#B54708" : "white",
        color: state.isSelected ? "white" : "#000",
        "&:hover": {
            background: "#B54708",
            color: "#FFFFFF",
            transition: "all 0.8s",
        },
    }),
    control: (styles) => ({
        ...styles,
        border: "none",
        backgroundColor: "#B54708",
        color: "#fff",
        borderRadius: "10px",
        height: "40px",
        width: "250px",
        boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
        "&:hover": {
            borderColor: "#a0a293",
        },
        fontWeight: "510",
        fontSize: "20px",
    }),
    menu: (base) => ({
        ...base,
        borderRadius: 0,
        borderColor: "2px solid #dadada",
        color: "#FFFFFF",
    }),
    menuList: (base) => ({
        ...base,
        cursor: "pointer",
        color: "#fff",
    }),
    placeholder: (base) => ({
        ...base,
        fontSize: "16px",
        color: "#fff",
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";
        const color = "#fff";
        const fontSize = "16px";
        return { ...provided, transition, color, opacity, fontSize };
    },
};
