import { createSlice } from "@reduxjs/toolkit";

const actionListSlice = createSlice({
    name: "actionList",
    initialState: {
        actionListData: {},
    },
    reducers: {
        setActionList: (state, action) => {
            state.actionListData = action.payload;
        },
    },
});

export const { setActionList } = actionListSlice.actions;
export default actionListSlice.reducer;
