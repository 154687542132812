import { createSlice } from "@reduxjs/toolkit";

const incidentTypeSlice = createSlice({
    name: "incidentType",
    initialState: {
        incidentTypeData: {},
    },
    reducers: {
        setIncidentType: (state, action) => {
            state.incidentTypeData = action.payload;
        },
    },
});

export const { setIncidentType } = incidentTypeSlice.actions;
export default incidentTypeSlice.reducer;
