import { createApi } from "@reduxjs/toolkit/query/react";
import { api } from "../utils/constants/url";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { showErrorToast } from "../utils/constants/api/toast";

const tagTypes = "Scenario";
const type = "Scenario";
export const scenarioApi = createApi({
    reducerPath: "scenarioApi",
    baseQuery: baseQueryWithAuthInterceptor({
        baseUrl: `${api.baseURL}/scenario`,
        prepareHeaders,
    }),
    type: [type],
    endpoints: (builder) => ({
        getScenario: builder.query({
            query: () => ({
                url: `/getScenario`,
                method: "GET",
            }),
            providesTags: [type],
        }),
        createScenario: builder.mutation({
            query: (body) => ({
                url: "/createScenario",
                method: "POST",
                body,
            }),
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        updateScenario: builder.mutation({
            query: (body) => {
                return {
                    url: `/updateScenario/${body?.scenarioId}`,
                    method: "PUT",
                    body,
                };
            },
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        deleteScenario: builder.mutation({
            query: (id) => ({
                url: `/deleteScenario/${id}`,
                method: "DELETE",
            }),
        }),
        updateScenarioPublish: builder.mutation({
            query: (data) => {
                const { id, ...body } = data;
                return {
                    url: `/updateScenarioPublish/${id}`,
                    method: "PUT",
                    body,
                };
            },
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
    }),
});

export const { useGetScenarioQuery, useCreateScenarioMutation, useUpdateScenarioMutation, useDeleteScenarioMutation, useUpdateScenarioPublishMutation } =
    scenarioApi;
