import { createApi } from "@reduxjs/toolkit/query/react";
import { api } from "../utils/constants/url";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { showErrorToast } from "../utils/constants/api/toast";

const tagTypes = "Objectives";
const type = "Objectives";
export const objectivesApi = createApi({
    reducerPath: "objectivesApi",
    baseQuery: baseQueryWithAuthInterceptor({
        baseUrl: `${api.baseURL}/objectives`,
        prepareHeaders,
    }),
    tagTypes: [tagTypes],
    endpoints: (builder) => ({
        getObjectives: builder.query({
            query: (incidentPrioritiesId) => ({
                url: `/getObjectives/${incidentPrioritiesId}`,
                method: "GET",
            }),
            providesTags: [type],
        }),
        createObjectives: builder.mutation({
            query: (body) => ({
                url: "/createObjectives",
                method: "POST",
                body,
            }),
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        updateObjectives: builder.mutation({
            query: (payload) => {
                const { allData, objectivesId } = payload;
                return {
                    url: `/updateObjectives/${objectivesId}`,
                    method: "PUT",
                    body: allData,
                };
            },
        }),
        deleteObjectives: builder.mutation({
            query: (objectivesId) => ({
                url: `/deleteObjectives/${objectivesId}`,
                method: "DELETE",
            }),
        }),
        updateIndex: builder.mutation({
            query: (payload) => {
                const { updateIndexResponse, incidentPrioritiesId } = payload;
                return {
                    url: `/updateIndex/${incidentPrioritiesId}`,
                    method: "POST",
                    body: updateIndexResponse,
                };
            },
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
    }),
});

export const { useGetObjectivesQuery, useCreateObjectivesMutation, useUpdateObjectivesMutation, useDeleteObjectivesMutation, useUpdateIndexMutation } =
    objectivesApi;
