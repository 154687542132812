import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import "./style.scss";

const ColorPickerInput = (props) => {
    const incidentPrioritiesId = props?.incidentPrioritiesDetail?.incidentPrioritiesId || props?.incidentPrioritiesDetail?.actionKeyId;
    const onChange = props?.onChange;

    const [color, setColor] = useState("#FFFFFF");
    const [showPicker, setShowPicker] = useState(false);

    const handleColorChange = (newColor) => {
        setColor(newColor.hex);
        onChange(newColor.hex); // Call the parent component's onChange with the selected color
    };

    const togglePicker = () => {
        setShowPicker(!showPicker);
    };

    useEffect(() => {
        if (!incidentPrioritiesId) return;
        setColor(props?.incidentPrioritiesDetail?.color);
    }, [incidentPrioritiesId]);

    return (
        <div className="">
            <div className="color-picker-trigger mt-0" onClick={togglePicker}>
                <span className="text-fs text-muted">{color}</span>
                <div className="color-preview" style={{ backgroundColor: color }} />
            </div>
            {showPicker && <SketchPicker color={color} onChange={handleColorChange} />}
        </div>
    );
};

export default ColorPickerInput;
