import React from "react";
import { forwardRef, useState, useEffect } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";

import { useGetActionKeysQuery } from "../../../api/actionKey";
import { isValidArray } from "../../../utils/constants/validation/array";

const selectActionKeys = forwardRef(
    ({ isError, disabled, defaultActionKey, handleChange, clearFilter, responseId, style, optionDisable = [], ...props }, ref) => {
        const [selectActionKeys, setSelectActionKeys] = useState([]);

        const { data: selectActionKey } = useGetActionKeysQuery(responseId, { refetchOnMountOrArgChange: true, skip: !responseId });

        useEffect(() => {
            if (selectActionKey?.status !== "success") return;
            const selectOptions = selectActionKey?.data?.map(({ name, actionKeyId }) => ({
                label: name,
                value: actionKeyId,
                isDisabled: optionDisable?.includes(actionKeyId),
            }));
            setSelectActionKeys(selectOptions);
        }, [selectActionKey, optionDisable]);

        return (
            <Select
                options={selectActionKeys}
                value={isValidArray(selectActionKeys) ? selectActionKeys.find((option) => option.value === defaultActionKey) : null}
                onChange={(selectedOption) => handleChange(selectedOption?.value)}
                ref={ref}
                {...props}
                styles={style}
            />
        );
    }
);

export default selectActionKeys;
