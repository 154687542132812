import { API } from "../variables/apiFields";

export const defaultValues = {
    LOGIN: {
        [API.LOGIN.EMAIL]: "",
        [API.LOGIN.PASS]: "",
        [API.LOGIN.NOTIFICATION_TOKEN]: "Hello",
        [API.LOGIN.DEVICE_NAME]: "iphone 14plus",
        [API.LOGIN.PLATFORM]: "IOS",
    },
    FORGOT_PASSWORD: {
        [API.FORGOT_PASSWORD.EMAIL]: "",
    },
    VERIFY_OTP: {
        [API.VERIFY_OTP.VERIFICATION_CODE]: "",
    },
    RESET_PASSWORD: {
        [API.RESET_PASSWORD.EMAIL]: "",
        [API.RESET_PASSWORD.PASS]: "",
        [API.RESET_PASSWORD.CONFIRM_PASS]: "",
    },
    ORGANIZATION: {
        [API.ORGANIZATION.NAME]: "",
        [API.ORGANIZATION.EMAIL]: "",
        [API.ORGANIZATION.RANK]: "",
        [API.ORGANIZATION.STATION]: "",
        [API.ORGANIZATION.SHIFT]: "",
        [API.ORGANIZATION.CONTACT_NAME]: "",
        [API.ORGANIZATION.CONTACT_NUMBER]: "",
        [API.ORGANIZATION.PROFILE_NAME]: "",
        [API.ORGANIZATION.LOGO]: "",
        [API.ORGANIZATION.SUBSCRIPTION_DATE]: "",
        [API.ORGANIZATION.PASSWORD]: "",
    },
    PROFILE: {
        [API.PROFILE.EMAIL]: "",
        [API.PROFILE.RANK]: "",
        [API.PROFILE.STATION]: "",
        [API.PROFILE.SHIFT]: "",
        [API.PROFILE.PROFILE_NAME]: "",
        [API.PROFILE.PROFILE_IMAGE]: "",
        [API.PROFILE.PASSWORD]: "",
    },
    USER_PROFILE: {
        [API.USER_PROFILE.EMAIL]: "",
        [API.USER_PROFILE.PROFILE_NAME]: "",
        [API.USER_PROFILE.PROFILE_IMAGE]: "",
    },
    UPDATE_PASSWORD: {
        [API.UPDATE_PASSWORD.PASSWORD]: "",
        [API.UPDATE_PASSWORD.CONFIRM_PASSWORD]: "",
    },

    RESPONSE_TYPES: {
        [API.RESPONSE_TYPES.NAME]: "",
    },
    INCIDENT_TYPES: {
        [API.INCIDENT_TYPES.NAME]: "",
    },
    ASSIGNMENTS: {
        [API.ASSIGNMENTS.NAME]: "",
    },
    FIREGROUND_TASKS: {
        [API.FIREGROUND_TASKS.RESPONSE_TYPE]: "",
        [API.FIREGROUND_TASKS.INCIDENT_TYPE]: "",
        [API.FIREGROUND_TASKS.ASSIGNMENT]: "",
        [API.FIREGROUND_TASKS.NAME]: "",
        [API.FIREGROUND_TASKS.GOAL_OBJECTIVE]: "",
        [API.FIREGROUND_TASKS.MISSION_BRIEFING]: "",
        [API.FIREGROUND_TASKS.TEXT]: "",
        [API.FIREGROUND_TASKS.AUDIO]: "",
        [API.FIREGROUND_TASKS.IMAGE]: null,
        [API.FIREGROUND_TASKS.TARGET_AUDIANCE]: null,
        [API.FIREGROUND_TASKS.BEST_NAMES]: "",
        [API.FIREGROUND_TASKS.PUBLISH]: false,
    },
    TACTICAL_DECISION_GAME: {
        [API.TACTICAL_DECISION_GAME.TEXT]: "",
        [API.TACTICAL_DECISION_GAME.IMAGE]: [],
        [API.TACTICAL_DECISION_GAME.SELECT_TARGET_AUDIANCE]: null,
        [API.TACTICAL_DECISION_GAME.BEST_NAMES]: "",
        [API.TACTICAL_DECISION_GAME.TIME_LIMIT]: "",
        [API.TACTICAL_DECISION_GAME.SELECT_ANS_TYPE]: "",
        [API.TACTICAL_DECISION_GAME.SELECT_NUM_OF_SLIDERS]: "",
        [API.TACTICAL_DECISION_GAME.INCIDENT_PRIORITIES_ID0]: "",
        [API.TACTICAL_DECISION_GAME.INCIDENT_PRIORITIES_ID1]: "",
        [API.TACTICAL_DECISION_GAME.INCIDENT_PRIORITIES_ID2]: "",
        [API.TACTICAL_DECISION_GAME.ACTION_KEY_ID0]: "",
        [API.TACTICAL_DECISION_GAME.ACTION_KEY_ID1]: "",
        [API.TACTICAL_DECISION_GAME.ACTION_KEY_ID2]: "",
        [API.TACTICAL_DECISION_GAME.ADD_ANS_TYPES]: null,
        [API.TACTICAL_DECISION_GAME.MIN_VALUE]: null,
        [API.TACTICAL_DECISION_GAME.MAX_VALUE]: null,
        [API.TACTICAL_DECISION_GAME.MIN_VALUE_1]: null,
        [API.TACTICAL_DECISION_GAME.MAX_VALUE_1]: null,
        [API.TACTICAL_DECISION_GAME.CORRECT_ANS]: null,
        [API.TACTICAL_DECISION_GAME.TEXTS]: null,
        [API.TACTICAL_DECISION_GAME.TEXTS_1]: null,
        [API.TACTICAL_DECISION_GAME.PRIORITY_TYPE]: false,
        [API.TACTICAL_DECISION_GAME.PUBLISH]: false,
        [API.TACTICAL_DECISION_GAME.NUMERIC]: "",
        [API.TACTICAL_DECISION_GAME.TEXTING]: "",
        [API.TACTICAL_DECISION_GAME.POSITION]: "",
    },
    INCIDENT_PRIORITIES: {
        [API.INCIDENT_PRIORITIES.NAME]: "",
        [API.INCIDENT_PRIORITIES.COLOR]: "",
        [API.INCIDENT_PRIORITIES.ICON]: null,
    },
    OBJECTIVES: {
        [API.OBJECTIVES.NAME]: "",
    },
    THINKING_PLANNING: {
        [API.THINKING_PLANNING.RESPONSE_TYPE]: "",
        [API.THINKING_PLANNING.INCIDENT_PRIORITIES]: "",
        [API.THINKING_PLANNING.OBJECTIVES]: "",
        [API.THINKING_PLANNING.NAME]: "",
        [API.THINKING_PLANNING.ANSWER_TYPE]: "",
        [API.THINKING_PLANNING.SLIDER_TYPE]: null,
        [API.THINKING_PLANNING.SELECT_NUM_OF_SLIDERS]: "",
        [API.THINKING_PLANNING.SELECT_SLIDER_TYPE]: "",
        [API.THINKING_PLANNING.MIN_VALUE]: "",
        [API.THINKING_PLANNING.MAX_VALUE]: "",
        [API.THINKING_PLANNING.MIN_VALUE_1]: "",
        [API.THINKING_PLANNING.MAX_VALUE_1]: "",
        [API.THINKING_PLANNING.TEXTS]: null,
        [API.THINKING_PLANNING.TEXTS_1]: null,
        [API.THINKING_PLANNING.PRIORITY_TYPE]: false,
    },
    ACTION_KEY: {
        [API.ACTION_KEY.NAME]: "",
        [API.ACTION_KEY.COLOR]: "",
        [API.ACTION_KEY.ICON]: null,
    },
    ACTION_LIST: {
        [API.ACTION_LIST.NAME]: "",
    },
    APPARATUS: {
        [API.APPARATUS.APPARATUSID]: "",
        [API.APPARATUS.ORGANIZATIONID]: "",
        [API.APPARATUS.STATION]: "",
        [API.APPARATUS.TOTALAPPARATUS]: "",
        [API.APPARATUS.APPARATUSNAME]: "",
    },
    SCENARIO: {
        [API.SCENARIO.RESPONSE_TYPE]: "",
        [API.SCENARIO.INCIDENT_TYPE]: "",
        [API.SCENARIO.ASSIGNMENT]: "",
        [API.SCENARIO.SCENARIO_PROJECT_LEAD]: "",
        [API.SCENARIO.GOAL]: "",
        [API.SCENARIO.INCIDENT_ADDRESS]: "",
        [API.SCENARIO.OCCUPANT_STATUS]: "",
        [API.SCENARIO.LOCATION_AND_EXTENT_OF_THE_FIRE]: "",
        [API.SCENARIO.BURNING_REGIME_AND_EXPOSURES]: "",
        [API.SCENARIO.BUILDING]: "",
        [API.SCENARIO.WEATHER]: "",
        [API.SCENARIO.NARRATIVE]: "",
        [API.SCENARIO.COMMUNICATION_DISPATCH]: "",
        [API.SCENARIO.RESOURCES]: "",
        [API.SCENARIO.SELECT_APPARATUS]: "",
        [API.SCENARIO.MISSION_BRIEFING]: "",
        [API.SCENARIO.PUBLISH]: false,
    },
    SCENARIO_ASSIGNMENTS: {
        [API.SCENARIO_ASSIGNMENTS.RESPONSE_TYPE]: "",
        [API.SCENARIO_ASSIGNMENTS.INCIDENT_TYPE]: "",
        [API.SCENARIO_ASSIGNMENTS.ASSIGNMENT]: "",
        [API.SCENARIO_ASSIGNMENTS.AUDIO]: "",
        [API.SCENARIO_ASSIGNMENTS.VIDEO]: "",
        [API.SCENARIO_ASSIGNMENTS.GAME_FROM_TDG]: "",
        [API.SCENARIO_ASSIGNMENTS.GAME_INFO]: "",
        [API.SCENARIO_ASSIGNMENTS.GOAL_OBJECTIVE]: "",
        [API.SCENARIO_ASSIGNMENTS.MISSION_BRIEFING]: "",
        [API.SCENARIO_ASSIGNMENTS.TARGET_AUDIANCE]: null,
        [API.SCENARIO_ASSIGNMENTS.BEST_NAMES]: "",
        [API.SCENARIO_ASSIGNMENTS.SCENARIO]: "",
        [API.SCENARIO_ASSIGNMENTS.APPARATUS_ID]: "",
        [API.SCENARIO_ASSIGNMENTS.TDG_NAME]: "",
        [API.SCENARIO_ASSIGNMENTS.APPARATUS_NAME]: "",
    },
};
