import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Select from "react-select";
import { ROUTES } from "../../../../../utils/constants/variables/routes";
import * as _ from "lodash";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import MainLayout from "../../../../../components/layouts/MainLayout";
import Elips from "../../../../../assets/images/action/elips.svg";
import Switch from "../../../../../assets/images/action/switch.svg";
import WhiteArrow from "../../../../../assets/images/action/white-arrow.svg";
import SelectIncidentPriorities from "../../../../../components/shared/SelectOptions/SelectIncidentPriorities";
import SelectActionKey from "../../../../../components/shared/SelectOptions/SelectActionKeys";
import RightArrow from "../../../../../assets/images/action/right-arrow.svg";
import WhitePlus from "../../../../../assets/images/action/white-plus.svg";
import DeleteIcon from "../../../../../assets/images/action/deleteIcon.svg";
import bulletIcon from "../../../../../assets/images/action/bulletIcon.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FileInput from "../../../../../components/shared/FileInput/FileInput";
import { API } from "../../../../../utils/constants/variables/apiFields";
import { defaultValues } from "../../../../../utils/constants/forms/defaultValues";
import { validation } from "../../../../../utils/constants/forms/validation";
import { useCreateTacticalDecisionGameMutation, useUpdateTacticalDecisionGameMutation } from "../../../../../api/tacticalDecisionGame";
import { SelectStyle } from "../../../../../components/shared/Options/styles/SelectStyle";
import { LuImagePlus } from "react-icons/lu";
import { IoIosPlay } from "react-icons/io";
import { RiDeleteBin5Line } from "react-icons/ri";
import { CgPlayPause } from "react-icons/cg";
import { ModifiedSelectStyle } from "../../../../../utils/constants/selectStyle";

import { isValidArray } from "../../../../../utils/constants/validation/array";

const prepareFormData = (payload) => {
    const formData = new FormData();
    if (!payload) return null;

    const appendFormData = (obj, parentKey) => {
        Object.entries(obj)?.forEach(([key, value]) => {
            const computedKey = parentKey ? `${parentKey}.${key}` : key;
            if (value !== undefined && value !== null) {
                if (typeof value === "object" && !(value instanceof File)) {
                    // If value is an object (likely JSON), recursively append its key-value pairs
                    appendFormData(value, computedKey);
                } else if (value instanceof File) {
                    // If value is a File object, append it directly
                    formData.append(computedKey, value);
                } else {
                    formData.append(computedKey, value);
                }
            }
        });
    };

    // Start appending key-value pairs recursively
    appendFormData(payload, null);

    return formData;
};

const CreateEditTacticalDecisionGame = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const isInitialMount = useRef(true);

    const params = useParams();
    const { responseType, incidentType, assignmentType, firegroundTasks } = params;
    const [responseTypeName, responseTypeId] = responseType?.split(/[=]+/);
    const [incidentTypeName, incidentTypeId] = incidentType?.split(/[=]+/);
    const [assignmentName, assignmentId] = assignmentType?.split(/[=]+/);
    const [tdgLibraryName, tdgLibraryId] = firegroundTasks?.split(/[=]+/);

    const tacticalDecisionGameDetail = location.state;
    const tacticalDecisionGameId = tacticalDecisionGameDetail?.id;

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [currentlyPlayingAudio, setCurrentlyPlayingAudio] = useState(null);

    const [isText, setIsText] = useState(false);
    const [isNumeric, setIsNumeric] = useState(false);
    const [radioVal, setRadioVal] = useState("");

    const [selectIncidentPrioritiesId, setSelectIncidentPrioritiesId] = useState("");
    const [selectActionKeyId, setSelectActionKeyId] = useState("");

    const [createTacticalDecisionGame] = useCreateTacticalDecisionGameMutation();
    const [updateTacticalDecisionGame] = useUpdateTacticalDecisionGameMutation();

    const [addTexts, setAddTexts] = useState([]);
    const [numSets, setNumSets] = useState(1);

    const checkInitialValue = (value) => {
        return value !== "" && value !== null && value !== undefined;
    };

    const audioRef = useRef(null);
    const [currentAudio, setCurrentAudio] = useState(null);

    const handleRemoveImage = (index) => {
        setValue(`image[${index}].image`, null);
    };

    const handleRemoveAudio = (index) => {
        setValue(`image[${index}].audio`, null);
    };

    const handleIncidentPrioritiesChange = (e, index) => {
        setSelectIncidentPrioritiesId(e);

        // if (index === 0) {
        //     setValue("incidentPrioritiesId1", null);
        //     setValue("incidentPrioritiesId2", null);
        // } else if (index === 1) {
        //     setValue("incidentPrioritiesId2", null);
        // }
    };

    const handleActionKeyChange = (e, index) => {
        setSelectActionKeyId(e);
        // if (index === 0) {
        //     setValue("actionKeyId1", null);
        //     setValue("actionKeyId2", null);
        // } else if (index === 1) {
        //     setValue("actionKeyId2", null);
        // }
    };

    const form = useForm({
        defaultValues: defaultValues.TACTICAL_DECISION_GAME,
        resolver: yupResolver(validation.TACTICAL_DECISION_GAME(radioVal)),
    });

    const {
        handleSubmit,
        formState: { errors },
        register,
        control,
        watch,
        setValue,
    } = form;

    console.log("watch----", watch());

    useEffect(() => {
        if (isNumeric) {
            setValue("texts", []);
            setValue("texts1", []);
        }
        if (isText) {
            setValue(API.TACTICAL_DECISION_GAME.MIN_VALUE, null);
            setValue(API.TACTICAL_DECISION_GAME.MAX_VALUE, null);
            setValue(API.TACTICAL_DECISION_GAME.MIN_VALUE_1, null);
            setValue(API.TACTICAL_DECISION_GAME.MAX_VALUE_1, null);
        }
    }, [isNumeric, isText, setValue]);

    const {
        fields: imageFields,
        append: appendImage,
        remove: removeImage,
    } = useFieldArray({
        control,
        name: "image", // should match with your backend field name
    });

    const handleAddImage = () => {
        if (imageFields.length < 12) {
            appendImage({ image: null, audio: null, answer: null });
        } else {
            alert("You can only add a maximum of 12 images.");
        }
    };
    const handledeleteImage = () => {
        if (imageFields.length > 0) {
            removeImage(imageFields.length - 1);
        }
    };

    const handlePlay = (audioRef) => {
        if (currentlyPlayingAudio && currentlyPlayingAudio !== audioRef) {
            currentlyPlayingAudio.pause();
        }
        setCurrentlyPlayingAudio(audioRef);
        audioRef.play();
    };

    const {
        fields: bestNamesFields,
        append: appendBestNames,
        remove: removeBestNames,
    } = useFieldArray({
        control,
        name: "bestNames", // should match with your backend field name
    });

    const handleAddBestNames = () => {
        appendBestNames({ name: "" });
    };

    const handleRemoveBestNames = (index) => {
        removeBestNames(index);
    };

    const {
        fields: addAnswerTypesFields,
        append: appendAddAnswerTypes,
        remove: removeAddAnswerTypes,
    } = useFieldArray({
        control,
        name: "addAnswerType", // should match with your backend field name
    });

    const handlePriorityTypeChange = (e) => {
        const isChecked = e.target.checked;
        // Loop through addAnswerType and update position values accordingly
        const updatedAddAnswerTypes = watch("addAnswerType").map((item, index) => {
            return {
                ...item,
                position: isChecked ? index + 1 : 0,
            };
        });
        setValue("addAnswerType", updatedAddAnswerTypes);
    };
    const handleRemoveAnswerTypes = (index) => {
        const updatedFields = watch("addAnswerType");
        updatedFields.splice(index, 1);

        // Update the answerType array with the updated fields
        const updatedAnswerTypes = updatedFields.map((item, idx) => ({
            ...item,
            position: idx + 1, // Reset positions in order
        }));

        setValue("addAnswerType", updatedAnswerTypes);
    };

    const handleAddAnswerTypes = () => {
        appendAddAnswerTypes({ answer: "", position: watch("priorityType") ? watch("addAnswerType").length + 1 : 0 });
    };

    const handleAnsDragStart = (e, index) => {
        e.dataTransfer.setData("index", index.toString());
    };

    // Function to handle drag over
    const handleAnsDragOver = (e) => {
        e.preventDefault();
    };

    // Function to handle drop
    const handleAnsDrop = (e, newIndex) => {
        e.preventDefault();
        const dragIndex = parseInt(e.dataTransfer.getData("index"));
        const items = watch("addAnswerType");
        const draggedItem = items[dragIndex];

        // Remove the item from its old position
        items.splice(dragIndex, 1);
        // Insert it into the new position
        items.splice(newIndex, 0, draggedItem);
        const updateIndexResponse = items.map((item, idx) => {
            return {
                ...item,
                index: idx + 1,
            };
        });

        // Update state with the reordered items
        setValue("addAnswerType", updateIndexResponse);
    };

    const handleTextDragStart = (e, index) => {
        e.dataTransfer.setData("index", index.toString());
    };

    // Function to handle drag over
    const handleTextDragOver = (e) => {
        e.preventDefault();
    };

    // Function to handle drop
    const handleTextDrop = (e, newIndex) => {
        e.preventDefault();
        const dragIndex = parseInt(e.dataTransfer.getData("index"));
        const items = watch("texts");
        const draggedItem = items[dragIndex];

        items.splice(dragIndex, 1);
        items.splice(newIndex, 0, draggedItem);
        const updateIndexResponse = items.map((item, idx) => {
            return {
                ...item,
                index: idx + 1,
            };
        });

        // Update state with the reordered items
        setValue("texts", updateIndexResponse);
    };

    const handleTexts1DragStart = (e, index) => {
        e.dataTransfer.setData("index", index.toString());
    };

    // Function to handle drag over
    const handleTexts1DragOver = (e) => {
        e.preventDefault();
    };

    // Function to handle drop
    const handleTexts1Drop = (e, newIndex) => {
        e.preventDefault();
        const dragIndex = parseInt(e.dataTransfer.getData("index"));
        const items = watch("texts1");
        const draggedItem = items[dragIndex];

        items.splice(dragIndex, 1);
        items.splice(newIndex, 0, draggedItem);
        const updateIndexResponse = items.map((item, idx) => {
            return {
                ...item,
                index: idx + 1,
            };
        });

        // Update state with the reordered items
        setValue("texts1", updateIndexResponse);
    };

    const {
        fields: addTextsSliderTextFields,
        append: appendTextsSliderText,
        remove: removeTextsSliderText,
    } = useFieldArray({
        control,
        name: "texts", // should match with your backend field name
    });

    const {
        fields: addTexts1SliderTextFields,
        append: appendTexts1SliderText,
        remove: removeTexts1SliderText,
    } = useFieldArray({
        control,
        name: "texts1", // should match with your backend field name
    });

    const handleAddTextsSliderText = () => {
        appendTextsSliderText({ text: "" });
    };

    const handleRemoveTextsSliderText = (index) => {
        removeTextsSliderText(index);
    };

    const handleAddTexts1SliderText = () => {
        appendTexts1SliderText({ text: "" });
    };

    const handleRemoveTexts1SliderText = (index) => {
        removeTexts1SliderText(index);
    };

    const selectTargateAudiance = [
        { value: "fireFighter", label: "Fire fighter" },
        { value: "officer", label: "Officer" },
        { value: "chiefOfficer", label: "Chief officer" },
        { value: "combinations", label: "Combinations" },
    ];
    console.log("hello======");
    console.log("errors======", errors);
    const setValueOfRadio = (e) => {
        if (e === "list") {
            setValue("texts", []);
            setValue("minimumValue", null);
            setValue("maximumValue", null);
            setValue("incidentPrioritiesId1", null);
            setValue("incidentPrioritiesId2", null);
            setValue("incidentPrioritiesId0", null);
            setValue("selectNumberOfSliders", null);
            setValue("actionKeyId0", null);
            setValue("actionKeyId1", null);
            setValue("actionKeyId2", null);
            setValue("numeric", false);
            setValue("texting", false);
            setIsText(false);
            setIsNumeric(false);
        } else if (e === "ratingScale") {
            setValue("addAnswerType", []);
            setValue("priorityType", false);
            setValue("incidentPrioritiesId1", null);
            setValue("incidentPrioritiesId2", null);
            setValue("incidentPrioritiesId0", null);
            setValue("actionKeyId0", null);
            setValue("actionKeyId1", null);
            setValue("actionKeyId2", null);
        } else if (e === "voiceToText") {
            setValue("numeric", false);
            setValue("texting", false);
            setIsText(false);
            setIsNumeric(false);
            setValue("addAnswerType", []);
            setValue("priorityType", false);
            setValue("selectNumberOfSliders", null);
            setValue("incidentPrioritiesId1", null);
            setValue("incidentPrioritiesId2", null);
            setValue("incidentPrioritiesId0", null);
            setValue("actionKeyId0", null);
            setValue("actionKeyId1", null);
            setValue("actionKeyId2", null);
            setValue("texts", []);
            setValue("minimumValue", null);
            setValue("maximumValue", null);
        } else if (e === "functionKeys") {
            setValue("numeric", false);
            setValue("texting", false);
            setIsText(false);
            setIsNumeric(false);
            setValue("selectNumberOfSliders", null);
            setValue("addAnswerType", []);
            setValue("priorityType", false);
            setValue("texts", []);
            setValue("minimumValue", null);
            setValue("maximumValue", null);
        }
    };

    const selectNoOfSlider = [
        { value: "singleSlider", label: "Single Slider" },
        { value: "twoSlider", label: "Two Slider" },
    ];

    const handleSelectNumberOfSlidersChange = (selectedOption) => {
        setValue(API.TACTICAL_DECISION_GAME.SELECT_NUM_OF_SLIDERS, selectedOption?.value);
        setSelectNoOfSlider(selectedOption?.value);
    };

    const setSelectNoOfSlider = (selectedOption) => {
        if (selectedOption === "singleSlider") {
            setValue("minimumValue", null);
            setValue("maximumValue", null);
            setValue("minimumValue1", null);
            setValue("maximumValue1", null);
            setValue("texts", []);
            setValue("texts1", []);
        } else if (selectedOption === "twoSlider") {
            setValue("minimumValue", null);
            setValue("maximumValue", null);
            setValue("texts", []);
        }
    };
    console.log("tacticalDecisionGameDetail*******==========", tacticalDecisionGameDetail);

    useEffect(() => {
        if (tacticalDecisionGameId && isInitialMount.current) {
            setValue("text", tacticalDecisionGameDetail?.text);
            setValue("timeLimit", tacticalDecisionGameDetail?.timeLimit);
            setValue("minimumValue", tacticalDecisionGameDetail?.minimumValue);
            setValue("maximumValue", tacticalDecisionGameDetail?.maximumValue);
            setValue("minimumValue1", tacticalDecisionGameDetail?.minimumValue1);
            setValue("maximumValue1", tacticalDecisionGameDetail?.maximumValue1);
            setValue("correctAnswer", tacticalDecisionGameDetail?.correctAnswer);
            setValue("publish", tacticalDecisionGameDetail?.publish);
            setValue("selectAnswerType", tacticalDecisionGameDetail?.selectAnswerType);
            setValue("texting", tacticalDecisionGameDetail?.texting);
            setValue("numeric", tacticalDecisionGameDetail?.numeric);
            setValue("selectNumberOfSliders", tacticalDecisionGameDetail?.selectNumberOfSliders);
            setValue("image", tacticalDecisionGameDetail?.image);

            tacticalDecisionGameDetail?.selectAnswerType === "functionKeys" && setRadioVal(tacticalDecisionGameDetail?.selectAnswerType);
            tacticalDecisionGameDetail?.selectAnswerType === "ratingScale" && setRadioVal(tacticalDecisionGameDetail?.selectAnswerType);
            setValue("priorityType", tacticalDecisionGameDetail?.priorityType);
            if (tacticalDecisionGameDetail?.texting === "true") {
                setIsText(true);
            } else {
                setIsText(false);
            }
            if (tacticalDecisionGameDetail?.numeric === "true") {
                setIsNumeric(true);
            } else {
                setIsNumeric(false);
            }

            setValue(API.TACTICAL_DECISION_GAME.SELECT_TARGET_AUDIANCE, tacticalDecisionGameDetail?.selectTargetAudience || []);

            const bestPracticesTactical = tacticalDecisionGameDetail?.bestNames || [];
            bestPracticesTactical.forEach((item, index) => {
                bestPracticesTactical[`bestNames[${index}]`] = item.name;
                appendBestNames({ name: item.name });
            });

            const addAnswerTypes = tacticalDecisionGameDetail?.addAnswerTypes;
            addAnswerTypes.forEach((item, index) => {
                addAnswerTypes[`addAnswerType[${index}].answer`] = item.answer;
                addAnswerTypes[`addAnswerType[${index}].position`] = item.position;
                appendAddAnswerTypes({ answer: item.answer, position: item.position });
            });

            isValidArray(tacticalDecisionGameDetail?.tacticalFunctionWithObjectivesAction) &&
                tacticalDecisionGameDetail?.tacticalFunctionWithObjectivesAction?.forEach((item, index) => {
                    setValue(`actionKeyId${index}`, item.actionKeyId);
                });
            isValidArray(tacticalDecisionGameDetail?.tacticalFunctionWithObjectivesIncident) &&
                tacticalDecisionGameDetail?.tacticalFunctionWithObjectivesIncident?.forEach((item, index) => {
                    setValue(`incidentPrioritiesId${index}`, item.incidentPrioritiesId);
                });

            const texts = tacticalDecisionGameDetail?.texts?.filter((item) => item?.slider === "left") || tacticalDecisionGameDetail?.texts;
            texts.forEach((item, index) => {
                texts[`texts[${index}].text`] = item.ratingScaleText;
                appendTextsSliderText({ text: item.ratingScaleText, slider: item.slider, position: item.position });
            });
            const texts1 = tacticalDecisionGameDetail?.texts?.filter((item) => item?.slider === "right") || [];
            texts1.forEach((item, index) => {
                texts1[`texts1[${index}]`] = item.texts1;
                appendTexts1SliderText({ text: item.ratingScaleText, slider: item.slider, position: item.position });
            });

            isInitialMount.current = false; //use this because in update form values of bestPracticesTdg are showing repeatedly
        }
    }, [setValue, tacticalDecisionGameDetail, tacticalDecisionGameId]);

    const onSubmit = async (data) => {
        console.log("data===", data);

        const bestNamesFiltered = data.bestNames?.filter((item) => item.name !== null && item.name.trim() !== "");
        bestNamesFiltered?.forEach((item, index) => {
            data[`bestNames[${index}]`] = item.name;
        });
        delete data.bestNames;

        const leftSlider = data?.texts?.map((item, idx) => {
            return { ...item, slider: "left", position: idx + 1 };
        });
        const rightSlider = data?.texts1?.map((item, idx) => {
            return { ...item, slider: "right", position: idx + 1 };
        });

        const addAnswer = Array.isArray(data?.addAnswerType) ? data.addAnswerType.map((item) => ({ ...item })) : [];

        const incidentPrioritiesArray = [data?.incidentPrioritiesId0, data?.incidentPrioritiesId1, data?.incidentPrioritiesId2].filter(
            (value) => value !== null
        );
        const ActionKeyArray = [data?.actionKeyId0, data?.actionKeyId1, data?.actionKeyId2].filter((value) => value !== null);

        const allData = {
            ...data,
            tdgLibraryId: tdgLibraryId,
            addAnswerType: JSON.stringify(addAnswer),
            selectTargetAudience: JSON.stringify(data.selectTargetAudience),
            numeric: isNumeric ? true : isText && false,
            texting: isText ? true : isNumeric && false,
            texts: JSON.stringify(leftSlider),
            texts1: JSON.stringify(rightSlider),
            // images: imageAudio,
        };
        console.log("alldata====", allData);
        const formData = prepareFormData(allData);
        const { incidentPrioritiesId0, incidentPrioritiesId1, incidentPrioritiesId2, actionKeyId0, actionKeyId1, actionKeyId2, ...body } = allData;

        // Append incidentPrioritiesId values to formData
        incidentPrioritiesArray.forEach((value, index) => {
            formData.append(`incidentPrioritiesId[${index}]`, value);
        });

        // Append actionKeyId values to formData
        ActionKeyArray.forEach((value, index) => {
            formData.append(`actionKeyId[${index}]`, value);
        });

        // Continue with the rest of your form data serialization
        prepareFormData(body);

        const response = tacticalDecisionGameId
            ? await updateTacticalDecisionGame({ tacticalDecisionGameId, formData })
            : await createTacticalDecisionGame(formData);
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            navigate(
                `/gameFunnel/tacticalDecisionGame/${responseTypeName}=${responseTypeId}/${incidentTypeName}=${incidentTypeId}/${assignmentName}=${assignmentId}/${tdgLibraryName}=${tdgLibraryId}`
            );
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };
    return (
        <MainLayout className="me-0">
            <div className="overflow_scroll">
                <div className="row">
                    <div className="col-12 my-2">
                        <div className="col-bread-crumbs">
                            <div className="bread-crumbs d-flex align-items-center">
                                <div className="d-flex align-items-center me-1">
                                    <span className="text-breadcrumb me-1" onClick={() => navigate(ROUTES.GAMEFUNNEL.RESPONSE)}>
                                        {responseTypeName}
                                    </span>
                                    <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                                </div>
                                <div className="d-flex align-items-center me-1">
                                    <span
                                        className="text-breadcrumb me-1"
                                        onClick={() => {
                                            navigate(`/gameFunnel/incidentTypes/${responseTypeName}=${responseTypeId}`);
                                        }}
                                    >
                                        {incidentTypeName}
                                    </span>
                                    <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                                </div>
                                <div className="d-flex align-items-center me-1">
                                    <span
                                        className="text-breadcrumb me-1"
                                        onClick={() => {
                                            navigate(`/gameFunnel/assignments/${responseTypeName}=${responseTypeId}/${incidentTypeName}=${incidentTypeId}`);
                                        }}
                                    >
                                        {assignmentName}
                                    </span>
                                    <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                                </div>
                                <div className="d-flex align-items-center me-1">
                                    <span
                                        className="text-breadcrumb me-1"
                                        onClick={() =>
                                            navigate(
                                                `/gameFunnel/firegroundTasks/${responseTypeName}=${responseTypeId}/${incidentTypeName}=${incidentTypeId}/${assignmentName}=${assignmentId}`
                                            )
                                        }
                                    >
                                        {tdgLibraryName}
                                    </span>
                                    <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                                </div>
                            </div>
                            <div className="my-3 me-3">
                                <Controller
                                    control={control}
                                    name={API.TACTICAL_DECISION_GAME.PUBLISH}
                                    render={({ field: { onChange, value } }) => {
                                        return (
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="custom-switch"
                                                label="Publish"
                                                className="customSwitch"
                                                checked={value}
                                                value={value}
                                                onChange={onChange}
                                                reverse
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-12">
                                <div className="frm-input mb-3">
                                    <Form.Group>
                                        <div className="d-flex align-items-center">
                                            <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                            <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                                Tactical decision game
                                            </Form.Label>
                                        </div>
                                        <Form.Control
                                            name={API.TACTICAL_DECISION_GAME.TEXT}
                                            {...register(API.TACTICAL_DECISION_GAME.TEXT)}
                                            type="text"
                                            className="custom-input"
                                            placeholder="Text*"
                                        />
                                    </Form.Group>
                                    <div className="error-msg">{errors?.text && <span className="text-danger">{errors?.text?.message}</span>}</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <div className="d-flex align-items-center">
                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                        <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                            Image / Audio
                                        </Form.Label>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end">
                                        <div className="custom-add-icon mx-2" onClick={handleAddImage}>
                                            <img src={WhitePlus} alt={"WhitePlus"} height={"30px"} width={"30px"} />
                                        </div>
                                        <div onClick={handledeleteImage}>
                                            <img src={DeleteIcon} alt={"WhitePlus"} height={"42px"} width={"44px"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="frm-input mb-2">
                                    <div className="row d-flex align-items-center">
                                        {console.log('watch|("image")', watch("image"))}
                                        {watch("image") &&
                                            watch("image").map((field, index) => (
                                                <>
                                                    <div className="col-sm-12 col-md-6" key={field.id}>
                                                        <div className="custom-component">
                                                            <div className="select-audio-image">
                                                                <div className="tactical_game_image">
                                                                    <label
                                                                        htmlFor={`photoInput${index}`}
                                                                        className="logo-image d-flex align-items-center justify-content-center"
                                                                    >
                                                                        {field.image ? (
                                                                            <div className="selected_photo">
                                                                                <img
                                                                                    src={
                                                                                        field.image instanceof File
                                                                                            ? URL.createObjectURL(field.image)
                                                                                            : field.image
                                                                                    }
                                                                                    alt="game_image"
                                                                                    className="game-image"
                                                                                />
                                                                                <div className="custom-delete-button" onClick={() => handleRemoveImage(index)}>
                                                                                    <RiDeleteBin5Line />
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                <LuImagePlus size={30} color="#999" />
                                                                            </>
                                                                        )}
                                                                    </label>
                                                                    <Controller
                                                                        name={`image[${index}].image`}
                                                                        control={control}
                                                                        render={({ field: { onChange } }) => (
                                                                            <input
                                                                                name={`image[${index}].image`}
                                                                                id={`photoInput${index}`}
                                                                                type="file"
                                                                                accept="image/png ,image/jpg ,image/jpeg"
                                                                                onChange={(e) => {
                                                                                    onChange(e.target.files[0]);
                                                                                }}
                                                                                className="d-none"
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>

                                                                <div className="tactical_game_audio me-4">
                                                                    {field.audio ? (
                                                                        <div className="selected_audio d-flex align-items-center">
                                                                            <audio controls onPlay={(e) => handlePlay(e.target)}>
                                                                                <source
                                                                                    src={
                                                                                        field.audio instanceof Blob
                                                                                            ? URL.createObjectURL(field.audio)
                                                                                            : field.audio
                                                                                    }
                                                                                />
                                                                            </audio>
                                                                            <div className="custom-delete-button" onClick={() => handleRemoveAudio(index)}>
                                                                                <RiDeleteBin5Line />
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <label
                                                                            htmlFor={`audioInput${index}`}
                                                                            className="audio-input d-flex align-items-center justify-content-center"
                                                                        >
                                                                            <div>+ Add audio</div>
                                                                        </label>
                                                                    )}

                                                                    <Controller
                                                                        name={`image[${index}].audio`}
                                                                        control={control}
                                                                        render={({ field: { onChange } }) => (
                                                                            <input
                                                                                name={`image[${index}].audio`}
                                                                                id={`audioInput${index}`}
                                                                                type="file"
                                                                                accept="audio/*"
                                                                                onChange={(e) => {
                                                                                    onChange(e.target.files[0]);
                                                                                }}
                                                                                className="d-none"
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="error-msg">
                                                                {errors?.[API.TACTICAL_DECISION_GAME.IMAGE]?.[index]?.[API.TACTICAL_DECISION_GAME.IMAGE] && (
                                                                    <span className="text-danger m-0">
                                                                        {
                                                                            errors?.[API.TACTICAL_DECISION_GAME.IMAGE]?.[index]?.[
                                                                                API.TACTICAL_DECISION_GAME.IMAGE
                                                                            ]?.message
                                                                        }
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="frm-input">
                                                                <div className="d-flex align-items-center">
                                                                    <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                                    <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                                                        Correct answer
                                                                    </Form.Label>
                                                                </div>
                                                                <Form.Control
                                                                    name={`image[${index}].answer`}
                                                                    {...register(`image[${index}].answer`)}
                                                                    type="text"
                                                                    className="custom-input"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                        <div className="error-msg">{errors?.image && <span className="text-danger">{errors?.image?.message}</span>}</div>
                                    </div>
                                </div>
                            </div>
                            {console.log("errors", errors)}
                            <div className="col-12">
                                <div className="frm-input mb-2">
                                    <Form.Group>
                                        <div className="d-flex align-items-center">
                                            <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                            <Form.Label className="mb-0 custom-frmlabel">Target audience</Form.Label>
                                        </div>

                                        <div>
                                            <Controller
                                                control={control}
                                                name={API.TACTICAL_DECISION_GAME.SELECT_TARGET_AUDIANCE}
                                                render={({ field: { onChange, value } }) => {
                                                    return (
                                                        <>
                                                            <Select
                                                                name={API.TACTICAL_DECISION_GAME.SELECT_TARGET_AUDIANCE}
                                                                options={selectTargateAudiance}
                                                                styles={SelectStyle}
                                                                isMulti
                                                                components={{
                                                                    IndicatorSeparator: () => null,
                                                                }}
                                                                value={
                                                                    Array.isArray(value)
                                                                        ? selectTargateAudiance.filter((option) => value.includes(option.value))
                                                                        : []
                                                                }
                                                                onChange={(selectedOptions) => {
                                                                    const selectedValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];
                                                                    onChange(selectedValues);
                                                                }}
                                                            />
                                                            <div className="error-msg">
                                                                {errors?.selectTargetAudience && (
                                                                    <span className="text-danger">{errors?.selectTargetAudience?.message}</span>
                                                                )}
                                                            </div>
                                                        </>
                                                    );
                                                }}
                                            />
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="frm-input mb-2">
                                    <Form.Group>
                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                            <div className="d-flex align-items-center">
                                                <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                                    Best practices
                                                </Form.Label>
                                            </div>
                                            <div className="custom-add-icon" onClick={handleAddBestNames}>
                                                <img src={WhitePlus} alt={"WhitePlus"} height={"30px"} width={"30px"} />
                                            </div>
                                        </div>
                                        {_.map(bestNamesFields, (item, index) => (
                                            <div key={item.id} className="d-flex align-items-center mb-3">
                                                <Form.Control
                                                    name={`bestNames[${index}].name`}
                                                    {...register(`bestNames[${index}].name`)}
                                                    type="text"
                                                    className="custom-input me-3"
                                                />
                                                <div className="" onClick={() => handleRemoveBestNames(index)}>
                                                    <img src={DeleteIcon} alt={"DeleteIcon"} height={"42px"} width={"44px"} />
                                                </div>
                                            </div>
                                        ))}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="frm-input mb-2">
                                    <Form.Group>
                                        <div className="d-flex align-items-center">
                                            <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                            <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                                Time Limit
                                            </Form.Label>
                                        </div>
                                        <Form.Control
                                            name={API.TACTICAL_DECISION_GAME.TIME_LIMIT}
                                            {...register(API.TACTICAL_DECISION_GAME.TIME_LIMIT)}
                                            type="number"
                                            className="custom-input"
                                        />
                                    </Form.Group>
                                    <div className="error-msg">{errors?.timeLimit && <span className="text-danger">{errors?.timeLimit?.message}</span>}</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="frm-input mb-3">
                                    <Form.Group>
                                        <div className="d-flex align-items-center">
                                            <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                            <Form.Label htmlFor="basic-url" className="mb-0 me-4 custom-frmlabel">
                                                Select answer type
                                            </Form.Label>
                                        </div>
                                        <div className="select-answer custom-radio-checked answer-tactical mb-3">
                                            <Controller
                                                control={control}
                                                name={API.TACTICAL_DECISION_GAME.SELECT_ANS_TYPE}
                                                render={({ field: { onChange, value } }) => {
                                                    return ["list", "ratingScale", "voiceToText", "functionKeys"].map((item, idx) => {
                                                        let customLabel = "";
                                                        switch (item) {
                                                            case "list":
                                                                customLabel = "List";
                                                                break;
                                                            case "ratingScale":
                                                                customLabel = "Rating Scale";
                                                                break;
                                                            case "voiceToText":
                                                                customLabel = "Voice to Text";
                                                                break;
                                                            case "functionKeys":
                                                                customLabel = "Function Keys";
                                                                break;
                                                            default:
                                                                break;
                                                        }
                                                        return (
                                                            <Form.Check
                                                                key={item}
                                                                type="radio"
                                                                id={item}
                                                                name={API.TACTICAL_DECISION_GAME.SELECT_ANS_TYPE}
                                                                label={customLabel}
                                                                value={item}
                                                                checked={value === item}
                                                                onChange={(e) => {
                                                                    onChange(e.target.value);
                                                                    setValueOfRadio(e.target.value);
                                                                    setRadioVal(e.target.value);
                                                                }}
                                                            />
                                                        );
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className="error-msg">
                                            {errors?.selectAnswerType && <span className="text-danger">{errors?.selectAnswerType?.message}</span>}
                                        </div>

                                        {watch("selectAnswerType") === "list" && (
                                            <div className="col-12">
                                                <div className="frm-input mb-2">
                                                    <Form.Group>
                                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                                            <div className="d-flex align-items-center">
                                                                <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                                <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel me-4">
                                                                    Add answer type
                                                                </Form.Label>
                                                                <div className="add-priority">
                                                                    <Controller
                                                                        className="d-flex align-items-center"
                                                                        control={control}
                                                                        name={API.TACTICAL_DECISION_GAME.PRIORITY_TYPE}
                                                                        render={({ field: { onChange, value } }) => {
                                                                            return (
                                                                                <Form.Check // prettier-ignore
                                                                                    type="checkbox"
                                                                                    id="custom-check"
                                                                                    label="Priority type"
                                                                                    className="fs-5"
                                                                                    checked={value}
                                                                                    // value={value}
                                                                                    onChange={(e) => {
                                                                                        onChange(e);
                                                                                        handlePriorityTypeChange(e);
                                                                                    }}
                                                                                    reverse
                                                                                />
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="custom-add-icon" onClick={handleAddAnswerTypes}>
                                                                <img src={WhitePlus} alt={"WhitePlus"} height={"30px"} width={"30px"} />
                                                            </div>
                                                        </div>
                                                        {_.map(addAnswerTypesFields, (item, index) => (
                                                            <div
                                                                key={item.id}
                                                                className="d-flex align-items-center mb-3"
                                                                draggable
                                                                onDragStart={(e) => handleAnsDragStart(e, index)}
                                                                onDragOver={handleAnsDragOver}
                                                                onDrop={(e) => handleAnsDrop(e, index)}
                                                            >
                                                                <div className="me-3">
                                                                    <img src={bulletIcon} alt={"bulletIcon"} height={"30px"} width={"30px"} />
                                                                </div>
                                                                <InputGroup className="me-3 mb-0 custom-ans-input">
                                                                    <Form.Control
                                                                        {...register(`addAnswerType[${index}].answer`)}
                                                                        type="text"
                                                                        className="custom-frm-control ans-input rounded"
                                                                        required
                                                                    />
                                                                    {watch("priorityType") && (
                                                                        <Form.Control
                                                                            type="number"
                                                                            className="custom-grouptext index-input"
                                                                            {...register(`addAnswerType[${index}].position`)}
                                                                            disabled
                                                                        />
                                                                    )}
                                                                </InputGroup>
                                                                <div className="" onClick={() => handleRemoveAnswerTypes(index)}>
                                                                    <img src={DeleteIcon} alt={"DeleteIcon"} height={"42px"} width={"44px"} />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </Form.Group>
                                                </div>

                                                <div className="error-msg">
                                                    {errors?.addAnswerType && <span className="text-danger">{errors?.addAnswerType?.message}</span>}
                                                </div>
                                            </div>
                                        )}

                                        {watch("selectAnswerType") === "ratingScale" && (
                                            <div className="col-12 custom-radio-checked">
                                                <div className="frm-input mb-3">
                                                    <Form.Group>
                                                        <div className="mb-3 select-slider">
                                                            <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                            <Form.Label htmlFor="basic-url" className="mb-0 me-4 custom-frmlabel">
                                                                Select slider type
                                                            </Form.Label>
                                                            <div>
                                                                <Controller
                                                                    control={control}
                                                                    name={API.TACTICAL_DECISION_GAME.SELECT_NUM_OF_SLIDERS}
                                                                    render={({ field }) => {
                                                                        return (
                                                                            <>
                                                                                <Select
                                                                                    options={selectNoOfSlider}
                                                                                    styles={ModifiedSelectStyle}
                                                                                    placeholder="Select Number Of Slider"
                                                                                    components={{
                                                                                        IndicatorSeparator: () => null,
                                                                                    }}
                                                                                    defaultValue={selectNoOfSlider.find(
                                                                                        (option) =>
                                                                                            option.value === tacticalDecisionGameDetail?.selectNumberOfSliders
                                                                                    )}
                                                                                    onChange={(selectedOption) => {
                                                                                        field.onChange(selectedOption?.value);
                                                                                        handleSelectNumberOfSlidersChange(selectedOption);
                                                                                    }}
                                                                                />
                                                                            </>
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="error-msg my-3">
                                                            {errors?.selectNumberOfSliders && (
                                                                <span className="text-danger">{errors?.selectNumberOfSliders?.message}</span>
                                                            )}
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <div>
                                                                <Form.Check
                                                                    onChange={() => {
                                                                        setIsNumeric(true);
                                                                        setIsText(false);
                                                                        setValue("texting", false);
                                                                        setValue("numeric", true);
                                                                    }}
                                                                    label="Numeric"
                                                                    name="slider"
                                                                    className="fs-5 w-50"
                                                                    type="radio"
                                                                    checked={isNumeric}
                                                                />
                                                                <div>
                                                                    {!isNumeric && !isText && errors?.numeric && (
                                                                        <span className="text-danger">{errors?.numeric?.message}</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <Form.Check
                                                                    onChange={() => {
                                                                        setIsText(true);
                                                                        setIsNumeric(false);
                                                                        setValue("texting", true);
                                                                        setValue("numeric", false);
                                                                    }}
                                                                    label="Text"
                                                                    name="slider"
                                                                    className="fs-5 w-50"
                                                                    type="radio"
                                                                    checked={isText}
                                                                />
                                                                <div>
                                                                    {!isText && !isNumeric && errors?.texting && (
                                                                        <span className="text-danger">{errors?.texting?.message}</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                {watch("selectNumberOfSliders") === "singleSlider" && isNumeric ? (
                                                    <div className="col-12 custom-radio-checked">
                                                        <div className="frm-input mb-3">
                                                            <Form.Group>
                                                                <div className="d-flex align-items-center mb-3">
                                                                    <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                                    <Form.Label htmlFor="basic-url" className="mb-0 me-4 custom-frmlabel">
                                                                        Single Slider
                                                                    </Form.Label>
                                                                </div>
                                                                <div className="row align-items-center justify-content-between">
                                                                    <div className="col-12 col-md-6 mb-2">
                                                                        <Form.Control
                                                                            name={API.TACTICAL_DECISION_GAME.MIN_VALUE}
                                                                            {...register(API.TACTICAL_DECISION_GAME.MIN_VALUE)}
                                                                            type="number"
                                                                            className="custom-input"
                                                                            placeholder="Minimum value"
                                                                            required
                                                                        />
                                                                        <div className="error-msg">
                                                                            {errors?.minimumValue && (
                                                                                <span className="text-danger">{errors?.minimumValue?.message}</span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-6 mb-2">
                                                                        <Form.Control
                                                                            name={API.TACTICAL_DECISION_GAME.MAX_VALUE}
                                                                            {...register(API.TACTICAL_DECISION_GAME.MAX_VALUE)}
                                                                            type="number"
                                                                            className="custom-input"
                                                                            placeholder="Maximum value"
                                                                            required
                                                                        />
                                                                        <div className="error-msg">
                                                                            {errors?.maximumValue && (
                                                                                <span className="text-danger">{errors?.maximumValue?.message}</span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    watch("selectNumberOfSliders") === "twoSlider" &&
                                                    isNumeric && (
                                                        <div className="col-12 custom-radio-checked">
                                                            <div className="frm-input mb-3">
                                                                <Form.Group>
                                                                    <div className="d-flex align-items-center mb-3">
                                                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                                        <Form.Label htmlFor="basic-url" className="mb-0 me-4 custom-frmlabel">
                                                                            Two Slider
                                                                        </Form.Label>
                                                                    </div>
                                                                    <div className="row align-items-center justify-content-between">
                                                                        <div className="col-md-6 col-lg-6 col-12">
                                                                            <div className="mb-3">
                                                                                <Form.Label htmlFor="basic-url" className="mb-1 me-4">
                                                                                    Minimum value
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    // name={API.TACTICAL_DECISION_GAME.MIN_VALUE}
                                                                                    {...register(API.TACTICAL_DECISION_GAME.MIN_VALUE)}
                                                                                    type="number"
                                                                                    className="custom-input my-2"
                                                                                    placeholder="Minimum value"
                                                                                    required
                                                                                />
                                                                                <div className="error-msg">
                                                                                    {errors?.minimumValue && (
                                                                                        <span className="text-danger">{errors?.minimumValue?.message}</span>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <Form.Label htmlFor="basic-url" className="mb-1 me-4">
                                                                                    Maximum value
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    // name={API.TACTICAL_DECISION_GAME.MAX_VALUE}
                                                                                    {...register(API.TACTICAL_DECISION_GAME.MAX_VALUE)}
                                                                                    type="number"
                                                                                    className="custom-input my-2"
                                                                                    placeholder="Maximum value"
                                                                                    required
                                                                                />
                                                                                <div className="error-msg">
                                                                                    {errors?.maximumValue && (
                                                                                        <span className="text-danger">{errors?.maximumValue?.message}</span>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 col-lg-6 col-12">
                                                                            <div className="mb-3">
                                                                                <Form.Label htmlFor="basic-url" className="mb-1 me-4">
                                                                                    Minimum value
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    // name={API.TACTICAL_DECISION_GAME.MIN_VALUE_1}
                                                                                    {...register(API.TACTICAL_DECISION_GAME.MIN_VALUE_1)}
                                                                                    type="number"
                                                                                    className="custom-input my-2"
                                                                                    placeholder="Minimum value"
                                                                                    required
                                                                                />
                                                                                <div className="error-msg">
                                                                                    {errors?.minimumValue1 && (
                                                                                        <span className="text-danger">{errors?.minimumValue1?.message}</span>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <Form.Label htmlFor="basic-url" className="mb-1 me-4">
                                                                                    Maximum value
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    // name={API.TACTICAL_DECISION_GAME.MAX_VALUE_1}
                                                                                    {...register(API.TACTICAL_DECISION_GAME.MAX_VALUE_1)}
                                                                                    type="number"
                                                                                    className="custom-input my-2"
                                                                                    placeholder="Maximum value"
                                                                                    required
                                                                                />
                                                                                <div className="error-msg">
                                                                                    {errors?.maximumValue1 && (
                                                                                        <span className="text-danger">{errors?.maximumValue1?.message}</span>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                    )
                                                )}

                                                {watch("selectNumberOfSliders") === "singleSlider" && isText ? (
                                                    <div className="col-12">
                                                        <div className="frm-input mb-2">
                                                            <Form.Group>
                                                                <div className="d-flex justify-content-between align-items-center mb-2">
                                                                    <div className="d-flex align-items-center">
                                                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                                        <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                                                            Single Slider
                                                                        </Form.Label>
                                                                    </div>
                                                                    <div className="custom-add-icon my-3" onClick={handleAddTextsSliderText}>
                                                                        <img src={WhitePlus} alt={"WhitePlus"} height={"30px"} width={"30px"} />
                                                                    </div>
                                                                </div>
                                                                {_.map(addTextsSliderTextFields, (item, index) => (
                                                                    <div
                                                                        key={item.id}
                                                                        className="d-flex align-items-center mb-3"
                                                                        draggable
                                                                        onDragStart={(e) => handleTextDragStart(e, index)}
                                                                        onDragOver={handleTextDragOver}
                                                                        onDrop={(e) => handleTextDrop(e, index)}
                                                                    >
                                                                        <div className="me-3">
                                                                            <img src={bulletIcon} alt={"bulletIcon"} height={"30px"} width={"30px"} />
                                                                        </div>
                                                                        <InputGroup className="me-3 mb-0">
                                                                            <Form.Control
                                                                                name={`texts[${index}].text`}
                                                                                {...register(`texts[${index}].text`)}
                                                                                type="text"
                                                                                className="custom-frm-control rounded"
                                                                                required
                                                                            />
                                                                        </InputGroup>
                                                                        <div className="" onClick={() => handleRemoveTextsSliderText(index)}>
                                                                            <img src={DeleteIcon} alt={"DeleteIcon"} height={"42px"} width={"44px"} />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                <div className="error-msg">
                                                                    {errors?.texts && <span className="text-danger">{errors?.texts?.message}</span>}
                                                                </div>
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    watch("selectNumberOfSliders") === "twoSlider" &&
                                                    isText && (
                                                        <div>
                                                            <div className="d-flex align-items-center">
                                                                <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                                <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                                                    Two Slider
                                                                </Form.Label>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="frm-input mb-2">
                                                                    <Form.Group>
                                                                        <div className="row">
                                                                            <div className="col-md-6 col-lg-6 col-12">
                                                                                <div className="custom-add-icon my-3" onClick={handleAddTextsSliderText}>
                                                                                    <img src={WhitePlus} alt={"WhitePlus"} height={"30px"} width={"30px"} />
                                                                                </div>
                                                                                {_.map(addTextsSliderTextFields, (item, index) => (
                                                                                    <div
                                                                                        key={item.id}
                                                                                        className="d-flex align-items-center mb-3"
                                                                                        draggable
                                                                                        onDragStart={(e) => handleTextDragStart(e, index)}
                                                                                        onDragOver={handleTextDragOver}
                                                                                        onDrop={(e) => handleTextDrop(e, index)}
                                                                                    >
                                                                                        <div className="me-3">
                                                                                            <img
                                                                                                src={bulletIcon}
                                                                                                alt={"bulletIcon"}
                                                                                                height={"30px"}
                                                                                                width={"30px"}
                                                                                            />
                                                                                        </div>
                                                                                        <InputGroup className="me-3 mb-0">
                                                                                            <Form.Control
                                                                                                name={`texts[${index}].text`}
                                                                                                {...register(`texts[${index}].text`)}
                                                                                                type="text"
                                                                                                className="custom-frm-control rounded"
                                                                                                required
                                                                                            />
                                                                                        </InputGroup>
                                                                                        <div className="" onClick={() => handleRemoveTextsSliderText(index)}>
                                                                                            <img
                                                                                                src={DeleteIcon}
                                                                                                alt={"DeleteIcon"}
                                                                                                height={"42px"}
                                                                                                width={"44px"}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                                <div className="error-msg">
                                                                                    {errors?.texts && (
                                                                                        <span className="text-danger">{errors?.texts?.message}</span>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6 col-lg-6 col-12">
                                                                                <div className="custom-add-icon my-3" onClick={handleAddTexts1SliderText}>
                                                                                    <img src={WhitePlus} alt={"WhitePlus"} height={"30px"} width={"30px"} />
                                                                                </div>
                                                                                {_.map(addTexts1SliderTextFields, (item, index) => (
                                                                                    <div
                                                                                        key={item.id}
                                                                                        className="d-flex align-items-center mb-3"
                                                                                        draggable
                                                                                        onDragStart={(e) => handleTexts1DragStart(e, index)}
                                                                                        onDragOver={handleTexts1DragOver}
                                                                                        onDrop={(e) => handleTexts1Drop(e, index)}
                                                                                    >
                                                                                        <div className="me-3">
                                                                                            <img
                                                                                                src={bulletIcon}
                                                                                                alt={"bulletIcon"}
                                                                                                height={"30px"}
                                                                                                width={"30px"}
                                                                                            />
                                                                                        </div>
                                                                                        <InputGroup className="me-3 mb-0">
                                                                                            <Form.Control
                                                                                                name={`texts1[${index}].text`}
                                                                                                {...register(`texts1[${index}].text`)}
                                                                                                type="text"
                                                                                                className="custom-frm-control rounded"
                                                                                                required
                                                                                            />
                                                                                        </InputGroup>
                                                                                        <div className="" onClick={() => handleRemoveTexts1SliderText(index)}>
                                                                                            <img
                                                                                                src={DeleteIcon}
                                                                                                alt={"DeleteIcon"}
                                                                                                height={"42px"}
                                                                                                width={"44px"}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                                <div className="error-msg">
                                                                                    {errors?.texts1 && (
                                                                                        <span className="text-danger">{errors?.texts1?.message}</span>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        )}

                                        {watch("selectAnswerType") === "functionKeys" && (
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="d-flex align-items-center">
                                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                        <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                                            Incident priorities
                                                        </Form.Label>
                                                    </div>
                                                    {Array.from({ length: 3 }, (_, index) => (
                                                        <div className="col-4" key={index}>
                                                            <div className="frm-input mb-3">
                                                                <Form.Group>
                                                                    <Controller
                                                                        control={control}
                                                                        name={`incidentPrioritiesId${index}`}
                                                                        render={({ field: { onChange, value } }) => (
                                                                            <SelectIncidentPriorities
                                                                                style={SelectStyle}
                                                                                isClearable={true}
                                                                                defaultIncidentPriorities={value}
                                                                                handleChange={(selectedOption) => onChange(selectedOption || null)}
                                                                                responseId={responseTypeId}
                                                                                optionDisable={[
                                                                                    watch("incidentPrioritiesId0"),
                                                                                    watch("incidentPrioritiesId1"),
                                                                                    watch("incidentPrioritiesId2"),
                                                                                ].filter((_, i) => i !== index)}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="error-msg">
                                                    {errors?.incidentPrioritiesId0 && (
                                                        <span className="text-danger">{errors?.incidentPrioritiesId0?.message}</span>
                                                    )}
                                                </div>
                                                <div className="row">
                                                    <div className="d-flex align-items-center">
                                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                                        <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                                            Action Keys
                                                        </Form.Label>
                                                    </div>

                                                    {Array.from({ length: 3 }, (_, index) => (
                                                        <div className="col-4" key={index}>
                                                            <div className="frm-input mb-3">
                                                                <Form.Group>
                                                                    <Controller
                                                                        control={control}
                                                                        name={`actionKeyId${index}`}
                                                                        render={({ field: { onChange, value } }) => (
                                                                            <SelectActionKey
                                                                                style={SelectStyle}
                                                                                isClearable={true}
                                                                                defaultActionKey={value}
                                                                                handleChange={(selectedOption) => onChange(selectedOption || null)}
                                                                                responseId={responseTypeId}
                                                                                optionDisable={[
                                                                                    watch("actionKeyId0"),
                                                                                    watch("actionKeyId1"),
                                                                                    watch("actionKeyId2"),
                                                                                ].filter((_, i) => i !== index)}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="error-msg">
                                                    {errors?.actionKeyId0 && <span className="text-danger">{errors?.actionKeyId0?.message}</span>}
                                                </div>
                                            </div>
                                        )}
                                    </Form.Group>
                                </div>
                            </div>

                            <div className="col-12 d-flex align-items-center justify-content-center">
                                {!tacticalDecisionGameId ? (
                                    <button type="submit" className="btn-save m-2">
                                        Save
                                    </button>
                                ) : (
                                    <button type="submit" className="btn-save m-2">
                                        Update
                                    </button>
                                )}
                                <button
                                    type="button"
                                    className="btn-cancel m-2"
                                    onClick={() =>
                                        navigate(
                                            `/gameFunnel/tacticalDecisionGame/${responseTypeName}=${responseTypeId}/${incidentTypeName}=${incidentTypeId}/${assignmentName}=${assignmentId}/${tdgLibraryName}=${tdgLibraryId}`
                                        )
                                    }
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </MainLayout>
    );
};

export default CreateEditTacticalDecisionGame;
