import { createApi } from "@reduxjs/toolkit/query/react";
import { api } from "../utils/constants/url";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";

const tagTypes = "ScenarioAssignments";
const type = "ScenarioAssignments";
export const scenarioAssignmentsApi = createApi({
    reducerPath: "scenarioAssignmentsApi",
    baseQuery: baseQueryWithAuthInterceptor({
        baseUrl: `${api.baseURL}/scenarioAssignments`,
        prepareHeaders,
    }),
    tagTypes: [tagTypes],
    endpoints: (builder) => ({
        getScenarioAssignment: builder.query({
            query: () => ({
                url: `/getScenarioAssignment`,
                method: "GET",
            }),
            providesTags: [type],
        }),
        createScenarioAssignment: builder.mutation({
            query: (body) => ({
                url: "/createScenarioAssignment",
                method: "POST",
                body,
            }),
        }),
        updateScenarioAssignment: builder.mutation({
            query: (payload) => {
                const { scenarioAssignmentId, formData } = payload;
                return {
                    url: `/updateScenarioAssignment/${scenarioAssignmentId}`,
                    method: "PUT",
                    body: formData,
                };
            },
        }),
        deleteScenarioAssignment: builder.mutation({
            query: (id) => ({
                url: `/deleteScenarioAssignment/${id}`,
                method: "DELETE",
            }),
        }),
    }),
});

export const { useGetScenarioAssignmentQuery, useCreateScenarioAssignmentMutation, useUpdateScenarioAssignmentMutation, useDeleteScenarioAssignmentMutation } =
    scenarioAssignmentsApi;
