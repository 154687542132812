import React, { useEffect, useState } from "react";
import MainLayout from "../../../../../components/layouts/MainLayout";
import { toast } from "react-toastify";
import * as _ from "lodash";
import WhitePlus from "../../../../../assets/images/action/white-plus.svg";
import RightArrow from "../../../../../assets/images/action/right-arrow.svg";
import PluseIcon from "../../../../../assets/images/action/plusIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../../../utils/constants/variables/routes";
import CreateEditIncidentType from "./CreateEditIncidentType";
import ImportIncidentType from "./ImportIncidentType";
import { useGetIncidentTypeQuery, useUpdateIndexMutation } from "../../../../../api/incidentType";
import { useGetAllDataMutation, useSyncDataMutation } from "../../../../../api/importData";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIncidentType } from "../../../../../redux/incidentTypeSlice";
import DataNotFound from "../../../../../components/shared/DataNotFound/DataNotFound";
import { BiImport } from "react-icons/bi";
import { LiaSyncAltSolid } from "react-icons/lia";
import { RiEdit2Fill } from "react-icons/ri";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const IncidentTypes = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();
    const { responseType } = params;
    const [responseTypeName, responseTypeId] = responseType?.split(/[=]+/);
    const originalDataId = location.state?.originalDataId;
    const [isTrue, setIsTrue] = useState(false);
    const [userRole, setUserRole] = useState("");
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showImportModal, setShowImportModal] = useState(false);
    const [incidentTypeData, setIncidentTypeData] = useState([]);
    const [importIncidentTypeData, setImportIncidentTypeData] = useState([]);
    const [showEditForm, setShowEditForm] = useState(false);
    const [incidentTypeDetail, setIncidentTypeDetail] = useState("");
    const [type, setType] = useState("incidentTypes");

    const { data: incidentType, refetch } = useGetIncidentTypeQuery(responseTypeId);
    const [importIncidentType] = useGetAllDataMutation();
    const [suyncData] = useSyncDataMutation();

    const [updateIndex] = useUpdateIndexMutation();

    useEffect(() => {
        const getUserRole = localStorage.getItem("userRole");
        if (getUserRole) {
            setUserRole(getUserRole);
        }
    }, []);

    useEffect(() => {
        refetch();
        if (incidentType?.data?.status === "success") {
            return;
        } else {
            toast.error(incidentType?.error?.data?.message, { autoClose: false });
        }
        if (incidentType) {
            setIncidentTypeData(incidentType?.data);
            dispatch(setIncidentType(incidentType?.data));
        } else {
            setIncidentTypeData([]);
        }
    }, [incidentType, refetch]);

    const handleDragStart = (e, index) => {
        e.dataTransfer.setData("index", index.toString());
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = async (e, newIndex) => {
        e.preventDefault();
        const dragIndex = parseInt(e.dataTransfer.getData("index"));
        const items = [...incidentTypeData];
        const draggedItem = items[dragIndex];

        // Remove the item from its old position
        items.splice(dragIndex, 1);
        // Insert it into the new position
        items.splice(newIndex, 0, draggedItem);
        const updateIndexResponse = _.map(items, (itemList, idx) => {
            return {
                incidentTypeId: _.get(itemList, "incidentTypeId", ""),
                index: idx + 1,
            };
        });
        const response = await updateIndex({ updateIndexResponse: updateIndexResponse, responseTypeId: responseTypeId });
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            refetch();
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
        setIncidentTypeData(response?.data?.data);
    };

    const allIncidentTypeData = _.map(incidentTypeData, (item) => {
        return {
            incidentTypeId: _.get(item, "incidentTypeId", ""),
            name: _.get(item, "name", ""),
            originalDataId: _.get(item, "originalDataId", ""),
            responseTypeId: _.get(item, "responseTypeId", ""),
            responseTypeName: _.get(item, "responseTypeName", ""),
            isMatch: _.get(item, "isMatch", ""),
        };
    });

    const handleImportData = async () => {
        const payload = { type, responseTypeId };
        const response = await importIncidentType(payload);
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            setShowImportModal(true);
            setImportIncidentTypeData(response?.data?.data);
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };
    const handleSyncData = async (currentDataId) => {
        const payload = { type, currentDataId };
        const response = await suyncData(payload);
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            refetch();
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };
    useEffect(() => {
        const isSelect = JSON.parse(sessionStorage.getItem("select"));
        if (isSelect?.isSelect) {
            setIsTrue(true);
        } else {
            setIsTrue(false);
        }
    }, []);

    return (
        <div>
            <MainLayout className="me-0">
                <div className="row">
                    <div className="col-12 my-2">
                        <div className="bread-crumbs">
                            <div className="d-flex align-items-center me-1">
                                <span className="text-breadcrumb me-1" onClick={() => navigate(ROUTES.GAMEFUNNEL.RESPONSE)}>
                                    {responseTypeName}
                                </span>
                                <img src={RightArrow} alt={"RightArrow"} height={"20px"} width={"20px"} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-2 d-flex align-items-center justify-content-between hr-bottom">
                        <div className="d-flex align-items-center">
                            <button className="btn-custom me-3">Incident types</button>
                            <button
                                className="btn-light"
                                onClick={() => navigate(`/gameFunnel/functionKeys/incidentPriorities/${responseTypeName}=${responseTypeId}`)}
                            >
                                Function keys
                            </button>
                        </div>

                        {userRole == "organization" && originalDataId !== null && (
                            <div>
                                <button className="btn-import d-flex align-items-center" onClick={handleImportData}>
                                    <span className="me-2" style={{ fontWeight: "700" }}>
                                        <BiImport />
                                    </span>
                                    <span>Import</span>
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="col-12 dataList">
                        {!_.isEmpty(allIncidentTypeData) ? (
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <span className="title-text">List</span>
                                </div>
                                {_.map(allIncidentTypeData, (item, index) => {
                                    var incidentTypeId = item.incidentTypeId;
                                    var incidentTypeName = item.name;
                                    var originalDataId = item.originalDataId;
                                    return (
                                        <div
                                            key={item.incidentTypeId}
                                            className="col-12 col-sm-6 my-2"
                                            draggable
                                            onDragStart={(e) => handleDragStart(e, index)}
                                            onDragOver={handleDragOver}
                                            onDrop={(e) => handleDrop(e, index)}
                                        >
                                            <div
                                                className="item-list"
                                                onClick={() => {
                                                    navigate(
                                                        `/gameFunnel/assignments/${responseTypeName}=${responseTypeId}/${item.name}=${item.incidentTypeId}`,
                                                        {
                                                            state: { originalDataId },
                                                        }
                                                    );
                                                }}
                                            >
                                                <div class="d-flex gap-2 align-items-center">
                                                    <div class="p-2 left-rect"></div>
                                                    <div class="p-2 flex-grow-1 d-block text-truncate">
                                                        {item.isMatch === false && userRole == "organization" && (
                                                            <LiaSyncAltSolid
                                                                className="me-2 icon-sync"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleSyncData(incidentTypeId);
                                                                }}
                                                            />
                                                        )}
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`} className="custom-tooltip">
                                                                    {item.name}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span className="text-fs">{item.name}</span>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div class="ms-auto">
                                                        <div className="action-icon d-flex flex-nowrap align-item-center">
                                                            <RiEdit2Fill
                                                                className="ico-edit"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setShowEditForm(true);
                                                                    setIncidentTypeDetail(item);
                                                                }}
                                                            />
                                                            <MdOutlineKeyboardArrowRight className="ico-arrow mx-2" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <DataNotFound texts={"Incident"} />
                        )}
                    </div>
                </div>
                <div className="btn-add d-none d-md-block d-lg-block" onClick={() => setShowCreateForm(true)}>
                    <button className="btn-custom">
                        <img alt="WhitePlus" src={WhitePlus} className="me-3" />
                        Add Incident type
                    </button>
                </div>

                <div className="customAdd d-block d-sm-block d-md-none">
                    <div className="add" onClick={() => setShowCreateForm(true)}>
                        <img src={PluseIcon} alt={"PluseIcon"} height={"50px"} width={"50px"} />
                    </div>
                </div>
            </MainLayout>
            {showCreateForm && (
                <CreateEditIncidentType
                    show={showCreateForm}
                    onHide={() => setShowCreateForm(false)}
                    responseTypeId={responseTypeId}
                    refetchCreateEditIncidentType={refetch}
                />
            )}
            {showEditForm && (
                <CreateEditIncidentType
                    incidentTypeDetail={incidentTypeDetail}
                    showEditForm={showEditForm}
                    show={showEditForm}
                    onHide={() => setShowEditForm(false)}
                    refetchCreateEditIncidentType={refetch}
                />
            )}
            {showImportModal && (
                <ImportIncidentType
                    importIncidentTypeData={importIncidentTypeData}
                    showImportModal={showImportModal}
                    show={showImportModal}
                    responseTypeId={responseTypeId}
                    onHide={() => setShowImportModal(false)}
                    refetchImportData={refetch}
                />
            )}
        </div>
    );
};

export default IncidentTypes;
