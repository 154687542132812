import { createSlice } from "@reduxjs/toolkit";

const responseTypeSlice = createSlice({
    name: "responseType",
    initialState: {
        responseTypeData: {},
    },
    reducers: {
        setResponseType: (state, action) => {
            state.responseTypeData = action.payload;
        },
    },
});

export const { setResponseType } = responseTypeSlice.actions;
export default responseTypeSlice.reducer;
