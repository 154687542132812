import React, { useState, useEffect } from "react";
import * as _ from "lodash";
import { DataGrid } from "@mui/x-data-grid";
// import "./style.scss";
import Form from "react-bootstrap/Form";
import MainLayout from "../../../../components/layouts/MainLayout";
import WhitePlus from "../../../../assets/images/action/white-plus.svg";
import { ROUTES } from "../../../../utils/constants/variables/routes";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import bulletIcon from "../../../../assets/images/action/bulletIcon.svg";
import PluseIcon from "../../../../assets/images/action/plusIcon.svg";
import Elips from "../../../../assets/images/action/elips.svg";
import DataNotFound from "../../../../components/shared/DataNotFound/DataNotFound";
import { useGetScenarioAssignmentQuery } from "../../../../api/scenarioAssignments";
import { toast } from "react-toastify";
import EditIcon from "../../../../assets/images/action/editIcon.svg";
import DeleteIcon from "../../../../assets/images/action/deleteIcon.svg";
import DeleteModal from "../../../../components/delete/DeleteModal";

const ScenarioAssignments = () => {
    const navigate = useNavigate();

    const [scenarioAssignmentsData, setScenarioAssignmentsData] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteScenarioAssignmentId, setDeletescenarioAssignmentId] = useState("");
    const { data: scenarioAssignments, refetch } = useGetScenarioAssignmentQuery();
    console.log("scenarioAssignments====", scenarioAssignments);
    useEffect(() => {
        refetch();
        if (scenarioAssignments?.data?.status === "success") {
            return;
        } else {
            toast.error(scenarioAssignments?.error?.data?.message, { autoClose: false });
        }
        if (scenarioAssignments) {
            setScenarioAssignmentsData(scenarioAssignments?.data);
        } else {
            setScenarioAssignmentsData([]);
        }
    }, [scenarioAssignments, refetch]);

    const rows =
        scenarioAssignmentsData &&
        _.map(scenarioAssignmentsData, (item, index) => {
            return {
                no: index + 1,
                id: _.get(item, "scenarioAssignmentId", ""),
                responseTypeId: _.get(item, "responseTypeId", ""),
                incidentTypeId: _.get(item, "incidentTypeId", ""),
                assignmentId: _.get(item, "assignmentId", ""),
                scenarioId: _.get(item, "scenarioId", ""),
                apparatusId: _.get(item, "apparatusId", ""),
                apparatusName: _.get(item, "apparatusName", ""),
                tdgName: _.get(item, "tdgName", ""),
                audio: _.get(item, "audio", ""),
                video: _.get(item, "video", ""),
                gameFromTDG: _.get(item, "gameFromTDG", ""),
                gameInfo: _.get(item, "gameInfo", ""),
                goalObjective: _.get(item, "goalObjective", ""),
                missionBriefing: _.get(item, "missionBriefing", ""),
                targetAudience: _.get(item, "targetAudience", ""),
                images: _.get(item, "images", ""),
                bestPractice: _.get(item, "bestPractice", ""),
            };
        });
    const columns = [
        {
            field: "#",
            headerName: "#",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>#</span>
                    </div>
                </div>
            ),
            renderCell: () => {
                return (
                    <div className="d-flex align-items-center justify-content-center w-100">
                        <div>
                            <img src={bulletIcon} alt={"bulletIcon"} height={"20px"} width={"20px"} />
                        </div>
                    </div>
                );
            },
            width: "lg" ? 100 : "sm" && 20,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "no",
            headerName: "No",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>No</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.no}</span>
                    </div>
                );
            },
            width: "lg" ? 100 : "sm" && 20,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "action",
            headerName: "Action",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Action</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                const scenarioAssignmentDetail = params.row;
                return (
                    <div className="d-flex align-items-center justify-content-center gap-2 w-100">
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(ROUTES.EDIT_SCENARIO_ASSIGNMENTS, {
                                    state: {
                                        scenarioAssignmentDetail,
                                    },
                                });
                            }}
                        >
                            <img src={EditIcon} alt={"EditIcon"} height={"20px"} width={"20px"} />
                        </div>
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowDeleteModal(true);
                                setDeletescenarioAssignmentId(scenarioAssignmentDetail?.id);
                            }}
                        >
                            <img src={DeleteIcon} alt={"DeleteIcon"} height={"20px"} width={"20px"} />
                        </div>
                    </div>
                );
            },
            width: "lg" ? 150 : "sm" && 20,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },

        {
            field: "apparatusId",
            headerName: "Appartus",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Appartus</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.apparatusName || "-"}</span>
                    </div>
                );
            },
            width: 250,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "gameFromTDG",
            headerName: "Game From TDG",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Game From TDG</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.tdgName || "-"}</span>
                    </div>
                );
            },
            width: 250,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "gameInfo",
            headerName: "Game Info",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Game Info</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.gameInfo || "-"}</span>
                    </div>
                );
            },
            width: 250,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "goalObjective",
            headerName: "Goal Objective",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Goal Objective</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.goalObjective || "-"}</span>
                    </div>
                );
            },
            width: 250,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "missionBriefing",
            headerName: "Mission Briefing",
            headerAlign: "center",
            renderHeader: () => (
                <div className="header">
                    <div className="d-flex align-items-center gap-1">
                        <img src={Elips} alt={"Elips"} height={"10px"} width={"10px"} />
                        <span>Mission Briefing</span>
                    </div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <div className="fs-item">
                        <span>{params.row.missionBriefing || "-"}</span>
                    </div>
                );
            },
            width: 250,
            height: 40,
            sortable: false,
            disableColumnMenu: true,
        },
        //
    ];

    return (
        <div>
            <MainLayout>
                <div className="row">
                    <div className="col-12 my-3 d-flex hr-bottom">
                        <button className="btn-light me-3" onClick={() => navigate(ROUTES.SCENARIO)}>
                            Scenario
                        </button>
                        <button className="btn-custom">Assignments</button>
                    </div>
                </div>

                <div className="dataList-table">
                    {!_.isEmpty(scenarioAssignmentsData) ? (
                        <DataGrid className="my-4v" rows={rows} columns={columns} hideFooterPagination={true} rowHeight={40} rowSelection={false} />
                    ) : (
                        <DataNotFound texts={"Scenario Assignments"}/>
                    )}
                </div>

                <div className="btn-add d-none d-md-block d-lg-block" onClick={() => navigate(ROUTES.CREATE_SCENARIO_ASSIGNMENTS)}>
                    <button className="btn-custom">
                        <img src={WhitePlus} alt="WhitePlus" className="me-3" />
                        Add
                    </button>
                </div>

                <div className="customAdd d-block d-sm-block d-md-none">
                    <div className="add" onClick={() => navigate(ROUTES.CREATE_SCENARIO)}>
                        <img src={PluseIcon} alt={"PluseIcon"} height={"50px"} width={"50px"} />
                    </div>
                </div>
            </MainLayout>

            {showDeleteModal && (
                <DeleteModal
                    deleteId={deleteScenarioAssignmentId}
                    scenarioAssignment={showDeleteModal}
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    refetch={refetch}
                />
            )}
        </div>
    );
};

export default ScenarioAssignments;
