import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import * as _ from "lodash";
import "./style.scss";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import MainLayout from "../../../../components/layouts/MainLayout";
import Elips from "../../../../assets/images/action/elips.svg";
import DropDowneArrow from "../../../../assets/images/action/arrow-downe.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { API } from "../../../../utils/constants/variables/apiFields";
import { defaultValues } from "../../../../utils/constants/forms/defaultValues";
import { validation } from "../../../../utils/constants/forms/validation";
import SelectResponse from "../../../../components/shared/SelectOptions/SelectResponse";
import SelectIncident from "../../../../components/shared/SelectOptions/SelectIncident";
import SelectAssignment from "../../../../components/shared/SelectOptions/SelectAssignment";
import SelectApparatus from "../../../../components/shared/SelectOptions/SelectApparatus";
import { useCreateScenarioMutation, useUpdateScenarioMutation } from "../../../../api/scenario";
import { SelectStyle } from "../../../../components/shared/Options/styles/SelectStyle";

const CreateEditscenario = () => {
    const [selectResponseTypeId, setSelectResponseTypeId] = useState([]);
    const [selectIncidentTypeId, setSelectIncidentTypeId] = useState([]);
    const [selectAssignmentId, setSelectAssignmentId] = useState([]);
    const [selectApparatusId, setSelectApparatusId] = useState([]);

    const [isChecked, setIsChecked] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const scenarioDetail = location.state?.scenarioDetail;
    const scenarioId = scenarioDetail?.id;

    const handleResponseChange = (e) => {
        setSelectResponseTypeId(e);
        setValue("responseTypeId", e);
        setSelectIncidentTypeId([]);
        setValue("incidentTypeId", null);
        setSelectAssignmentId([]);
        setValue("assignmentId", null);
    };

    const handleIncidentChange = (e) => {
        setSelectIncidentTypeId(e);
        setValue("incidentTypeId", e);
        setSelectAssignmentId([]);
        setValue("assignmentId", null);
    };

    const handleApparatusChange = (e) => {
        setSelectApparatusId(e);
        setValue("selectApparatus", e);
    };

    const handleSwitchChange = () => {
        setIsChecked(!isChecked);
    };

    const handleAssignmentChange = (e) => {
        setSelectAssignmentId(e);
        setValue("assignmentId", e);
    };

    const [createScenario] = useCreateScenarioMutation();
    const [updateScenario] = useUpdateScenarioMutation();

    const form = useForm({
        defaultValues: defaultValues.SCENARIO,
        resolver: yupResolver(validation.SCENARIO),
    });

    const {
        handleSubmit,
        formState: { errors },
        register,
        control,
        setValue,
        watch,
    } = form;

    useEffect(() => {
        if (scenarioDetail) {
            setValue("scenarioId", scenarioDetail?.id);
            setValue("responseTypeId", scenarioDetail?.responseTypeId);
            setValue("incidentTypeId", scenarioDetail?.incidentTypeId);
            setValue("assignmentId", scenarioDetail?.assignmentId);
            setValue("responseTypeName", scenarioDetail?.responseTypeName);
            setValue("incidentTypeName", scenarioDetail?.incidentTypeName);
            setValue("assignmentName", scenarioDetail?.assignmentName);
            setValue("scenarioProjectLead", scenarioDetail?.scenarioProjectLead);
            setValue("goal", scenarioDetail?.goal);
            setValue("incidentAddress", scenarioDetail?.incidentAddress);
            setValue("occupantStatus", scenarioDetail?.occupantStatus);
            setValue("locationAndExtentOfTheFire", scenarioDetail?.locationAndExtentOfTheFire);
            setValue("burningRegimeAndExposures", scenarioDetail?.burningRegimeAndExposures);
            setValue("building", scenarioDetail?.building);
            setValue("weather", scenarioDetail?.weather);
            setValue("narrative", scenarioDetail?.narrative);
            setValue("communicationDispatch", scenarioDetail?.communicationDispatch);
            setValue("resources", scenarioDetail?.resources);
            setValue("selectApparatus", scenarioDetail?.selectApparatus);
            setValue("missionBriefing", scenarioDetail?.missionBriefing);
            setValue("publish", scenarioDetail?.publish);
        }
    }, [setValue, scenarioDetail]);

    const onSubmit = async (data) => {
        const scenarioId = scenarioDetail?.id;
        const response = scenarioId ? await updateScenario(data) : await createScenario(data);
        if (response?.data?.status === "success") {
            toast.success(response?.data?.message, { autoClose: 1000 });
            navigate(`/scenario`);
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };

    return (
        <MainLayout className="me-0">
            <div className="overflow_scroll">
                <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex align-items-center float-end my-3 me-3">
                                <Controller
                                    control={control}
                                    name={API.SCENARIO.PUBLISH}
                                    render={({ field: { onChange, value } }) => {
                                        return (
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="custom-switch"
                                                label="Publish"
                                                className="customSwitch"
                                                checked={value}
                                                value={value}
                                                onChange={onChange}
                                                reverse
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-lg-4 col-12 mb-2">
                            <div className="mb-1 d-flex align-items-center">
                                <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                    Response type
                                </Form.Label>
                            </div>
                            <div>
                                <Controller
                                    control={control}
                                    name={`responseTypeId`}
                                    render={({ field: { onChange, value } }) => {
                                        return (
                                            <>
                                                <SelectResponse
                                                    style={SelectStyle}
                                                    isClearable={true}
                                                    defaultResponse={value}
                                                    handleChange={(selectedOption) => {
                                                        onChange(selectedOption || null);
                                                        handleResponseChange(selectedOption);
                                                    }}
                                                />
                                            </>
                                        );
                                    }}
                                />
                            </div>
                            <div className="error-msg">{errors?.responseTypeId && <span className="text-danger">{errors?.responseTypeId?.message}</span>}</div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-12 mb-2">
                            <div className="mb-1 d-flex align-items-center">
                                <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                    Incident type
                                </Form.Label>
                            </div>
                            <div>
                                <Controller
                                    control={control}
                                    name={`incidentTypeId`}
                                    render={({ field: { onChange, value } }) => {
                                        return (
                                            <>
                                                <SelectIncident
                                                    style={SelectStyle}
                                                    isClearable={true}
                                                    defaultIncident={value}
                                                    handleChange={(selectedOption) => {
                                                        onChange(selectedOption || null);
                                                        handleIncidentChange(selectedOption);
                                                    }}
                                                    responseId={selectResponseTypeId?.length > 0 ? selectResponseTypeId : scenarioDetail?.responseTypeId}
                                                />
                                            </>
                                        );
                                    }}
                                />
                            </div>
                            <div className="error-msg">{errors?.incidentTypeId && <span className="text-danger">{errors?.incidentTypeId?.message}</span>}</div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-12 mb-2">
                            <div className="mb-1 d-flex align-items-center">
                                <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                    Assignments
                                </Form.Label>
                            </div>
                            <div>
                                <Controller
                                    control={control}
                                    name={`assignmentId`}
                                    render={({ field: { onChange, value } }) => {
                                        return (
                                            <>
                                                <SelectAssignment
                                                    style={SelectStyle}
                                                    isClearable={true}
                                                    defaultAssignment={value}
                                                    handleChange={(selectedOption) => {
                                                        onChange(selectedOption || null);
                                                        handleAssignmentChange(selectedOption);
                                                    }}
                                                    incidentTypeId={selectIncidentTypeId?.length > 0 ? selectIncidentTypeId : scenarioDetail?.incidentTypeId}
                                                />
                                            </>
                                        );
                                    }}
                                />
                            </div>
                            <div className="error-msg">{errors?.assignmentId && <span className="text-danger">{errors?.assignmentId?.message}</span>}</div>
                        </div>
                        <div className="col-12">
                            <div className="frm-input mb-2">
                                <Form.Group>
                                    <div className="d-flex align-items-center">
                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                        <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                            Scenarios Project lead
                                        </Form.Label>
                                    </div>
                                    <Form.Control
                                        name={API.SCENARIO.SCENARIO_PROJECT_LEAD}
                                        {...register(API.SCENARIO.SCENARIO_PROJECT_LEAD)}
                                        type="text"
                                        className="custom-input"
                                    />
                                </Form.Group>
                                <div className="error-msg">
                                    {errors?.scenarioProjectLead && <span className="text-danger">{errors?.scenarioProjectLead?.message}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="frm-input mb-2">
                                <Form.Group>
                                    <div className="d-flex align-items-center">
                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                        <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                            Goal
                                        </Form.Label>
                                    </div>
                                    <Form.Control name={API.SCENARIO.GOAL} {...register(API.SCENARIO.GOAL)} type="text" className="custom-input" />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="frm-input mb-2">
                                <Form.Group>
                                    <div className="d-flex align-items-center">
                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                        <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                            Incident address
                                        </Form.Label>
                                    </div>
                                    <Form.Control
                                        name={API.SCENARIO.INCIDENT_ADDRESS}
                                        {...register(API.SCENARIO.INCIDENT_ADDRESS)}
                                        type="text"
                                        className="custom-input"
                                    />
                                </Form.Group>
                                <div className="error-msg">
                                    {errors?.incidentAddress && <span className="text-danger">{errors?.incidentAddress?.message}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="frm-input mb-2">
                                <Form.Group>
                                    <div className="d-flex align-items-center">
                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                        <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                            Occupant status
                                        </Form.Label>
                                    </div>
                                    <Form.Control
                                        name={API.SCENARIO.OCCUPANT_STATUS}
                                        {...register(API.SCENARIO.OCCUPANT_STATUS)}
                                        type="text"
                                        className="custom-input"
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="frm-input mb-2">
                                <Form.Group>
                                    <div className="d-flex align-items-center">
                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                        <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                            Location and extent of the Fire
                                        </Form.Label>
                                    </div>
                                    <Form.Control
                                        name={API.SCENARIO.LOCATION_AND_EXTENT_OF_THE_FIRE}
                                        {...register(API.SCENARIO.LOCATION_AND_EXTENT_OF_THE_FIRE)}
                                        type="text"
                                        className="custom-input"
                                    />
                                </Form.Group>
                                <div className="error-msg">
                                    {errors?.locationAndExtentOfTheFire && <span className="text-danger">{errors?.locationAndExtentOfTheFire?.message}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="frm-input mb-2">
                                <Form.Group>
                                    <div className="d-flex align-items-center">
                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                        <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                            Burning Regime and Exposures
                                        </Form.Label>
                                    </div>
                                    <Form.Control
                                        name={API.SCENARIO.BURNING_REGIME_AND_EXPOSURES}
                                        {...register(API.SCENARIO.BURNING_REGIME_AND_EXPOSURES)}
                                        type="text"
                                        className="custom-input"
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="frm-input mb-2">
                                <Form.Group>
                                    <div className="d-flex align-items-center">
                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                        <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                            Building
                                        </Form.Label>
                                    </div>
                                    <Form.Control name={API.SCENARIO.BUILDING} {...register(API.SCENARIO.BUILDING)} type="text" className="custom-input" />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="frm-input mb-2">
                                <Form.Group>
                                    <div className="d-flex align-items-center">
                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                        <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                            Weather
                                        </Form.Label>
                                    </div>
                                    <Form.Control name={API.SCENARIO.WEATHER} {...register(API.SCENARIO.WEATHER)} type="text" className="custom-input" />
                                </Form.Group>
                                <div className="error-msg">{errors?.weather && <span className="text-danger">{errors?.weather?.message}</span>}</div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="frm-input mb-2">
                                <Form.Group>
                                    <div className="d-flex align-items-center">
                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                        <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                            Narrative
                                        </Form.Label>
                                    </div>
                                    <Form.Control name={API.SCENARIO.NARRATIVE} {...register(API.SCENARIO.NARRATIVE)} type="text" className="custom-input" />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="frm-input mb-2">
                                <Form.Group>
                                    <div className="d-flex align-items-center">
                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                        <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                            Communication dispatch
                                        </Form.Label>
                                    </div>
                                    <Form.Control
                                        name={API.SCENARIO.COMMUNICATION_DISPATCH}
                                        {...register(API.SCENARIO.COMMUNICATION_DISPATCH)}
                                        type="text"
                                        className="custom-input"
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="frm-input mb-2">
                                <Form.Group>
                                    <div className="d-flex align-items-center">
                                        <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                        <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                            Resources
                                        </Form.Label>
                                    </div>
                                    <Form.Control name={API.SCENARIO.RESOURCES} {...register(API.SCENARIO.RESOURCES)} type="text" className="custom-input" />
                                </Form.Group>
                            </div>
                            {/* <div className="frm-input mb-2">
                                <Form.Group className="d-flex">
                                    <Form.Control
                                        as="select"
                                        id="selectAppartus"
                                        className="w-100 custom-input"
                                        name={API.SCENARIO.SELECT_APPARATUS}
                                        {...register(API.SCENARIO.SELECT_APPARATUS)}
                                        placeholder="Select Apparatus"
                                        isSearchable={false}
                                    >
                                        <option value="" label="Select Apparatus" disabled />
                                        <option value="fireFighter">Fire fighter</option>
                                        <option value="officer">Officer</option>
                                        <option value="chiefOfficer">Chief officer</option>
                                        <option value="combinations">Combinations</option>
                                    </Form.Control>
                                    <Form.Label className="dropdown" htmlFor="selectAppartus">
                                        <img src={DropDowneArrow} alt={"DropDowneArrow"} height={"52px"} width={"52px"} />
                                    </Form.Label>
                                </Form.Group>

                                <div className="error-msg">
                                    {errors?.selectApparatus && <span className="text-danger">{errors?.selectApparatus?.message}</span>}
                                </div>
                            </div> */}
                        </div>

                        <div className="col-12 my-2">
                            <div className="mb-1 d-flex align-items-center">
                                <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                    Select Apparatus
                                </Form.Label>
                            </div>
                            <div>
                                <Controller
                                    control={control}
                                    name={`selectApparatus`}
                                    render={({ field: { onChange, value } }) => {
                                        return (
                                            <>
                                                <SelectApparatus
                                                    style={SelectStyle}
                                                    isClearable={true}
                                                    defaultApparatus={value}
                                                    handleChange={(selectedOption) => {
                                                        onChange(selectedOption || null);
                                                        handleApparatusChange(selectedOption);
                                                    }}
                                                />
                                            </>
                                        );
                                    }}
                                />
                            </div>
                            <div className="error-msg">
                                {errors?.selectApparatus && <span className="text-danger">{errors?.selectApparatus?.message}</span>}
                            </div>
                        </div>
                        <div className="col-12">
                            <Form.Group>
                                <div className="d-flex align-items-center">
                                    <img src={Elips} alt={"Elips"} className="me-2" height={"13px"} width={"13px"} />
                                    <Form.Label htmlFor="basic-url" className="mb-0 custom-frmlabel">
                                        Mission briefing
                                    </Form.Label>
                                </div>
                                <Form.Control
                                    name={API.SCENARIO.MISSION_BRIEFING}
                                    {...register(API.SCENARIO.MISSION_BRIEFING)}
                                    as="textarea"
                                    className="custom-textarea"
                                    style={{ height: "100px" }}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-12 d-flex align-items-center justify-content-center my-3">
                            {!scenarioId ? (
                                <button type="submit" className="btn-save m-2">
                                    Save
                                </button>
                            ) : (
                                <button type="submit" className="btn-save m-2">
                                    Update
                                </button>
                            )}
                            <button type="button" className="btn-cancel m-2" onClick={() => navigate(`/scenario`)}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
        </MainLayout>
    );
};

export default CreateEditscenario;
