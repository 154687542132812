import { createApi } from "@reduxjs/toolkit/query/react";
import { api } from "../utils/constants/url";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { showErrorToast } from "../utils/constants/api/toast";

const tagTypes = "TacticalDecisionGame";
const type = "TacticalDecisionGame";
export const tacticalDecisionGameApi = createApi({
    reducerPath: "tacticalDecisionGameApi",
    baseQuery: baseQueryWithAuthInterceptor({
        baseUrl: `${api.baseURL}/tacticalDecisionGame`,
        prepareHeaders,
    }),
    tagTypes: [tagTypes],
    endpoints: (builder) => ({
        getTacticalDecisionGame: builder.query({
            query: (tdgLibraryId) => ({
                url: `/getTacticalDecisionGame/${tdgLibraryId}`,
                method: "GET",
            }),
            providesTags: [type],
        }),
        createTacticalDecisionGame: builder.mutation({
            query: (body) => {
                return {
                    url: "/createTacticalDecisionGame",
                    method: "POST",
                    body,
                };
            },
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        updateTacticalDecisionGame: builder.mutation({
            query: (payload) => {
                const { tacticalDecisionGameId, formData } = payload;
                return {
                    url: `/updateTacticalDecisionGame/${tacticalDecisionGameId}`,
                    method: "PUT",
                    body: formData,
                };
            },
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
        deleteTacticalDecisionGame: builder.mutation({
            query: (id) => ({
                url: `/deleteTacticalDecisionGame/${id}`,
                method: "DELETE",
            }),
        }),
        updateTacticalDecisionGamePublish: builder.mutation({
            query: (data) => {
                const { id, ...body } = data;
                return {
                    url: `/updateTacticalDecisionGamePublish/${id}`,
                    method: "PUT",
                    body,
                };
            },
            transformErrorResponse: (response, meta, arg) => {
                if (response?.status === 400) {
                    showErrorToast(response?.data?.message);
                }
                return response;
            },
            invalidatesTags: (result, error, arg) => !error && [type],
        }),
    }),
});

export const {
    useGetTacticalDecisionGameQuery,
    useCreateTacticalDecisionGameMutation,
    useUpdateTacticalDecisionGameMutation,
    useDeleteTacticalDecisionGameMutation,
    useUpdateTacticalDecisionGamePublishMutation,
} = tacticalDecisionGameApi;
