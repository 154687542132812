import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import * as _ from "lodash";
import { useForm, Controller } from "react-hook-form";
import { defaultValues } from "../../../../../utils/constants/forms/defaultValues";
import { toast } from "react-toastify";
import DataNotFound from "../../../../../components/shared/DataNotFound/DataNotFound";
import { BiImport } from "react-icons/bi";
import { useImportMutation } from "../../../../../api/importData";

const ImportActionList = (props) => {
    const importActionListData = props.importActionListData;
    const actionKeyId = props.actionKeyId;
    const [importData] = useImportMutation();
    const [importActionList, setImportActionList] = useState([]);
    const [arrayOfIds, srtArrayOfIds] = useState([]);
    const [type, setType] = useState("actionList");

    const form = useForm({
        defaultValues: defaultValues.RESPONSE_TYPES,
    });

    const { handleSubmit, control } = form;

    const onSubmit = async (data) => {
        const payload = {
            arrayOfIds,
            type,
            actionKeyId,
        };
        const response = await importData(payload);
        if (response?.data?.status === "success") {
            setImportActionList(response?.data?.data);
            props.onHide();
            props.refetchImportData();
            toast.success(response?.data?.message, { autoClose: 1000 });
        } else {
            toast.error(response?.error?.data?.message, { autoClose: false });
        }
    };
    const ActionListData = _.map(importActionListData, (item) => {
        return {
            actionListId: _.get(item, "_id", ""),
            actionKeyId: _.get(item, "actionKeyId", ""),
            name: _.get(item, "name", ""),
            recordExists: _.get(item, "recordExists", ""),
        };
    });

    console.log("data ====", arrayOfIds);
    return (
        <div>
            <div className="response-type">
                <Modal {...props} size="lg" centered aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Body className="p-3">
                        <div className="my-2 text-center">
                            <div className="d-flex align-items-center justify-content-center">
                                <button className="btn-custom me-3">Incident types</button>
                            </div>
                        </div>
                        <div className="col-12 import-dataList">
                            {!_.isEmpty(ActionListData) ? (
                                <>
                                    <div className="col-12 mb-2">
                                        <span className="title-text">List</span>
                                    </div>
                                    <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="import-item">
                                            <div className="row">
                                                {ActionListData.map((item, index) => {
                                                    return (
                                                        <div key={index + 1} className="col-12 col-lg-6 my-2">
                                                            <div className="item-list">
                                                                <div class="d-flex gap-2 align-items-center">
                                                                    <div class="p-2 left-rect"></div>
                                                                    <div class="p-2 flex-grow-1 d-block text-truncate">
                                                                        <OverlayTrigger
                                                                            placement="bottom"
                                                                            overlay={
                                                                                <Tooltip id={`tooltip-bottom`} className="custom-tooltip">
                                                                                    {item.name}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span className="text-fs">{item.name}</span>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    <div class="ms-auto">
                                                                        <div className="right-rect d-flex justify-content-center align-items-center">
                                                                            <div className="add-import d-flex justify-content-center align-items-center">
                                                                                <Controller
                                                                                    className="d-flex align-items-center"
                                                                                    control={control}
                                                                                    name="actionListId"
                                                                                    render={({ field: { onChange, value } }) => {
                                                                                        return (
                                                                                            <Form.Check // prettier-ignore
                                                                                                type="checkbox"
                                                                                                id="custom-check"
                                                                                                className="fs-5 custom-check p-0"
                                                                                                disabled={item.recordExists}
                                                                                                checked={
                                                                                                    item.recordExists || arrayOfIds.includes(item.actionListId)
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    const isChecked = e.target.checked;
                                                                                                    if (isChecked) {
                                                                                                        srtArrayOfIds((prevItems) => [
                                                                                                            ...prevItems,
                                                                                                            item.actionListId,
                                                                                                        ]);
                                                                                                    } else {
                                                                                                        srtArrayOfIds((prevItems) =>
                                                                                                            prevItems.filter(
                                                                                                                (itemId) => itemId !== item.actionListId
                                                                                                            )
                                                                                                        );
                                                                                                    }
                                                                                                    onChange(e);
                                                                                                }}
                                                                                                reverse
                                                                                            />
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>

                                        <div className="my-4 d-flex justify-content-center">
                                            <button type="submit" className="btn-custom d-flex align-items-center">
                                                <span className="me-2" style={{ fontWeight: "700" }}>
                                                    <BiImport />
                                                </span>
                                                <span>Import</span>
                                            </button>
                                        </div>
                                    </Form>
                                </>
                            ) : (
                                <DataNotFound texts={"Action List"} />
                            )}
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
};

export default ImportActionList;
